import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

import Dollars from '../../templates/Dollars/Dollars'
import './TableContainer.css'

import { Button, Col, CustomInput, Input, Row } from 'reactstrap'

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center">
            <div className="mr-2">Filter:</div>
            <input
                className="form-control"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
                placeholder={`${count} records...`}
            />
        </div>
    )
}

export default function TableContainer({ reportType, data, columns, totals, timeRatio }) {
    const {
        getTableProps,
        getTableBodyProps,
        headers,
        //rows,
        page,
        prepareRow,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 100 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value))
    }

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    /*
     * Depending on different reports, there could be different type of totals
     */

    let totalsRow

    if (reportType && reportType === 'merchant margin') {
        let totalNetEarnings = 0,
            totalAffEarnings = 0,
            totalMargin = 0

        page.forEach((item) => {
            totalNetEarnings = totalNetEarnings + item.values.network_earnings
            totalAffEarnings = totalAffEarnings + item.values.affiliate_earnings
        })

        totalMargin = totalNetEarnings - totalNetEarnings

        totalsRow = (
            <tr className="font-weight-bold">
                <td colSpan="2">Totals</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totalNetEarnings)}</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totalAffEarnings)}</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totalMargin)}</td>
                <td align="right">{parseFloat(totalMargin ? (totalMargin / totalNetEarnings) * 100 : 0).toFixed(2)}%</td>
                <td align="right">&nbsp;</td>
            </tr>
        )
    } else if (reportType && (reportType === 'affiliate trend' || reportType === 'campaign trend')) {
        let classes = `text-right `
        let delta = <span></span>
        let grand_deltap

        try {
            grand_deltap = ((totals.grand_D1 - totals.grand_D2) / totals.grand_D2) * 100
        } catch (error) {
            grand_deltap = 0
        }

        // Delta sign for positive or negative number
        if (grand_deltap < 0) {
            delta = <span style={{ color: 'red', fontSize: '10px' }}>&#9660;</span>
            classes += `text-danger`
        } else if (grand_deltap > 0) {
            delta = <span style={{ color: 'green', fontSize: '10px' }}>&#9650;</span>
            classes += `text-success`
        } else {
            delta = ''
        }

        totalsRow = (
            <tr>
                <td>&nbsp;</td>
                <td>
                    <b>Grand Totals:</b>
                </td>
                <td>
                    <b>
                        <div className='text-right'>
                            <Dollars value={totals.grand_projected_delta} />
                        </div>
                    </b>
                </td>
                <td>
                    <b>
                        <div className="text-right">
                            <Dollars value={totals.grand_projected} />
                        </div>
                    </b>
                </td>
                <td>
                    <b>
                        <div className="text-right">
                            <Dollars value={totals.grand_D0} />
                        </div>
                    </b>
                </td>
                <td align="right">
                    <b>
                        <Dollars value={totals['grand_D1']} />
                    </b>
                </td>
                <td align="right">
                    <b>
                        <Dollars value={totals['grand_D2']} />
                    </b>
                </td>
                <td className={classes}>
                    <b>
                        <Dollars value={totals['grand_delta']} />
                    </b>
                </td>
                <td className={classes}>
                    <b>
                        {Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(grand_deltap)} % {delta}
                    </b>
                </td>
                <td className="text-right">
                    {reportType === 'campaign trend' ? (
                        <Link target="_blank" to={`/reports/campaignTrendDetail?oid=0`}>
                            Trends
                        </Link>
                    ) : (
                        <Link target="_blank" to={`/reports/affiliateTrendDetail?aid=0`}>
                            {' '}
                            Trends
                        </Link>
                    )}
                </td>
            </tr>
        )
    } else if (reportType && reportType === 'invoice report') {
        let totalCost = 0

        page.forEach((item) => {
            totalCost = totalCost + item.values.total_cost
        })

        totalsRow = (
            <tr className="font-weight-bold">
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">&nbsp;</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalCost)}</td>
            </tr>
        )
    } else if (reportType && reportType === 'invoices list') {
        totalsRow = <></>
    } else if (reportType && reportType === 'merchant balances') {
        totalsRow = <></>
    } else {
        let totalClicks = 0,
            totalLeads = 0,
            totalSales = 0,
            totalNetEarnings = 0,
            totalAffEarnings = 0,
            totalMargin = 0,
            totalAffEPC = 0

        page.forEach((item) => {
            totalClicks = totalClicks + item.values.clicks
            totalLeads = totalLeads + item.values.leads
            totalSales = totalSales + item.values.sales
            totalNetEarnings = totalNetEarnings + item.values.network_earnings
            totalAffEarnings = totalAffEarnings + item.values.affiliate_earnings
        })

        totalMargin = totalNetEarnings - totalAffEarnings
        totalAffEPC = totalClicks > 0 ? totalNetEarnings / totalClicks : 0

        totalsRow = (
            <tr className="font-weight-bold">
                <td colSpan="2">Totals</td>
                <td align="right">{Intl.NumberFormat('en-GB').format(totalClicks)}</td>
                <td align="right">{Intl.NumberFormat('en-GB').format(totalLeads)}</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalSales)}</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalNetEarnings)}</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAffEarnings)}</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalMargin)}</td>
                <td align="right">{parseFloat(totalMargin ? (totalMargin / totalNetEarnings) * 100 : 0).toFixed(2)}%</td>
                <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalAffEPC)}</td>
                <td align="right">&nbsp;</td>
            </tr>
        )
    }

    return (
        <>
            <table className="table table-striped table-card table-hover mb-0 text-small" {...getTableProps()}>
                <thead>
                    <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        </th>
                    </tr>
                    <tr>
                        {headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <span className="float-right">{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {/* {rows.map((row, i) => { */}
                    {(reportType === 'campaign trend' || reportType === 'affiliate trend') && totalsRow}
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps({ className: cell.column.className })}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    {/* {(reportType && reportType === 'merchant margin') ?

            <tr className="font-weight-bold">
              <td colSpan="2">Totals</td>
              <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.network_earnings)}</td>
              <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.affiliate_earnings)}</td>
              <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.margin)}</td>
              <td align="right">{parseFloat(totals.margin ? (totals.margin / totals.network_earnings) * 100 : 0).toFixed(2)}%</td>
              <td align="right">&nbsp;</td>
            </tr>
            :
            <tr className="font-weight-bold">
              <td colSpan="2">Totals</td>
              <td align="right">{Intl.NumberFormat('en-GB').format(totals.clicks)}</td>
              <td align="right">{Intl.NumberFormat('en-GB').format(totals.leads)}</td>
              <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.sales)}</td>
              <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.network_earnings)}</td>
              <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.affiliate_earnings)}</td>
              <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.margin)}</td>
              <td align="right">{parseFloat(totals.margin ? (totals.margin / totals.network_earnings) * 100 : 0).toFixed(2)}%</td>
              <td align="right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(totals.affiliate_epc)}</td>
              <td align="right">&nbsp;</td>
            </tr>
          } */}
                    {reportType !== 'offer trend' && reportType !== 'postbacks' && totalsRow}
                </tfoot>
            </table>

            {/* // Pagination CSS */}
            <Row className="my-3" style={{ width: 1000, maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
                <Col md={3}>
                    <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="mx-1">
                        {'<<'}
                    </Button>
                    <Button color="primary" onClick={previousPage} disabled={!canPreviousPage} className="mx-1">
                        {'<'}
                    </Button>
                </Col>
                <Col md={2} style={{ marginTop: 7 }}>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </Col>
                <Col md={2}>
                    <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                </Col>
                <Col md={2}>
                    <CustomInput id={pageSize} type="select" value={pageSize} onChange={onChangeInSelect}>
                        {[100, 500, 1000].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </CustomInput>
                </Col>
                <Col md={3}>
                    <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                        {'>'}
                    </Button>
                    <Button color="primary" className="mx-1" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </Button>
                </Col>
            </Row>
        </>
    )
}
