import { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import callAPI from '../../../../helpers/callAPI'
import useCallApi from '../../../../hooks/useCallApi'
import Advertiser from '../../../../templates/Advertiser/Advertiser'
import Loading from '../../../../templates/UI/Loading/Loading'
import AdvertiserNavbar from '../../AdvertiserNavBar/AdvertiserNavBar'

const CreateMapAccount = ({ advertiserId, showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'create_map_account')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [adminExists, setAdminExists] = useState(false)

    const { data, dataLoaded } = useCallApi(`advertiser/${advertiserId}/info`, 'GET', {}, [advertiserId], showSystemMessage)
    const { data: mapData, dataLoaded: mapDataLoaded } = useCallApi(`advertiser/${advertiserId}/mapaccount`, 'GET', {}, [advertiserId], showSystemMessage)

    useEffect(() => {
        if (mapDataLoaded && mapData.success) {
            if (mapData.admin.user_id === 0) {
                setAdminExists(false)
            } else {
                setAdminExists(true)
            }
        }
    }, [mapDataLoaded])

    const submit = async () => {
        const result = await callAPI(`advertiser/${advertiserId}/mapaccount`, 'POST', { email, password })
        if (result.success) {
            showSystemMessage('success', 'Account created successfully')
            setAdminExists(true)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Manage Advertiser</h1>
                <div className="ml-5">{dataLoaded && <AdvertiserNavbar advertiserId={data.info.merchant_id} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="row">
                <div className="col-lg-5">
                    <Card className="mb-3">
                        <Card.Header className="d-flex justify-content-start align-items-center">
                            Add admin user for Advertiser:{' '}
                            {dataLoaded ? (
                                <>
                                    {data.success ? (
                                        <span className="ml-2">
                                            <Advertiser id={data.info.merchant_id} name={data.info.name} status={data.info.status} />
                                        </span>
                                    ) : (
                                        'Advertiser Not Found'
                                    )}
                                </>
                            ) : (
                                <Loading />
                            )}
                        </Card.Header>
                        <Card.Body>
                            {!adminExists ? (
                                <>
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <div className="w-25 text-right pr-2">Email:</div>
                                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={adminExists} />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="w-25 text-right pr-2">Password:</div>
                                        <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} disabled={adminExists} />
                                    </div>
                                </>
                            ) : (
                                <div className="alert alert-info">Admin user {dataLoaded ? <>{data.success ? data.info.name : 'Advertiser Not Found'}</> : ''} already exists for this advertiser. Only one is allowed.</div>
                            )}
                            {!adminExists && (
                                <div className="text-right mt-3">
                                    <Button onClick={submit} size="md">
                                        Submit
                                    </Button>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default CreateMapAccount
