import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useCallApi from '../../../../hooks/useCallApi'

const TrackingPlatforms = ({ platformId, platformSelected }) => {
    const { dataLoaded, data } = useCallApi(`advertisers/platforms`, 'GET', {}, [])
    const spinner = <FontAwesomeIcon icon="spinner" spin />

    return (
        <div>
            <div className="mr-2">
                <select className="form-control" onChange={(e) => platformSelected(e.target.value)} value={platformId} name="tracking_platform" required>
                    <option value=""></option>
                    {dataLoaded &&
                        data.platforms.map((platform) => {
                            return (
                                <option value={platform.platform_id} key={platform.platform_id}>
                                    {platform.platform_name}
                                </option>
                            )
                        })}
                </select>
            </div>
        </div>
    )
}

export default TrackingPlatforms
