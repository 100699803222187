import React from 'react'

export default function RedirectMacros() {
    return (
        <>
            <div className="alert alert-danger">
                You MUST add <strong>&avi=#AVATAR#</strong> if redirecting to another MaxBounty offer.
            </div>
            <div className="alert alert-secondary mt-2">
                Macros:<br></br>
                <code>
                    Event token: #EVENT#<br></br>
                    Avatar Internal Redirect Token: #AVATAR#<br></br>
                    Affiliate ID: #MBAID#<br></br>
                    Click ID: #ACID#<br></br>
                    Subid 1: #SUB1#<br></br>
                    Subid 2: #SUB2#<br></br>
                    Subid 3: #SUB3#<br></br>
                    Subid 4: #SUB4#<br></br>
                    Subid 5: #SUB5#<br></br>
                    mb1: #MB1#<br></br>
                    mb2: #MB2#<br></br>
                    mb3: #MB3#<br></br>
                    mb4: #MB4#<br></br>
                    mb5: #MB5#<br></br>
                    IP Address: #IP#<br></br>
                    User Agent: #USERAGENT#
                </code>
            </div>
        </>
    )
}
