import React from 'react';
import { useRef, useEffect } from 'react';
import { User } from 'react-feather';

const TrackableClick = ({ children, element_type = null, reference_type = null, reference_id = null, reference_name = null }) => {

    const location = window.location.pathname;

    const handleClick = () => {
        //id autoincrement
        let source = 'Admin';
        let platform = 'Web';
        const page = location;
        
        let tracking_url = `${process.env.REACT_APP_TRACKING_API_URL}/mb?source=${source}&platform=${platform}&page=${page}&userID=${localStorage.getItem('mb-aid')}`
        if (element_type){
            tracking_url += `&element_type=${element_type}`
        }
        if (reference_type){
            tracking_url += `&reference_type=${reference_type}`
        }
        if (reference_id){
            tracking_url += `&reference_id=${reference_id}`
        }
        if (reference_name){
            tracking_url += `&reference_name=${encodeURIComponent(reference_name)}`
        }

        return fetch(tracking_url, {
            method: 'GET'                        
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === false) {
                    if (result.code === 401) {
                        return false;
                    } else {
                        return false;
                    }
                }
                return true;
            });
    };

    return (
        <div onClick={handleClick} style={{ display: 'inline-block' }}>{children}</div>
    );
}

export default TrackableClick;