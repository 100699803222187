import { useState } from 'react'
import linkifyHtml from 'linkify-html'

import callAPI from '../../../../../../helpers/callAPI'

const ExtraInfo = ({ affExtraInfo, affiliateId, additionalContacts, affiliateName, showSystemMessage, handleClose, updateExtraInfo }) => {
    const [extraInfo, setExtraInfo] = useState(affExtraInfo)

    const notes = extraInfo ? extraInfo.replace(/(\r\n|\n|\r)/gm, '<br />') : ''
    const linkedNotes = linkifyHtml(notes, {})

    const submitNotes = (e) => {
        e.preventDefault()
        let url = `affiliate/${affiliateId}/accountNotes?source=compliance`

        let postBody = {
            extraInfo: e.target.extra_info.value,
            additional_contacts: e.target.additional_contacts.value,
        }

        callAPI(url, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    // setExtraInfo(tempExtraInfo);
                    showSystemMessage('success', result.message)

                    // Update parent state to see changed info
                    if (e.target.extra_info.value !== '' || e.target.additional_contacts.value !== '') {
                        updateExtraInfo(e.target.extra_info.value, e.target.additional_contacts.value)
                    }

                    handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error while saving extra info: ', error)
                showSystemMessage('error', 'An error occurred while updating account notes')
            })
    }

    return (
        <div className="card">
            <div className="card-header">Extra Info</div>
            <div className="card-body">
                <form onSubmit={submitNotes} method="POST">
                    <table className="table table-card table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    Extra Info for Affiliate: <span className="font-weight-bold">{affiliateName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Extra Info for Affiliate: </td>
                                <td>
                                    <textarea name="extra_info" rows="4" cols="40" className="form-control" defaultValue={linkedNotes} />
                                </td>
                            </tr>
                            <tr align="left" width="100%" valign="top">
                                <td>
                                    Extra E-mails for Batch Send:
                                    <br />
                                    (separated by commas)
                                </td>
                                <td>
                                    <textarea cols="40" rows="2" name="additional_contacts" className="form-control" defaultValue={additionalContacts} />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit" className="btn btn-primary">
                                        Save
                                    </button>
                                    &nbsp; &nbsp;
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    )
}

export default ExtraInfo
