import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import TableContainer from '../TableContainer'
import '../TableContainer.css'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import ReportsMoreAvatar from '../AvatarReportsMore/AvatarReportsMore'
import Dollars from '../../../../templates/Dollars/Dollars'
import useCallApi from '../../../../hooks/useCallApi'

const ReportAffiliate = ({ propertyId = 0, siteId = 0, surveyId = 0, affiliateId = 0, offerId = 0, startDate, endDate }) => {
    const { dataLoaded, data } = useCallApi(`reports/avatar/affiliate?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&offerId=${offerId}`, 'GET', {}, [propertyId, siteId, surveyId, affiliateId, offerId, startDate, endDate])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'AffiliateFilter',
                accessor: 'affiliate.name',
                hide: true,
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status} priority={tableData.data[tableData.row.index].affiliate.priority} />
                },
            },
            {
                Header: 'Property',
                accessor: 'property',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Site',
                accessor: 'site',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Survey',
                accessor: 'survey',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Completions/Starts</div>,
                accessor: 'surveyStats',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                accessor: 'clicks',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Link to={`/avatar/reports/daily?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&offerId=${offerId}&affiliateId=${tableData.data[tableData.row.index].affiliate.id}`}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].clicks)}`}</Link>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                accessor: 'conversions',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Link to={`/avatar/reports/conversions?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&offerId=${offerId}&affiliateId=${tableData.data[tableData.row.index].affiliate.id}`}>{Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}</Link>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Aff Leads</div>,
                accessor: 'payableLeads',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{Intl.NumberFormat('en-GB').format(value)}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Cost</div>,
                accessor: 'cost',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars cost value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Revenue</div>,
                accessor: 'revenue',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                accessor: 'sales',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>eCPA</div>,
                accessor: 'eCPA',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>epc</div>,
                accessor: 'epc',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars bold highlight value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Margin %</div>,
                accessor: 'marginPct',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Contribution %</div>,
                accessor: 'marginContribution',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div className="float-right"></div>,
                accessor: 'leadActionId',
                className: 'text-right',
                Cell: (tableData) => {
                    return (
                        <>
                            <ReportsMoreAvatar avatarAffiliateSummaryReport avatarDailyReport avatarPropertyReport avatarOfferReport avatarSurveyQuestionReport avatarSurveyResponseReport subid avatarConversionReport avatarEventReport avatarSetReport affiliateId={tableData.data[tableData.row.index].affiliate.id} offerId={offerId} propertyId={propertyId} siteId={siteId} surveyId={surveyId} startDate={startDate} endDate={endDate} />
                        </>
                    )
                },
            },
        ]

        if (propertyId < 1) {
            columns.splice(13, 2)
            columns.splice(7, 1)
        }

        return (
            <React.Fragment>
                <TableContainer
                    data={data.report}
                    totals={data.totals}
                    getRowProps={(row) =>
                        row.values.affiliate.offerStatus !== 'A'
                            ? {
                                  className: 'affiliate-offer-rejected',
                              }
                            : ''
                    }
                    columns={columns}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${propertyId}`} forText={data.name} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${startDate}&endDate=${endDate}&propertyId=0&siteId=0&surveyId=0&affiliateId=${affiliateId}&offerId=${offerId}`}></DrilldownItem>}
                {siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${siteId}`} forText={data.site} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=0&surveyId=${surveyId}&affiliateId=${affiliateId}&offerId=${offerId}`}></DrilldownItem>}
                {surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${surveyId}`} forText={data.survey} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=0&affiliateId=${affiliateId}&offerId=${offerId}`}></DrilldownItem>}
                {affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliateId}`} forText={data.affiliate} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=0&offerId=${offerId}`}></DrilldownItem>}
                {offerId > 0 && <DrilldownItem for="Offer" forUrl={`/offer/${offerId}`} forText={data.offer} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&offerId=0`}></DrilldownItem>}
            </div>
            {dataLoaded ? data.report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportAffiliate
