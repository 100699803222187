import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { Bell } from 'react-feather'

const NotificationDropdown = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link nav-link-right dropdown-toggle no-caret">
                <Bell size="18"></Bell>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-dark">
                <div className="dropdown-item">Notification</div>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <div className="dropdown-item">Another Notification</div>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <div className="dropdown-item">Another Notification</div>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <div className="dropdown-item">Another Notification</div>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <div className="dropdown-item">Another Notification</div>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <div className="dropdown-item">Another Notification</div>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <div className="dropdown-item">Another Notification</div>
            </DropdownMenu>
        </Dropdown>
    )
}

export default NotificationDropdown
