import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import TableContainer from '../TableContainer'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const ReportInvoice = (props) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_invoices')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    const { showSystemMessage, startDate, endDate } = props
    // const history = useHistory();

    useEffect(() => {
        const mbToken = localStorage.getItem('adm-auth-token')
        setDataLoaded(false)

        let url = `${process.env.REACT_APP_API_URL}/reports/merchants/invoicereport?startDate=${startDate}&endDate=${endDate}`

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'appliation/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setReport(result.report)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error fetching the data. Please try again')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Erorr in ReportInvoice: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }, [showSystemMessage, startDate, endDate])

    const getReportOutput = () => (
        <React.Fragment>
            <TableContainer
                reportType="invoice report"
                data={report}
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'merchant_id',
                        Cell: ({ cell: { value } }) => {
                            return <>{value}</>
                        },
                    },
                    {
                        Header: 'Merchant',
                        accessor: 'merchant_name',
                        Cell: (tableData) => {
                            return <>{<Link to={`/merfollowup?mid=${tableData.data[tableData.row.index].merchant_id}`}>{tableData.data[tableData.row.index].merchant_name}</Link>}</>
                        },
                    },
                    {
                        Header: 'Offer',
                        accessor: 'offer_name',
                        Cell: (tableData) => {
                            return <> {<Link to={`view_offer?oid=${tableData.data[tableData.row.index].offer_id}`}>{tableData.data[tableData.row.index].offer_name}</Link>} </>
                        },
                    },
                    {
                        Header: 'Rev Criteria',
                        accessor: 'reversal_criteria',
                        Cell: ({ cell: { value } }) => {
                            let revCriteria = 'Other'
                            if (value === 'U') {
                                revCriteria = 'Unknown'
                            } else if (value === 'N') {
                                revCriteria = 'Not Allowed'
                            } else if (value === 'F') {
                                revCriteria = 'Fraud Only'
                            } else if (value === 'I') {
                                revCriteria = 'Fraud/Invalid Leads'
                            }
                            return <div className={value === 'U' ? 'bg-danger' : ''}>{revCriteria}</div>
                        },
                    },
                    {
                        Header: 'Contact',
                        accessor: 'contact',
                        Cell: (tableData) => {
                            return <>{<a href={`mailto:${tableData.data[tableData.row.index].e_mail}`}>{tableData.data[tableData.row.index].contact}</a>}</>
                        },
                    },
                    {
                        Header: () => <div className="float-right">Sales</div>,
                        accessor: 'total_sales',
                        className: 'text-right',
                        Cell: ({ cell: { value } }) => {
                            return <Dollars value={value} />
                        },
                    },
                    {
                        Header: () => <div className="float-right">Rate</div>,
                        accessor: 'network_rate',
                        className: 'text-right',
                        Cell: ({ cell: { value } }) => {
                            return <Dollars value={value} />
                        },
                    },
                    {
                        Header: () => <div className="float-right">Leads</div>,
                        accessor: 'total_leads',
                        className: 'text-right',
                        Cell: ({ cell: { value } }) => {
                            return <>{Intl.NumberFormat('en-GB').format(value)}</>
                        },
                    },
                    {
                        Header: () => <div className="float-right">Cost</div>,
                        accessor: 'total_cost',
                        className: 'text-right',
                        Cell: ({ cell: { value } }) => {
                            return <>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)}</>
                        },
                    },
                ]}
            />
        </React.Fragment>
    )

    return (
        <React.Fragment>
            {/* <hr className="mt-0 mb-3"></hr> */}
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for this parameters.</div> : getReportOutput() : <Loading></Loading>}
        </React.Fragment>
    )
}

export default ReportInvoice
