import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import queryString from 'query-string'

import callAPI from '../../helpers/callAPI'
import useDateRangeFilter from '../../hooks/useDateRangeFilter'
import useQueryParams from '../../hooks/useQueryParams'
import Loading from '../../templates/UI/Loading/Loading'
import DateRangeFilter from '../Reports/Filters/DateRangeFilter/DateRangeFilter'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Dollars from '../../templates/Dollars/Dollars'

import { useAuth, useProtectedPage } from '../../context/AuthContext'

const LeadReversalTicketManager = ({ showSystemMessage }) => {

    document.title = 'Ticket Manager'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'ticket_manager')

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const location = useLocation()
    const history = useHistory()
    let urlParams = queryString.parse(location.search)

    const [dataLoaded, setDataLoaded] = useState(false)
    const [status, setStatus] = useState(urlParams.status ? urlParams.status : 'all')
    const [type, setType] = useState(urlParams.type ? urlParams.type : 'all')
    const [tickets, setTickets] = useState([])
    const [totals, setTotals] = useState({})

    const refreshReport = (e) => {
        e.preventDefault()
        let url = `/admin/tickets/manage?startDate=${selectedStartDate}&endDate=${selectedEndDate}&status=${status}&type=${type}`

        history.push(url)
    }

    // TODO: Have to fix totals alignment with the column
    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Ticket ID',
                accessor: 'ticket_id',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Date',
                accessor: 'db_date',
                Cell: ({ value }) => {
                    return <>{moment(value).utc().format('YYYY/MM/DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Created For',
                accessor: 'created_for',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Created By',
                accessor: 'full_name',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Subject Line',
                accessor: 'subject_line',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Priority',
                accessor: 'priority',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Leads left to Process',
                accessor: 'leads_left_to_process',
                Cell: (tableData) => {
                    return (
                        <>
                            {tableData.data[tableData.row.index].leads_left_to_process ? tableData.data[tableData.row.index].leads_left_to_process : 0} / {tableData.data[tableData.row.index].leads_on_ticket}
                        </>
                    )
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: ({ value }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Success</div>,
                accessor: 'success_cnt',
                className: 'text-right',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Failure</div>,
                accessor: 'failure_cnt',
                className: 'text-right',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => {
                    return <>{value === 'A' ? 'Completed' : value === 'P' ? 'Pending' : 'Unknown'}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={tickets} columns={columns} totals={totals} />
            </React.Fragment>
        )
    }

    useEffect(() => {
        getReport()
    }, [location])

    const getReport = () => {
        setDataLoaded(false)

        let url = `admin/tickets/leads?startDate=${initStartDate}&endDate=${initEndDate}&status=${status}&type=${type}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    let reversals = result.tickets_reversals.map((ticket) => {
                        return {
                            ...ticket,
                            type: 'reversal',
                        }
                    })

                    let modifications = result.tickets_modifications.map((ticket) => {
                        return {
                            ...ticket,
                            type: 'modification',
                        }
                    })

                    let unreversals = result.tickets_unreversals.map((ticket) => {
                        return {
                            ...ticket,
                            type: 'unreversal',
                        }
                    })

                    let tempTickets = [...reversals, ...modifications, ...unreversals]

                    setTickets(tempTickets)
                    setTotals(result.totals)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load followups list.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching lead reversal tickets: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching lead reversal tickets: ${error.message}`)
                setDataLoaded(true)
            })
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Manage Tickets</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />

                            <div className="mr-2">
                                <select className="custom-select custom-select-sm" name="status" onChange={(e) => setStatus(e.target.value)} value={status}>
                                    <option value="all">All Statuses</option>
                                    <option value="pending">Pending Tickets</option>
                                    <option value="completed">Completed Tickets</option>
                                </select>
                            </div>

                            <div className="mr-2">
                                <select className="custom-select custom-select-sm" name="type" onChange={(e) => setType(e.target.value)} value={type}>
                                    <option value="all">All Types</option>
                                    <option value="reversals">Reversals</option>
                                    <option value="modifications">Modifications</option>
                                    <option value="unreversals">Unreversals</option>
                                </select>
                            </div>

                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                    </div>

                    {dataLoaded ? tickets.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                </div>
            </div>
        </div>
    )
}

export default LeadReversalTicketManager
