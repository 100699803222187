import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { Link } from 'react-router-dom'

import callAPI from '../../../../helpers/callAPI'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'

const LandingPagesReview = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [landingPages, setLandingPages] = useState([])

    const { manager, showSystemMessage, limit } = props

    useEffect(async () => {
        let url = `reports/landingPagesReview/${manager.id}/unreviewed`

        let result = await callAPI(url, 'GET')

        if (result.success === false) {
            showSystemMessage('error', 'There was an error while fetching landing pages to review. Please try again.')
        }

        setLandingPages(result.landing_pages)
        setDataLoaded(true)
    }, [manager.id])

    return (
        <div className="card mb-3">
            <div className="card-header justify-content-between d-flex">
                <div className="font-weight-bold float-left">Landing Pages to Check</div>
                <span className="align-items-center">
                    In Queue{' '}
                    <Link to="/" className="ml-1">
                        {landingPages.length}
                    </Link>
                </span>
            </div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    landingPages.length === 0 ? (
                        <div className="p-2 border-top">No stats available</div>
                    ) : (
                        <table className="table table-striped table-card m-0">
                            <thead>
                                <tr className="text-secondary">
                                    <th>Campaign</th>
                                    <th>LP ID</th>
                                    <th>LP Name</th>
                                    <th>Unsafe</th>
                                    <th>Phishing</th>
                                    <th>Malware</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {landingPages.slice(0, limit).map((item, index) => {
                                    return (
                                        <tr key={`${item.id}-${index}`} className={!item.code ? 'bgDanger25' : null}>
                                            <td>
                                                <Campaign oid={item.campaign.id} name={item.campaign.name} />
                                            </td>
                                            <td>{item.id}</td>
                                            <td>{item.landingPage.name}</td>
                                            <td className="text-center">{item.unsafe ? <X color="red" size={18} /> : ''}</td>
                                            <td className="text-center">{item.phishing ? <X color="red" size={18} /> : ''}</td>
                                            <td className="text-center">{item.malware ? <X color="red" size={18} /> : ''}</td>
                                            <td className="text-right">{item.risk_score}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default LandingPagesReview
