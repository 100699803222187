const getTrafficType = (trafficTypeLetter) => {
    let trafficType = ''

    if (trafficTypeLetter === 'I') {
        trafficType = 'Incentive'
    } else if (trafficTypeLetter === 'E') {
        trafficType = 'E-Mail'
    } else if (trafficTypeLetter === 'S') {
        trafficType = 'Display'
    } else if (trafficTypeLetter === 'C') {
        trafficType = 'Contextual'
    } else if (trafficTypeLetter === 'R') {
        trafficType = 'Search'
    } else if (trafficTypeLetter === 'L') {
        trafficType = 'Social'
    } else if (trafficTypeLetter === 'M') {
        trafficType = 'Mobile Ads'
    } else if (trafficTypeLetter === 'N') {
        trafficType = 'Native'
    }
    return trafficType
}

module.exports = getTrafficType
