import React, { useState } from 'react'
import moment from 'moment'

const SotuOffers = (props) => {
    const [open, setOpen] = useState(false)

    const getClass = (delta) => {
        return Math.sign(delta) !== -1 ? 'mb-text-sm text-success' : 'mb-text-sm text-danger'
    }

    const { quarter, off_statusA_count, off_statusB_count, off_statusH_count, off_statusT_count, detailed } = props.quarter

    const nextIndex = props.index + 1

    let off_statusA_delta = 0
    let off_statusB_delta = 0
    let off_statusH_delta = 0
    let off_statusT_delta = 0

    if (props.report[nextIndex]) {
        off_statusA_delta = off_statusA_count - props.report[nextIndex].off_statusA_count
        off_statusB_delta = off_statusB_count - props.report[nextIndex].off_statusB_count
        off_statusH_delta = off_statusH_count - props.report[nextIndex].off_statusH_count
        off_statusT_delta = off_statusT_count - props.report[nextIndex].off_statusT_count
    }

    return (
        <React.Fragment>
            <tr>
                <td className="font-weight-bold">{quarter}</td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(off_statusA_count)}
                    <div className={getClass(off_statusA_delta)}>
                        ({Math.sign(off_statusA_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(off_statusA_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(off_statusB_count)}
                    <div className={getClass(off_statusB_delta)}>
                        ({Math.sign(off_statusB_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(off_statusB_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(off_statusH_count)}
                    <div className={getClass(off_statusH_delta)}>
                        ({Math.sign(off_statusH_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(off_statusH_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(off_statusT_count)}
                    <div className={getClass(off_statusT_delta)}>
                        ({Math.sign(off_statusT_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(off_statusT_delta)})
                    </div>
                </td>
                <td>
                    <span className="badge badge-primary" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                        {open ? '-' : '+'}
                    </span>
                </td>
            </tr>
            {open &&
                detailed.map((record, subIndex) => {
                    let subDelta1 = 0
                    let subDelta2 = 0
                    let subDelta3 = 0
                    let subDelta4 = 0
                    const subNextIndex = subIndex + 1

                    if (detailed[subNextIndex]) {
                        subDelta1 = record.off_statusA_count - detailed[subNextIndex].off_statusA_count
                        subDelta2 = record.off_statusB_count - detailed[subNextIndex].off_statusB_count
                        subDelta3 = record.off_statusH_count - detailed[subNextIndex].off_statusH_count
                        subDelta4 = record.off_statusT_count - detailed[subNextIndex].off_statusT_count
                    } else {
                        // If there is none, let's jump to the first of the LAST QUARTER
                        if (props.report[nextIndex]) {
                            if (props.report[nextIndex].detailed[0]) {
                                subDelta1 = record.off_statusA_count - props.report[nextIndex].detailed[0].off_statusA_count
                                subDelta2 = record.off_statusB_count - props.report[nextIndex].detailed[0].off_statusB_count
                                subDelta3 = record.off_statusH_count - props.report[nextIndex].detailed[0].off_statusH_count
                                subDelta4 = record.off_statusT_count - props.report[nextIndex].detailed[0].off_statusT_count
                            }
                        }
                    }

                    return (
                        <tr className="text-secondary">
                            <td>
                                <div className="pl-3">{moment(record.date).utc().format('YYYY-MM')}</div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.off_statusA_count)}
                                <div className={getClass(subDelta1)}>
                                    ({Math.sign(subDelta1) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta1)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.off_statusB_count)}
                                <div className={getClass(subDelta2)}>
                                    ({Math.sign(subDelta2) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta2)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.off_statusH_count)}
                                <div className={getClass(subDelta3)}>
                                    ({Math.sign(subDelta3) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta3)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.off_statusT_count)}
                                <div className={getClass(subDelta4)}>
                                    ({Math.sign(subDelta4) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta4)})
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    )
                })}
        </React.Fragment>
    )
}

export default SotuOffers
