import React, { Component } from 'react'

class DateSelector extends Component {
    state = {}

    render() {
        let { question, order, options = [], questionId, customMapping, callToAction } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Date Selector</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="mt-3 d-flex m-auto" style={{ maxWidth: '250px' }}>
                    <div className="text-center mx-2">
                        YYYY
                        <input className="form-control text-center" type="text" />
                    </div>
                    <div className="text-center mx-2">
                        MM
                        <input className="form-control text-center" type="text" />
                    </div>
                    <div className="text-center mx-2">
                        DD
                        <input className="form-control text-center" type="text" />
                    </div>
                </div>
                <div className="border rounded p-3 mt-3">
                    Custom Event Mapping
                    <hr></hr>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="custom_event_map" onClick={this.props.handleCustomEventMap(questionId)} checked={customMapping === 'Y' ? true : false} />
                        <label className="custom-control-label" for="custom_event_map">
                            Send range instead of date. Use the smallest date in the range. Example: (0-17, 18-24, 25-34) use (0, 18, 25)
                        </label>
                    </div>
                    {customMapping === 'Y' && (
                        <div className="mt-3">
                            {options.map((option, index) => {
                                return (
                                    <div key={index} className="m-3 d-flex align-items-center">
                                        <div className="mx-3">&gt;</div> <input type="text" className="btn btn-light border btn-lg d-block w-100 btn-text-input" value={option.value} onChange={this.props.handleChangeMultipleChoice(questionId, option.optionId)} />{' '}
                                        {options.length > 0 && (
                                            <button onClick={() => this.props.handleRemoveMultipleChoice(questionId, option.optionId)} className="ml-2 btn btn-danger badge">
                                                X
                                            </button>
                                        )}
                                    </div>
                                )
                            })}
                            <div className="m-3 text-center">
                                <button className="btn btn-warning" onClick={() => this.props.handleAddMultipleChoice(questionId)}>
                                    + Add Choice
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-4">
                    <div className="mt-3">
                        <input className="form-control mb-button-input-text" type="text" defaultValue={callToAction} placeholder="Custom Call to Action" onChange={this.props.handleChangeCallToAction(questionId)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default DateSelector
