import VerifyApplication from "../../Dashboard/components/Applications/VerifyApplication/VerifyApplication"

const VerifyApplicationWrapper = ({ affiliateId, showSystemMessage }) => {    

    document.title = "Verify Application"

    const handleClose = () => {}

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Verify Application</h1>
            </div>
            {/* <div className="row"> */}
            <div>
                <VerifyApplication affiliateId={affiliateId} showSystemMessage={showSystemMessage} handleReviewClose={handleClose} />
            </div>
            {/* </div> */}
        </div>
    )
}

export default VerifyApplicationWrapper
