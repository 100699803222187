import { Card, Modal } from 'react-bootstrap'
import AdvertiserReversalRequestContent from '../../../../components/AdvertiserReversalRequestContent/AdvertiserReversalRequestContent'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import useCallApi from '../../../../hooks/useCallApi'
import callAPI from '../../../../helpers/callAPI'
import { SystemMessageContext } from '../../../../context/SystemMessageContext'
import Loading from '../../../../templates/UI/Loading/Loading'
import moment from 'moment'

const ReversalDetails = ({ advertiserId, reversals_flag }) => {

    const [showModal, setShowModal] = useState(false)
    const [reversalRequestId, setReversalRequestId] = useState(0)
    const { showSystemMessage } = useContext(SystemMessageContext)

    const {data, dataLoaded, refetch} = useCallApi(`advertisers/reversalRequests?advertiserId=${advertiserId}&status=O,R,V`, 'GET', {}, [advertiserId])

    const getReversalDetails = () => {
        switch (reversals_flag) {
            case 'U':
                return 'Unknown'
            case 'L':
                return 'Login for Reversals'
            case 'M':
                return 'Get Reversals from Advertiser'
            case 'F':
                return 'Fraud Pubs Only. Provide Ad-Hoc'
            default:
                return 'Unknown'
        }
    }

    const createReversalRequest = async () => {
        let result = await callAPI(`advertisers/reversalRequests`, 'POST', {advertiserId: advertiserId}, false)
        if (result.success){
            showSystemMessage('success', result.message)
            refetch()
        }
    }

    const openModal = (e, id) => {
        e.preventDefault();
        setShowModal(true);
        setReversalRequestId(id);
    }

    const closeModal = () => {
        setShowModal(false);
        setReversalRequestId(0);
    }

    return (
        <Card className="mt-2">
            <Card.Header>
                <div className="font-weight-bold d-flex justify-content-between align-items-center">
                    <div>Reversals</div>
                    <button className="btn btn-primary btn-sm my-2" onClick={()=>{createReversalRequest()}}>Create Reversal</button>
                </div>
            </Card.Header>
            <Card.Body>
                <div className="alert alert-info"><strong>{getReversalDetails()}</strong></div>
                <div className="mt-2">
                    <Modal show={showModal} size="xl" onHide={() => setShowModal(false)}>
                        <AdvertiserReversalRequestContent advertiserId={advertiserId} reversalRequestId={reversalRequestId} refetch={refetch} closeModal={closeModal} />
                    </Modal>
                    <table className='table striped hover'>
                        <tbody>
                            <tr>
                                <th>Created</th>
                                <th>Status</th>
                                <th>Status Date</th>
                                <th>Opened By</th>
                                <th className="text-right"></th>
                            </tr>                            
                            {
                                dataLoaded ?
                                    data.reversalRequests.map( request => {
                                        return (
                                            <tr key={request.id}>
                                                <td>{moment(request.db_date).utc().format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>{request.status}</td>
                                                <td>{moment(request.status_date).utc().format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>{request.admin_name}</td>
                                                <td className="text-right"><Link to={`/advertiser/${advertiserId}/reversalRequest?id=${request.id}`} className="btn btn-warning btn-sm my-2" onClick={(e)=>{openModal(e, request.id)}}>View</Link></td>
                                            </tr>
                                        )
                                    })                                        
                                :
                                    <tr>
                                        <td colSpan={4}><Loading /></td>
                                    </tr>                                        
                            }                            
                        </tbody>
                    </table>                    
                </div>
            </Card.Body>
        </Card>
    )
}

export default ReversalDetails
