import React, { useState, useEffect } from 'react';
import useCallApi from '../../../hooks/useCallApi';
import Loading from '../../../templates/UI/Loading/Loading';

export default function PhotoId({affiliateId, side, showSystemMessage}){
    
    const [dataLoaded, setDataLoaded] = useState(false)
    const [image, setImage] = useState('')
    const [rotation, setRotation] = useState('normal')

    useEffect(()=>{
        const mbToken = localStorage.getItem('adm-auth-token')

        fetch(`${process.env.REACT_APP_API_URL}/affiliate/${affiliateId}/photoId/${side}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.blob())
            .then((response) => {
                if (!response) {
                    showSystemMessage('error', 'No images found')
                    setDataLoaded(true)
                    return
                }

                setImage(URL.createObjectURL(response))
                setDataLoaded(true)
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'Error while getting ID images')
            })
    },[])

    const getTransformationStyle = () => {
        if (rotation === 'normal') {
            return { transform: 'none', height: '500px', width: '500px' }
        } else if (rotation === 'clockwise') {
            return { transform: 'rotate(90deg)', height: '500px', width: '500px' }
        } else if (rotation === 'counter_clockwise') {
            return { transform: 'rotate(-90deg)', height: '500px', width: '500px' }
        } else if (rotation === 'flip') {
            return { transform: 'rotate(180deg)', height: '500px', width: '500px' }
        }
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Photo Identification - {side}</h1>               
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex align-items-center p-2">
                                Rotation:
                                <button className="btn btn-link shadow-none" onClick={() => setRotation('normal')}>
                                    Normal
                                </button>
                                /
                                <button className="btn btn-link shadow-none" onClick={() => setRotation('clockwise')}>
                                    Clockwise
                                </button>
                                /
                                <button className="btn btn-link shadow-none" onClick={() => setRotation('counter_clockwise')}>
                                    Counter Clockwise
                                </button>
                                /
                                <button className="btn btn-link shadow-none" onClick={() => setRotation('flip')}>
                                    Flip
                                </button>
                            </div>
                            <div className="mt-3 d-flex justify-content-center">{dataLoaded ? <div style={getTransformationStyle()} className="mt-4 mb-4 text-center" ><img src={image} crossOrigin={process.env.REACT_APP_API_URL} alt="Identification Images" width="auto" height="auto" style={{maxWidth: '100%', maxHeight: '100%', margin: 'auto'}} /></div> : <Loading />}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}