import React, { useEffect } from 'react'

const FraudOverrides = (props) => {
    const { fraudOverrides, handleChangeFraudOverride } = props

    useEffect(() => {}, [fraudOverrides])

    return (
        <div className="card mb-3">
            <div className="card-header">
                <h1 className="mb-h1-dark">Traffic Filter Overrides</h1>
            </div>
            <div className="card-body">
                {fraudOverrides.length > 0 ? (
                    <div>
                        <div className="alert alert-secondary">Overriding a filter type will result in posting responses that ONLY get flagged on the overridden filter types for the event. Affiliate payouts will NOT be paid for these events.</div>
                        <div>
                            Send filtered responses if the response ONLY gets flagged for:
                            <hr />
                        </div>
                        <div>
                            {fraudOverrides.map((fraudType) => (
                                <div className="custom-control custom-switch ml-5">
                                    <input type="checkbox" className="custom-control-input" id={`fraud_override_${fraudType.id}`} checked={fraudType.override} onClick={() => handleChangeFraudOverride(fraudType.id)} />
                                    <label className="custom-control-label" for={`fraud_override_${fraudType.id}`}>
                                        {fraudType.fraudType.toUpperCase()}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="alert alert-warning">Fraud settings will be available once the event has been saved.</div>
                )}
            </div>
        </div>
    )
}

export default FraudOverrides
