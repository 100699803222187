/*
    1. URL
    2. method - get/post/delete
    3. requst body if - post component

 */

const callAPI = (url, method, body = {}, isFile) => {
    const mbToken = localStorage.getItem('adm-auth-token')

    let options
    if (method === 'GET') {
        if (isFile) {
            options = {
                method: method,
                credentials: 'include',
                headers: {
                    'x-app-token': mbToken,
                },
            }
        } else {
            options = {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': mbToken,
                },
            }
        }
    } else if (method === 'POST') {
        if (isFile) {
            options = {
                method: method,
                credentials: 'include',
                headers: {
                    'x-app-token': mbToken,
                },
                body: body,
            }
        } else {
            options = {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': mbToken,
                },
                body: JSON.stringify(body),
            }
        }
    } else if (method === 'DELETE') {
        if (isFile) {
            options = {
                method: method,
                credentials: 'include',
                headers: {
                    'x-app-token': mbToken,
                },
                body: body,
            }
        } else {
            options = {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': mbToken,
                },
                body: JSON.stringify(body),
            }
        }
    } else if (method === 'PUT') {
        if (isFile) {
            options = {
                method: method,
                credentials: 'include',
                headers: {
                    'x-app-token': mbToken,
                },
                body: body,
            }
        } else {
            options = {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': mbToken,
                },
                body: JSON.stringify(body),
            }
        }
    }

    // verifyToken().then(result => {
    //     console.log(result);
    //     if(result === false) {
    //         // window.location.reload();
    //     }
    //     return;
    // }); Keeps reloading the same page over and over. Need to re-write the endpoint

    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, options)
        .then((res) => res.json())
        .then((result) => result)
        .catch((err) => ({ success: false, errors: { message: err.message } }))
}

export default callAPI
