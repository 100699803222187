import { useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useCallApi from '../../../hooks/useCallApi'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import AdvertiserReversalRequestContent from '../../../components/AdvertiserReversalRequestContent/AdvertiserReversalRequestContent'
import callAPI from '../../../helpers/callAPI'
import queryString from 'query-string'
import useQueryParams from '../../../hooks/useQueryParams'
import AdvertiserNavbar from '../../Advertiser/AdvertiserNavBar/AdvertiserNavBar'

const ReversalStatusReport = ({ showSystemMessage, advertiserId }) => {
    document.title = 'Advertiser Reversal Status Report'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_reversal_status')

    const location = useLocation()
    let urlParams = queryString.parse(location.search)
    
    const [showModal, setShowModal] = useState(false)
    const [reversalRequestId, setReversalRequestId] = useState(0)
    const [status, setStatus] = useState('O,R,V,C')
    const [adminId, setAdminId] = useState(parseInt(urlParams.adminId) > 0 ? parseInt(urlParams.adminId) : '')
    const [workingAdvertiserId, setWorkingAdvertiserId] = useState(false)

    const { data, dataLoaded, refetch } = useCallApi(`advertisers/reversalStatusReport?adminId=${adminId}&status=${status}&advertiserId=${advertiserId}`, 'GET', {}, [adminId, status, advertiserId], showSystemMessage)

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Advertiser ID',
                accessor: 'advertiser.id',
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.name',
                Cell: ({ cell: { value, row } }) => {
                    return <Advertiser name={value} id={row.original.advertiser.id} />
                },
            },
            {
                Header: 'Manager',
                accessor: 'manager.name',
            },
            {
                Header: 'Last Period Processed',
                accessor: 'last_processed_end_date',
                Cell: ({ cell: { value, row } }) => {
                    return <>{row.original.last_processed_start_date} - {row.original.last_processed_end_date}</>
                },
            },
            {
                Header: 'Created',
                accessor: 'created_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value !== 'Invalid date' ? value : ''}</>
                },
            },
            {
                Header: 'Period',
                accessor: 'end_date',
                Cell: ({ cell: { value, row } }) => {
                    return <>{row.original.start_date} - {row.original.end_date}</>
                },
            },
            {
                Header: 'Requested By',
                accessor: 'created_by',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{ (value === 'Open' || value === 'Reviewing' || value === 'AAM Review' || value === 'Compliance Review') ? <span className="font-weight-bold text-info">{value}</span> : value === 'Archived' ? <span className="text-danger">{value}</span> : value}</>
                },
            },
            {
                Header: 'Status Date',
                accessor: 'status_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value !== 'Invalid date' ? value : ''}</>
                },
            },
            {
                Header: '',
                accessor: 'advertiser',
                Cell: ({ cell: { value, row } }) => {
                    return (
                        <div className="d-flex justify-content-start">
                            {row.original.id > 0 ?
                                <Link to={`/advertiser/${row.original.advertiser.id}/reversalRequest?id=${row.original.id}`} className="btn btn-warning btn-sm my-2" onClick={(e)=>{setWorkingAdvertiserId(row.original.advertiser.id); openModal(e, row.original.id)}}>View</Link>
                            :
                                <button className="btn btn-primary btn-sm my-2" onClick={()=>{createReversalRequest(row.original.advertiser.id)}}>Create Reversal</button>
                            }
                        </div>
                    )
                },
            },
        ]

        return (
            <>
                <ReportTable data={data.report} columns={columns} />
            </>
        )
    }

    const openModal = (e, id) => {
        e.preventDefault();
        setShowModal(true);
        setReversalRequestId(id);
    }

    const closeModal = () => {
        setShowModal(false);
        setReversalRequestId(0);
    }

    const createReversalRequest = async (workingAdvertiserId) => {
        let result = await callAPI(`advertisers/reversalRequests`, 'POST', {advertiserId: workingAdvertiserId}, false)
        if (result.success){
            showSystemMessage('success', result.message)
            refetch()
        }
    }

    return (
        <>
            {
                (advertiserId > 0 && advertiserId !== '') &&
                <div className="page-header d-flex align-items-center">
                    <h1 className="mb-h1">Reversal Request</h1>
                    <div className="ml-5">
                        <AdvertiserNavbar advertiserId={advertiserId} showSystemMessage={showSystemMessage} />
                    </div>
                </div>
            }            
            <Card>
                <Card.Header className="d-flex align-items-center">
                    <h1 className="mb-h1-dark mr-2">Advertisers Reversal Status Report</h1>
                    <div className="d-flex">
                        <div><AdminPicker adminId={adminId} setAdminId={setAdminId} role={['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']} /></div>
                        <select className="form-control form-control-sm ml-2" onChange={(e)=>{ setStatus(e.target.value) }}>
                            <option value="O,R,V,C">To Do</option>
                            <option value="A">Processed</option>
                            <option value="X">Archived</option>
                        </select>
                    </div>
                </Card.Header>
                <Modal show={showModal} size="xl" onHide={() => setShowModal(false)}>
                    <AdvertiserReversalRequestContent advertiserId={workingAdvertiserId} reversalRequestId={reversalRequestId} refetch={refetch} closeModal={closeModal} />
                </Modal>
                {dataLoaded ? data.report.length === 0 ? <div className="p-3">No missing invoice information found.</div> : getReportOutput() : <Loading />}
            </Card>
        </>        
    )
}

export default ReversalStatusReport
