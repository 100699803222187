const Rates = ({ rates }) => {
    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Rates</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped table-hover m-0">
                    <thead>
                        <tr>
                            <th className="border-top-0">Name</th>
                            <th className="border-top-0">Aff Rate</th>
                            <th className="border-top-0">Net Rate</th>
                            <th className="border-top-0">Display</th>
                            <th className="border-top-0">Default</th>
                            <th className="border-top-0">Description</th>
                            <th className="border-top-0" style={{ textDecoration: 'line-through' }}>
                                Sale
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rates
                            .filter((rate) => rate.status === 'A')
                            .map((rate) => {
                                return (
                                    <tr key={rate.rate_name}>
                                        <td>{rate.rate_name}</td>
                                        <td>{rate.affiliate_rate_text}</td>
                                        <td>{rate.network_rate_text}</td>
                                        <td>{rate.display_rate_text}</td>
                                        <td>{rate.default_flag ? 'Yes' : ''}</td>
                                        <td>{rate.description}</td>
                                        <td>{rate.hide_sale_amount ? 'Yes' : ''}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Rates
