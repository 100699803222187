import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Modal } from 'react-bootstrap'
import moment from 'moment'

import RequestToRunCampaign from '../../RequestToRunCampaign/RequestToRunCampaign'
import FraudWatchModal from '../../../../components/FraudWatchModal/FraudWatchModal'
import { useEffect } from 'react'
import callAPI from '../../../../helpers/callAPI'
import AffiliateTestCallbackModal from '../../../../components/AffiliateTestCallbackModal/AffiliateTestCallbackModal'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const AffiliateNavbar = ({ affiliateId, affiliateData, showSystemMessage }) => {
    /*
        PROPS
            affiliateData: this is optional. If it's present, we will not make an API request to get affiliate data since we already have it from the parent.
    */

    const auth = useAuth()
    const showAdvertiserApprovals = useProtectedContent(auth.permissions, 'advertiser_approvals')
    const showCampaignApprovals = useProtectedContent(auth.permissions, 'campaign_approvals')
    const showSpecialRates = useProtectedContent(auth.permissions, 'special_rates')
    const showCallbacks = useProtectedContent(auth.permissions, 'affiliate_callbacks')
    const showCountryBlacklist = useProtectedContent(auth.permissions, 'affiliate_campaign_country_blacklist')
    const showFilterOverrides = useProtectedContent(auth.permissions, 'affiliate_filter_overrides')

    const [statsOpen, setStatsOpen] = useState(false)
    const [accountOpen, setAccountOpen] = useState(false)
    const [trafficOpen, setTrafficOpen] = useState(false)
    const [campaignDataOpen, setCampaignDataOpen] = useState(false)
    const [complianceOpen, setComplianceOpen] = useState(false)
    const [showRequestModal, setShowRequestModal] = useState(false)
    const [showTestCallbackModal, setShowTestCallbackModal] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(affiliateData ? affiliateData : {})
    const [modalData, setModalData] = useState({
        isOpen: false,
        isCreate: false,
    })

    const [fraudWatch, setFraudWatch] = useState({
        id: '',
        affiliate: {
            id: affiliateId,
            name: data.affiliate ? data.affiliate.name : '',
            status: '',
        },
        reason: '',
        frequency: '',
        frequency_start_date: moment().format('YYYY-MM-DD'),
        notes: '',
    })

    const [refetch, setRefetch] = useState(false)

    useEffect(async () => {
        if (affiliateId > 0 && !affiliateData) {
            const mbToken = localStorage.getItem('adm-auth-token')
            if (mbToken) {
                let result = await callAPI(`affiliate/${affiliateId}`, 'GET')

                if (result.success) {
                    setData(result)
                    setFraudWatch({ ...fraudWatch, affiliate: { ...fraudWatch.affiliate, name: result.affiliate.name } })
                }

                setDataLoaded(true)
            }
        } else if (affiliateData.affiliate) {
            setDataLoaded(true)
        }
    }, [affiliateId, affiliateData, refetch])

    const handleClick = (e) => {
        e.preventDefault()
        setShowRequestModal(true)
    }

    const handleClose = (isEdited = false) => {
        setShowRequestModal(false)

        // if (isEdited) {
        //     getReport()
        // }
    }

    return (
        <nav className="mb-header-nav">
            <Modal show={showRequestModal} onHide={handleClose} size="lg">
                <RequestToRunCampaign affiliateId={affiliateId} showSystemMessage={showSystemMessage} handleClose={handleClose} />
            </Modal>
            <FraudWatchModal refetch={() => setRefetch(!refetch)} modalData={modalData} setModalData={setModalData} showSystemMessage={showSystemMessage} />
            <AffiliateTestCallbackModal show={showTestCallbackModal} hide={() => setShowTestCallbackModal(false)} affiliate={data.affiliate} showSystemMessage={showSystemMessage} />
            <ul>
                <li>
                    <Dropdown isOpen={accountOpen} toggle={() => setAccountOpen(!accountOpen)} direction="down">
                        <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={accountOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                            Account
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}`}>
                                Manage Affiliate
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/edit`}>
                                Affiliate Settings
                            </Link>
                            {/* <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate`}>
                                Create Issue
                            </Link> */}
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/accountHistory`}>
                                Account History
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/maxmoney/accountHistory`}>
                                Account History (MaxMoney)
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/loginHistory`}>
                                Login History
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/fraudCheck`}>
                                Fraud Check
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/OTPTokens`}>
                                OTP Tokens
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/referrals`}>
                                Referrals
                            </Link>
                            <DropdownItem divider className="m-0" />
                        </DropdownMenu>
                    </Dropdown>
                </li>
                <li>
                    <Dropdown isOpen={statsOpen} toggle={() => setStatsOpen(!statsOpen)} direction="down">
                        <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={statsOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                            Reports
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link className="dropdown-item" to={`/reports/stats/campaigns?affiliateId=${affiliateId}&withAvatar=true&navigation=affiliate`}>
                                Campaign Earnings
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/reports/stats/daily?affiliateId=${affiliateId}&withAvatar=true&navigation=affiliate`}>
                                Daily Earnings
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/reports/stats/monthly?affiliateId=${affiliateId}&withAvatar=true&navigation=affiliate&startDate=${moment().add(-12, 'months').startOf('month').format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`}>
                                Monthly Earnings
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/reports/stats/conversions?affiliateId=${affiliateId}&navigation=affiliate&startDate=${moment().startOf('day').format('YYYY-MM-DD')}`}>
                                Conversions
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/reports/custom/campaignPromiseReport?affiliateId=${affiliateId}&navigation=affiliate`}>
                                Promise to Run
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/lifetimeEarnings`}>
                                Lifetime Earnings
                            </Link>
                        </DropdownMenu>
                    </Dropdown>
                </li>
                <li>
                    <Dropdown isOpen={campaignDataOpen} toggle={() => setCampaignDataOpen(!campaignDataOpen)} direction="down">
                        <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={campaignDataOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                            Campaign Settings
                        </DropdownToggle>
                        <DropdownMenu>
                            {showAdvertiserApprovals && (
                                <>
                                    <Link className="dropdown-item" to={`/affiliate/${affiliateId}/advertiserApprovals`}>
                                        Advertiser Approvals
                                    </Link>
                                    <DropdownItem divider className="m-0" />
                                </>
                            )}
                            {showCampaignApprovals && (
                                <>
                                    <Link className="dropdown-item" to={`/affiliate/${affiliateId}/campaignApprovals`}>
                                        Campaign Approvals
                                    </Link>
                                    <DropdownItem divider className="m-0" />
                                </>
                            )}
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/paymentDelayOverrides`}>
                                Payment Delay Overrides
                            </Link>
                            <DropdownItem divider className="m-0" />
                            {showCountryBlacklist && (
                                <>
                                    <Link className="dropdown-item" to={`/affiliate/${affiliateId}/campaignCountryOverrides`}>
                                        Campaign Country Blacklist
                                    </Link>
                                    <DropdownItem divider className="m-0" />
                                </>
                            )}
                            {showSpecialRates && (
                                <>
                                    <Link className="dropdown-item" to={`/affiliate/${affiliateId}/specialRates`}>
                                        Special Rates
                                    </Link>
                                    <DropdownItem divider className="m-0" />
                                </>
                            )}
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/campaignCaps`}>
                                Daily Caps
                            </Link>
                            <DropdownItem divider className="m-0" />
                            {showCallbacks && (
                                <>
                                    <Link className="dropdown-item" to={`/affiliate/${affiliateId}/callbacks`}>
                                        Callbacks
                                    </Link>
                                    <DropdownItem divider className="m-0" />
                                </>
                            )}
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/campaignRequests`}>
                                Campaign Requests
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate`} onClick={handleClick}>
                                Request to Run
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/campaignViews`}>
                                Campaign Views
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/campaignSearches`}>
                                Campaign Searches
                            </Link>
                            {showFilterOverrides && (
                                <>
                                    <DropdownItem divider className="m-0" />
                                    <Link className="dropdown-item" to={`/affiliate/${affiliateId}/filterOverrides`}>
                                        Link Filter Overrides
                                    </Link>
                                </>
                            )}
                            {/* <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate`}>
                                Suggested Campaigns
                            </Link> */}
                        </DropdownMenu>
                    </Dropdown>
                </li>
                <li>
                    <Dropdown isOpen={trafficOpen} toggle={() => setTrafficOpen(!trafficOpen)} direction="down">
                        <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={trafficOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                            Traffic
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/analytics`}>
                                Analytics
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/clickReferrers?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`}>
                                Click Referrers
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/clickReferrers?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}&withLeads=true`}>
                                Click Referrers (with leads)
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/leadReferrers?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`}>
                                Lead Referrers
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/clickUserAgents`}>
                                Click User Agents
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/leadUserAgents`}>
                                Lead User Agents
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/leadLocations`}>
                                Lead Locations
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/affiliate/${affiliateId}/callbackHistory`}>
                                Callback History
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link
                                className="dropdown-item"
                                to={`/`}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowTestCallbackModal(true)
                                }}
                            >
                                Test Lead and Callback
                            </Link>
                        </DropdownMenu>
                    </Dropdown>
                </li>
                <li>
                    <Dropdown isOpen={complianceOpen} toggle={() => setComplianceOpen(!complianceOpen)} direction="down">
                        <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={complianceOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                            Compliance
                        </DropdownToggle>
                        <DropdownMenu>
                            {data.affiliate && !data.affiliate.compliance.fraud_watch.enabled ? (
                                <Link
                                    className="dropdown-item"
                                    to={`/`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModalData({ isCreate: true, item: fraudWatch, isOpen: true })
                                    }}
                                >
                                    Add to Fraud Watch
                                </Link>
                            ) : (
                                <Link
                                    className="dropdown-item"
                                    to={`/`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModalData({ isCreate: false, item: data.affiliate.compliance.fraud_watch.details, isOpen: true })
                                    }}
                                >
                                    Edit Fraud Watch
                                </Link>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </li>
            </ul>
        </nav>
    )
}

export default AffiliateNavbar
