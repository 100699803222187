import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../helpers/callAPI'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'

const FlagSettings = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [selectedFlagId, setSelectedFlagId] = useState([])
    const [addFlagId, setAddFlagId] = useState()
    const { id, showSystemMessage, existingFlags, availableFlags, user, updateFlagList, target, refetch } = props

    useEffect(() => {}, [dataLoaded])

    const handleDelete = (flagId) => {
        setSelectedFlagId(flagId)
    }

    const handleConfirmDelete = () => {
        setDataLoaded(false)

        let url

        if (target === 'affiliate') {
            url = `affiliate/${id}/flags`
        } else if (target === 'advertiser') {
            url = `advertiser/${id}/flags`
        } else if (target === 'campaign') {
            url = `campaign/${id}/flags`
        }

        callAPI(url, 'DELETE', { flagId: selectedFlagId })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    updateFlagList()
                    setDataLoaded(true)
                    if (refetch) {
                        refetch()
                    }
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error while deleting the flag. Please try again')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error in FlagSettings: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }

    const addFlag = (event) => {
        event.preventDefault()

        let url

        if (target === 'affiliate') {
            url = `affiliate/${id}/flags`
        } else if (target === 'advertiser') {
            url = `advertiser/${id}/flags`
        } else if (target === 'campaign') {
            url = `campaign/${id}/flags`
        }

        setDataLoaded(false)

        callAPI(url, 'POST', { flagId: addFlagId })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setAddFlagId()
                    updateFlagList()
                    setDataLoaded(true)
                    if (refetch) {
                        refetch()
                    }
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error while saving flag: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }

    const handleFlagChange = (event) => {
        setAddFlagId(event.target.value)
    }

    const getName = () => {
        if (target === 'affiliate') {
            return <Affiliate aid={user.id} name={user.name} status={user.status.value} priority={user.priority.value} />
        } else if (target === 'advertiser') {
            return <Advertiser id={user.id} name={user.name} />
        } else if (target === 'campaign') {
            return <Campaign oid={user.id} name={user.name} status={user.status.value} />
        }
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Flags for {target} : {getName()}
                </Modal.Title>
            </Modal.Header>
            <div className="card">
                <div className="card-body">
                    <table className="table table-card table-borderless table-hover">
                        <tbody>
                            <tr>
                                <td className="font-weight-bold">ID</td>
                                <td className="font-weight-bold">Name</td>
                                <td></td>
                            </tr>
                            {existingFlags.map((flag) => (
                                <tr key={flag.flag_id}>
                                    <td>{flag.flag_id}</td>
                                    <td>{flag.flag_name}</td>
                                    <td>
                                        {selectedFlagId === flag.flag_id ? (
                                            <button type="button" className="btn btn-danger btn-sm" onClick={handleConfirmDelete}>
                                                Confirm
                                            </button>
                                        ) : (
                                            <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => handleDelete(flag.flag_id)}>
                                                Delete
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td></td>
                                <td className="font-weight-bold">Add Flags</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <select name="flags" className="form-control" onChange={handleFlagChange}>
                                        <option value=" ">Select flag to add</option>
                                        {availableFlags.map((flag) => (
                                            <option value={flag.flag_id} key={flag.flag_id}>
                                                {flag.flag_name}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <button type="button" className="btn btn-sm btn-primary" onClick={addFlag}>
                                        Create
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FlagSettings
