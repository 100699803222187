import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Loading from '../../../../templates/UI/Loading/Loading'
import DayPartModal from '../PropertySets/DayPartModal/DayPartModal'
import PropertySetOverrideModal from './Modals/PropertySetOverrideModal'

export default function AvatarPropertySetOverrides({ propertyId, showSystemMessage }) {
    const [property, setProperty] = useState('')
    const [overrides, setOverrides] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [dayPartingModalOpen, setDayPartingModalOpen] = useState(false)
    const [selectedSetForScheduling, setSelectedSetForScheduling] = useState(0)

    const deleteOverride = (overrideId, affiliateId, overrideSiteId, overrideSurveyId) => {
        const authtoken = localStorage.getItem('adm-auth-token')

        fetch(`${process.env.REACT_APP_API_URL}/avatar/property/${propertyId}/overrides`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken,
            },
            body: JSON.stringify({
                override_id: overrideId,
                affiliate_id: affiliateId,
                override_site_id: overrideSiteId,
                override_survey_id: overrideSurveyId,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Override Deleted')
                    setRefresh(!refresh)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                } else {
                    showSystemMessage('error', 'Error fetching data.')
                }
            })
            .catch((error) => {
                console.log('Error deleting override: ', error.message)
                showSystemMessage('error', 'Connection error.')
            })
    }

    useEffect(() => {
        // Get the site/survey information as well as the overrides\

        setDataLoaded(false)

        const authtoken = localStorage.getItem('adm-auth-token')

        fetch(`${process.env.REACT_APP_API_URL}/avatar/property/${propertyId}/overrides`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    setProperty(result.property)
                    setOverrides(result.overrides)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                } else {
                    showSystemMessage('error', 'Error fetching site data.')
                }
            })
            .catch((error) => {
                console.log('Error on Avatar Site/Set Overrides: ', error.message)
                showSystemMessage('error', 'Connection error.')
                setDataLoaded(true)
            })
    }, [refresh])

    const handleScheduleClick = (e) => {
        e.preventDefault()

        setSelectedSetForScheduling(e.target.value)
        setDayPartingModalOpen(true)
    }

    const toggleDayPartModal = (isEdited = false) => {
        setDayPartingModalOpen(false)

        if (isEdited) {
            setRefresh(!refresh)
        }
    }

    return (
        <React.Fragment>
            <div className="page-header">
                <h1 className="mb-h1">Affiliate Site/Survey Set Overrides</h1>
            </div>
            {dayPartingModalOpen && <DayPartModal isOpen={dayPartingModalOpen} closeModal={toggleDayPartModal} selectedSetId={selectedSetForScheduling} propertyId={propertyId} showSystemMessage={showSystemMessage} />}
            {dataLoaded ? (
                <React.Fragment>
                    <div className="mb-2">
                        <div className="card">
                            <div className="p-2 d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div className="mb-h1-dark mr-2">Property:</div>
                                        <div className="text-light">
                                            <Link to={`/avatar/property/${propertyId}`}>{property}</Link>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className="btn btn-warning btn-sm"
                                    onClick={() => {
                                        setModalOpen(!modalOpen)
                                    }}
                                >
                                    + New Override
                                </button>

                                <PropertySetOverrideModal propertyId={propertyId} modalOpen={modalOpen} setModalOpen={setModalOpen} refresh={refresh} setRefresh={setRefresh} showSystemMessage={showSystemMessage} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="card">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Affiliate</th>
                                            <th>Set</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {overrides.map((override) => {
                                            return (
                                                <tr key={override.id}>
                                                    <td>{override.affiliate.id}</td>
                                                    <td>
                                                        <Affiliate aid={override.affiliate.id} name={override.affiliate.name} status={override.affiliate.status.value} priority={override.affiliate.priority.value} />
                                                    </td>
                                                    <td>
                                                        <div className="alert alert-info" style={{ maxWidth: '500px' }}>
                                                            Site: <Link to={`/avatar/site/${override.site.id}`}>{override.site.name}</Link> <br />
                                                            Survey: <Link to={`/avatar/survey/${override.survey.id}`}>{override.survey.name}</Link>
                                                            {override.schedule.length > 0 ? (
                                                                <div>
                                                                    <hr></hr>
                                                                    {override.schedule.map((time) => {
                                                                        return (
                                                                            <small className="d-flex">
                                                                                <div className="mr-2 font-weight-bold">{time.day_display} ..... </div>
                                                                                <div>
                                                                                    <i>
                                                                                        {time.start_time_display}-{time.end_time_display}
                                                                                    </i>
                                                                                </div>
                                                                            </small>
                                                                        )
                                                                    })}
                                                                    <hr></hr>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div className="text-right">
                                                                <button className="btn btn-warning btn-sm mr-2" onClick={handleScheduleClick} value={`${override.site.id}-${override.survey.id}-${override.affiliate.id}`}>
                                                                    Schedule
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => {
                                                                        deleteOverride(override.id, override.affiliate.id, override.site.id, override.survey.id)
                                                                    }}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    )
}
