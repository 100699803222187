import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import callAPI from '../../../helpers/callAPI'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import '../../../templates/ReportTable/ReportTable.css'
import Loading from '../../../templates/UI/Loading/Loading'
import CompleteInvoiceModal from './CompleteInvoiceModal/CompleteInvoiceModal'
import CustomStripeLink from './CustomStripeLink/CustomStripeLink'
import ReceivePaymentModal from './ReceivePaymentModal/ReceivePaymentModal'

import { useAuth, useProtectedPage } from '../../../context/AuthContext'

export default function AdvertiserInvoiceList({ showSystemMessage }) {
    document.title = 'Advertiser Invoices To Do'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_invoices')

    const [dataLoaded, setDataLoaded] = useState(false)
    const location = useLocation()

    const [report, setReport] = useState([])
    const [invoiceStatusFilter, setInvoiceStatusFilter] = useState('')

    const [showCompleteInvoiceModal, setShowCompleteInvoiceModal] = useState(false)
    const [showReceivePaymentModal, setShowReceivePaymentModal] = useState(false)
    const [showCustomStripeModal, setShowCustomStripeModal] = useState(false)
    const [invoiceStatus, setInvoiceStatus] = useState()
    const [advertiserId, setAdvertiserId] = useState()
    const [periodType, setPeriodType] = useState()
    const [date, setDate] = useState()

    useEffect(() => {
        // Because it loads the same route (withLeads), there is odd functionality where the URL is built before withLeads is actually set.
        // To overcome this, we added props.location.search to the Route in App.js. By updating the key, we force a reload of the component.
        getReport()
    }, [location])

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = `advertisers/invoices/list`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.report)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching invoice report: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching invoice report')
            })
    }

    const handleClick = (status, advertiserId, periodType, date) => {
        setInvoiceStatus(status)
        setAdvertiserId(advertiserId)
        setPeriodType(periodType)
        setDate(date)

        if (status === 'P') {
            setShowCompleteInvoiceModal(true)
        } else {
            setShowReceivePaymentModal(true)
        }
    }

    const handleClose = (isEdited = false) => {
        setShowCompleteInvoiceModal(false)
        setShowReceivePaymentModal(false)
        setShowCustomStripeModal(false)
        setInvoiceStatus()
        setAdvertiserId()
        setPeriodType()
        setDate()

        if (isEdited) {
            getReport()
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Type',
                accessor: 'type.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Terms',
                accessor: 'terms',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Invoice Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Period',
                accessor: 'period',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].advertiser.id} name={tableData.data[tableData.row.index].advertiser.name} status={tableData.data[tableData.row.index].advertiser.status.value} />
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.email',
                Cell: (tableData) => {
                    return <a href={`mailto:${tableData.data[tableData.row.index].advertiser.email}`}>Email</a>
                },
            },
            {
                Header: 'Ad Mgr',
                accessor: 'advertiser.manager.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Stats',
                accessor: 'stats.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Reversals',
                accessor: 'reversals.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Inv #',
                accessor: 'invoice_number',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: '',
                accessor: ' ',
                Cell: (tableData) => {
                    return (
                        <div className="d-flex align-items-center">
                            {tableData.data[tableData.row.index].type.value !== 10 ? (
                                <Link className="btn btn-sm btn-link ml-1 m-0 p-0" to={`/advertisers/invoices/report?advertiserId=${tableData.data[tableData.row.index].advertiser.id}&startDate=${tableData.data[tableData.row.index].period_start}&endDate=${tableData.data[tableData.row.index].period_end}`}>
                                    Stats
                                </Link>
                            ) : (
                                <></>
                            )}

                            <button className="btn btn-sm btn-link ml-1 m-0 p-0" onClick={() => handleClick(tableData.data[tableData.row.index].invoice_status.value, tableData.data[tableData.row.index].advertiser.id, tableData.data[tableData.row.index].type.value, tableData.data[tableData.row.index].date)}>
                                {tableData.data[tableData.row.index].invoice_status.value === 'P' ? 'Complete Invoice' : 'Receive Payment'}{' '}
                            </button>
                        </div>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={invoiceStatusFilter === '' ? report : report.filter((item) => item.invoice_status.value === invoiceStatusFilter)} columns={columns} />
            </React.Fragment>
        )
    }

    const handleFilterChange = (event) => {
        event.preventDefault()
        setInvoiceStatusFilter(event.target.value)
    }

    return (
        <div>
            {showCompleteInvoiceModal && <CompleteInvoiceModal isOpen={showCompleteInvoiceModal} status={invoiceStatus} handleClose={handleClose} advertiserId={advertiserId} periodType={periodType} date={date} showSystemMessage={showSystemMessage} />}
            {showReceivePaymentModal && <ReceivePaymentModal isOpen={showReceivePaymentModal} status={invoiceStatus} handleClose={handleClose} advertiserId={advertiserId} periodType={periodType} date={date} showSystemMessage={showSystemMessage} />}
            {showCustomStripeModal && <CustomStripeLink isOpen={showCustomStripeModal} handleClose={handleClose} advertiserId={advertiserId} showSystemMessage={showSystemMessage} />}

            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Invoice Report</h1>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex align-items-center">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">Invoice Status:</h1>
                                    </div>
                                    <div>
                                        <select className="form-control form-control-sm" onChange={handleFilterChange} defaultValue={invoiceStatusFilter}>
                                            <option value="">All</option>
                                            <option value={'P'}>Requests</option>
                                            <option value={'S'}>Awaiting Payment</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-sm btn-warning" onClick={() => setShowCustomStripeModal(true)}>
                                        Custom Stripe Link
                                    </button>
                                </div>
                            </div>
                            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
