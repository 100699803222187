/*
    This is the component that will log you in from OTT Coldfusion.
    Set the url param CFLogin?p= page?param=&param=etc here
*/
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import queryString from 'query-string'
import callAPI from '../../helpers/callAPI'

const CFLogin = ({ location, checkAuthentication }) => {
    const history = useHistory()
    const urlParams = queryString.parse(location.search)

    const page = urlParams.page ? encodeURIComponent(urlParams.page) : ''
    const email = urlParams.e ? urlParams.e : ''
    const password = urlParams.pw ? urlParams.pw : ''

    useEffect(() => {
        const refreshApp = () => {
            history.push(`/${decodeURIComponent(page)}`)
        }

        /* Login Via OTT */
        if (Cookies.get('CF-ADM-TOKEN') !== '' && Cookies.get('CF-ADM-TOKEN') !== undefined) {
            localStorage.setItem('adm-auth-token', Cookies.get('CF-ADM-TOKEN'))
            checkAuthentication()
            refreshApp()
        } else {
            console.log('No OTT Credentials.')

            /* Login via URL */
            const autoLogin = async (email, password) => {
                let data = {
                    email: email,
                    password: password,
                }

                let result = await callAPI(`tempAuthenticationMB`, 'POST', data)
                if (result['success'] === true) {
                    const admAppToken = result['adm-auth-token']
                    console.log(admAppToken)
                    localStorage.setItem('adm-auth-token', admAppToken)
                    checkAuthentication()
                    refreshApp()
                } else {
                    console.log('error', result.errors.message)
                }
            }

            autoLogin(email, password)
            /* Login via URL End*/
        }
    }, [])

    return <div>Logging In</div>
}

export default CFLogin
