import React, { useState, useEffect } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap'

export default function GlobalMacros(){

    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
                <div className="card">           
                    <div className="card-header">Avatar Global Macros</div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Macro</th>
                                    <th>Description</th>
                                    <th>Example</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>#todayDate#</td>
                                    <td>The current date.</td>
                                    <td>December 4th, 2024</td>
                                </tr>
                                <tr>
                                    <td>#ipState#</td>
                                    <td>The visitors state, based on IP.</td>
                                    <td>Alaska</td>
                                </tr>
                                <tr>
                                    <td>#ipCity#</td>
                                    <td>The visitors city, based on IP.</td>
                                    <td>Santa Monica</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
            <button className="btn btn-link d-flex align-items-center" onClick={(e)=>{e.preventDefault(); setShowModal(true)}}><FaQuestionCircle className="mr-1" /> View Macros</button>
        </>
    )
}