import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import { useAuth, useProtectedPage, useSetManager } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import Campaign from '../../../templates/Campaign/Campaign'
import Dollars from '../../../templates/Dollars/Dollars'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import moment from 'moment'

const ReportNewlyRunCampaigns = ({ showSystemMessage }) => {

    document.title = 'Newly Run Campaigns'

    let auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliates_top')
    let showManagerDropdown = useSetManager(auth.permissions, 'affiliates_top')
    //TODO
    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const history = useHistory()
    const location = useLocation()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    useEffect(() => {
        getReport()
    }, [location])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Leads</div>,
                accessor: 'leads',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: (tableData) => {
                    return  <Link to={`/reports/stats/affiliates?withAvatar=true&campaignId=${tableData.data[tableData.row.index].id}&startDate=${moment().add(-30, 'days').format('YYYY-MM-DD')}`} className="text-primary font-weight-bold">
                                <Dollars value={tableData.data[tableData.row.index].margin} />
                            </Link>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    // const refreshReport = (e) => {
    //     e.preventDefault()

    //     let url = buildUrl(`/campaigns/newlyRun?`)
    //     history.push(url)
    // }

    const getReport = () => {
        setDataLoaded(false)

        let url = buildUrl(`campaigns/newlyRun/Monthly?z=1`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.campaigns)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }

                setDataLoaded(true)
            })
            .catch((error) => {
                console.log(`Error while fetching newly run campaigns: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching newly run campaigns')
            })
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reports</h1>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex p-3">
                                <div className="mr-4 d-flex align-items-center">
                                    <h1 className="mb-h1-dark">Newly Run Campaigns last 30 days</h1>
                                </div>
                                {/* {showManagerDropdown && (
                                    <>
                                        <div className="mr-4">
                                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                                Refresh Report
                                            </button>
                                        </div>
                                    </>
                                )} */}
                            </div>
                            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportNewlyRunCampaigns
