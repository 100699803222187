import { Card, Button, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'
import AdvertiserNavbar from '../../AdvertiserNavBar/AdvertiserNavBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Campaign from '../../../../templates/Campaign/Campaign'

const CampaignList = ({ advertiserId, showSystemMessage }) => {
    const { data, dataLoaded, refetch } = useCallApi(`advertiser/${advertiserId}/campaignList`, 'GET', {}, [advertiserId], showSystemMessage)
    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Campaign List</h1>
                <div className="ml-5">
                    <AdvertiserNavbar advertiserId={advertiserId} showSystemMessage={showSystemMessage} updateCampaignList={refetch} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <Card className="mb-3">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <span>
                                <span className="mr-1">Open Campaigns</span>
                            </span>
                        </Card.Header>
                        <Card.Body className="p-0 overflow-auto">
                            <Table striped bordered hover className="m-0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Affiliate Rate</th>
                                        <th>Network Rate</th>
                                        <th>Margin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded && data.success ? (
                                        <>
                                            {data.success && data.openCampaigns.length === 0 ? (
                                                <tr>
                                                    <td colSpan={6}>No closed campaigns Available</td>
                                                </tr>
                                            ) : (
                                                data.openCampaigns.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.offer_id}</td>
                                                            <td>
                                                                <Campaign oid={item.offer_id} name={item.name} status={item.status} />
                                                            </td>
                                                            <td>{item.status}</td>
                                                            <td>{item.affiliate_rate}</td>
                                                            <td>{item.network_rate}</td>
                                                            <td>{parseFloat(item.margin).toFixed(2)}%</td>
                                                        </tr>
                                                    )
                                                })
                                            )}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan="6">
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <span>
                                <span className="mr-1">Closed Campaigns</span>
                            </span>
                        </Card.Header>
                        <Card.Body className="p-0 overflow-auto">
                            <Table striped bordered hover className="m-0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Affiliate Rate</th>
                                        <th>Network Rate</th>
                                        <th>Margin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded && data.success ? (
                                        <>
                                            {data.success && data.closedCampaigns.length === 0 ? (
                                                <tr>
                                                    <td colSpan={6}>No closed campaigns Available</td>
                                                </tr>
                                            ) : (
                                                data.closedCampaigns.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.offer_id}</td>
                                                            <td>
                                                                <Campaign oid={item.offer_id} name={item.name} status={item.status} />
                                                            </td>
                                                            <td>{item.status}</td>
                                                            <td>{item.affiliate_rate}</td>
                                                            <td>{item.network_rate}</td>
                                                            <td>{parseFloat(item.margin).toFixed(2)}%</td>
                                                        </tr>
                                                    )
                                                })
                                            )}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan="6">
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default CampaignList
