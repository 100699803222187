export default function getCreativeName(type, width = null, height = null, id){
    if (type === 'B') {
        return `Banner_${id} (${width}x${height})`
    } else if (type === 'E') {
        return `Email_${id}`
    } else if (type === 'H') {
        return `HTML_${id}`
    } else if (type === 'T') {
        return `Text_${id}`
    }
}