import React, { useCallback, useState } from 'react'
import { Button, Card, Form, Modal, Table } from 'react-bootstrap'
import callApi from '../../helpers/callAPI'
import useCallApi from '../../hooks/useCallApi'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import Loading from '../../templates/UI/Loading/Loading'
import ReportTable from '../../templates/ReportTable/ReportTable'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import { useAuth, useProtectedPage } from '../../context/AuthContext'
import { useRef } from 'react'
import AvatarScrubOverridesModal from '../AvatarScrubOverridesModal/AvatarScrubOverridesModal'

const AvatarScrubOverrides = ({ affiliateId, campaignId, showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'avatar')

    const scrubThresholdRef = useRef([])
    const scrubRateRef = useRef([])

    const [isOpen, setIsOpen] = useState(false)

    const { dataLoaded, data, setData, refetch } = useCallApi(affiliateId ? `affiliate/${affiliateId}/avatarScrubOverrides` : `campaign/${campaignId}/avatarScrubOverrides`, 'GET', {}, [affiliateId, campaignId])

    if (dataLoaded && affiliateId){
        document.title = `${data.affiliate.name}: Avatar Scrub Overrides`
    } else if (dataLoaded && campaignId){
        document.title = `${data.campaign.name}: Avatar Scrub Overrides`
    } else {
        document.title = `Avatar Scrub Overrides`
    }

    const updateOverride = useCallback(
        async (index, affiliateId, campaignId) => {
            let body = {
                affiliateId: affiliateId,
                campaignId: campaignId,               
                threshold: scrubThresholdRef.current[index].value,
                rate: scrubRateRef.current[index].value
            }

            const res = await callApi(affiliateId ? `affiliate/${affiliateId}/avatarScrubOverrides` : `campaign/${campaignId}/avatarScrubOverrides`, 'PUT', body)
            if (res.success) {
                showSystemMessage('success', 'Scrub override updated.')
                refetch()
            } else {
                showSystemMessage('error', res.errors.message)
            }
        },
        [affiliateId, campaignId, refetch, showSystemMessage, data]
    )

    const deleteOverride = useCallback(
        async (index, affiliateId, campaignId) => {

            let body = {
                affiliateId: affiliateId,
                campaignId: campaignId
            }
            
            const res = await callApi(affiliateId ? `affiliate/${affiliateId}/avatarScrubOverrides` : `campaign/${campaignId}/avatarScrubOverrides`, 'DELETE', body)
            if (res.success) {
                showSystemMessage('success', `Scrub override deleted.`)
                refetch()
            } else {
                showSystemMessage('error', res.errors.message)
            }
        },
        [affiliateId, campaignId, refetch, showSystemMessage, data]
    )

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'item_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <>{affiliateId ? <Campaign oid={tableData.data[tableData.row.index].item_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].aff_status} /> : <Affiliate aid={tableData.data[tableData.row.index].item_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].aff_status} priority={tableData.data[tableData.row.index].aff_priority_value} />}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'item_status',
                Cell: (tableData) => {
                    return <>{ affiliateId ? tableData.data[tableData.row.index].status : tableData.data[tableData.row.index].aff_status}</>
                },
            },
            {
                Header: 'Manager',
                accessor: 'full_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },            
            {
                Header: 'Scrub Threshold',
                accessor: 'lead_scrub_threshold',
                Cell: (tableData) => {
                    let threshold = tableData.data[tableData.row.index].lead_scrub_threshold
                    return (
                        <div className="d-flex align-items-center">
                            <Form.Control type="text" size="sm" ref={(el) => (scrubThresholdRef.current[tableData.row.index] = el)} className="w-50" defaultValue={threshold ? threshold : 0} />
                        </div>
                    )
                },
            },
            {
                Header: 'Scrub Rate',
                accessor: 'lead_scrub_rate',
                Cell: (tableData) => {
                    let rate = tableData.data[tableData.row.index].lead_scrub_rate
                    return (
                        <div className="d-flex align-items-center">
                            <Form.Control type="text" size="sm" ref={(el) => (scrubRateRef.current[tableData.row.index] = el)} className="w-50" defaultValue={rate ? rate : 0} />
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'scrub',
                Cell: (tableData) => {
                    return (
                        <div className="d-flex">
                            <Button className="m-1" size="sm" onClick={() => updateOverride(tableData.row.index, affiliateId ? affiliateId : tableData.data[tableData.row.index].item_id, campaignId ? campaignId : tableData.data[tableData.row.index].item_id)}>
                                Save
                            </Button>
                            <Button variant="danger" size="sm" onClick={() => deleteOverride(tableData.row.index, affiliateId ? affiliateId : tableData.data[tableData.row.index].item_id, campaignId ? campaignId : tableData.data[tableData.row.index].item_id)} className="m-1">
                                Delete
                            </Button>
                        </div>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={data.overrides}
                    columns={columns}                    
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Avatar Scrub Overrides</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <Card>
                        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
                            <AvatarScrubOverridesModal setIsOpen={setIsOpen} affiliate={{ ...data.affiliate, dataLoaded }} campaign={{ ...data.campaign, dataLoaded }} refetch={refetch} showSystemMessage={showSystemMessage} />
                        </Modal>
                        <Card.Body className="p-0">
                            <div className="d-flex justify-content-between p-3 align-items-center">
                                <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliateId ? <Affiliate aid={affiliateId} name={data.affiliate.name} priority={data.affiliate.priority.value} status={data.affiliate.status.value} /> : <Campaign oid={campaignId} name={data.campaign.name} /> : null}</h1>
                                <Button variant="warning" size="sm" onClick={() => setIsOpen(true)}>
                                    Add Scrub Override
                                </Button>
                            </div>
                            {dataLoaded ? data.overrides.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default AvatarScrubOverrides