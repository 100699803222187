import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../../../templates/UI/Loading/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropertySetModal from './PropertySetModal/PropertySetModal'
import DayPartModal from './DayPartModal/DayPartModal'

class PropertySets extends Component {
    state = {
        dataLoaded: true,
        propertySetModalOpen: false,
        showDeleteConfirmId: 0,
        showDeleteConfirmLoadingId: 0,
        dayPartingModalOpen: false,
        selectedIdForScheduling: 0,
    }

    toggleModal = () => {
        this.setState({
            ...this.state,
            propertySetModalOpen: !this.state.propertySetModalOpen,
        })
    }

    toggleDayPartModal = (isEdited = false) => {
        this.setState({
            ...this.state,
            dayPartingModalOpen: !this.state.dayPartingModalOpen,
        })

        if (isEdited) {
            this.props.refreshProperty()
        }
    }

    handleUpdatePropertySet = (e) => {
        const authtoken = localStorage.getItem('adm-auth-token')
        const propertyId = this.props.propertyId
        const siteId = e.target.value.split('-')[0]
        const surveyId = e.target.value.split('-')[1]
        const status = e.target.value.split('-')[2]

        fetch(`${process.env.REACT_APP_API_URL}/avatar/property/${propertyId}/propertySet`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken,
            },
            body: JSON.stringify({
                siteId: siteId,
                surveyId: surveyId,
                status: status,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    this.props.showSystemMessage('success', 'Property set has been updated.')
                    // Call a props function to push the set to AvatarProperty's state upon success.
                    this.props.refreshProperty()
                } else if (result['success'] === false) {
                    this.props.showSystemMessage('error', result.errors.message)
                } else {
                    this.props.showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error on Avatar Property Set: ', error.message)
                this.props.showSystemMessage('error', 'Connection error.')
            })
    }

    handleDelete = (e) => {
        this.setState({
            ...this.state,
            showDeleteConfirmId: e.target.value,
        })
    }

    confirmDelete = (e) => {
        const authtoken = localStorage.getItem('adm-auth-token')
        const propertyId = this.props.propertyId
        const siteId = e.target.value.split('-')[0]
        const surveyId = e.target.value.split('-')[1]

        this.setState({
            ...this.state,
            showDeleteConfirmLoadingId: `${siteId}-${surveyId}`,
        })

        // DELETE the set and this.props.refreshProperty
        fetch(`${process.env.REACT_APP_API_URL}/avatar/property/${propertyId}/propertySet`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken,
            },
            body: JSON.stringify({
                siteId: siteId,
                surveyId: surveyId,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    this.props.showSystemMessage('success', 'Property set has been deleted.')
                    this.props.refreshProperty()
                } else if (result['success'] === false) {
                    this.props.showSystemMessage('error', result.errors.message)
                    this.setState({
                        ...this.state,
                        showDeleteConfirmLoadingId: 0,
                    })
                } else {
                    this.props.showSystemMessage('error', result.errors.message)
                    this.setState({
                        ...this.state,
                        showDeleteConfirmLoadingId: 0,
                    })
                }
            })
            .catch((error) => {
                console.log('Error on Avatar Property Page: ', error.message)
                this.props.showSystemMessage('error', 'Connection error.')
                this.setState({
                    setSubmitLoaded: true,
                })
            })
    }

    handleScheduleClick = (e) => {
        e.preventDefault()

        this.setState({ ...this.state, selectedIdForScheduling: e.target.value, dayPartingModalOpen: true })
    }

    render() {
        return (
            <div className="card mt-3">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="font-weight-bold">Site/Survey sets</div>
                    <div>
                        <Link to={`/avatar/property/${this.props.propertyId}/overrides`} className="btn btn-warning btn-sm mr-2">
                            Aff Overrides
                        </Link>
                        <input type="submit" className="btn btn-warning btn-sm" value="+ New Set" onClick={this.toggleModal} />
                    </div>
                </div>
                <div className="card-body">
                    {this.state.dataLoaded ? (
                        <React.Fragment>
                            {this.state.dayPartingModalOpen && <DayPartModal isOpen={this.state.dayPartingModalOpen} closeModal={this.toggleDayPartModal} selectedSetId={this.state.selectedIdForScheduling} propertyId={this.props.propertyId} showSystemMessage={this.props.showSystemMessage} />}
                            <PropertySetModal open={this.state.propertySetModalOpen} closeModal={this.toggleModal} propertyId={this.props.propertyId} refreshProperty={this.props.refreshProperty} showSystemMessage={this.props.showSystemMessage} />

                            {this.props.propertySets
                                .filter((set) => set.set_status == 'Active' || set.set_status == 'Inactive')
                                .map((propertySet) => {
                                    let classes = propertySet.set_status.toLowerCase() === 'active' ? 'alert alert-info' : 'alert alert-danger'

                                    return (
                                        <div className={classes}>
                                            Site: <Link to={`/avatar/site/${propertySet.site_id}`}>{propertySet.meta_title}</Link>
                                            <br></br>
                                            Survey: <Link to={`/avatar/survey/${propertySet.survey_id}`}>{propertySet.survey_description}</Link>
                                            <br></br>
                                            <hr></hr>
                                            <select className="form-control" defaultValue={propertySet.set_status.toLowerCase() === 'active' ? `${propertySet.site_id}-${propertySet.survey_id}-A` : `${propertySet.site_id}-${propertySet.survey_id}-C`} onChange={this.handleUpdatePropertySet}>
                                                <option value={`${propertySet.site_id}-${propertySet.survey_id}-A`}>Active</option>
                                                <option value={`${propertySet.site_id}-${propertySet.survey_id}-C`}>Inactive</option>
                                            </select>
                                            <div className="mt-2">
                                                {propertySet.schedule.length > 0 ? (
                                                    propertySet.schedule.map((time) => {
                                                        return (
                                                            <small className="d-flex">
                                                                <div className="mr-2 font-weight-bold">{time.day_display} ..... </div>
                                                                <div>
                                                                    <i>
                                                                        {time.start_time_display}-{time.end_time_display}
                                                                    </i>
                                                                </div>
                                                            </small>
                                                        )
                                                    })
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <hr></hr>
                                            <div className="text-right">
                                                <button className="btn btn-sm btn-warning mr-2" onClick={this.handleScheduleClick} value={`${propertySet.site_id}-${propertySet.survey_id}`}>
                                                    Schedule
                                                </button>
                                                {this.state.showDeleteConfirmId === `${propertySet.site_id}-${propertySet.survey_id}` ? (
                                                    this.state.showDeleteConfirmLoadingId === `${propertySet.site_id}-${propertySet.survey_id}` ? (
                                                        <button className="btn btn-primary btn-sm" disabled>
                                                            <FontAwesomeIcon icon="spinner" spin />
                                                        </button>
                                                    ) : (
                                                        <button key={`${propertySet.site_id}-${propertySet.survey_id}`} value={`${propertySet.site_id}-${propertySet.survey_id}`} className="btn btn-sm btn-danger" onClick={this.confirmDelete}>
                                                            Confirm Delete
                                                        </button>
                                                    )
                                                ) : (
                                                    <button key={`${propertySet.site_id}-${propertySet.survey_id}`} value={`${propertySet.site_id}-${propertySet.survey_id}`} className="btn btn-sm btn-danger" onClick={this.handleDelete}>
                                                        Delete Set
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            {/* {this.props.siteId && <div><Link to={`/avatar/site/${this.props.siteId}`}>Edit active site design</Link></div>} */}
                        </React.Fragment>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
        )
    }
}

export default PropertySets
