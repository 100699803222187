import React from 'react'
import { Card, Table } from 'react-bootstrap'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const TrafficToday = ({ showSystemMessage }) => {
    document.title = 'Affiliate Top Traffic Today'

    const { data, dataLoaded } = useCallApi(`affiliates/trafficToday`, 'GET', {}, [], showSystemMessage)

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].aff_status_value} priority={tableData.data[tableData.row.index].aff_priority_value} />
                },
            },
            {
                Header: 'Aff. Mgr.',
                accessor: 'aff_mgr',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Email',
                accessor: 'e_mail',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Contact',
                accessor: 'contact',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div className="text-right">Clicks</div>,
                accessor: 'affiliateEarnings',
                className: 'text-right',
                Cell: (tableData) => {
                    return (
                        <>
                            {Number(tableData.data[tableData.row.index].click_count).toLocaleString()}
                            <span className="text-success ml-2">({Number((tableData.data[tableData.row.index].click_count / data.totalClicks) * 100).toFixed(2)}%)</span>
                        </>
                    )
                },
            },
            {
                Header: () => <div className="text-right">Margin</div>,
                accessor: 'thirtyDayAffiliateEarnings',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Dollars value={tableData.data[tableData.row.index].today_margin} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.stats} columns={columns} totals={data.totals} />
            </React.Fragment>
        )
    }

    return (
        <Card>
            <Card.Header className="d-flex justify-content-start">Top 30 Affiliates sending traffic today</Card.Header>
            {dataLoaded ? data.stats.length === 0 ? <div className="p-3">No stats for the current parameters</div> : getReportOutput() : <Loading />}
        </Card>
    )
}

export default TrafficToday
