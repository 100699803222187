import React, { Component } from 'react'
import Click from '../../../templates/Click/Click'
import Creative from '../../../templates/Creative/Creative'

import Loading from '../../../templates/UI/Loading/Loading'

import ReportsMore from '../ReportsMore/ReportsMore'
import TableContainer from '../TableContainer'

class ReportCreatives extends Component {
    state = {
        dataLoaded: true,
        report: [],
        totals: {},
    }

    componentDidMount() {
        this._isMounted = true

        this.setState({
            dataLoaded: false,
        })

        const mbToken = localStorage.getItem('adm-auth-token')

        /* Fetch Affiliate Applications Stats from DB and populate the reports table and charts */

        let url = `${process.env.REACT_APP_API_URL}/reports/creatives?startDate=${this.props.startDate}&endDate=${this.props.endDate}&page=all`

        if (this.props.oid > 0) {
            url = url + `&oid=${this.props.oid}`
        }

        if (this.props.aid > 0) {
            url = url + `&aid=${this.props.aid}`
        }

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    //this.props.handleTotalPages(result.total_pages);
                    if (this._isMounted) {
                        this.setState({
                            dataLoaded: true,
                            report: result.report,
                            totals: result.totals,
                        })
                    }
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        this.props.showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                    }
                } else {
                    this.props.showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error in ReportAffSignup: ', error)
                this.props.showSystemMessage('error', 'Connection error.')
            })
    }

    getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={this.state.report}
                    totals={this.state.totals}
                    columns={[
                        {
                            Header: 'Id',
                            accessor: 'creative_id',
                            width: 80,
                        },
                        {
                            Header: 'Description',
                            accessor: 'creative_name',
                            Cell: ({ row }) => {
                                return <>{<Creative cr_id={row.original.creative_id} name={row.original.creative_name} />}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Clicks</div>,
                            accessor: 'clicks',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ row }) => {
                                return (
                                    <>
                                        <Click cid={row.original.creative_id} aid={this.props.aid} oid={this.props.oid} startDate={this.props.startDate} endDate={this.props.endDate} value={Intl.NumberFormat('en-GB').format(row.original.clicks)} />
                                    </>
                                )
                            },
                        },
                        {
                            Header: () => <div className="float-right">Leads</div>,
                            accessor: 'leads',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{Intl.NumberFormat('en-GB').format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Sales</div>,
                            accessor: 'sales',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Network Earnings</div>,
                            accessor: 'network_earnings',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Affiliate Earnings</div>,
                            accessor: 'affiliate_earnings',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Margin</div>,
                            accessor: 'margin',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Margin %</div>,
                            accessor: 'pct_margin',
                            className: 'text-right',
                            // To customize rendering according to the row object using other fields from the original object
                            Cell: ({ row }) => {
                                //console.log(row.original.pct_margin)
                                return <>{parseFloat(row.original.margin ? (row.original.margin / row.original.network_earnings) * 100 : 0).toFixed(2)}%</>
                                // Cell: ({ cell: { value } }) => {
                                //     return (<>{parseFloat(value).toFixed(2)}%</>);
                            },
                        },
                        {
                            Header: () => <div className="float-right">Affiliate EPC</div>,
                            accessor: 'affiliate_epc',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right"></div>,
                            accessor: 'reports_more',
                            className: 'text-right',
                            Cell: ({ row }) => {
                                return <>{<ReportsMore refClicks oid={this.props.oid} aid={this.props.aid} cid={row.original.creative_id} startDate={this.props.startDate} endDate={this.props.endDate} />}</>
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <hr className="mt-0 mb-3"></hr>
                <div className="d-flex mx-2"></div>

                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {this.state.report.length === 0 ? (
                            <table className="table table-striped table-card table-hover mb-0">
                                <tbody>
                                    <tr>
                                        <td colSpan="11">No stats for this date range.</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <React.Fragment>
                                {this.props.aid ? (
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mr-4 d-flex align-items-center">
                                                <h1 className="mb-h1-dark">Campaign Stats by Creative for Affiliate: {this.props.aid}</h1>
                                            </div>
                                        </div>
                                        {this.props.oid ? (
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <h1 className="mb-h1-dark">Campaign: {this.props.oid}</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ) : this.props.oid ? (
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mr-4 d-flex align-items-center">
                                                <h1 className="mb-h1-dark">Campaign Stats by Creative for Offer: {this.props.oid}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {this.getReportOutput()}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <table className="table table-striped table-card table-hover mb-0">
                        <tbody>
                            <tr>
                                <td colSpan="11">
                                    <Loading />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </React.Fragment>
        )
    }
}

export default ReportCreatives
