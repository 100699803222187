export default {
    types: [
        {
            value: 'G',
            text: 'Global',
        },
        {
            value: 'I',
            text: 'I-Frame',
        },
        {
            value: 'L',
            text: 'Pixel',
        },
        {
            value: 'S',
            text: 'Server',
        },
        {
            value: 'M',
            text: 'MaxBounty Tag',
        },
        {
            value: 'A',
            text: 'API',
        },
        {
            value: 'H',
            text: 'Instant (Host & Post)',
        },
    ],
}
