import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import ReviewRequest from '../../../components/ReviewRequest/ReviewRequest'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'

const ApprovalRequests = ({ showSystemMessage }) => {

    const [dataLoaded, setDataLoaded] = useState(false)
    const [role, setRole] = useState(false)
    const [report, setReport] = useState([])
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [requestId, setRequestId] = useState(0)
    const [requestStatus, setRequestStatus] = useState()
    const [affiliateId, setAffiliateId] = useState(0)
    const [campaignId, setCampaignId] = useState(0)

    const [flow, setFlow] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(false)

    const { adminId, setAdminId } = useQueryParams()
    const history = useHistory()
    const location = useLocation()

    document.title = 'Approval Requests'

    useEffect(() => {
        getReport()
    }, [location])

    const getReport = () => {
        setDataLoaded(false)

        let url = `campaigns/approvalRequests?mgrId=${adminId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.list)
                    setRole(result.role)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load followups list.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching campaigns approval requests: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching campaigns approval requests: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const refreshReport = () => {
        let url = `/campaigns/approvalRequests?adminId=${adminId}`
        history.push(url)
    }

    const handleClick = (index, e, requestId, affiliateId, campaignId, requestStatus) => {
        e.preventDefault()
        // selectedIndex is to set the current modal, so that we can go to the next one easily if flow mode is on
        setSelectedIndex(index)
        // Set the information for the modal
        setRequestId(requestId)
        setRequestStatus(requestStatus)
        setAffiliateId(affiliateId)
        setCampaignId(campaignId)
        setShowReviewModal(true)
    }

    const handleClose = (isEdited = false) => {
        setShowReviewModal(false)

        if (isEdited || flow) {
            getReport()
        }
    }

    const handleCloseFlow = (isEdited = false) => {
        setShowReviewModal(false)

        if (flow) {
            //open the next one of the same campaign if Ad team, open next one if anything else
            let nextIndex = (role === 'MERMGR' || role === 'ADOPS_MANAGER' || role === 'ADOPS_TEAMLEAD') ?
                report.findIndex((request) => request.offer_id === campaignId && request.request_id !== requestId && request.req_status === 'Reviewing')
                :
                report.findIndex((request) => request.request_id !== requestId && request.req_status === 'Open')

            if (nextIndex >= 0) {
                const nextItemInList = report[nextIndex]
                setSelectedIndex(nextIndex)
                setRequestId(nextItemInList.request_id)
                setAffiliateId(nextItemInList.affiliate_id)
                setCampaignId(nextItemInList.offer_id)
                setShowReviewModal(true)
                getReport()
            } else {
                setShowReviewModal(false)
                getReport()
            }

        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Req. ID',
                accessor: 'request_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Priority',
                accessor: 'aff_priority',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Aff ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate_name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].affiliate_name} status={tableData.data[tableData.row.index].status} priority={tableData.data[tableData.row.index].aff_priority} />
                },
            },
            {
                Header: 'Affiliate Manager',
                accessor: 'am_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign ID',
                accessor: 'offer_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'offer_name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].offer_id} name={tableData.data[tableData.row.index].offer_name} status={tableData.data[tableData.row.index].offer_status} />
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser_name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].advertiser_id} name={tableData.data[tableData.row.index].advertiser_name} status={tableData.data[tableData.row.index].advertiser_status} />
                },
            },
            {
                Header: 'Advertiser Manager',
                accessor: 'mm_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Requested',
                accessor: 'request_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY/MM/DD')}</>
                },
            },
            {
                Header: 'AM Req',
                accessor: 'admin_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value > 0 ? 'Yes' : ''}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'req_status',
                Cell: (tableData) => {
                    return (
                        <>
                            <a href={`/campaigns/approvalRequests/${tableData.data[tableData.row.index].request_id}?affiliateId=${tableData.data[tableData.row.index].affiliate_id}&campaignId=${tableData.data[tableData.row.index].offer_id}`} onClick={(e) => handleClick(tableData.row.index, e, tableData.data[tableData.row.index].request_id, tableData.data[tableData.row.index].affiliate_id, tableData.data[tableData.row.index].offer_id, tableData.data[tableData.row.index].req_status)}>
                                {tableData.data[tableData.row.index].req_status}
                            </a>
                        </>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reports</h1>
            </div>
            <div className="card">
                <Modal show={showReviewModal} onHide={handleClose} size="xl">
                    <ReviewRequest affiliateId={affiliateId} campaignId={campaignId} requestId={requestId} handleClose={handleClose} handleCloseFlow={handleCloseFlow} flow={flow} showSystemMessage={showSystemMessage} requestStatus={requestStatus} />
                </Modal>
                <div className="card-body p-0">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex p-3">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark">Current Campaign Requests in Queue for:</h1>
                            </div>
                            <div className="mr-4">
                                <AdminPicker adminId={adminId} setAdminId={setAdminId} />
                            </div>
                            <div className="d-flex align-items-center">
                                <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                    Refresh Report
                                </button>
                            </div>
                        </div>
                        <div className="d-flex align-items-center p-3">
                            <div className="alert alert-secondary m-0 py-0 px-1 mr-2">
                                <div className="custom-control custom-switch">
                                    <ReactTooltip></ReactTooltip>
                                    <input type="checkbox" className="custom-control-input" id="filterSwitch" onClick={() => setFlow(!flow)} defaultChecked={flow} />
                                    <label className="custom-control-label" htmlFor="filterSwitch" data-tip="If flow mode is on, processing a request will automatically open the next relevant request.">
                                        Flow Mode
                                    </label>{' '}
                                </div>
                            </div>
                        </div>
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default ApprovalRequests
