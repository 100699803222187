import React, { useState, useEffect } from 'react';
import callAPI from '../../helpers/callAPI';
import Loading from '../../templates/UI/Loading/Loading';

export default function Signature({id}){

    const [data, setData] = useState({ admin: {name: 'MaxBounty', email: 'support@maxbounty.com'} })
    const [dataLoaded, setDataLoaded] = useState(true)

    useEffect(async () => {
        if (!isNaN(parseInt(id)) && parseInt(id) > 0){
            setDataLoaded(false)
            let result = await callAPI(`admin/user/${parseInt(id)}`, 'GET', {}, false)            
            if (result.success){
                setDataLoaded(true)
                setData(result)
            } else {
                setDataLoaded(true)
                setData({ admin: {name: 'MaxBounty', email: 'support@maxbounty.com'} })
            }
        } else if (!isNaN(id) && parseInt(id) === 0) {
            setData({admin: {name: '{Account Manager}', email: '{account_manager}@maxbounty.com'}})
        } else {
            setData({admin: {name: 'MaxBounty', email: id ? id : 'support@maxbounty.com'}})
        }
    }, [id])

    const getSignature = (name, email, role, phone) => {
        let convertedRole = false
    
        if (role){
            switch(role) {
                case 'AM_SENIOR':
                    convertedRole = 'Senior Affiliate Manager'
                    break;
                case 'AM_JUNIOR':
                    convertedRole = 'Affiliate Manager'
                    break;
                case 'AM_JR_TEAMLEAD':
                    convertedRole = 'Affiliate Manager Team Leader'
                    break;
                case 'MERMGR':
                    convertedRole = 'Advertiser Manager'
                    break;
                case 'BIZDEV':
                    convertedRole = 'Business Development Manager'
                    break;
                case 'ADOPS_TEAMLEAD':
                    convertedRole = 'Advertiser Operations Team Leader'
                    break;
                case 'ADOPS_MANAGER':
                    convertedRole = 'Advertiser Operations Manager'
                    break;
                case 'COMPLIANCE':
                    convertedRole = 'Compliance Manager'
                    break;
                case 'MARKETING':
                    convertedRole = 'Marketing Manager'
                    break;
                case 'CEO':
                    convertedRole = 'Chief Executive Officer'
                    break;
                case 'CTO':
                    convertedRole = 'Chief Technology Officer'
                    break;
                case 'AFFILIATE_DIRECTOR':
                    convertedRole = 'Director of Affiliate Relations'
                    break;
                case 'ADVERTISER_DIRECTOR':
                    convertedRole = 'Director of Advertiser Relations'
                    break;
                case 'COMPLIANCE_DIRECTOR':
                    convertedRole = 'Director of Network Quality Assurance'
                    break;
                default:
                    convertedRole = false
            }
        }

        return (            
            <div style={{margin: '20px 0px 0px 0px', color: 'black'}}>
                <p style={{fontWeight: 'bold', margin: '0px'}}>{name}</p>
                {convertedRole ? <p style={{fontWeight: 'bold', margin: '0px'}}>{convertedRole}</p> : ''}
                <p style={{margin: '0px'}}>{email}</p>
                {phone ? <p style={{margin: '0px'}}>{phone}</p> : ''}
            </div>            
        )
    }

    return (
        <>
            { (dataLoaded && data.admin) ? getSignature(data.admin.name, data.admin.email, data.admin.role, data.admin.phone) : <Loading />}
        </>
    )

}