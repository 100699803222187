import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import callAPI from '../../../helpers/callAPI'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../hooks/useQueryParams'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import Number from '../../../templates/Number/Number'
import moment from 'moment'
import DownloadCSV from '../../../templates/DownloadCSV/DownloadCSV'
import { FaTimes, FaCheck } from 'react-icons/fa'

const AffiliateMonthEndBalancesReport = ({ showSystemMessage, data, dataLoaded }) => {

    const getReportOutput = () => {
        let columns = [
            
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (tableProps) => {
                    return <Affiliate aid={tableProps.row.original.affiliate_id} name={tableProps.row.original.name} status={tableProps.row.original.aff_status_value} priority={tableProps.row.original.aff_priority_value} />
                },
            },
            {
                Header: 'Current Status',
                accessor: 'aff_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Signup Date',
                accessor: 'signup_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD')}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Balance</div>,
                accessor: 'balance',                
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>MM Balance</div>,
                accessor: 'mm_balance',                
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'center' }}>Pay Status</div>,
                accessor: 'pay_status',                
                className: 'text-center',
                Cell: ({ cell: { value } }) => {
                    return <>{value==="Y" ? <FaCheck className="text-success" /> : ''}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'center' }}>Hold Status</div>,
                accessor: 'hold_status',                
                className: 'text-center',
                Cell: ({ cell: { value } }) => {
                    return <>{value==="Y" ? <FaTimes className="text-danger" /> : ''}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.balanceReport} columns={columns} />
            </React.Fragment>
        )
    }

    return <div>{dataLoaded ? data.balanceReport.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
}

export default AffiliateMonthEndBalancesReport
