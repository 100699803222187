import ReviewApplication from "../../Dashboard/components/Applications/ReviewApplication/ReviewApplication"

const ReviewApplicationWrapper = ({ affiliateId, showSystemMessage }) => {    

    document.title = "Review Application"

    const handleClose = () => {}

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Review Application</h1>
            </div>
            {/* <div className="row"> */}
            <div>
                <ReviewApplication affiliateId={affiliateId} showSystemMessage={showSystemMessage} handleReviewClose={handleClose} />
            </div>
            {/* </div> */}
        </div>
    )
}

export default ReviewApplicationWrapper
