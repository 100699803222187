import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../hooks/useQueryParams'
import DateRangeFilter from '../../../pages/Reports/Filters/DateRangeFilter/DateRangeFilter'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import AffiliateNavbar from '../../Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../Campaign/CampaignNavBar/CampaignNavBar'
import { colors } from '../../../theme/variables'

const CampaignPromiseReport = ({ showSystemMessage }) => {
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { affiliateId, campaignId, adminId, setAdminId, navigation, buildUrl } = useQueryParams()

    let apiUrl = buildUrl(`reports/campaignPromises?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)

    const { data, dataLoaded, refetch } = useCallApi(apiUrl, 'GET', {}, [affiliateId, campaignId], showSystemMessage)

    const history = useHistory()
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    if (affiliate.id) {
        document.title = `${affiliate.name}: Campaign Promises`
    } else if (campaign.id) {
        document.title = `${campaign.name}: Campaign Promises`
    } else {
        document.title = 'Campaign Promises'
    }

    useEffect(() => {
        if (data.affiliate) {
            setAffiliate(data.affiliate)
        }
        if (data.campaign) {
            setCampaign(data.campaign)
        }
    }, [data])

    const refreshReport = () => {
        refetch()
        let url = buildUrl(`/reports/custom/campaignPromiseReport?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        history.push(url)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate_name',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].affiliate.online === 'Y' ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill={colors.success} className="bi bi-circle-fill mr-3" viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                    ) : null}<Affiliate name={tableData.data[tableData.row.index].affiliate.name} aid={tableData.data[tableData.row.index].affiliate.id} status={tableData.data[tableData.row.index].affiliate.status.value} priority={tableData.data[tableData.row.index].affiliate.priority.value} /></>
                },
            },
            {
                Header: 'Priority',
                accessor: 'affiliate_priority_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'offer_name',
                Cell: (tableData) => {
                    return <Campaign name={tableData.data[tableData.row.index].offer.name} oid={tableData.data[tableData.row.index].offer.id} status={tableData.data[tableData.row.index].offer.status} />
                },
            },
            {
                Header: 'Last Followup',
                accessor: 'last_followup_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Promise Date',
                accessor: 'promise_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Results',
                accessor: 'result',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return <ReportTable data={data.promises} columns={columns} />
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Campaign Promises</h1>
                <div className="ml-5">{navigation === 'affiliate' ? <AffiliateNavbar affiliateId={affiliateId} /> : navigation === 'campaign' ? <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} /> : ''}</div>
            </div>
            <Card>
                <div className="d-flex align-items-center px-3 pt-3 pb-0">
                    <h1 className="mb-h1-dark mr-2">{affiliate ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority ? affiliate.priority.value : 0} status={affiliate.status ? affiliate.status.value : 'A'} /> : campaign ? <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : <Loading />}</h1>
                    {!affiliateId && (
                        <span className="ml-2">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={'AM_SENIOR'} />
                        </span>
                    )}
                    <div className="d-flex align-items-center p-3">
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <Button size="sm" onClick={refreshReport}>
                            Refresh Report
                        </Button>
                    </div>
                </div>
                <hr />
                {/* {dataLoaded ?
                    <div>
                        {data.summary.map(item => {
                            return <div key={item.results}>
                                <span className='font-weight-bold'>{item.results}: </span>
                                <span>{item.promise_count}</span>
                            </div>
                        })}
                    </div>
                    : spinner} */}
                {dataLoaded ? data.promises.length === 0 ? <div className="p-3">No stats for the current parameters.</div> :
                    <div>
                        <div className='px-2 pb-2 pt-0'>
                            <span className='font-weight-bold'>Summary Results: </span>
                            {data.summary.map(item => {
                                return <div key={item.results}>
                                    <span >{item.results}: </span>
                                    <span>{item.promise_count}</span>
                                </div>
                            })}
                        </div>
                        {getReportOutput()}
                    </div> : <Loading></Loading>}
            </Card>
        </div>
    )
}

export default CampaignPromiseReport
