import ApplicationModalContent from './ApplicationModalContent/ApplicationModalContent'

const EditApplication = ({ applicationId, showSystemMessage }) => {
    // const location = useLocation()

    const handleClose = (isEdited = false, actionType) => {
        if (actionType !== 'delete') {
            window.location.reload()
        }
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertiser Application</h1>
            </div>
            <div className="row">
                <div className="col-lg-5">
                    <ApplicationModalContent showSystemMessage={showSystemMessage} applicationId={applicationId} handleClose={handleClose} />
                </div>
            </div>
        </div>
    )
}

export default EditApplication
