import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../helpers/callAPI'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import AffiliateSearch from '../../AffiliateSearch/AffiliateSearch'
import CampaignSearch from '../../CampaignSearch/CampaignSearch'
import { useEffect } from 'react'

export default function AddFilterOverride({ affiliateId, campaignId, rowAffiliate, rowCampaign, showSystemMessage, handleClose, isOpen, filterSettings }) {
    const [campaign, setCampaign] = useState({ id: -1, name: '' })
    const [affiliate, setAffiliate] = useState({ id: -1, name: '' })
    const [filters, setFilters] = useState(filterSettings)

    useEffect(() => {
        setCampaign({ id: -1, name: '' })
        setAffiliate({ id: -1, name: '' })
        setFilters(filterSettings)
    }, [affiliateId, campaignId, rowAffiliate, rowCampaign, filterSettings])

    const handleOverride = (action) => {
        let url = affiliateId ? `affiliate/${affiliateId}/filterOverrides` : `campaign/${campaignId}/filterOverrides`
        let method = 'POST'
        let postBody = {
            id: affiliateId ? (rowCampaign.id ? rowCampaign.id : campaign.id) : rowAffiliate.id ? rowAffiliate.id : affiliate.id,
            filters: {
                ip: filters.ip,
                os: filters.os,
                device: filters.device,
            },
        }

        if (action === 'save') {
            method = rowAffiliate.id && rowCampaign.id ? 'PUT' : 'POST'
        } else if (action === 'delete') {
            method = 'DELETE'
        } else {
            return
        }

        callAPI(url, method, postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', `Filter Overrides ${action === 'save' ? 'Update' : 'Deleted'}`)
                    handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => showSystemMessage('error', `Error while ${action === 'save' ? 'updating' : 'deleting'} filter overrides`))
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            {isOpen && (
                <>
                    <Modal.Header closeButton>
                        <h1 className="mb-h1-dark">Create Link Filter Override for: {affiliateId ? <Affiliate aid={affiliateId} name={rowAffiliate.name} status={rowAffiliate.status.value} priority={rowAffiliate.priority.value} /> : <Campaign oid={campaignId} name={rowCampaign.name} status={rowCampaign.status.value} />}</h1>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className="card"> */}
                        <table className="table table-card table-borderless w-100 m-0">
                            <tbody>
                                <tr className="align-items-center">
                                    <td width="30%">{affiliateId ? 'Campaign:' : 'Affiliate:'}</td>
                                    <td className="pt-3 align-items-center">{affiliateId ? <CampaignSearch campaign={rowCampaign.id ? rowCampaign : false} setCampaign={setCampaign} hideChange /> : <AffiliateSearch affiliate={rowAffiliate.id ? rowAffiliate : false} setAffiliate={setAffiliate} />}</td>
                                </tr>
                                <tr>
                                    <td>IP Override:</td>
                                    <td>
                                        <select
                                            name="ip"
                                            defaultValue={filterSettings.ip ? 'Y' : 'N'}
                                            className="form-control"
                                            onChange={(e) => {
                                                setFilters({ ...filters, ip: e.target.value === 'Y' ? true : false })
                                            }}
                                        >
                                            <option value="N">No</option>
                                            <option value="Y">Yes</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>OS Override:</td>
                                    <td>
                                        <select
                                            name="os"
                                            defaultValue={filterSettings.os ? 'Y' : 'N'}
                                            className="form-control"
                                            onChange={(e) => {
                                                setFilters({ ...filters, os: e.target.value === 'Y' ? true : false })
                                            }}
                                        >
                                            <option value="N">No</option>
                                            <option value="Y">Yes</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Device Override:</td>
                                    <td>
                                        <select
                                            name="device"
                                            defaultValue={filterSettings.device ? 'Y' : 'N'}
                                            className="form-control"
                                            onChange={(e) => {
                                                setFilters({ ...filters, device: e.target.value === 'Y' ? true : false })
                                            }}
                                        >
                                            <option value="N">No</option>
                                            <option value="Y">Yes</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>
                                        <button type="button" name="submit" className="btn btn-primary" disabled={!campaign.id && !affiliate.id ? true : false} onClick={() => handleOverride('save')}>
                                            {rowAffiliate.id && rowCampaign.id ? 'Save' : 'Create'}
                                        </button>
                                        {rowAffiliate.id && rowCampaign.id && (
                                            <button type="button" name="submit" className="btn btn-danger ml-2" onClick={() => handleOverride('delete')}>
                                                Delete
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                </>
            )}
        </Modal>
    )
}
