import React, { Component } from 'react'

class NameFilter extends Component {
    state = {}

    componentDidMount() {
        this._isMounted = true
    }

    render() {
        return (
            <div className="mr-2">
                <input type="text" className="form-control form-control-sm" placeholder="Filter #ID or Name" />
            </div>
        )
    }
}

export default NameFilter
