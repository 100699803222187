import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import callAPI from '../../../helpers/callAPI'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../hooks/useQueryParams'
import Dollars from '../../../templates/Dollars/Dollars'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import ReversalReportDropdown from '../ReversalReportDropDown/ReversalReportDropDown'
import Number from '../../../templates/Number/Number'
import moment from 'moment'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Campaign from '../../../templates/Campaign/Campaign'

const ByCampaign = ({ showSystemMessage, setAffiliate, setAdvertiser, setCampaign }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])
    const location = useLocation()
    const { initStartDate, initEndDate } = useDateRangeFilter()
    const { affiliateId, advertiserId, campaignId, buildUrl } = useQueryParams()

    useEffect(() => {
        getReport()
    }, [location])

    const getReport = () => {
        setDataLoaded(false)

        let url = buildUrl(`reports/reversals/admin?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.report)
                    setTotals(result.totals)
                    result.affiliate && setAffiliate(result.affiliate)
                    result.advertiser && setAdvertiser(result.advertiser)
                    result.campaign && setCampaign(result.campaign)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                } else {
                    showSystemMessage('error', 'Could not load reversals report.')
                }
            })
            .catch((error) => {
                console.log(`Error while fetching reversals report: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching reversals report: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'admin.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Admin',
                accessor: 'admin.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Num Leads</div>,
                accessor: 'num_leads',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Number value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Rev. Margin</div>,
                accessor: 'rev_margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} totals={totals} />
            </React.Fragment>
        )
    }

    return <div>{dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
}

export default ByCampaign
