import React, { useState, useEffect } from 'react'
import callAPI from '../../../helpers/callAPI'

export default function AdminRolePicker({ record, passedRoles, selectRole, showSystemMessage }) {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [roles, setRoles] = useState(passedRoles ? passedRoles : false)

    useEffect(async () => {
        
        if (!roles){
            await callAPI(`authorization/roles`, 'GET')
                .then((result) => {
                    if (result['success'] === true) {
                        setRoles(result.roles)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', error.message)
                })            
        }

        setDataLoaded(true)
        
    }, [roles])

    return (
        <select className="form-control form-control-sm w-50" onChange={(event) => selectRole(event.target.value)}>
            <option value=""></option>
            {dataLoaded &&
                roles &&
                roles.map((role) => {
                    if (
                        record.roles.findIndex((element) => {
                            return element.name == role.role
                        }) < 0
                    ) {
                        return (
                            <option key={role.role_id} value={role.role_id}>
                                {role.role}
                            </option>
                        )
                    } else {
                        return true
                    }
                })}
        </select>
    )
}
