import React, { useState, useEffect } from 'react'
import { User } from 'react-feather'
import callAPI from '../../../helpers/callAPI'

export default function LoginAsAdvertiser({ advertiserId, showSystemMessage }) {
    const login = async () => {
        let loginResult = await callAPI(`advertiser/${advertiserId}/login`, 'POST', {}, false)

        if (loginResult.success) {
            window.open(loginResult.url, '_blank')
        } else {
            showSystemMessage('error', loginResult.errors.message)
        }
    }

    return (
        <button type="button" className="btn btn-success btn-sm align-items-center d-flex" onClick={login}>
            <User width={13} height={13} viewBox="0 0 24 24" fill="none" strokeWidth="3" />
            Login
        </button>
    )
}
