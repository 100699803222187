import { Button, Form, Modal } from 'react-bootstrap'
import { useState } from 'react'
import callAPI from '../../../helpers/callAPI'

const MerModal = ({ isOpen, setIsOpen, mgrId, refetch, showSystemMessage }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        domains: 'Not Applicable',
        mgrId,
    })

    const createAdvertiser = async (e) => {
        e.preventDefault()
        const result = await callAPI('advertisers/createAdvertiser', 'POST', formData)
        if (result.success) {
            setIsOpen(false)
            setFormData({
                name: '',
                email: '',
                domains: 'Not Applicable',
                mgrId,
            })
            showSystemMessage('success', 'Advertiser created successfully.')
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Form onSubmit={createAdvertiser}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Advertiser</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Advertiser Name: </span>
                        <div className="w-75">
                            <Form.Control type="text" onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>E-mail: </span>
                        <div className="w-75">
                            <Form.Control type="text" onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span style={{ width: '20%' }}>Domains to Whitelist: </span>
                        <div className="w-75">
                            <Form.Control type="text" defaultValue={formData.domains} onChange={(e) => setFormData({ ...formData, domains: e.target.value })} required />
                            (separate multiple domains with commas - eg. abc.com, xyz.com)
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default MerModal
