import { useState, useEffect } from 'react'
import { breakpoints } from '../theme/variables'

const useBreakpoint = () => {
    const assignSize = () => {
        switch (true) {
            case window.innerWidth < parseInt(breakpoints.xs):
                return 'xs'
                break
            case window.innerWidth < parseInt(breakpoints.sm):
                return 'sm'
                break
            case window.innerWidth < parseInt(breakpoints.md):
                return 'md'
                break
            case window.innerWidth < parseInt(breakpoints.lg):
                return 'lg'
                break
            default:
                return 'xl'
                break
        }
    }
    window.addEventListener('resize', null)
    const [breakpoint, setBreakpoint] = useState(assignSize())
    window.removeEventListener('resize', null)
    const resize = () => {
        switch (true) {
            case window.innerWidth < parseInt(breakpoints.xs):
                setBreakpoint('xs')
                break
            case window.innerWidth < parseInt(breakpoints.sm):
                setBreakpoint('sm')
                break
            case window.innerWidth < parseInt(breakpoints.md):
                setBreakpoint('md')
                break
            case window.innerWidth < parseInt(breakpoints.lg):
                setBreakpoint('lg')
                break
            default:
                setBreakpoint('xl')
                break
        }
    }

    useEffect(() => {
        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    return breakpoint
}

export default useBreakpoint
