import { useState } from 'react'
import { Button, ButtonGroup, Card, Table } from 'react-bootstrap'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Loading from '../../../templates/UI/Loading/Loading'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import Dollars from '../../../templates/Dollars/Dollars'
import moment from 'moment'
import useQueryParams from '../../../hooks/useQueryParams'

const APILeadsReview = ({ showSystemMessage }) => {
    const { adminId, setAdminId } = useQueryParams()
    const { data, dataLoaded, refetch } = useCallApi(`advertisers/apiLeadsReview?adminId=${adminId}`, 'GET', {}, [adminId], showSystemMessage)

    const markAsReviewed = async (id) => {
        const result = await callAPI(`advertisers/apiLeadsReview/${id}`, 'PUT')
        if (result.success) {
            showSystemMessage('success', 'API Lead Reviewed')
            refetch()
        } else {
            showSystemMessage('error', 'Error Updating API Lead')
        }
    }

    return (
        <Card>
            <Card.Header className="d-flex align-items-center">
                <h1 className="mb-h1-dark mr-2">API Leads to Review</h1>
                <div>
                    <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['MERMGR', 'BIZDEV', 'ADMIN', 'ADOPS_TEAMLEAD', 'ADOPS_MANAGER']} />
                </div>
            </Card.Header>
            <Table responsive hover className="mb-0 pb-0">
                <thead>
                    <tr>
                        <th>Campaign</th>
                        <th>Affiliate</th>
                        <th>Platform</th>
                        <th align="right">Net Rate</th>
                        <th align="right">Sale</th>
                        <th>Lead Data</th>
                        <th>Date</th>
                        <th>Note</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {dataLoaded ? (
                        data.data.length > 0 ? (
                            data.data.map((row, index) => (
                                <tr key={index} className={row.reviewed ? 'table-success' : ''}>
                                    <td>
                                        <Campaign name={row.offer_name} oid={row.offer_id} />
                                    </td>
                                    <td>
                                        <Affiliate name={row.name} aid={row.affiliate_id} />
                                    </td>
                                    <td>{row.platform_name}</td>
                                    <td align="right">
                                        <Dollars value={row.net_rate} />
                                    </td>
                                    <td align="right">
                                        <Dollars value={row.sale_amount} />
                                    </td>
                                    <td>{row.lead_data}</td>
                                    <td>{moment(row.transaction_date).format('YYYY/MM/DD')}</td>
                                    <td>{row.notes}</td>
                                    <td>
                                        {!row.reviewed ? (
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                onClick={() => {
                                                    markAsReviewed(row.id)
                                                }}
                                            >
                                                reviewed
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9">No API Leads.</td>
                            </tr>
                        )
                    ) : (
                        <tr>
                            <td colSpan="9">
                                <Loading />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    )
}

export default APILeadsReview
