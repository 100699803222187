import moment from 'moment'
import { useState } from 'react'
import { User } from 'react-feather'
import { Link } from 'react-router-dom'

import useCallApi from '../../../hooks/useCallApi'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import DuplicateModal from '../../Dashboard/components/Applications/DuplicateModal/DuplicateModal'

import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const AppsVerificationList = ({ showSystemMessage }) => {

    document.title = 'Affiliate Applications to Verify'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_verifications')
    
    const location = useLocation()
    let urlParams = queryString.parse(location.search)

    let apiUrl = `compliance/affiliates/applications`
    if (urlParams.marketing){
        apiUrl += `?marketing=true`
    }

    const { dataLoaded, data, refetch } = useCallApi(apiUrl, 'GET', {}, [])

    const [showDuplicateModal, setShowDuplicateModal] = useState(false)
    const [affiliateId, setAffiliateId] = useState()

    const handleClick = (e, affiliateId) => {
        e.preventDefault()
        window.open(`/affiliate/${affiliateId}/application/verify`, '_blank', 'height=750,width=800')   
    }

    const handleDupClick = (affiliateId) => {
        setAffiliateId(affiliateId)
        setShowDuplicateModal(true)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate.id',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].affiliate.id}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return (
                        <>
                            <FontAwesomeIcon icon={faAddressCard} className="text-primary mr-1" />
                            <Link to={`/affiliate/${tableData.data[tableData.row.index].affiliate.id}/application/verify`} onClick={(e) => handleClick(e, tableData.data[tableData.row.index].affiliate.id)}>
                                {tableData.data[tableData.row.index].affiliate.name}
                            </Link>
                            {(tableData.data[tableData.row.index].experience_level.value === 3 || tableData.data[tableData.row.index].experience_level.value === 2) && tableData.data[tableData.row.index].country_tier === 1 ? <span className="ml-2">⭐</span> : ''}
                        </>
                    )
                },
            },
            {
                Header: 'Experience',
                accessor: 'experience_level.text',
                Cell: (tableData) => {
                    let customClass = tableData.data[tableData.row.index].experience_level.value === 3 || (tableData.data[tableData.row.index].experience_level.value === 2 && tableData.data[tableData.row.index].country_tier === 1) ? 'text-success font-weight-bold' : ''
                    return <span className={customClass}>{tableData.data[tableData.row.index].experience_level.text}</span>
                },
            },
            {
                Header: 'Country',
                accessor: 'country',
                Cell: (tableData) => {
                    let customClass = tableData.data[tableData.row.index].country_tier === 1 ? 'text-success font-weight-bold' : ''
                    return <span className={customClass}>{tableData.data[tableData.row.index].country}</span>
                },
            },
            {
                Header: 'Local/Best Time',
                accessor: 'contact_time',
                Cell: (tableData) => {
                    return (
                        <>
                            {tableData.data[tableData.row.index].affiliate_time}, {tableData.data[tableData.row.index].contact_time}
                        </>
                    )
                },
            },
            {
                Header: 'App Date',
                accessor: 'application_date',
                Cell: (tableData) => {
                    return <>{moment(tableData.data[tableData.row.index].application_date).format('YYYY/MM/DD')}</>
                },
            },
            {
                Header: 'Duplicate',
                accessor: 'affiliate.status.value',
                Cell: (tableData) => {
                    let status = tableData.data[tableData.row.index].affiliate.status.value
                    return (
                        <>
                            {status === 'D' ? (
                                <button className="btn btn-link shadow-none" onClick={() => handleDupClick(tableData.data[tableData.row.index].affiliate.id)}>
                                    <User size={14} color={'red'} fill={'red'} />
                                </button>
                            ) : ''}
                        </>
                    )
                },
            },
        ]
        return (
            <ReportTable
                data={data.applications}
                columns={columns}
                getRowProps={(row) => {
                    if (row.original.experience_level.value === 3 || (row.original.experience_level.value === 2 && row.original.country_tier === 1)) {
                        return {
                            className: 'bgSuccess25',
                        }
                    }
                }}
            />
        )
    }

    const handleClose = (isEdited = false) => {
        setShowDuplicateModal(false)        
        if (isEdited) {
            refetch()
        }
    }

    return (
        <div>
            {showDuplicateModal && <DuplicateModal showSystemMessage={showSystemMessage} affiliateId={affiliateId} isOpen={showDuplicateModal} handleClose={handleClose} />}
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Applications to Verify</h1>
            </div>
            <div className="card">{dataLoaded ? data.applications.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
        </div>
    )
}

export default AppsVerificationList
