import { Card, Table, OverlayTrigger, Tooltip, Popover, Modal } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import Campaign from '../../../../templates/Campaign/Campaign'
import '../../../../theme/classes.scss'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import styles from './FraudReport.module.scss'
import CompliancePaymentInfoCheck from '../CompliancePaymentInfoCheck/CompliancePaymentInfoCheck'

const FraudReport = ({ manager }) => {
    const [modalData, setModalData] = useState({ isOpen: false, affiliate: { id: 0 } })
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const result = await callAPI(`compliance/fraudReport?mode=unreviewed`, 'GET')
            if (result.success) {
                setData(result)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchData()
    }, [manager])

    const warningBg = (item, warningRate, dangerRate) => {
        if (item > dangerRate) {
            return 'bgDanger25'
        }
        if (item > warningRate) {
            return 'bgWarning25'
        }
        return null
    }

    return (
        <Card className="mb-3">
            <CompliancePaymentInfoCheck modalData={modalData} setModalData={setModalData} manager={manager} />
            <Card.Header className="d-flex justify-content-between align-items-center">
                <div className="font-weight-bold">Fraud Report</div>
                <span className="align-items-center">
                    In Queue{' '}
                    <Link to="/compliance/fraudReport" className="ml-1">
                        {dataLoaded ? <>{!data || data.report.length === 0 ? 0 : data.report.length}</> : 0}
                    </Link>
                </span>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover bordered className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th className="text-center">Sub1</th>
                            <th className="text-right">IP %</th>
                            <th className="text-center">AID</th>
                            <th className="text-right">PERM %</th>
                            <th>PERM(s)</th>
                            <th className="text-right">UA %</th>
                            <th>Proxy %</th>
                            <th>Campaign</th>
                            <th>C2L</th>
                            <th>Pay</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.report.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.report.map((item, index) => {
                                            if (index < 15) {
                                                return (
                                                    <tr key={item.affiliate.id}>
                                                        <td>{item.affiliate.id}</td>
                                                        <td>
                                                            <Affiliate aid={`${item.affiliate.id}`} name={`${item.affiliate.name}`} priority={`${item.affiliate.priority}`} status={`${item.affiliate.status}`} />
                                                        </td>
                                                        <td className={`${warningBg(item.sub1, 5, 10)} text-center`}>{item.sub1}</td>
                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{item.ip_concentration}% of this affiliate's leads came from a device with the same IP.</Tooltip>}>
                                                            <td className={`${warningBg(item.ip_concentration, 5, 10)} text-right`}>{item.ip_concentration}</td>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{item.aid_concentration} leads came from a device logged in as this affiliate.</Tooltip>}>
                                                            <td className={`${warningBg(item.AID, 5, 10)} text-center`}>{item.AID}</td>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{item.PERM.cookie_leads}% of this affiliate's leads came from a device with the same perm.</Tooltip>}>
                                                            <td className={`${warningBg(item.PERM.cookie_leads, 5, 10)} text-right`}>{item.PERM.cookie_leads}%</td>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{item.PERMs} PERM cookies shared with other affiliates.</Tooltip>}>
                                                            <td className={warningBg(item.PERMs, 5, 10)}>{item.PERMs}</td>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{item.UA.concentration}% of this affiliate's leads came from a device the same user agent.</Tooltip>}>
                                                            <td className={`${warningBg(item.UA.concentration, 25, 50)} text-right`}>{item.UA.concentration ? `${item.UA.concentration.toFixed(2)}%` : ''}</td>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{item.proxy}% of this affiliate's leads came from IPs with a proxy score over 50.</Tooltip>}>
                                                            <td className={`${warningBg(item.proxy * 100, 15, 30)} text-right`}>{item.proxy_affiliate_fraud === 'Y' ? item.proxy : ''}</td>
                                                        </OverlayTrigger>
                                                        <td>
                                                            {/* conditional in case there is no campaign or id 0 */}
                                                            {item.campaign.id !== 0 ? <Campaign oid={item.campaign.id} name={item.campaign.name} status={item.campaign.status.value} /> : ''}
                                                        </td>
                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{item.C2L}Click 2 lead is less than 10 seconds in the last day.</Tooltip>}>
                                                            <td className={item.C2L > 30 ? 'bgDanger25' : item.C2L > 15 ? 'bgWarning' : ''}>{item.C2L}</td>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{item.pay.reason}</Tooltip>}>
                                                            <td className={item.pay.payment_held ? 'bgDanger25' : null}>
                                                                {item.pay.payment_held && (
                                                                    <button type="button" className="btn btn-link btn-sm" onClick={() => setModalData({ isOpen: true, affiliate: { id: item.affiliate.id, name: item.affiliate.name } })}>
                                                                        Check
                                                                        {item.pay.hold_payment_flag === 77 && <span className="font-weight-bold ml-1">(PAY HELD)</span>}
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </OverlayTrigger>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={12} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default FraudReport
