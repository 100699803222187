import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Card, Table } from 'react-bootstrap'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import { useHistory } from 'react-router-dom'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const Cancelled = ({ showSystemMessage, mgrId }) => {
    document.title = 'Cancelled Affiliates 30d'

    const defaultMgrId = Number(mgrId) ? Number(mgrId) : 0
    const [adminId, setAdminId] = useState(defaultMgrId)
    const { data, dataLoaded } = useCallApi(`affiliates/cancelled?mgrId=${adminId}`, 'GET', {}, [adminId], showSystemMessage)
    const history = useHistory()
    useEffect(() => {
        history.push(`/affiliates/cancelled?mgrId=${adminId}`)
    }, [adminId])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} priority={tableData.data[tableData.row.index].aff_priority} />
                },
            },
            {
                Header: 'Created',
                accessor: 'aff_db_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY/MM/DD')}</>
                },
            },
            {
                Header: 'Cancelled',
                accessor: 'status_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY/MM/DD')}</>
                },
            },
            {
                Header: 'Reason',
                accessor: 'cancel_reason',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Period',
                accessor: 'tot_days',
                Cell: ({ cell: { value } }) => {
                    return <>{Number(value / 365).toFixed(2)}</>
                },
            },
            {
                Header: 'Aff. Mgr',
                accessor: 'full_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div className="text-right">30d Net. Earn</div>,
                className: 'text-right',
                accessor: 'net_earnings',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div className="text-right">30d Aff. Earn</div>,
                className: 'text-right',
                accessor: 'aff_earnings',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div className="text-right">30d Margin</div>,
                className: 'text-right',
                accessor: 'margin',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div className="text-right">Life Earn</div>,
                className: 'text-right',
                accessor: 'aff_life_earnings',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: 'Last Lead',
                accessor: 'aff_last_lead_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value === null ? '' : moment(value).format('YYYY/MM/DD')}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.cancelled} columns={columns} totals={data.totals} />
            </React.Fragment>
        )
    }

    return (
        <Card>
            <Card.Header className="d-flex align-items-center">
                <div>Affiliates who were Cancelled in the past 30 days</div>
                <div className="ml-2">
                    <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR']} defaultSelf />
                </div>
            </Card.Header>
            {dataLoaded ? data.cancelled.length === 0 ? <div className="p-3">No stats for the current parameters</div> : getReportOutput() : <Loading />}
        </Card>
    )
}

export default Cancelled
