import { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import CampaignViews from './CampaignViews/CampaignViews'
import CampaignSearches from './CampaignSearches/CampaignSearches'

import '../Affiliate.css'

const RecentActivity = (props) => {
    const [selectedActivity, setSelectedActivity] = useState('views')

    const { showSystemMessage, affiliateId } = props

    let statLabelClasses = 'btn btn-secondary'
    let startDate = moment().startOf('month').format('YYYY-MM-DD')
    let endDate = moment().endOf('month').format('YYYY-MM-DD')

    const handleViewChange = (event) => {
        setSelectedActivity(event.target.value)
    }

    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Recent Portal Activity</div>
                <div className="float-right">
                    <Link to={selectedActivity === 'views' ? `/affiliate/${affiliateId}/campaignViews?startDate=${startDate}&endDate=${endDate}` : `/affiliate/${affiliateId}/campaignSearches?startDate=${startDate}&endDate=${endDate}`} className="text-primary">
                        List All
                    </Link>
                </div>
            </div>

            <div className="m-2 btn-group btn-group-sm btn-group-toggle" data-toggle="buttons" role="group">
                <label className={selectedActivity === 'views' ? statLabelClasses + ' active' : statLabelClasses}>
                    <input type="radio" name="activityOptions" id="option1" className="change-graph-option" autoComplete="off" value="views" onClick={handleViewChange} /> Campaign Views
                </label>
                <label className={selectedActivity === 'searches' ? statLabelClasses + ' active' : statLabelClasses}>
                    <input type="radio" name="activityOptions" id="option2" className="change-graph-option" autoComplete="off" value="searches" onClick={handleViewChange} /> Campaign Searches
                </label>
            </div>
            {selectedActivity === 'views' ? <CampaignViews affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignSearches affiliateId={affiliateId} showSystemMessage={showSystemMessage} />}
        </div>
    )
}

export default RecentActivity
