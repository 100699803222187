import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { HelpCircle } from 'react-feather'
import useCallApi from '../../../../hooks/useCallApi'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'
import Dollars from '../../../../templates/Dollars/Dollars'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

export default function AffiliateCampaignPickups({ manager }) {
    const [selectedStatPeriod, setSelectedStatPeriod] = useState('Monthly')

    let apiUrl = `reports/affiliateCampaignPickups?limit=10&period=${selectedStatPeriod}`

    if (manager) {
        apiUrl = `${apiUrl}&adminId=${manager.id}`
    }

    const { data, dataLoaded, refetch } = useCallApi(apiUrl, 'GET', {}, [])

    let statLabelClasses = 'btn btn-secondary'

    useEffect(() => {
        refetch()
    }, [manager, selectedStatPeriod])

    const handleStatTimeChange = (event) => {
        event.preventDefault()
        setSelectedStatPeriod(event.target.value)
    }

    return (
        <div className="card mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <ReactTooltip />
                <span>
                    New Campaign Pickups <HelpCircle className="text-primary" size={14} data-tip="Campaigns that are being run for the first time by your affiliates during the selected period." />
                </span>
                <Link to={`/affiliates/campaignPickups?adminId=${manager ? manager.id : 0}`}>List All</Link>
            </div>
            <div className="d-flex justify-content-center">
                <div className="m-2 btn-group btn-group-sm btn-group-toggle w-75" data-toggle="buttons" role="group">
                    <label className={selectedStatPeriod === 'Monthly' ? statLabelClasses + ' active' : statLabelClasses}>
                        <input type="radio" name="statOptions" id="option1" className="change-graph-option" autoComplete="off" value="Monthly" onClick={handleStatTimeChange} /> Last 30 days
                    </label>
                    <label className={selectedStatPeriod === 'Weekly' ? statLabelClasses + ' active' : statLabelClasses}>
                        <input type="radio" name="statOptions" id="option2" className="change-graph-option" autoComplete="off" value="Weekly" onClick={handleStatTimeChange} /> Last 7 days
                    </label>
                </div>
            </div>
            <div className="card-body p-0 overflow-auto">
                <table className="table w-100 m-0 table-striped table-hover">
                    <thead>
                        <tr className="text-secondary">
                            <th>Campaign</th>
                            <th>Affiliate</th>
                            <th className="text-right">Margin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.report.length === 0 ? (
                                    <tr>
                                        <td colSpan={3}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.report.map((record) => (
                                            <tr key={`${record.affiliate_id}-${record.offer_id}`}>
                                                <td xs={6}>
                                                    <Campaign oid={record.offer_id} name={record.offer_name} status={record.offer_status} />
                                                </td>
                                                <td xs={3}>
                                                    <Affiliate aid={record.affiliate_id} name={record.affiliate_name} priority={record.aff_priority} status={record.affiliate_status} />
                                                </td>
                                                <td xs={3} align="right">
                                                    <Link to={`/reports/stats/affiliates?affiliateId=${record.affiliate_id}&campaignId=${record.offer_id}&startDate=${selectedStatPeriod === 'Monthly' ? moment().add(-30, 'days').format('YYYY-MM-DD') : moment().add(-7, 'days').format('YYYY-MM-DD')}&withAvatar=true`} className="text-primary font-weight-bold">
                                                        <Dollars value={record.margin} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
