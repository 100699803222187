import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import useCallApi from '../../hooks/useCallApi';
import ScrollToBottom from '../../hoc/ScrollToBottom/ScrollToBottom';
import { SystemMessageContext } from '../../context/SystemMessageContext';
import callAPI from '../../helpers/callAPI';
import TextareaImage from '../TextareaImage/TextareaImage';

const maxLogLength = 500

export default function NotesLog({uri, id}){

    const { showSystemMessage } = useContext(SystemMessageContext)

    const { dataLoaded, data, refetch } = useCallApi(`${uri}/${id}/log`, 'GET', {}, [id])

    const [logLength, setLogLength] = useState(0)
    const [preview, setPreview] = useState(false)
    const [file, setFile] = useState(false)

    const saveLog = (e) => {
        e.preventDefault()        

        const formData = new FormData()        
        formData.append('file', file)
        formData.append('log', e.target.log.value)

        callAPI(`${uri}/${id}/log`, 'POST', formData, true)
            .then((result) => {
                if (result['success'] === true) {
                    // showSystemMessage('success', result.message) kind of annoying: MC
                    refetch()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    refetch()
                }
                //reset the textarea
                e.target.log.value = ''
                setFile(false)
                setPreview(false)
                setLogLength(0)
            })
            .catch((error) => {                
                showSystemMessage('error', `Error while saving campaign request: ${error.message}`)
            })
    }

    return (
        <div className='card mb-3'>
            <div className="card-header">
                <h1 className="mb-h1-dark">Log</h1>
            </div>
            <div className='card-body p-0'>
                <div style={{ backgroundColor: 'rgba(0,0,0,.03)', height: '200px', maxHeight: '200px', flexDirection: 'column', overflowY: 'auto' }} className="p-2 border-bottom">
                    {   (dataLoaded && data.log.length > 0) ?
                            data.log.map( item => {
                                return (
                                    <div>
                                        <div>
                                            <span className="font-weight-bold">{moment(item.db_date).utc().format('YYYY-MM-DD HH:mm')}</span>
                                            <span className="text-secondary ml-3">{item.full_name}</span>                                                        
                                        </div>
                                        <div>
                                            <span style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: item.notes }} />
                                            {item.image &&
                                                <div className="mt-2" style={{height:'100px'}}>
                                                    <a target="_blank" href={item.image}>
                                                        <img src={item.image} className="border rounded" style={{maxHeight: '100px'}} alt="Request image" crossOrigin={process.env.REACT_APP_PORTAL_URL} />
                                                    </a>
                                                </div>
                                            }
                                            {item.file &&
                                                <div className="mt-2">
                                                    <div className="alert alert-secondary d-inline-block m-0"><a target="_blank" href={item.file}>📄 Download File</a></div>
                                                </div>
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        :
                            <div className="text-secondary">No notes.</div>
                    }                        
                    <ScrollToBottom />
                </div>                                      
                <div className="p-2">                    
                    <form onSubmit={saveLog}>
                        <TextareaImage name="log" required={true} maxLength={maxLogLength} preview={preview} setPreview={setPreview} setFile={setFile} setLength={setLogLength} />
                        <div className="justify-content-end mt-2 d-flex align-items-center"><span className="text-secondary mr-2">{maxLogLength-logLength}</span> <button className="btn btn-primary">Save Note</button></div>                        
                    </form>
                </div>                
            </div>
        </div>
    )
}