import React, { useState, useEffect, useContext } from 'react';
import Loading from '../../templates/UI/Loading/Loading';
import EarningSummary from '../EarningSummary/EarningSummary';
import { SystemMessageContext } from '../../context/SystemMessageContext';
import NotesLog from '../NotesLog/NotesLog';
import callAPI from '../../helpers/callAPI';
import useCallApi from '../../hooks/useCallApi';
import moment from 'moment';
import Campaign from '../../templates/Campaign/Campaign';
import Dollars from '../../templates/Dollars/Dollars';
import Advertiser from '../../templates/Advertiser/Advertiser';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Number from '../../templates/Number/Number';

export default function AdvertiserReversalRequest({ advertiserId, reversalRequestId, refetch, closeModal }) {

    const { showSystemMessage } = useContext(SystemMessageContext)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [confirmArchive, setConfirmArchive] = useState(false)
    const [reversalData, setReversalData] = useState('')
    const [validateDataLoading, setValidateDataLoading] = useState(false)
    const [saveDataLoading, setSaveDataLoading] = useState(false)
    const [validatedData, setValidatedData] = useState(false)
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [readyToReview, setReadyToReview] = useState(false)
    const [saveStatusLoading, setSaveStatusLoading] = useState(false)

    const { data, dataLoaded } = useCallApi(`advertisers/reversalRequest?id=${reversalRequestId}`, 'GET', {}, [], showSystemMessage)

    useEffect(() => {
        console.log(data)
        if (data.reversalRequest && data.reversalRequest.start_date) {
            setStartDate(moment(data.reversalRequest.start_date).utc().format('YYYY-MM-DD'))
        }
        if (data.reversalRequest && data.reversalRequest.end_date) {
            setEndDate(moment(data.reversalRequest.end_date).utc().format('YYYY-MM-DD'))
        }
        if (data.reversalRequest && data.reversalRequest.reversal_data) {
            setReversalData(data.reversalRequest.reversal_data)
        }
    }, [data])

    const deleteReversalRequest = async () => {
        let result = await callAPI(`advertisers/reversalRequests`, 'DELETE', { id: reversalRequestId }, false)
        if (result.success) {
            showSystemMessage('success', result.message)
            if (refetch) {
                refetch && refetch()
                closeModal && closeModal()
            }
        }
    }

    const validateReversalData = async () => {

        setValidateDataLoading(true)
        setValidatedData(false)

        let result = await callAPI(`advertisers/reversalRequest/validate`, 'POST', { id: reversalRequestId, advertiserId: advertiserId, startDate: startDate, endDate: endDate, data: reversalData }, false)
        if (result.success) {
            setValidatedData(result.report)
        } else {
            showSystemMessage('error', result.errors.message)
        }
        setValidateDataLoading(false)
    }

    const saveReversalData = async () => {
        setSaveDataLoading(true)

        let result = await callAPI(`advertisers/reversalRequest/data`, 'PUT', { id: reversalRequestId, data: reversalData, startDate: startDate, endDate: endDate }, false)
        if (result.success) {
            showSystemMessage('success', result.message)
        } else {
            showSystemMessage('error', result.errors.message)
        }

        setSaveDataLoading(false)
    }

    const updateReversal = async (status, condition) => {
        setSaveStatusLoading(true)

        let result = await callAPI(`advertisers/reversalRequest`, 'PUT', { id: reversalRequestId, data: reversalData, startDate: startDate, endDate: endDate, status: status, condition: condition || false }, false)
        if (result.success) {
            showSystemMessage('success', result.message)
            closeModal && closeModal()
            refetch && refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }

        setSaveStatusLoading(false)
    }

    const getFoundLeadsCount = (campaigns) => {
        let leads = 0
        campaigns.map(campaign => {
            leads += campaign.leads
            return
        })
        return leads
    }

    return (
        <div className="card">
            <div className="card-header">Reversal Request</div>
            <div className="card-body p-0">
                <div className="alert alert-info m-3">
                    Page Link: <Link to={`/advertiser/${advertiserId}/reversalRequest?id=${reversalRequestId}`}>{`${process.env.REACT_APP_PORTAL_URL}/advertiser/${advertiserId}/reversalRequest?id=${reversalRequestId}`}</Link>
                    <span className='ml-3'>
                        <CopyToClipboard text={`${process.env.REACT_APP_PORTAL_URL}/advertiser/${advertiserId}/reversalRequest?id=${reversalRequestId}`}>
                            <button type="button" className="btn btn-outline-primary shadow-none" size="sm">
                                <FontAwesomeIcon icon="copy" />
                            </button>
                        </CopyToClipboard>
                    </span>
                </div>
                {dataLoaded ? (
                    <div className="m-3">
                        <div className="row">
                            <div className="col-xl-6">
                                {dataLoaded && (
                                    <div className="d-flex border rounded mb-3 justify-content-between">
                                        <div className="mx-2 p-2 d-flex align-items-center"><Advertiser id={advertiserId} name={data.reversalRequest.advertiser_name} /></div>
                                        <div className="mx-2 border-left p-2"><strong>{data.reversalRequest.admin_name}</strong><div className="text-secondary">{moment(data.reversalRequest.created_date).utc().format('YYYY-MM-DD HH:mm:ss')}</div></div>
                                        <div className="mx-2 border-left p-2"><strong>{data.reversalRequest.status}</strong><div className="text-secondary">{moment(data.reversalRequest.status_date).utc().format('YYYY-MM-DD HH:mm:ss')}</div></div>
                                    </div>
                                )}
                                <NotesLog uri={`advertisers/reversalRequests`} id={reversalRequestId} />
                                <EarningSummary showSystemMessage={showSystemMessage} advertiserId={advertiserId} />
                            </div>
                            <div className="col-xl-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h1 className="mb-h1-dark">Reversal Data</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="alert alert-secondary">
                                            Paste reversal data here.<br></br>
                                            <strong>Format in spreadsheet should be:</strong> [ClickId] [Lead Data]
                                        </div>
                                        <div className='mb-3 d-flex align-items-center'>
                                            <div>Start Date: </div>
                                            <div className="ml-2">
                                                <input className="form-control" type="date" defaultValue={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                                            </div>
                                            <div className="ml-2">End Date: </div>
                                            <div className="ml-2">
                                                <input className="form-control" type="date" defaultValue={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                                            </div>
                                        </div>
                                        <textarea rows={10} className="form-control" defaultValue={data.reversalRequest.reversal_data} onChange={(e) => { setReversalData(e.target.value) }}></textarea>
                                        <div className="text-right">
                                            <button className="btn btn-warning mt-2" onClick={() => { validateReversalData() }} disabled={validateDataLoading}>Validate Data</button>
                                            <button className="btn btn-primary mt-2 ml-2" onClick={() => { saveReversalData() }} disabled={saveDataLoading}>Save Data</button>
                                        </div>
                                        {
                                            validateDataLoading &&
                                            <Loading />
                                        }
                                        {
                                            (!validateDataLoading && validatedData) &&
                                            <>
                                                <div className="border-top pt-2 mt-2">{getFoundLeadsCount(validatedData.campaigns)}/{validatedData.rowsInputted} leads are reversable</div>
                                                <div className="mt-2">
                                                    {
                                                        validatedData.campaigns &&
                                                        <table className="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>Campaigns</th>
                                                                    <th>Lead Month</th>
                                                                    <th className="text-right">Leads</th>
                                                                    <th className="text-right">Net Earnings</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {validatedData.campaigns.map(campaign => {
                                                                    return <tr>
                                                                        <td><Campaign oid={campaign.offer_id} name={campaign.name} /></td>
                                                                        <td>{moment(campaign.lead_month).utc().format('YYYY / MMMM')}</td>
                                                                        <td className="text-right"><strong className="text-danger">{campaign.leads}</strong> / {campaign.total_leads} (<Number value={campaign.leads / campaign.total_leads * 100} />%)</td>
                                                                        <td className="text-right"><strong className="text-danger"><Dollars value={campaign.net_earnings} /></strong> / <Dollars value={campaign.total_earnings} /></td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-header">
                                        <h1 className="mb-h1-dark">Actions</h1>
                                    </div>
                                    <div className="card-body">
                                        {(data.reversalRequest.status_value === 'O' || data.reversalRequest.status_value === 'V') &&
                                            <>
                                                <label className="d-flex align-items-center alert alert-info">
                                                    <input type="checkbox" name="ready_to_review" onClick={(e) => setReadyToReview(e.target.checked)} />
                                                    <span className="ml-1">I have verified that all leads are being reversed for valid reasons.</span>
                                                </label>
                                                <button className="btn btn-primary" disabled={!readyToReview || saveStatusLoading} onClick={() => updateReversal('C')}>Submit to Compliance</button>
                                                <button className="btn btn-primary ml-2" disabled={!readyToReview || saveStatusLoading} onClick={() => { updateReversal('R'); }}>Submit for Review</button>                                                
                                            </>
                                        }
                                        {
                                            (data.reversalRequest.status_value === 'C') &&
                                            <>
                                                <label className="d-flex align-items-center alert alert-info">
                                                    <input type="checkbox" name="ready_to_review" onClick={(e) => setReadyToReview(e.target.checked)} />
                                                    <span className="ml-1">The data displayed in the window above is ready for review.</span>
                                                </label>
                                                <button className="btn btn-danger" disabled={saveStatusLoading} onClick={() => updateReversal('V')}>Send back to AAM</button>
                                                <button className="btn btn-primary ml-2" disabled={!readyToReview || saveStatusLoading} onClick={() => { updateReversal('R'); }}>Submit for Review</button>
                                            </>
                                        }
                                        {(data.reversalRequest.status_value === 'R') &&
                                            <>
                                                <div><button className="btn btn-primary" disabled={saveStatusLoading} onClick={() => updateReversal('A')}>Create Reversal Ticket</button></div>
                                                <div className="mt-2">
                                                    <button className="btn btn-danger" disabled={saveStatusLoading} onClick={() => updateReversal('V')}>Send back to AAM</button>
                                                    <button className="btn btn-danger ml-2" disabled={saveStatusLoading} onClick={() => updateReversal('C')}>Send back to Compliance</button>
                                                </div>
                                            </>
                                        }
                                        {(data.reversalRequest.status_value === 'A') &&
                                            <div className="alert alert-info font-weight-bold">
                                                This request has been processed. <Link to={`/admin/tickets/manage`}>Queue Ticket ID: {data.reversalRequest.queue_ticket_id}</Link>
                                            </div>
                                        }
                                        {(data.reversalRequest.status_value !== 'A' && data.reversalRequest.status_value !== 'X') &&
                                            <div className="mt-2 border-top pt-2">
                                                {!confirmArchive ?
                                                    <button className="btn btn-outline-danger" onClick={() => { setConfirmArchive(true) }}>Archive</button> :
                                                    <button className="btn btn-danger" disabled={saveStatusLoading} onClick={() => updateReversal('X')}> Confirm Archive</button>
                                                }
                                                <button className="btn btn-info ml-2" disabled={saveStatusLoading} onClick={() => updateReversal('A', 'noReversals')}>No reversals for the selected period</button>
                                            </div>
                                        }
                                        {
                                            (data.reversalRequest.status_value === 'X') &&
                                            <button className="btn btn-success" disabled={saveStatusLoading} onClick={() => updateReversal('O')}>Reopen Reversal Request</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {data.reversalRequest.status_value !== 'A' &&
                                (!confirmDelete ?
                                    <button className="btn btn-outline-danger" onClick={() => { setConfirmDelete(true) }}>Delete</button>
                                    :
                                    <button className="btn btn-danger" onClick={() => { deleteReversalRequest() }}>Confirm Delete</button>
                                )
                            }

                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}