import React, { useState, useEffect, useContext } from 'react'
import callAPI from '../../../helpers/callAPI'

import Loading from '../../../templates/UI/Loading/Loading'
import { SystemMessageContext } from '../../../context/SystemMessageContext'
import useCallApi from '../../../hooks/useCallApi'
import BizYear from '../../Reports/ReportBizDevPipeline/BizYear'

const BizdevPipeline = () => {
    
    const {data, dataLoaded} = useCallApi(`reports/bizdev_pipeline`, 'GET', {}, [])

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <table className="table table-striped table-card table-hover mb-0 bizdev">
                    <thead>
                        <tr>
                            <th scope="col">&nbsp;</th>
                            <th scope="col" className="text-right">
                                Active Adv.
                            </th>
                            <th scope="col" className="text-right">
                                Adv. with Sales
                            </th>
                            <th scope="col" className="text-right">
                                New
                            </th>
                            <th scope="col" className="text-right">
                                Discussion
                            </th>
                            <th scope="col" className="text-right">
                                Negotiation
                            </th>
                            <th scope="col" className="text-right">
                                Promoted to Active
                            </th>
                            <th scope="col" className="text-right">
                                Cancel-old
                            </th>
                            <th scope="col" className="text-right">
                                Cancel-new
                            </th>
                            <th scope="col" width="20px">
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.report.map((record) => {
                            return <BizYear record={record} key={record.db_date} />
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    return (
        <div className="row">
                <div className="col-xl-6">
                    <div className="page-header d-flex align-items-center">
                        <span className="mb-h1">Bizdev Pipeline Report</span>
                        {/* <HeaderStats showSystemMessage={props.showSystemMessage}></HeaderStats> */}
                    </div>
                    <div className="card pb-0">
                        { dataLoaded ? 
                            data.report.length === 0 ?                                    
                                        <table className="table table-striped table-card table-hover mb-0">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="11">No stats for this date range.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    :
                                        <React.Fragment>{getReportOutput()}</React.Fragment>                        
                                    
                         : 
                            <table className="table table-striped table-card table-hover mb-0">
                                <tbody>
                                    <tr>
                                        <td colSpan="11">
                                            <Loading />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
        </div>
    )
}

export default BizdevPipeline