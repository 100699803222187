import { Card } from "react-bootstrap"


const AdvertiserApprovalInfoMainPage = ({ approvalRequirements, approvalNotes }) => {

    console.log(approvalRequirements, approvalNotes)

    let approvalReq = ''

    if (approvalRequirements === 'NOT_REQUIRED') {
        approvalReq = 'Placement not needed'
    } else if (approvalRequirements === 'ADVERTISER_REQUIRED') {
        approvalReq = 'Placement needed with advertiser approval'
    } else if (approvalRequirements === 'MB_REQUIRED') {
        approvalReq = 'Placement needed with approval at our discretion'
    }

    return (
        <Card className="mb-3">
            <Card.Header>
                Advertiser Approval Info
            </Card.Header>
            <Card.Body className="p-2">
                <div>
                    {approvalReq}
                </div>
                <div className="text-muted">
                    {approvalNotes}
                </div>
            </Card.Body>
        </Card>
    )
}

export default AdvertiserApprovalInfoMainPage