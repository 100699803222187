import { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'

const CategoryModal = ({ isOpen, setIsOpen, isCreate, formData, setFormData, refetch, showSystemMessage }) => {
    useEffect(() => {
        setFormData({ ...formData, newOrder: formData.order })
    }, [isOpen])
    const createCategory = async () => {
        const response = await callAPI('affiliates/resources/categories', 'POST', formData)
        if (response.success) {
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while creating the resource')
        }
    }
    const updateCategory = async () => {
        console.log(formData)
        const response = await callAPI(`affiliates/resources/categories`, 'PUT', formData)
        if (response.success) {
            showSystemMessage('success', 'Category updated successfully')
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while updating the category')
        }
    }

    const deleteCategory = async () => {
        const response = await callAPI(`affiliates/resources/categories/${formData.order}`, 'DELETE')
        if (response.success) {
            showSystemMessage('success', 'Category deleted successfully')
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while deleting the category')
        }
    }
    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Create' : 'Edit'} Resource Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isOpen ? (
                    <>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Order:</span>
                            {!isCreate ? <input type="number" className="form-control" value={formData.newOrder || formData.order} onChange={(event) => setFormData((prev) => ({ ...prev, newOrder: Number(event.target.value) }))} /> : <input type="number" className="form-control" value={formData.order} onChange={(event) => setFormData((prev) => ({ ...prev, order: Number(event.target.value) }))} />}
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Name:</span>
                            <input type="text" className="form-control" value={formData.name} onChange={(event) => setFormData((prev) => ({ ...prev, name: event.target.value }))} />
                        </div>
                    </>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="primary" onClick={createCategory}>
                        Create Category
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={updateCategory}>
                            Update Category
                        </Button>
                        <Button variant="danger" onClick={deleteCategory}>
                            Delete Category
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default CategoryModal
