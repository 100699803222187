import { Card, Form, Table, Button } from 'react-bootstrap'

const Restrictions = ({ data, setData, trafficTypes, setIsDisabled }) => {
    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <div>Rules and Restrictions</div>
                </div>
            </Card.Header>
            <Table striped bordered>
                <tbody>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Search Restrictions</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                value={data.restrictions.search_restrictions}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, restrictions: { ...data.restrictions, search_restrictions: e.target.value } })
                                }}
                                required={trafficTypes.search_flag === 'Y' ? true : false}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Email Rules</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                value={data.restrictions.email_usage_rules}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, restrictions: { ...data.restrictions, email_usage_rules: e.target.value } })
                                }}
                                required={trafficTypes.solo_emails_flag === 'Y' ? true : false}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Acceptable Email Subject Lines</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                value={data.restrictions.subject_lines}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, restrictions: { ...data.restrictions, subject_lines: e.target.value } })
                                }}
                                required={trafficTypes.solo_emails_flag === 'Y' ? true : false}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Acceptable Email From Lines</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                value={data.restrictions.from_lines}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, restrictions: { ...data.restrictions, from_lines: e.target.value } })
                                }}
                                required={trafficTypes.solo_emails_flag === 'Y' ? true : false}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Email Suppression List</span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.restrictions.suppression_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, restrictions: { ...data.restrictions, suppression_flag: e.target.checked } })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Email Suppression URLs</span>
                        </td>
                        <td>
                            {data.restrictions.suppression_urls.length > 0 ? (
                                data.restrictions.suppression_urls.map((url, index) => (
                                    <div className="d-flex justify-content-between align-items-center mb-2" key={index}>
                                        <Form.Control
                                            className="mb-2 w-75"
                                            size="sm"
                                            type="text"
                                            value={url.suppression_url}
                                            onChange={(e) => {
                                                setIsDisabled(false)
                                                setData({ ...data, restrictions: { ...data.restrictions, suppression_urls: data.restrictions.suppression_urls.map((url, i) => (i == index ? { ...url, suppression_url: e.target.value } : url)) } })
                                            }}
                                        />
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            onClick={() => {
                                                setIsDisabled(false)
                                                setData({ ...data, restrictions: { ...data.restrictions, suppression_urls: data.restrictions.suppression_urls.filter((url, i) => i != index) } })
                                            }}
                                        >
                                            Remove URL
                                        </Button>
                                    </div>
                                ))
                            ) : (
                                <span className="text-muted">No URLs </span>
                            )}
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={() => {
                                    setData({ ...data, restrictions: { ...data.restrictions, suppression_urls: [...data.restrictions.suppression_urls, ''] } })
                                }}
                            >
                                Add URL
                            </Button>
                        </td>
                    </tr>
                    {/* <tr>
                            <td className="w-25">
                                <span className="d-flex justify-content-end align-items-center">Suppression File Last Loaded</span>
                            </td>
                            <td>
                                <span>{restrictions.suppression_date != null ? restrictions.suppression_date : 'No file found'}</span>
                            </td>
                        </tr> */}
                </tbody>
            </Table>
        </Card>
    )
}

export default Restrictions
