import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'

import CreateCampaign from '../CreateCampaign/CreateCampaign'
import moment from 'moment'
import useCallApi from '../../../hooks/useCallApi'

const AdvertiserNavbar = ({ advertiserId, updateCampaignList, showSystemMessage }) => {
    const auth = useAuth()
    const showEdit = useProtectedContent(auth.permissions, 'advertiser_settings')
    const showCreateCampaign = useProtectedContent(auth.permissions, 'advertiser_create_campaign')
    const showCreateMap = useProtectedContent(auth.permissions, 'create_map_account')

    const { data, dataLoaded } = useCallApi(`advertiser/${advertiserId}`, 'GET', {}, [advertiserId])

    const [statsOpen, setStatsOpen] = useState(false)
    const [accountOpen, setAccountOpen] = useState(false)
    const [campaignDataOpen, setCampaignDataOpen] = useState(false)
    const [showCreateCampaignModal, setShowCreateCampaignModal] = useState(false)

    const handleClose = (isCreated = false) => {
        setShowCreateCampaignModal(false)

        if (isCreated) {
            updateCampaignList()
        }
    }

    return (
        <nav className="mb-header-nav">
            {showCreateCampaignModal && <CreateCampaign advertiserId={advertiserId} showSystemMessage={showSystemMessage} handleClose={handleClose} isOpen={showCreateCampaignModal} />}
            <ul>
                <li>
                    <Dropdown isOpen={accountOpen} toggle={() => setAccountOpen(!accountOpen)} direction="down">
                        <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={accountOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                            Account
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link className="dropdown-item" to={`/advertiser/${advertiserId}`}>
                                Manage Advertiser
                            </Link>
                            {showEdit && (
                                <>
                                    <DropdownItem divider className="m-0" />
                                    <Link className="dropdown-item" to={`/advertiser/${advertiserId}/edit`}>
                                        Advertiser Settings
                                    </Link>
                                </>
                            )}
                            {dataLoaded ? (data.general_info.status.toLowerCase() !== 'cancelled' && showCreateMap) && (
                                <>
                                    <DropdownItem divider className="m-0" />
                                    <Link className="dropdown-item" to={`/advertiser/${advertiserId}/createMapAccount`}>
                                        Create MAP Account
                                    </Link>
                                </>
                            ) : ''}
                        </DropdownMenu>
                    </Dropdown>
                </li>
                <li>
                    <Dropdown isOpen={statsOpen} toggle={() => setStatsOpen(!statsOpen)} direction="down">
                        <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={statsOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                            Reports
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link className="dropdown-item" to={`/reports/stats/campaigns?advertiserId=${advertiserId}&navigation=advertiser&withAvatar=true`}>
                                Campaign Earnings
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/reports/stats/affiliates?advertiserId=${advertiserId}&navigation=advertiser&withAvatar=true`}>
                                Affiliate Earnings
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/reports/stats/monthly?advertiserId=${advertiserId}&navigation=advertiser&startDate=${moment().add(-12, 'months').startOf('month').format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}&withAvatar=true`}>
                                Monthly Earnings
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/reports/stats/conversions?advertiserId=${advertiserId}&navigation=advertiser&startDate=${moment().startOf('day').format('YYYY-MM-DD')}`}>
                                Conversions
                            </Link>
                            <DropdownItem divider className="m-0" />
                            <Link className="dropdown-item" to={`/advertiser/${advertiserId}/reversalStatusReport`}>
                                Reversal Status Report
                            </Link>
                        </DropdownMenu>
                    </Dropdown>
                </li>
                <li>
                    <Dropdown isOpen={campaignDataOpen} toggle={() => setCampaignDataOpen(!campaignDataOpen)} direction="down">
                        <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={campaignDataOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                            Campaigns
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link className="dropdown-item" to={`/advertiser/${advertiserId}/campaigns`}>
                                View Campaigns
                            </Link>
                            {showCreateCampaign && (
                                <>
                                    <DropdownItem divider className="m-0" />
                                    <button
                                        className="dropdown-item btn btn-link"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setShowCreateCampaignModal(true)
                                        }}
                                    >
                                        Create Campaign
                                    </button>
                                </>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </li>
            </ul>
        </nav>
    )
}

export default AdvertiserNavbar
