import { useEffect, useState } from 'react'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default function MailerEditor({ setConvertedContent }) {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

    useEffect(() => {
        let rawContentState = convertToRaw(editorState.getCurrentContent())

        let convertedContent = draftToHtml(rawContentState)
        setConvertedContent(convertedContent)
    }, [editorState])

    return <Editor editorState={editorState} onEditorStateChange={setEditorState} />
}
