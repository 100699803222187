import { Card, Table, OverlayTrigger, Popover, Modal } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import '../../../../theme/classes.scss'
import EditCampaignPhase from './EditCampaignPhase/EditCampaignPhase'
import Campaign from '../../../../templates/Campaign/Campaign'

const CampaignsInSetup = ({ manager, limit, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)
    const [showEditCampaignPhaseModal, setShowEditCampaignPhaseModal] = useState(false)
    const [selectedCampaignId, setSelectedCampaignId] = useState(0)

    useEffect(() => {
        fetchData()
    }, [manager])

    const fetchData = async () => {
        setDataLoaded(false)

        const data = await callAPI(`campaigns/setupPhase?id=${manager.id}`, 'GET')
        if (data.success) {
            setData(data)
        }
        setDataLoaded(true)
    }

    const handleClick = (e, campaignId) => {
        e.preventDefault()
        setSelectedCampaignId(campaignId)
        setShowEditCampaignPhaseModal(true)
    }

    const handleClose = (isEdited = false) => {
        setShowEditCampaignPhaseModal(false)
        if (isEdited) {
            fetchData()
        }
    }

    return (
        <Card className="mb-3">
            <Modal show={showEditCampaignPhaseModal} onHide={handleClose}>
                <EditCampaignPhase handleClose={handleClose} selectedCampaignId={selectedCampaignId} showSystemMessage={showSystemMessage} />
            </Modal>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>
                    <span className="mr-1">Campaigns in Setup Phase</span>
                </span>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0 nowrap">
                    <thead>
                        <tr className="text-secondary">
                            <th width="8%">ID</th>
                            <th width="32%">Advertiser</th>
                            <th width="32%">Campaign</th>
                            <th width="13%">Status</th>
                            <th width="15%">Checklist</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.campaigns.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.campaigns.map((campaign) => {
                                            return (
                                                <tr key={campaign.id}>
                                                    <td>{campaign.id}</td>
                                                    <td>
                                                        <Link to={`/advertiser/${campaign.advertiser.id}`}>{campaign.advertiser.name}</Link>
                                                    </td>
                                                    <td>
                                                        <Campaign oid={campaign.id} name={campaign.name} status={campaign.status} />
                                                    </td>
                                                    <td>{campaign.status}</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <span className="mr-1">
                                                                <Link to="" onClick={(e) => handleClick(e, campaign.id)}>
                                                                    View
                                                                </Link>
                                                            </span>
                                                            <div>
                                                                ({campaign.checklist.completed} / {campaign.checklist.total})
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default CampaignsInSetup
