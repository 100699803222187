import moment from 'moment'
import React from 'react'

import getAdvertiserFollowupResult from '../../../helpers/getAdvertiserFollowupResult'
import getFollowupResult from '../../../helpers/getFollowupResult'
import Pill from '../../../templates/UI/Pill/Pill'

const FollowupListItem = (props) => {
    return (
        <div>
            <div>
                <span className="font-weight-bold">{props.followup.followup_date ? moment(props.followup.followup_date).utc().format('YYYY-MM-DD HH:mm') : 'Notes:'}</span>
                <span className="text-secondary ml-3">{props.followup.full_name}</span>
                <span className="float-right">{props.type === 'affiliate' ? <Pill text={getFollowupResult(props.followup.results)} borderColor="#2fb62f" /> : <Pill text={getAdvertiserFollowupResult(props.followup.results)} borderColor="#2fb62f" />}</span>
            </div>
            <div>
                <span style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: props.followup.notes }} />
            </div>
            <hr />
        </div>
    )
}

export default FollowupListItem
