import React from 'react'

import './Pill.css'

const Pill = (props) => {
    const borderColor = props.borderColor ? props.borderColor : ''
    const textColor = props.textColor ? props.textColor : ''

    return (
        <div className="mb-pill" style={{ borderColor: borderColor, color: textColor }}>
            {props.text}
        </div>
    )
}

export default Pill
