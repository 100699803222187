import moment from 'moment'
import React from 'react'

import Pill from '../../../../templates/UI/Pill/Pill'

const AffiliatePaymentInfo = ({ payment, lastLoginDate }) => {

    return (
        <div className="p-2 border-top d-flex align-items-center justify-content-between">
            <div className='mr-2'>
                Payout Details <Pill borderColor={payment.platform ? '#17a2b8' : ''} text={payment.platform ? payment.platform.toUpperCase() : 'Not Set'} /> : <Pill borderColor={payment.method.method ? '#17a2b8' : ''} text={payment.method.method ? payment.method.method : 'Not Set'} />
            </div>

            <div className='ml-2 d-flex'>
                <span>Last Login Date</span>
                <span className="ml-3 mr-0">
                    <span className="name">
                        {lastLoginDate ? moment(lastLoginDate).utc().format('YYYY-MM-DD') : 'Never'}
                    </span>
                </span>
            </div>
        </div>
    )
}

export default AffiliatePaymentInfo
