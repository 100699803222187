import { Card, Row, Col, Table, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import TimeScalePicker from '../../../../components/TimeScalePicker/TimeScalePicker'
import { colors } from '../../../../theme/variables'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapPin, faPen } from '@fortawesome/free-solid-svg-icons'
import CampaignSlidersModal from '../CampaignSlidersModal/CampaignSlidersModal'
import Campaign from '../../../../templates/Campaign/Campaign'

const CampaignSliders = ({ showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)
    const [modalData, setModalData] = useState({
        isOpen: false,
        campaign: {
            name: '',
        },
        slider: {
            id: null,
        },
        description: '',
        image: '',
    })
    const [formData, setFormData] = useState({
        offer: '',
        url: null,
        desc: null,
        imgName: null,
        customBtnTxt: 'View Offer',
        pinned: null,
    })

    const fetchCampaigns = async () => {
        setDataLoaded(false)
        const data = await callAPI(`campaigns/sliders`, 'GET')
        if (data.success) {
            const result = data.campaigns.map((item, index) => {
                return {
                    offerId: item.offer_id,
                    id: item.id,
                    name: item.name,
                    status: item.status,
                    description: item.description,
                    image: item.image,
                    pinned: item.pinned == 'Y' ? true : false,
                }
            })
            setData(result)
        }
        setDataLoaded(true)
    }

    useEffect(() => {
        fetchCampaigns()
    }, [])

    const openEditModal = (sliderId, campaignId, campaign, description, image) => {
        setModalData((prevState) => ({ ...prevState, campaign: { id: campaignId > 0 ? campaignId : 0, name: campaign }, slider: { id: sliderId }, description: description, image: image, type: 'edit', isOpen: true }))
    }

    return (
        <Card className="mb-3">
            <CampaignSlidersModal modalData={modalData} setModalData={setModalData} fetchCampaigns={fetchCampaigns} showSystemMessage={showSystemMessage} formData={formData} setFormData={setFormData} />
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>Campaign Sliders </span>

                <Button
                    variant="warning"
                    size="sm"
                    onClick={() => {
                        setFormData({
                            offer: '',
                            url: '',
                            desc: '',
                            imgName: '',
                            customBtnTxt: '',
                            pinned: '',
                        })
                        setModalData((prevState) => ({ ...prevState, isOpen: true, type: 'create' }))
                    }}
                >
                    Add Slider
                </Button>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Campaign</th>
                            <th>Description</th>
                            <th>Image</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.length === 0 ? (
                                    <tr>
                                        <td colSpan={4}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.map((record) => (
                                            <tr key={record.id}>
                                                <td>{record.offerId}</td>
                                                <td>
                                                    <Campaign name={record.name} oid={record.offerId} status={record.status} />
                                                </td>
                                                <td>{record.description}</td>
                                                <td>{record.image}</td>
                                                <td align="right">{record.pinned ? <FontAwesomeIcon style={{ color: 'red', marginRight: '15px' }} icon={faMapPin} /> : null}</td>
                                                <td align="right">
                                                    <button style={{ color: 'orange', marginRight: '15px' }} className="btn btn-sm btn-lnk" onClick={() => openEditModal(record.id, record.offerId, record.name, record.description, record.image)}>
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default CampaignSliders
