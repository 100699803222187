import React, { Component } from 'react'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Click from '../../../templates/Click/Click'
import ReactTooltip from 'react-tooltip'

import Loading from '../../../templates/UI/Loading/Loading'
import ReportsMore from '../ReportsMore/ReportsMore'
import TableContainer from '../TableContainer'
import { FaCaretUp, FaCaretDown } from 'react-icons/fa'
import callAPI from '../../../helpers/callAPI'
import moment from 'moment'

class ReportTestPostbacks extends Component {
    state = {
        dataLoaded: true,
        report: [],
    }

    componentDidMount() {
        const fetchData = async () => {
            this._isMounted = true

            this.setState({
                dataLoaded: false,
            })

            const mbToken = localStorage.getItem('adm-auth-token')

            /* Fetch Affiliate Applications Stats from DB and populate the reports table and charts */

            let url = `reports/${this.props.oid}/postbacks?startDate=${this.props.startDate}&endDate=${this.props.endDate}&page=all`
            if (this.props.oid > 0) {
                url = url + `&oid=${this.props.oid}`
            }

            if (this.props.filterStartDate && this.props.filterStartDate !== '') {
                url = url + `&filterStartDate=${this.props.filterStartDate}`
            }

            if (this.props.filterEndDate && this.props.filterEndDate !== '') {
                url = url + `&filterEndDate=${this.props.filterEndDate}`
            }

            if (this.props.ap > 0) {
                url = url + `&ap=${this.props.ap}`
            }

            if (this.props.as && this.props.as !== '') {
                url = url + `&as=${this.props.as}`
            }

            if (this.props.ta && this.props.ta === 'true') {
                url = url + `&ta=${this.props.ta}`
            }

            //console.log('TA:'+this.props.ta);
            const result = await callAPI(url, 'GET')

            if (result['success'] === true) {
                if (this._isMounted) {
                    this.setState({
                        dataLoaded: true,
                        report: result.data,
                    })
                }
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    this.props.showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                this.props.showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }
    getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={this.state.report}
                    reportType="postbacks"
                    columns={[
                        {
                            Header: 'Date',
                            accessor: 'db_date',
                        },
                        {
                            Header: 'URL Params',
                            accessor: 'call_string',
                        },
                        {
                            Header: 'Lead data',
                            accessor: 'lead_data',
                        },
                        {
                            Header: 'Action ID',
                            accessor: 'action_id',
                        },
                        {
                            Header: 'Rate Name',
                            accessor: 'rate_name',
                        },
                        {
                            Header: 'Sale Amount',
                            accessor: 'sale_amount',
                        },
                        {
                            Header: 'Debug',
                            accessor: 'debug_message',
                        },
                    ]}
                />
                <ReactTooltip />
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {this.state.report.length === 0 ? (
                            <table className="table table-striped table-card table-hover mb-0">
                                <tr>
                                    <td colSpan="11">No stats for this date range.</td>
                                </tr>
                            </table>
                        ) : (
                            <React.Fragment>
                                {this.props.oid ? (
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mr-4 d-flex align-items-center">
                                                <h1 className="mb-h1-dark">Test postbacks for Offer: {this.props.oid}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {this.getReportOutput()}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <table className="table table-striped table-card table-hover mb-0">
                        <tr>
                            <td colSpan="11">
                                <Loading />
                            </td>
                        </tr>
                    </table>
                )}
            </React.Fragment>
        )
    }
}

export default ReportTestPostbacks
