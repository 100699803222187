import React, { useState, useEffect } from 'react'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import MarketingCampaignList from '../Components/MarketingCampaignList/MarketingCampaignList'
import callAPI from '../../../helpers/callAPI'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import MarketingPlatformDropdown from '../Components/MarketingPlatformDropdown/MarketingPlatformDropdown'

export default function MarketingCampaigns({ showSystemMessage }) {

    document.title = 'Marketing Campaigns'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'marketing')

    const [campaign, setCampaign] = useState(false)

    const [campaignInfoLoaded, setCampaignInfoLoaded] = useState(false)
    const [campaignInfo, setCampaignInfo] = useState(false)
    const [marketingPlatforms, setMarketingPlatforms] = useState([])
    const [marketingPlatformId, setMarketingPlatformId] = useState(false)

    // Get the marketing campaigns using useCallAPI
    const { data, dataLoaded, refetch } = useCallApi('marketing/campaigns', 'GET', {}, [])

    useEffect(() => {
        // fetch the campaign data if the campaignId changes using callAPI
        if (campaign.id > 0) {
            setCampaignInfoLoaded(false)

            fetchCampaign(campaign)
        } else if (campaign.id === 0) {
            setCampaignInfoLoaded(true)
            setCampaignInfo({
                id: 0,
                name: '',
                date: '',
                status: {
                    name: 'Active',
                    value: 'A',
                },
                header: '',
                image: '',
                content: '',
            })
            setMarketingPlatforms([])
        }
    }, [campaign])

    const fetchCampaign = (campaign) => {
        callAPI(`marketing/campaign/${campaign.id}`, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setCampaignInfo(result.campaign_info)
                    setMarketingPlatforms(result.marketing_platforms)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setCampaignInfo({
                        id: 0,
                        name: '',
                        date: '',
                        status: {
                            name: 'Active',
                            value: 'A',
                        },
                        header: '',
                        image: '',
                        content: '',
                    })
                }
                setCampaignInfoLoaded(true)
            })
            .catch((error) => {
                console.log(`Error while fetching marketing campaign info: ${error}`)
                showSystemMessage('error', 'Error fetching marketing campaign info.')
                setCampaignInfoLoaded(true)
            })
    }

    const saveCampaign = (e) => {
        e.preventDefault()

        let apiUrl = campaign.id > 0 ? `marketing/campaign/${campaign.id}` : `marketing/campaign`
        let method = campaign.id > 0 ? 'PUT' : 'POST'

        callAPI(apiUrl, method, campaignInfo)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Marketing campaign saved.')
                    refetch()
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while saving marketing campaign info: ${error.message}`)
                showSystemMessage('error', 'Error saving marketing campaign info.')
            })
    }

    const addPlatform = (campaignId, marketingPlatformId) => {
        if (marketingPlatformId > 0) {
            callAPI(`marketing/campaign/${campaignId}/platform`, 'POST', { platformId: marketingPlatformId })
                .then((result) => {
                    if (result['success'] === true) {
                        fetchCampaign(campaign)
                    } else {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    console.log(`Error while adding marketing platform: ${error.message}`)
                    showSystemMessage('error', 'Error while adding marketing platform.')
                })
        }
    }

    const removePlatform = (campaignId, marketingPlatformId) => {
        if (marketingPlatformId > 0) {
            callAPI(`marketing/campaign/${campaignId}/platform`, 'DELETE', { platformId: marketingPlatformId })
                .then((result) => {
                    if (result['success'] === true) {
                        fetchCampaign(campaign)
                    } else {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    console.log(`Error while removing marketing platform: ${error.message}`)
                    showSystemMessage('error', 'Error while removing marketing platform.')
                })
        }
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Marketing Campaigns</h1>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="mb-h1-dark">Active Campaigns</h1>
                                <button
                                    className="btn btn-warning btn-sm"
                                    onClick={() => {
                                        setCampaign({ id: 0, name: '' })
                                    }}
                                >
                                    Create Campaign
                                </button>
                            </div>
                        </div>
                        <div>{dataLoaded && data.marketing_campaigns ? <MarketingCampaignList data={data.marketing_campaigns.active} selectedCampaign={campaign} setCampaign={setCampaign} /> : <Loading />}</div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-body">
                            <h1 className="mb-h1-dark">Inactive Campaigns</h1>
                        </div>
                        <div>{dataLoaded && data.marketing_campaigns ? <MarketingCampaignList data={data.marketing_campaigns.inactive} selectedCampaign={campaign} setCampaign={setCampaign} /> : <Loading />}</div>
                    </div>
                </div>
                {campaign.id >= 0 && (
                    <div className="col-xl-6">
                        {campaign.id > 0 && (
                            <div className="card mb-2">
                                <div className="card-header">Registration Links</div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <div>
                                            Campaign: <strong>{campaign.name}</strong>
                                        </div>
                                        <i>Marketing platforms can be used to distinguish traffic sources. Eg. Google, Bing, Facebook, etc.</i>
                                    </div>
                                    {marketingPlatforms.map((platform) => {
                                        return (
                                            <div className="alert alert-secondary mt-2 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div>
                                                        <strong>{platform.name}</strong>
                                                    </div>
                                                    <div>
                                                        <a target="_blank" rel="noopener noreferrer" href={platform.url}>
                                                            {platform.url}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button
                                                        className="btn btn-link text-danger"
                                                        onClick={() => {
                                                            removePlatform(campaign.id, platform.id)
                                                        }}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="d-flex">
                                        <MarketingPlatformDropdown setMarketingPlatformId={setMarketingPlatformId} omittedPlatformIdArray={marketingPlatforms} />
                                        <button
                                            className="btn btn-primary ml-3"
                                            style={{ width: '110px' }}
                                            onClick={() => {
                                                addPlatform(campaign.id, marketingPlatformId)
                                            }}
                                        >
                                            Create Link
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="card">
                            <div className="card-header">Custom Registration Page</div>
                            <div className="card-body">
                                {campaignInfoLoaded ? (
                                    <form
                                        onSubmit={(e) => {
                                            saveCampaign(e)
                                        }}
                                    >
                                        <div className="form-group d-flex align-items-center">
                                            <label for="name" style={{ width: '100px' }}>
                                                Name
                                            </label>
                                            <div className="w-100">
                                                <input
                                                    name="name"
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    value={campaignInfo.name}
                                                    onChange={(e) => {
                                                        setCampaignInfo({ ...campaignInfo, name: e.target.value })
                                                    }}
                                                />
                                                <i>*No Special Characters</i>
                                            </div>
                                        </div>
                                        <div className="form-group d-flex align-items-center">
                                            <label for="header" style={{ width: '100px' }}>
                                                Header
                                            </label>
                                            <input
                                                name="header"
                                                className="form-control"
                                                type="text"
                                                value={campaignInfo.header}
                                                onChange={(e) => {
                                                    setCampaignInfo({ ...campaignInfo, header: e.target.value })
                                                }}
                                            />
                                        </div>
                                        <div className="form-group d-flex align-items-center">
                                            <label for="content" style={{ width: '100px' }}>
                                                Content
                                            </label>
                                            <textarea
                                                name="content"
                                                className="form-control"
                                                type="text"
                                                value={campaignInfo.content}
                                                style={{ height: '250px' }}
                                                onChange={(e) => {
                                                    setCampaignInfo({ ...campaignInfo, content: e.target.value })
                                                }}
                                            ></textarea>
                                        </div>
                                        <div className="form-group d-flex align-items-center">
                                            <label for="image" style={{ width: '100px' }}>
                                                Image
                                            </label>
                                            <div className="w-100">
                                                <input
                                                    name="image"
                                                    className="form-control"
                                                    type="text"
                                                    value={campaignInfo.image}
                                                    onChange={(e) => {
                                                        setCampaignInfo({ ...campaignInfo, image: e.target.value })
                                                    }}
                                                />
                                                <i>*FTP: /maxbounty.com/www/resources/images/marketingPartners</i>
                                            </div>
                                        </div>
                                        <div className="form-group d-flex align-items-center">
                                            <label for="status" style={{ width: '100px' }}>
                                                Status
                                            </label>
                                            <select
                                                className="form-control"
                                                value={campaignInfo.status.value}
                                                onChange={(e) => {
                                                    setCampaignInfo({ ...campaignInfo, status: { value: e.target.value, name: e.target.selectedOptions[0].text } })
                                                }}
                                            >
                                                <option value="A">Active</option>
                                                <option value="C">Inactive</option>
                                            </select>
                                        </div>
                                        <div className="text-right">
                                            <button className="btn btn-primary">{campaign.id > 0 ? 'Save' : 'Create Campaign'}</button>
                                        </div>
                                    </form>
                                ) : (
                                    <Loading />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}
