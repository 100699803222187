import React, { useEffect, useState } from 'react'

import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import callAPI from '../../../helpers/callAPI'
import AffiliateTipModal from './AffiliateTipModal/AffiliateTipModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const AffiliateTips = ({ showSystemMessage }) => {

    document.title = 'Affiliate Tips'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_tips')

    const [tips, setTips] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [showTipModal, setShowTipModal] = useState(false)
    const [selectedSequenceId, setSelectedSequenceId] = useState()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        callAPI(`admin/affiliateTips`, 'GET')
            .then((result) => {
                if (result.success) {
                    setTips(result.tips)
                    setDataLoaded(true)
                } else if (!result.success) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while fetching affiliate tips data.')
            })
    }

    const handleDeleteClick = (sequenceId) => {
        setSelectedSequenceId(sequenceId)
    }

    const handleConfirmDelete = (e) => {
        e.preventDefault()

        callAPI(`admin/affiliateTips/${selectedSequenceId}`, 'DELETE', {})
            .then((result) => {
                if (result.success) {
                    showSystemMessage('success', result.message)
                    let tempList = tips.filter((item) => item.tip_sequence !== selectedSequenceId)
                    setTips(tempList)
                } else if (!result.success) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while deleting affiliate tip')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Tip Sequence',
                accessor: 'tip_sequence',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Tip Text',
                accessor: 'tip_text',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: '',
                accessor: 'tips',
                Cell: (tableData) => {
                    let button

                    if (selectedSequenceId === tableData.data[tableData.row.index].tip_sequence) {
                        button = (
                            <button type="button" className="btn btn-danger btn-sm" onClick={handleConfirmDelete}>
                                Confirm
                            </button>
                        )
                    } else {
                        button = (
                            <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                    handleDeleteClick(tableData.data[tableData.row.index].tip_sequence)
                                }}
                            >
                                Delete
                            </button>
                        )
                    }

                    return button
                },
            },
        ]

        return <ReportTable data={tips} columns={columns} />
    }

    const handleClose = (isEdited = false) => {
        setShowTipModal(false)

        if (isEdited) {
            fetchData()
        }
    }

    return (
        <div>
            {showTipModal && <AffiliateTipModal showSystemMessage={showSystemMessage} isOpen={showTipModal} handleClose={handleClose} />}
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Manage Affiliate Tips</h1>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="d-flex justify-content-end p-3 align-items-center">
                            <button
                                type="button"
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    setShowTipModal(true)
                                }}
                            >
                                Create Affiliate Tip
                            </button>
                        </div>
                        {dataLoaded ? tips.length === 0 ? <div className="p-3">No tips to show</div> : getReportOutput() : <Loading />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AffiliateTips
