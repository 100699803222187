import moment from "moment"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import callAPI from "../../../../helpers/callAPI"
import Advertiser from "../../../../templates/Advertiser/Advertiser"
import Campaign from "../../../../templates/Campaign/Campaign"
import Loading from "../../../../templates/UI/Loading/Loading"

const MerchantCampaignPullsFull = ({ isOpen, affiliateId, showSystemMessage, type, handleClose }) => {

    document.title = 'Advertiser/Campaign Pulls'

    const [pulls, setPulls] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        getMerchantPulls()
    }, [affiliateId])

    const getMerchantPulls = () => {
        let url = `affiliate/merchantPulls/${affiliateId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success']) {
                    setPulls(result.pulls)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching advertiser campaign pulls')
                setDataLoaded(true)
            })
    }

    return (
        <React.Fragment>
            {type !== 'page' && (
                <Modal.Header closeButton={handleClose}>
                    <Modal.Title>Advertiser/Campaign Pulls</Modal.Title>
                </Modal.Header>
            )}
            <div className={type === 'page' ? `row` : ``}>
                <div className={type === 'page' ? `col-lg-6` : ``}>
                    <div className={type == 'page' ? "card mb-3" : ''}>
                        <div className={type == 'page' ? "card-body p-0" : ''}>
                            {dataLoaded ? (
                                pulls.length === 0 ? (
                                    <div className="p-3">No stats available.</div>
                                ) : (
                                    <table className="table table-striped table-card m-0">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Advertiser/Campaign</th>
                                                <th className="text-right">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pulls.map((item) => {
                                                return (
                                                    <tr key={item.name}>
                                                        <td>{item.record_type === 'O' ? 'Campaign' : 'Advertiser'}</td>
                                                        <td>{item.record_type === 'O' ? <Campaign oid={item.id} name={item.name} status={item.status} /> : <Advertiser id={item.id} name={item.name} status={item.status} />}</td>
                                                        <td className="text-right">{moment(item.status_date).format('YYYY/MM/DD')}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                )
                            ) : (
                                <Loading />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MerchantCampaignPullsFull