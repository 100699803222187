import { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Modal } from 'reactstrap'

import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import IpDetailsContent from '../../Affiliate/AffiliateLoginHistory/IpDetails/IpDetailsContent'

const SignupIpCheck = ({ data, dataLoaded }) => {
    const [showIpDetailsModal, setShowIpDetailsModal] = useState(false)
    const [modalIp, setModalIp] = useState('')

    return (
        <Card className="mb-4">
            <Modal isOpen={showIpDetailsModal} size="md" toggle={() => setShowIpDetailsModal(!showIpDetailsModal)}>
                <IpDetailsContent ipAddress={modalIp} handleClose={() => setShowIpDetailsModal(false)} isModal={true} />
            </Modal>
            <Card.Header>
                <div>
                    Signup IP Check <span className="font-weight-normal"> : {dataLoaded && data.info.remote_host ? <a
                        href={`/ipDetails?ip=${data.info.remote_host}`}
                        onClick={(e) => {
                            e.preventDefault()
                            setShowIpDetailsModal(true)
                            setModalIp(data.info.remote_host)
                        }}
                    >
                        {data.info.remote_host}
                    </a> : ''}</span>
                </div>
            </Card.Header>
            <div className="m-2 font-weight-bold">Other affiliates (up to 10) with this same signup IP:</div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Affiliate</th>
                        <th>Status</th>
                        <th>Contact</th>
                    </tr>
                </thead>
                {dataLoaded ? (
                    <>
                        {data.success && data.info.matchingIPs.length > 0 ? (
                            <tbody>
                                {data.info.matchingIPs.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.affiliate_id}</td>
                                            <td>
                                                <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                            </td>
                                            <td>{item.aff_status}</td>
                                            <td>{item.contact}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4">No data found</td>
                                </tr>
                            </tbody>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Table>
        </Card>
    )
}

export default SignupIpCheck
