import React, { useState, useEffect } from 'react'
import RedirectMacros from './RedirectMacros'

export default function RedirectSettings({ questionId, placeholder, callToAction, handleChangePlaceholder }) {
    const [showRedirect, setShowRedirect] = useState(placeholder !== '' ? true : false)

    return (
        <div className="mt-4">
            <div className="alert alert-info mt-3">
                <div className="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="showRedirectSwitch" onClick={() => setShowRedirect(!showRedirect)} checked={showRedirect} />
                    <label className="custom-control-label" for="showRedirectSwitch">
                        Show redirect settings
                    </label>{' '}
                    {placeholder !== '' && callToAction ? <span className="badge badge-success">Redirect is ON</span> : <span className="badge badge-danger">Redirect is OFF</span>}
                    <div>
                        <i>*You must use a custom call to action to use redirects.</i>
                    </div>
                </div>
            </div>
            {showRedirect && (
                <div className="mt-3 alert alert-info">
                    <div className="mb-2">Redirect Settings:</div>
                    <input className="form-control mb-3" type="text" defaultValue={placeholder} placeholder="Add URL Here." onChange={handleChangePlaceholder(questionId)} />
                    <RedirectMacros />
                </div>
            )}
        </div>
    )
}
