import React, { useEffect, useState } from 'react'
import moment from 'moment'

import Loading from '../../templates/UI/Loading/Loading'
import { Modal, Table } from 'react-bootstrap'
import useCallApi from '../../hooks/useCallApi'

const FollowupList = ({ advertiserId, affiliateId, showSystemMessage, isOpen, setIsOpen }) => {
    const url = advertiserId ? `advertiser/${advertiserId}/followups` : `affiliate/${affiliateId}/followups`
    const { data, dataLoaded } = useCallApi(url, 'GET', {}, [advertiserId], showSystemMessage)

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Followups List {dataLoaded && `(${data.followups ? data.followups.length : 0})`}</Modal.Title>
            </Modal.Header>
            <div className="table-container-scroll">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Results</th>
                            <th>Entered By</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {data.success && data.followups && data.followups.length > 0 ? (
                                    data.followups.map((item, index) => (
                                        <tr key={index}>
                                            <td width="10%">{moment(item.followup_date).format('YYYY-MM-DD')}</td>
                                            <td>{item.results}</td>
                                            <td style={{ whiteSpace: 'nowrap' }}>{item.full_name}</td>
                                            <td>{item.notes}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No followups found</td>
                                    </tr>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan="4">
                                    <Loading></Loading>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </Modal>
    )
}

export default FollowupList
