import moment from "moment"

const CustomDomains = (props) => {
    return (
        <div className="card">
            <div className="float-left font-weight-bold border-bottom pb-2 card-header">Custom Domains</div>
            <table className="table table-borderless table-striped m-0">
                <thead>
                    <tr>
                        <th>Domain URL</th>
                        <th className="text-right">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {props.custom_domains.map(domain => <tr key={domain.domain_url}>
                        <td>{domain.domain_url}</td>
                        <td className="text-right">{moment(domain.date).utc().format('YYYY/MM/DD')}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}

export default CustomDomains