
import { useEffect, useState } from 'react'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'
import moment from 'moment'
import ScrollToBottom from '../../../hoc/ScrollToBottom/ScrollToBottom'

const maxLogLength = 500

const RequestResultsForm = ({ requestId, showSystemMessage, flow, handleClose, handleCloseFlow }) => {

    const auth = useAuth()
    const showReview = useProtectedContent(auth.permissions, 'campaign_request_review')
    const showApprove = useProtectedContent(auth.permissions, 'campaign_request_approve')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [requestDetails, setRequestDetails] = useState({})
    const [deleteClicked, setDeleteClicked] = useState(false)

    const [selectedResult, setSelectedResult] = useState('')

    useEffect(() => {
        let url = `affiliate/campaignRequests/${requestId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setRequestDetails(result.list)                    
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => showSystemMessage('error', 'Error while fetching request details'))
    }, [requestId])

    const saveRequest = (e) => {
        e.preventDefault()

        let url = `affiliate/campaignRequests/${requestId}`

        let putBody = {
            affiliate_id: requestDetails.affiliate_id,
            offer_id: requestDetails.offer_id,
            results: e.target.results.value,
            notes: e.target.notes ? e.target.notes.value : ''
        }

        callAPI(url, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    if (flow) {
                        handleCloseFlow(true)
                    } else {
                        handleClose(true)
                    }
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'Error while saving campaign request')
            })
    }

    const deleteRequest = (e) => {
        e.preventDefault()

        let url = `affiliate/campaignRequests/${requestId}`

        callAPI(url, 'DELETE', {})
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    if (flow) {
                        handleCloseFlow(true)
                    } else {
                        handleClose(true)
                    }
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'Error while saving campaign request')
            })
    }

    const handleDeleteClick = (e) => {
        e.preventDefault()

        setDeleteClicked(true)

        setTimeout(() => {
            setDeleteClicked(false)
        }, 5000)
    }

    return (
        <div className='card mb-3'>
            <div className="card-header">
                <h1 className="mb-h1-dark">Action</h1>
            </div>
            <div className='card-body p-0'>
                <form onSubmit={saveRequest}>
                    <table className='table table-borderless m-0'>
                        <tbody>
                            <tr>
                                <td className="font-weight-bold">Current Status: {requestDetails.status_name}</td>
                            </tr>                            
                            <tr>
                                <td>
                                    <div className="alert alert-secondary pt-2 pb-0 px-2">
                                        {(requestDetails.status === 'O' || requestDetails.status === 'N' || requestDetails.status === 'M') && (showReview) && (
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="FAAM" defaultChecked onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Forward: send to advertiser manager for review</span>
                                                </label>
                                            </span>
                                        )}

                                        {((requestDetails.status === 'V' || requestDetails.status === 'W') && showApprove) && (
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="FAM" defaultChecked onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Forward: send to affiliate manager for review</span>
                                                </label>
                                            </span>
                                        )}
                                    </div>

                                    {(((requestDetails.status === 'M' || requestDetails.status === 'O')  && showReview) || (requestDetails.status === 'V' && showApprove)) &&
                                        <div className="alert alert-warning pt-2 pb-0 px-2">
                                            {((requestDetails.status === 'M' || requestDetails.status === 'O') && showReview) && (
                                                <span>
                                                    <label className="d-flex align-items-center">
                                                        <input type="radio" name="results" value="N" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                        <span className="ml-1">Wait: need more information from affiliate</span>
                                                    </label>
                                                </span>
                                            )}

                                            {(requestDetails.status === 'V' && showApprove) && (
                                                <span>
                                                    <label className="d-flex align-items-center">
                                                        <input type="radio" name="results" value="W" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                        <span className="ml-1">Wait: need to get approval from advertiser</span>
                                                    </label>
                                                </span>
                                            )}
                                        </div>
                                    }                                    

                                    {(requestDetails.status === 'O' || requestDetails.status === 'V' || requestDetails.status === 'W' || requestDetails.status === 'N') && showApprove && (
                                        <div className="alert alert-success pt-2 pb-0 px-2">
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="A" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Approve: allow affiliate to run campaign</span>
                                                </label>
                                            </span>
                                        </div>
                                    )}

                                    {
                                        (showReview || showApprove) &&
                                        <div className="alert alert-danger pt-2 pb-0 px-2">
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="I" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Deny: insufficient info was provided</span>
                                                </label>
                                            </span>

                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="G" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Deny: gibberish answers were given</span>
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="C" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Deny: no cap is available to assign</span>
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="M" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Deny: promotional method is not allowed</span>
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="H" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Deny: more affiliate history is required</span>
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="P" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Deny: too many pulls on other campaigns</span>
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="O" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Deny: affiliate is not suitable for campaign</span>
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex align-items-center">
                                                    <input type="radio" name="results" value="R" onClick={(e)=>setSelectedResult(e.target.value)} />
                                                    <span className="ml-1">Deny: see affiliate manager for more details</span>
                                                </label>
                                            </span>
                                        </div>
                                    }
                                </td>
                            </tr>
                            {
                                (showApprove || showReview) &&
                                <>
                                    {
                                        ['N','A','I','C','M','H','P','O','R','G'].includes(selectedResult) &&
                                        <tr>
                                            <td>
                                                Notes emailed to affiliates:
                                                <textarea name="notes" rows={4} cols={40} className="form-control mt-2" placeholder="Notes to include in the email to the affiliate." />
                                            </td>
                                        </tr>
                                    }                                    
                                    <tr className="py-2">
                                        <td className='d-flex justify-content-between'>
                                            {requestDetails.status !== 'X' && (
                                                deleteClicked ?
                                                    <button className="btn btn-danger text-left" type="button" onClick={deleteRequest}>
                                                        Confirm Delete
                                                    </button>
                                                    : <button className="btn btn-outline-danger" type="button" onClick={handleDeleteClick}>
                                                        Delete
                                                    </button>
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                            <button className="btn btn-primary ml-4" type="submit">
                                                Save
                                            </button>
                                        </td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    )
}

export default RequestResultsForm