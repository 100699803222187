import { Card, Table, OverlayTrigger, Popover } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Pill from '../../../../templates/UI/Pill/Pill'
import { colors } from '../../../../theme/variables'

import '../../../../theme/classes.scss'
import Affiliate from '../../../../templates/Affiliate/Affiliate'

const RejectedAppsToReview = ({ manager }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const result = await callAPI(`compliance/affiliates/flaggedRejectedApplications`, 'GET')
            if (result.success) {
                setData(result)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchData()
    }, [manager])

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <div className="font-weight-bold">Rejected Apps To Review</div>
                <span className="align-items-center">
                    In Queue{' '}
                    <Link to="/compliance/applications/rejected" className="ml-1">
                        {dataLoaded ? <>{!data || data.applications.length === 0 ? 0 : data.applications.length}</> : 0}
                    </Link>
                </span>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th>Country</th>
                            <th>Aff Time / Best Time</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.applications.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.applications.map((item, index) => {
                                            if (index < 15) {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            <Affiliate aid={`${item.affiliate.id}`} name={`${item.affiliate.name}`} />
                                                        </td>
                                                        <td>{item.country}</td>
                                                        <td>
                                                            {item.affiliate_time}, {item.contact_time}
                                                        </td>
                                                        <td>{moment(item.application_date).format('DD/MM/YYYY')}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default RejectedAppsToReview
