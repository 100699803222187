import React, { Component } from 'react'

import Loading from '../../../templates/UI/Loading/Loading'

import moment from 'moment'
import TableContainer from '../TableContainer'
import callAPI from '../../../helpers/callAPI'

class ReportClicks extends Component {
    state = {
        dataLoaded: true,
        report: [],
        totals: {},
    }

    componentDidMount() {
        this._isMounted = true

        this.setState({
            dataLoaded: false,
        })

        const fetchData = async () => {
            /* Fetch Affiliate Applications Stats from DB and populate the reports table and charts */

            let url = `reports/clicks?startDate=${this.props.startDate}&endDate=${this.props.endDate}&page=all`

            if (this.props.oid > 0) {
                url = url + `&oid=${this.props.oid}`
            }

            if (this.props.aid > 0) {
                url = url + `&aid=${this.props.aid}`
            }

            if (this.props.cid > 0) {
                url = url + `&cid=${this.props.cid}`
            }

            if (this.props.lpid > 0) {
                url = url + `&lpid=${this.props.lpid}`
            }
            const result = await callAPI(url, 'GET')

            if (result['success'] === true) {
                //this.props.handleTotalPages(result.total_pages);
                if (this._isMounted) {
                    this.setState({
                        dataLoaded: true,
                        report: result.report,
                        totals: result.totals,
                    })
                }
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    this.props.showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                this.props.showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }

    getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={this.state.report}
                    totals={this.state.totals}
                    columns={[
                        {
                            Header: 'Date',
                            accessor: 'date',
                            Cell: ({ cell: { value } }) => {
                                return <>{value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Clicks</div>,
                            accessor: 'clicks',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{Intl.NumberFormat('en-GB').format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Leads</div>,
                            accessor: 'leads',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{Intl.NumberFormat('en-GB').format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Sales</div>,
                            accessor: 'sales',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Network Earnings</div>,
                            accessor: 'network_earnings',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Affiliate Earnings</div>,
                            accessor: 'affiliate_earnings',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Margin</div>,
                            accessor: 'margin',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right">Margin %</div>,
                            accessor: 'pct_margin',
                            className: 'text-right',
                            // To customize rendering according to the row object using other fields from the original object
                            Cell: ({ row }) => {
                                //console.log(row.original.pct_margin)
                                return <>{parseFloat(row.original.margin ? (row.original.margin / row.original.network_earnings) * 100 : 0).toFixed(2)}%</>
                                // Cell: ({ cell: { value } }) => {
                                //     return (<>{parseFloat(value).toFixed(2)}%</>);
                            },
                        },
                        {
                            Header: () => <div className="float-right">Affiliate EPC</div>,
                            accessor: 'affiliate_epc',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}</>
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <hr className="mt-0 mb-3"></hr>
                <div className="d-flex mx-2"></div>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {this.state.report.length === 0 ? (
                            <table className="table table-striped table-card table-hover mb-0">
                                <tbody>
                                    <tr>
                                        <td colSpan="9">No stats for this date range.</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <React.Fragment>
                                {this.props.aid ? (
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mr-4 d-flex align-items-center">
                                                <h1 className="mb-h1-dark">Affiliate: {this.props.aid}</h1>
                                            </div>
                                        </div>
                                        {this.props.oid ? (
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <h1 className="mb-h1-dark">Campaign: {this.props.oid}</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ) : this.props.oid ? (
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mr-4 d-flex align-items-center">
                                                <h1 className="mb-h1-dark">Campaign: {this.props.oid}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {this.props.cid ? (
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mr-4 d-flex align-items-center">
                                                <h1 className="mb-h1-dark">Creative: {this.props.cid}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : this.props.lpid ? (
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="mr-4 d-flex align-items-center">
                                                <h1 className="mb-h1-dark">Landing Page: {this.props.lpid}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {this.getReportOutput()}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <table className="table table-striped table-card table-hover mb-0">
                        <table>
                            <tr>
                                <td colSpan="9">
                                    <Loading />
                                </td>
                            </tr>
                        </table>
                    </table>
                )}
            </React.Fragment>
        )
    }
}

export default ReportClicks
