const PostbackElement = ({ campaignId, campaign, rate, dataLoaded, data }) => {

    const displayLink = (rate, campaignId) => {
        if (campaign.type.value !== 'A') {
            // It is Normal or Host and Post
            if (!campaign.tracking.is_mobile_offer) {
                // Not mobile
                if (campaign.tracking.type.value === 'S') {
                    //let link = `https://chant3rm1.com/lead_nc.asp?o=${campaignId}&r=${rate.rate_name}&d=XXXXX&i=ZZZZZZZ`
                    let link = `https://chant3rm1.com/trk/lead?mbm=${campaign.advertiser.id}&mbr=${rate.rate_name}&mbd={lead_data}&mbi={click_id}`
                    if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                        link = `https://chant3rm1.com/trk/lead?mbm=${campaign.advertiser.id}&mbr=${rate.rate_name}&mbs={sale_amount}&mbd={lead_data}&mbi={click_id}`
                        if (campaign.advertiser.tracking.multiple_postbacks) {
                            return (
                                <div>
                                    <div>{link}</div>
                                    <br />
                                    {!campaign.advertiser.tracking.merchant_supports_multiple_postbacks && <div className="bg-warning">Merchant does not support multiple postbacks at this time. The following code is for testing only:</div>}
                                    {`<iframe src='https://www.chant3rm1.com/mb_html_callback.asp?r=${rate.rate_name}&i=ZZZZZZZ&s={sale_amount}' width=1 height=1 frameborder=0></iframe>`}
                                </div>
                            )
                        } else {
                            return (
                                <div>                                    
                                    <div>{link}</div>
                                </div>
                            )
                        }
                    } else {
                        if (campaign.advertiser.tracking.multiple_postbacks) {
                            return (
                                <div>
                                    <div>{link}</div> <br />
                                    {!campaign.advertiser.tracking.merchant_supports_multiple_postbacks && <div className="bg-warning">Merchant does not support multiple postbacks at this time. The following code is for testing only:</div>}
                                    {`<iframe src='https://www.chant3rm1.com/mb_html_callback.asp?r=${rate.rate_name}&i=ZZZZZZZ' width=1 height=1 frameborder=0></iframe>`}
                                </div>
                            )
                        }
                    }
                    return <div>{link}</div>
                }
                if (campaign.tracking.type.value === 'G') {
                    let link = `https://chant3rm1.com/lead_global.asp?i=ZZZZZZZ&d={advertiser_data}`
                    if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                        link = `https://chant3rm1.com/lead_global.asp?i=ZZZZZZZ&s={sale_amount}&d={advertiser_data}`
                        if (rate.platform_supports_multiple_postback === 'Y') {
                            if (rate.merchant_supports_multiple_postback === 'N') {
                                return (
                                    <div>
                                        <div>{link}</div>
                                        <br />
                                        <div className="bg-warning">Merchant does not support multiple postbacks at this time. The following code is for testing only:</div>
                                        {`<iframe src='https://www.chant3rm1.com/mb_html_callback.asp?r=${rate.rate_name}&i=ZZZZZZZ&s={sale_amount}' width=1 height=1 frameborder=0></iframe>`}
                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        <div>{link}</div> <br />
                                        <div className="bg-warning">Merchant does not support multiple postbacks at this time. The following code is for testing only:</div>
                                        {`<iframe src='https://www.chant3rm1.com/mb_html_callback.asp?r=${rate.rate_name}&i=ZZZZZZZ' width=1 height=1 frameborder=0></iframe>`}
                                    </div>
                                )
                            }
                        }
                        return <div>{link}</div>
                    }
                    return <div>{link}</div>
                }
                if (campaign.tracking.type.value === 'L') {
                    let link = `<img src="https://www.maxbounty.com/lead.asp?o=${campaignId}&r=${rate.rate_name}&d=xxxxx&s={sale_amount}" border=0 width=1 height=1>`
                    if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                        return <div>{link}</div>
                    } else {
                        let link = `<img src="https://www.maxbounty.com/lead.asp?o=${campaignId}&r=${rate.rate_name}&d=xxxxx" border=0 width=1 height=1>`
                        return <div>{link}</div>
                    }
                }
                if (campaign.tracking.type.value === 'I') {
                    let link = `<iframe src="https://www.maxbounty.com/lead_if.asp?o=${campaignId}&r=${rate.rate_name}&d=xxxxx&s={sale_amount}" width=1 height=1 frameborder=0></iframe>`
                    if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                        return <div>{link}</div>
                    } else {
                        let link = `<iframe src="https://www.maxbounty.com/lead_if.asp?o=${campaignId}&r=${rate.rate_name}&d=xxxxx" width=1 height=1 frameborder=0></iframe>`
                        return <div>{link}</div>
                    }
                }
                if (campaign.tracking.type.value === 'M') {
                    if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                        let link = `<script>mbc('track', {mbr:'${rate.rate_name}', mbs:'sale_amount', mbd:'XXXXX'})</script>`
                        return <div>{link}</div>
                    } else {
                        let link = `<script>mbc('track', {mbr:'${rate.rate_name}', mbd:'XXXXX'})</script>`
                        return <div>{link}</div>
                    }
                }
            } else {
                // Mobile
                let link = `https://chant3rm1.com/event_m.asp?`
                if (dataLoaded) {
                    data.parameters.map((parameter, index) => {
                        if (index > 0) {
                            link += `&${parameter.param_name}=`
                        } else {
                            link += `${parameter.param_name}=`
                        }
                        return
                    })
                    return <div>{link}</div>
                } else {
                    return <div>{link} parameters loading...</div>
                }
            }
        } else {
            // Avatar         
            let link = `https://chant3rm1.com/trk/avlead?mbt={event_token}&mbs={sale/commission_amount}&mbd={lead_data}`
            return <div>{link}</div>
        }
    }

    return (
        <div className="alert alert-secondary">
            <pre className="p-0 m-0" style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                {displayLink(rate, campaignId)}
            </pre>
        </div>
    )
}

export default PostbackElement
