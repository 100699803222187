import Affiliate from '../../../../../../templates/Affiliate/Affiliate'
import getAffiliateStatus from '../../../../../../helpers/getAffiliateStatus'

const MatchingAffiliatesTable = ({ data }) => {
    return (
        <table className="table table-bordered">
            <tbody>
                {data.slice(0, 10).map((item) => (
                    <tr key={item.affiliate_id}>
                        <td>{item.affiliate_id}</td>
                        <td>
                            <Affiliate aid={item.affiliate_id} name={item.name} status={item.status}/>
                        </td>
                        <td>{getAffiliateStatus(item.status)}</td>
                        <td>{item.followup_notes}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default MatchingAffiliatesTable
