import React, { useState, useEffect } from 'react'
import useCallApi from '../../hooks/useCallApi'
import useDateRangeFilter from '../../hooks/useDateRangeFilter'
import Loading from '../../templates/UI/Loading/Loading'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Dollars from '../../templates/Dollars/Dollars'
import { useAuth, useProtectedPage } from '../../context/AuthContext'
import DateRangeFilter from '../Reports/Filters/DateRangeFilter/DateRangeFilter'
import callAPI from '../../helpers/callAPI'
import CommissionReportDetailsModal from './CommissionReportDetailsModal/CommissionReportDetailsModal'

export default function CommissionReport({ showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'commission_report')

    const [data, setData] = useState([])
    const [fetched, setFetched] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [password, setPassword] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState(false)

    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const handleReport = async () => {
        setFetched(true)
        setDataLoaded(false)
        let response = await callAPI(`reports/commission?startDate=${selectedStartDate}&endDate=${selectedEndDate}`, 'POST', { password: password })

        if (response.success) {
            setData(response)
            setDataLoaded(true)
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Admin Id',
                accessor: 'admin.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'admin.name',
                Cell: (tableData) => {
                    return (
                        <button
                            className="btn btn-link"
                            onClick={() => {
                                setModalData({ adminId: tableData.data[tableData.row.index].admin.id, name: tableData.data[tableData.row.index].admin.name, startDate: selectedStartDate, endDate: selectedEndDate })
                                setModalOpen(true)
                            }}
                        >
                            {tableData.data[tableData.row.index].admin.name}
                        </button>
                    )
                },
            },
            {
                Header: 'Role',
                accessor: 'admin.role.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Margin Offset</div>,
                accessor: 'margin_offset',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Commission</div>,
                accessor: 'commission',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.report} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <React.Fragment>
                <div className="page-header">
                    <span className="mb-h1">Protected Report</span>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark">Commission Report</h1>
                            </div>
                            {modalData.adminId > 0 && (
                                <CommissionReportDetailsModal
                                    open={modalOpen}
                                    password={password}
                                    handleClose={() => {
                                        setModalOpen(false)
                                        setModalData(false)
                                    }}
                                    modalData={modalData}
                                    showSystemMessage={showSystemMessage}
                                />
                            )}
                            <React.Fragment>
                                <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                <div className="mr-2">
                                    <input type="password" className="form-control form-control-sm" placeholder="Password" onChange={(e) => setPassword(e.target.value)}></input>
                                </div>
                                <div>
                                    <button onClick={handleReport} className="btn btn-sm btn-primary">
                                        Refresh Report
                                    </button>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                    {fetched ? dataLoaded ? data.report.length === 0 ? <div className="p-3">Invalid password / no stats for the current dates.</div> : getReportOutput() : <Loading></Loading> : <></>}
                </div>
            </React.Fragment>
        </div>
    )
}
