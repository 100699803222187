import getCreativeName from "./getCreativeName"

const downloadCreativeImage = async (creative_type, width, height, creative_id, preview) => {
    const a = document.createElement("a")
    a.href = await fetchImage(`${process.env.REACT_APP_API_URL}/${preview}`)

    a.download = `${getCreativeName(creative_type, width, height, creative_id)}.jpg`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

const fetchImage = async (url) => {

    const mbToken = localStorage.getItem('adm-auth-token')

    return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'x-app-token': mbToken,
        },
    })
        .then((res) => res.blob())
        .then((blob) => URL.createObjectURL(blob))
        .catch((err) => ({ success: false, errors: { message: err.message } }))
}

export default downloadCreativeImage