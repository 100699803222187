import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function AdvertiserStatusDisplay({item, manager}){
    return (
        <div key={item.status} className="d-flex">
            <div className={`border rounded p-2 ${item.status === 'Active' ? 'border-info' : item.status === 'Negotiation' && 'border-warning'}`}>
                {item.status} &nbsp;{' '}
                <strong>
                    <Link to={`/advertisers/search?z=1&status=${item.status === 'Active' ? 'A' : item.status === 'Negotiation' ? 'G' : item.status === 'New Lead' ? 'N' : item.status === 'Discussion' ? 'D' : 'A'}&adminId=${manager.id}`}>{item.count}</Link>
                </strong>
            </div>
        </div>
    )
}