import React, { useState } from 'react'

import { useAuth } from '../../context/AuthContext'
import callAPI from '../../helpers/callAPI'
import CustomButton from '../../templates/UI/CustomButton/CustomButton'
import Loading from '../../templates/UI/Loading/Loading'

const ChangePassword = ({ showSystemMessage }) => {

    document.title = 'Change Password'

    const auth = useAuth()

    const adminId = auth.user.id

    const [submitting, setSubmitting] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(true)

    const handleSubmit = (e) => {
        e.preventDefault()

        setSubmitting(true)

        let postBody = {
            username: e.target.username.value,
            old_password: e.target.current_password.value,
            password1: e.target.new_password.value,
            password2: e.target.new_password_confirm.value,
        }

        callAPI(`user/${adminId}/changePassword`, 'POST', postBody)
            .then((result) => {
                if (result.success) {
                    setDataLoaded(false)
                    showSystemMessage('success', result.message)
                    setSubmitting(false)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while updating the password.')
                setSubmitting(false)
            })
    }

    const changeHandler = (e) => {
        e.preventDefault()
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Change Password</h1>
            </div>
            <div className="row">
                <div className="col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                {dataLoaded ? (
                                    <table className="table table-borderless m-0">
                                        <tbody>
                                            <tr>
                                                <td>Username:</td>
                                                <td>
                                                    <input type="text" name="username" className="form-control" onChange={changeHandler} required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Current Password:</td>
                                                <td>
                                                    <input type="password" name="current_password" className="form-control" onChange={changeHandler} required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>New Password:</td>
                                                <td>
                                                    <input type="password" name="new_password" className="form-control" defaultValue="" required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Confirm New Password:</td>
                                                <td>
                                                    <input type="text" name="new_password_confirm" className="form-control" defaultValue="" required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <CustomButton name="Change Password" type="submit" spin={submitting} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ) : (
                                    <Loading />
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ChangePassword
