import { Card, Table } from "react-bootstrap"
import useCallApi from "../../hooks/useCallApi"
import Loading from "../../templates/UI/Loading/Loading"

const UserClicksByElem = ({ source, elem, startDate, endDate }) => {
    const { data, dataLoaded } = useCallApi(`useranalytics?src=${source}&elem=${elem}&startDate=${startDate}&endDate=${endDate}`, 'GET', {}, [])

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">{elem}</Card.Header>
            <Card.Body className="p-0 overflow-auto table-container-scroll">
                {(dataLoaded) ?
                    (data.success && data.data.length > 0) ? (
                        <Table striped hover className="m-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className='text-right'>Clicks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.data.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.reference_name}</td>
                                            <td className='text-right'>{row.count}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </Table>
                    ) : (
                        <div className="text-center p-3">No data available</div>

                    ) : (
                        <Loading />
                    )}
            </Card.Body>
        </Card>
    )
}

export default UserClicksByElem