// import { Dropdown, Form } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import callAPI from '../../helpers/callAPI'
import { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'

const EmployeePicker = ({ employee, setEmployee, role, className, formSize, defaultSelf }) => {
    /*
        Roles can be found in administrator_roles table
        role prop can be passed as a string, or as an array, which will fetch all admins within the array
    */

    const [nameList, setNameList] = useState(['Loading...'])

    const auth = useAuth()

    useEffect(() => {
        let url = `admin/get-admins`

        if (Array.isArray(role)) {
            role.forEach((adminRole, index) => {
                console.log(adminRole)
                if (index === 0) {
                    url = url + `?role[]=${adminRole}`
                } else {
                    url = url + `&role[]=${adminRole}`
                }
            })
        } else {
            url = url + `?role=${role}`
        }

        const employeeLookup = async () => {
            const data = await callAPI(url, 'GET')
            if (data.success) {
                let names = []
                data.admins.forEach((admin) => {
                    names.push({
                        id: admin.id,
                        name: admin.name,
                    })
                })
                setNameList(names)
            }
        }
        employeeLookup()
    }, [role])

    const selectEmployee = (event) => {
        if (event.target.value === 'self') {
            setEmployee({ ...employee, id: auth.user.id, name: auth.user.name })
        } else {
            setEmployee({ id: nameList[event.target.value].id, name: nameList[event.target.value].name })
        }
    }

    return (
        <Form.Control className={`w-50 ${className}`} as="select" size={formSize ? formSize : 'sm'} onChange={(event) => selectEmployee(event)}>
            <option value={defaultSelf ? 'self' : employee.id}>{defaultSelf ? auth.user.name : employee.name}</option>
            {nameList.map((employee, index) => {
                return (
                    <option key={index} value={index}>
                        {employee.name}
                    </option>
                )
            })}
        </Form.Control>
    )
}

export default EmployeePicker
