import React from 'react'
import { useEffect, useState } from 'react'
import { Card, Button, Modal } from 'react-bootstrap'
import useCallApi from '../../hooks/useCallApi'
import Loading from '../../templates/UI/Loading/Loading'
import Affiliate from '../../templates/Affiliate/Affiliate'
import ReportTable from '../../templates/ReportTable/ReportTable'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import Campaign from '../../templates/Campaign/Campaign'
import useDateRangeFilter from '../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../pages/Reports/Filters/DateRangeFilter/DateRangeFilter'
import DownloadCSV from '../../templates/DownloadCSV/DownloadCSV'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'

const LeadUserAgents = ({ affiliateId, campaignId, showSystemMessage }) => {
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    let apiUrl = affiliateId ? `affiliate/${affiliateId}/leadUserAgents?startDate=${initStartDate}&endDate=${initEndDate}` : `campaign/${campaignId}/leadUserAgents?startDate=${initStartDate}&endDate=${initEndDate}`

    const { dataLoaded, data, refetch } = useCallApi(apiUrl, 'GET', {}, [affiliateId, campaignId], showSystemMessage)
    const history = useHistory()
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})
    const [report, setReport] = useState([])

    if (dataLoaded && affiliate){
        document.title = `${affiliate.name}: Lead User Agents`
    } else if (dataLoaded && campaign){
        document.title = `${campaign.name}: Lead User Agents`
    } else {
        document.title = `Lead User Agents`
    }

    useEffect(() => {
        setAffiliate(data.affiliate)
        setCampaign(data.campaign)
        setReport(data.actions || [])
    }, [data])

    const refreshReport = () => {
        refetch()
        const routeUrl = affiliateId ? `/affiliate/${affiliateId}/leaduseragents?startDate=${selectedStartDate}&endDate=${selectedEndDate}` : `/campaign/${campaignId}/leaduseragents?startDate=${selectedStartDate}&endDate=${selectedEndDate}`
        history.push(routeUrl)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'User Agent',
                accessor: 'user_agent',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'C2L',
                accessor: 'c2l',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'MB ID',
                accessor: 'mb_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'PERM',
                accessor: 'perm',
                Cell: ({ cell: { value } }) => {
                    return <Link to={`/searchId?actionId=${value}`}>{value}</Link>
                },
            },
        ]

        if (affiliateId) {
            columns.unshift({
                Header: 'Campaign',
                accessor: 'campaign.name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.row.original.campaign.id} name={tableData.row.original.campaign.name} status={tableData.row.original.campaign.status.value} />
                },
            })
            columns.unshift({
                Header: 'Campaign ID',
                accessor: 'campaign.id',
            })
        }

        if (campaignId) {
            columns.unshift({
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.row.original.affiliate.id} name={tableData.row.original.affiliate.name} priority={'A'} />
                },
            })
            columns.unshift({
                Header: 'Affiliate ID',
                accessor: 'affiliate.id',
            })
        }

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    let CSVHeaders = ['Campaign', 'Date', 'User Agent', 'C2L', 'MB ID', 'PERM']
    let CSVDataLabels = ['campaign.name', 'date', 'user_agent', 'c2l', 'mb_id', 'perm']

    if (affiliateId) {
        CSVHeaders = ['Affiliate', 'Date', 'User Agent', 'C2L', 'MB ID', 'PERM']
        CSVDataLabels = ['affiliate.name', 'date', 'user_agent', 'c2l', 'mb_id', 'perm']
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Lead User Agents</h1>
                <div className="ml-5">{affiliate ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <Card>
                <Card.Body className="p-0">
                    <div className="d-flex justify-content-between flex-row w-100 align-items-center p-3">
                        <h1 className="mb-h1-dark mr-2 d-flex align-items-center">
                            <span className="mr-2">{affiliate ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority ? affiliate.priority.value : 0} status={affiliate.status ? affiliate.status.value : 'A'} /> : campaign ? <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : <Loading />}</span>
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <Button size="sm" onClick={refreshReport}>
                                Refresh Report
                            </Button>
                        </h1>

                        {data.success ? <DownloadCSV filename={`Lead User Agents - ${selectedStartDate}-${selectedEndDate}`} headerLabels={CSVHeaders} data={data.actions} dataLabels={CSVDataLabels} /> : null}
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </Card.Body>
            </Card>
        </div>
    )
}

export default LeadUserAgents
