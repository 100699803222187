import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'

const CreateCampaign = ({ advertiserId, showSystemMessage, handleClose, isOpen }) => {
    const { dataLoaded, data } = useCallApi(`advertiser/${advertiserId}/summary`, 'GET', {}, [advertiserId])

    const [submitting, setSubmitting] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitting(true)

        let postBody = {
            name: e.target.name.value,
            rate_name: e.target.rate_name.value,
        }

        callAPI(`advertiser/${advertiserId}/campaign`, 'POST', postBody)
            .then((result) => {
                if (result.success) {
                    showSystemMessage('success', result.message)
                    setSubmitting(false)
                    handleClose(true)
                } else if (!result.success) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                console.log('error while creating campaign:', error)
                setSubmitting(false)
                showSystemMessage('error', 'Error while creating campaign')
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Create Campaign for: {dataLoaded ? <Advertiser id={advertiserId} name={data.name} status={data.status} /> : spinner}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form method="POST" onSubmit={handleSubmit}>
                    <table className="table table-borderless">
                        <tbody>
                            <tr>
                                <td>Campaign Name</td>
                                <td>
                                    <input type="text" className="form-control" name="name" required />
                                </td>
                            </tr>
                            <tr>
                                <td>Rate Name</td>
                                <td>
                                    <input type="text" className="form-control" name="rate_name" required />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <CustomButton variant="primary" name="Create" spin={submitting} type="submit" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateCampaign
