import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ReactToolTip from 'react-tooltip'

import Dollar from '../../../templates/Dollars/Dollars'
import Campaign from '../../../templates/Campaign/Campaign'

// TODO: style the tooltip

const EarningsTable = (props) => {
    const { target, affiliateId, advertiserId, stats, startDate, endDate } = props

    return (
        <div className="table-container-scroll">
            <ReactToolTip place="bottom" />
            {!stats || stats.length === 0 ? (
                <div className="p-2">No stats available</div>
            ) : (
                <table className="table table-striped table-card m-0">
                    <thead>
                        <tr>
                            <th scope="col">Campaign</th>
                            <th scope="col" className="text-right">
                                Clicks
                            </th>
                            <th scope="col" className="text-right">
                                Leads
                            </th>
                            {/* <th scope="col" className="text-right">
                                Net Earn.
                            </th> */}
                            <th scope="col" className="text-right">
                                {affiliateId ? 'Aff Earn.' : 'Net Earn.'}
                            </th>
                            <th scope="col" className="text-right">
                                Margin
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats.map((stat, index) => (
                            <tr key={stat.campaign.id}>
                                <td>
                                    <Campaign oid={stat.campaign.id} name={stat.campaign.name} status={stat.campaign.status.value} affiliateStatus={stat.affiliate_status} />
                                </td>
                                <td className="text-right">
                                    <Link to={target === 'affiliate' ? `/reports/stats/daily?affiliateId=${affiliateId}&campaignId=${stat.campaign.id}&startDate=${startDate}&endDate=${endDate}&navigation=affiliate&withAvatar=true` : `/reports/stats/daily?advertiserId=${advertiserId}&campaignId=${stat.campaign.id}&startDate=${startDate}&endDate=${endDate}&navigation=advertiser&withAvatar=true`}>{Intl.NumberFormat('en-GB').format(stat.clicks)}</Link>
                                </td>
                                <td className="text-right">
                                    <Link to={target === 'affiliate' ? `/reports/stats/conversions?affiliateId=${affiliateId}&campaignId=${stat.campaign.id}&startDate=${startDate}&endDate=${endDate}&navigation=affiliate&withAvatar=true` : `/reports/stats/conversions?advertiserId=${advertiserId}&campaignId=${stat.campaign.id}&startDate=${startDate}&endDate=${endDate}&navigation=advertiser&withAvatar=true`}>{Intl.NumberFormat('en-GB').format(stat.conversions)}</Link>
                                </td>
                                {/* <td className="text-right">{<Dollar value={stat.network_earnings} />}</td> */}
                                <td className="text-right" data-tip={`EPC=$${stat.epc.toFixed(2)}`}>
                                    {<Dollar value={affiliateId ? stat.affiliate_earnings : stat.network_earnings} />}
                                </td>
                                <td className="text-right">
                                    <Link to={target === 'affiliate' ? `/reports/stats/campaigns?affiliateId=${affiliateId}&campaignId=${stat.campaign.id}&startDate=${startDate}&endDate=${endDate}&navigation=affiliate&withAvatar=true` : `/reports/stats/affiliates?advertiserId=${advertiserId}&campaignId=${stat.campaign.id}&startDate=${startDate}&endDate=${endDate}&navigation=advertiser&withAvatar=true`}>
                                        <Dollar value={stat.margin} />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default EarningsTable
