import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import AffiliateStatuses from '../../../data/AffiliateStatuses'
import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

const Affiliates = ({ showSystemMessage, defaultAdminId, defaultStatus }) => {
    document.title = 'Affiliates'

    const [adminId, setAdminId] = useState(defaultAdminId)
    const [statusCode, setStatusCode] = useState(defaultStatus)
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initEndDate, initStartDate } = useDateRangeFilter()
    const { data, dataLoaded, refetch } = useCallApi(`affiliates?startDate=${selectedStartDate || ''}&endDate=${selectedEndDate || ''}&manager_id=${adminId || ''}&status=${statusCode || ''}`, 'GET', {}, [], showSystemMessage)
    const history = useHistory()

    useEffect(() => {
        history.push(`/affiliates?startDate=${selectedStartDate || ''}&endDate=${selectedEndDate || ''}&adminId=${adminId || ''}&status=${statusCode || ''}`)
    }, [selectedStartDate, selectedEndDate, adminId, statusCode])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate.id',
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ cell }) => <div><Affiliate aid={cell.row.original.affiliate.id} status={cell.row.original.affiliate.status} name={cell.row.original.affiliate.name} /> {(cell.row.original.affiliate.experience_level.value === 3 || cell.row.original.affiliate.experience_level.value === 2) && cell.row.original.affiliate.country_tier === 1 ? <span className="ml-2">⭐</span> : ''}</div>,
            },            
            {
                Header: 'Country',
                accessor: 'affiliate.country',
                Cell: ({ cell }) => {
                    let customClass = cell.row.original.affiliate.country_tier === 1 ? 'text-success font-weight-bold' : ''
                    return <span className={customClass}>{cell.row.original.affiliate.country}</span>
                },
            },
            {
                Header: 'Experience',
                accessor: 'affiliate.experience_level.name',
                Cell: ({ cell }) => {
                    let customClass = cell.row.original.affiliate.experience_level.value === 3 || (cell.row.original.affiliate.experience_level.value === 2 && cell.row.original.affiliate.country_tier === 1) ? 'text-success font-weight-bold' : ''
                    return <span className={customClass}>{cell.row.original.affiliate.experience_level.name}</span>
                },
            },
            {
                Header: 'Registration Date',
                accessor: 'registration_date',
                Cell: ({ cell }) => moment(cell.row.original.affiliate.registration_date).format('YYYY-MM-DD'),
            },
            {
                Header: 'Status',
                accessor: 'affiliate.status',
            },
            {
                Header: 'Priority',
                accessor: 'affiliate.priority',
            },
            {
                Header: 'Registered On',
                accessor: 'affiliate.registration_device_os',
                Cell: ({ value }) => {
                    return <>{value ? 'Mobile App' : 'Website'}</>
                }
            }
        ]

        return (
            <>
                <ReportTable
                    data={data.affiliates}
                    columns={columns}
                    getRowProps={(row) => {
                        if (row.original.affiliate.experience_level.value === 3 || (row.original.affiliate.experience_level.value === 2 && row.original.affiliate.country_tier === 1)) {
                            return {
                                className: 'bgSuccess25',
                            }
                        }
                    }}
                />
            </>
        )
    }

    return (
        <Card>
            <Card.Header>
                <h1 className="mb-h1-dark d-flex justify-content-start align-items-center">
                    <span className="d-flex mr-2 align-items-center">
                        Affiliates
                        <span className="ml-2 ">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                        </span>
                        <span className="ml-2 ">
                            <Form.Control as="select" value={statusCode} onChange={(e) => setStatusCode(e.target.value)} size="sm">
                                {
                                    AffiliateStatuses.affiliateStatuses.map((status) => {
                                        return <option value={status.value}>{status.text}</option>
                                    })
                                }
                            </Form.Control>
                        </span>
                    </span>
                    <span className="d-flex ml-2">
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                                history.push(`/affiliates?adminId=${adminId ? adminId : ''}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&status=${statusCode}`)
                                refetch()
                            }}
                        >
                            Refresh
                        </Button>
                    </span>
                </h1>
            </Card.Header>
            <Card.Body className="m-0 p-0">{dataLoaded ? getReportOutput() : <Loading />}</Card.Body>
        </Card>
    )
}

export default Affiliates
