import React, { useEffect, useState } from 'react'
import { ExternalLink, HelpCircle } from 'react-feather'
import ReactTooltip from 'react-tooltip'
import { Modal } from 'reactstrap'
import { useAuth, useEditableContent } from '../../../../context/AuthContext'
import callAPI from '../../../../helpers/callAPI'
import EFTCountries from './EFTCountries/EFTCountries'

const PaymentInfo = ({ affiliate, affiliateId, showSystemMessage, setIsDisabled }) => {
    const auth = useAuth()
    const editMinimumPaymentThreshold = useEditableContent(auth.permissions, 'affiliate_payment_threshold')

    const [paymentPlatform, setPaymentPlatform] = useState(affiliate.payment.platform)
    const [payModal, setPayModal] = useState(false)
    const [payIframe, setPayIframe] = useState('')
    const [showEFTCountriesModal, setShowEFTCountriesModal] = useState(false)

    const inputChangeHandler = (e) => {
        if (e.target.name === 'paymentPlatform') {
            setPaymentPlatform(e.target.value)
        }
        setIsDisabled(false)
    }

    useEffect(() => {}, [paymentPlatform, affiliateId])

    const openPay = async (e) => {
        e.preventDefault()
        const result = await callAPI(`affiliate/${affiliateId}/paychange/${paymentPlatform}`, 'POST')

        if (result['success'] === true) {
            setPayIframe(result.iframe)
        } else if (result['success'] === false) {
            if (result.code !== 401) {
                showSystemMessage('error', 'There was an error while fetching the data. Please try again')
            }
        } else {
            showSystemMessage('error', result.errors.message)
        }
        setPayModal(true)
    }

    const handleClose = () => {
        setShowEFTCountriesModal(false)
    }

    return (
        <div className="card">
            {showEFTCountriesModal && <EFTCountries isOpen={showEFTCountriesModal} showSystemMessage={showSystemMessage} handleClose={handleClose} />}
            <ReactTooltip />
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Payment Information</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td width="25%" className="border-right text-right">
                                Payment Platform
                            </td>
                            <td className="border-right">
                                <select name="paymentPlatform" defaultValue={paymentPlatform} className="form-control" onChange={inputChangeHandler}>
                                    <option value="">None</option>
                                    <option value="tipalti">Tipalti</option>
                                    <option value="payoneer">Payoneer</option>
                                </select>
                            </td>
                            <td className="border-right text-right">
                                Payment Method
                                <button className="btn btn-sm btn-link shadow-none" type="button" onClick={() => setShowEFTCountriesModal(true)}>
                                    EFT countries
                                </button>
                            </td>
                            <td>
                                <select name="paymentMethod" defaultValue={affiliate.payment.method} className="form-control" onChange={inputChangeHandler}>
                                    <option value="N">None</option>
                                    <option value="B">Bitcoin</option>
                                    <option value="W">Wire</option>
                                    <option value="X">Payoneer Account</option>
                                    <option value="P">Paypal</option>
                                    <option value="M">Prepaid Card</option>
                                    <option value="A">ACH</option>
                                    <option value="E">eCheck (Local Bank Tx.)</option>
                                    <option value="R">Intercash</option>
                                    <option value="C">Paper Check</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Platform Settings</td>
                            <td className="border-right text-center">
                                {paymentPlatform == 'tipalti' || paymentPlatform == 'payoneer' ? (
                                    <button className="btn btn-warning btn-sm" onClick={(e) => openPay(e)}>
                                        Open {paymentPlatform.toUpperCase()} <ExternalLink size={14} />
                                    </button>
                                ) : (
                                    'Unavailable'
                                )}
                                <Modal isOpen={payModal} toggle={() => setPayModal(!payModal)} className="modal-lg">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{paymentPlatform.toUpperCase()} Settings</h5>
                                        <button type="button" className="close" onClick={() => setPayModal(!payModal)}>
                                            <span aria-hidden="true">x</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">{payIframe !== '' && <iframe src={payIframe} style={{ height: '500px', width: '100%' }}></iframe>}</div>
                                </Modal>
                            </td>
                            <td className="border-right text-right">
                                Minimum Payment Threshold <HelpCircle className="text-primary" size={14} data-tip="Wire=$1000, eCheck=$500, others=$100" />
                            </td>
                            <td>{editMinimumPaymentThreshold ?
                                    <input type="text" name="minimumPayout" className="form-control" defaultValue={affiliate.payment.minimum_payout} onChange={inputChangeHandler} />
                                :
                                    <div>
                                        ${affiliate.payment.minimum_payout}
                                        <input type="hidden" name="minimumPayout" value={affiliate.payment.minimum_payout}></input>
                                    </div>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Extra Data Field 1</td>
                            <td className="text-left">
                                <input name="data1" type="text" className="form-control d-inline-block" defaultValue={affiliate.payment.extra_data1} onChange={inputChangeHandler} />
                            </td>
                            <td className="text-left" colSpan={2}>
                                Account Number (US), IBAN Number (EU) or Paypal address
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Extra Data Field 2</td>
                            <td className="text-left">
                                <input name="data2" type="text" className="form-control d-inline-block" defaultValue={affiliate.payment.extra_data2} onChange={inputChangeHandler} />
                            </td>
                            <td className="text-left" colSpan={2}>
                                ABA/Bank number (US) or SWIFT/BIC Number (EU)
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Extra Data Field 3</td>
                            <td className="text-left">
                                <input name="data3" type="text" className="form-control d-inline-block" defaultValue={affiliate.payment.extra_data3} onChange={inputChangeHandler} />
                            </td>
                            <td className="text-left" colSpan={2}>
                                Not used at this time
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">MaxMoney Redemption Limit</td>
                            <td className="text-left">
                                <input name="mmRedemptionLimit" type="text" className="form-control d-inline-block" defaultValue={affiliate.payment.maxmoney_redemption_limit} onChange={inputChangeHandler} />
                            </td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PaymentInfo
