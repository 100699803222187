import { Card, Table } from "react-bootstrap";
import useCallApi from "../../../hooks/useCallApi";
import moment from "moment";
import { colors } from '../../../theme/variables'
import Loading from '../../../templates/UI/Loading/Loading'
import { DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { set } from "lodash";
import callAPI from "../../../helpers/callAPI";
import serverColor from "../../../helpers/serverColor";

const AvatarProperties = () => {
    const [init, setInit] = useState(true)

    const [data, setData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)

    const fetchData = async () => {
        setDataLoaded(false)
        let result = await callAPI(`mbStatus/monitoring/summary`, 'GET')
        if (result.success) {
            setData(result)
        }
        setDataLoaded(true)
    }

    useEffect(() => {
        if (init) {
            fetchData()
            setInit(false)
        }
        const interval = setInterval(() => {
            fetchData()
        }, 60000)
        return () => clearInterval(interval)
    }, [])
    const [avProps, setAvProps] = useState([]);
    //sort the table to usable data
    useEffect(() => {
        if (data) {
            setAvProps([]);
            const temp = [];
            data.details.applicationHealth.forEach((server, index) => {
                
                if (server.type === 'AVATAR') {
                    if (server.av_property_id !== data.details.applicationHealth[index - 1].av_property_id) {                      
                        temp.push(server);
                    } else {
                        temp[temp.length - 1].ws2Status = server.status;
                        temp[temp.length - 1].ws2last_success_date = server.last_success_date;
                    }
                }
            });
            setAvProps(temp);
        }
    }, [data])


    return (
        <Card className="mb-3">
            <Card.Header>Avatar Properties</Card.Header>
            <Card.Body>
                {dataLoaded && data ? (
                    <Table className="m-0">
                        <thead>
                            <tr>
                                <th>WS1</th>
                                <th>WS2</th>
                                <th>ID</th>
                                <th>Property</th>
                            </tr>
                        </thead>
                        <tbody>
                            {avProps.map((server, index) => {
                                if (server.type === 'AVATAR') {
                                    return (
                                        <tr key={index}>
                                            <td><FontAwesomeIcon className={serverColor(server.status)} icon="circle" /> ({moment(server.last_success_date).utc().format('YYYY-MM-DD hh:mm:ss A')})</td>
                                            <td><FontAwesomeIcon className={serverColor(server.ws2Status)} icon="circle" /> ({moment(server.ws2last_success_date).utc().format('YYYY-MM-DD hh:mm:ss A')})</td>
                                            <td>{server.av_property_id}</td>
                                            <td><a href={server.url}>{server.url}</a></td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <Loading />
                )}
            </Card.Body>
        </Card>
    )
}

export { AvatarProperties };
