import React, { useEffect, useState } from 'react'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Campaign from '../../../templates/Campaign/Campaign'
import Dollars from '../../../templates/Dollars/Dollars'
import moment from 'moment'

const ReportTopCampaigns = ({ showSystemMessage }) => {
    document.title = 'Top Campaigns'

    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const location = useLocation()
    const history = useHistory()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    useEffect(() => {
        getReport()
    }, [location])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Earnings</div>,
                accessor: 'earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const refreshReport = (e) => {
        e.preventDefault()

        let url = buildUrl(`/campaigns/top?`)
        history.push(url)
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = buildUrl(`campaigns/top?`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.list)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }

                setDataLoaded(true)
            })
            .catch((error) => {
                console.log(`Error while fetching top campaigns: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching top campaigns')
            })
    }

    return (
        <div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <h1 className="mb-h1-dark">Top Campaigns for:</h1>
                        </div>
                        <div className="mr-4">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD', 'MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']} />
                        </div>
                        <div className="d-flex align-items-center mr-2">
                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                        <div className="align-items-center d-flex">
                            (Last 30 days:{' '}
                            <span className="ml-2">
                                {moment().subtract(30, 'days').format('YYYY/MM/DD')}-{moment().format('YYYY/MM/DD')}){' '}
                            </span>{' '}
                        </div>
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default ReportTopCampaigns
