import React from 'react'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Dollars from '../../../templates/Dollars/Dollars'
import Advertiser from '../../../templates/Advertiser/Advertiser'

const SearchResults = ({ searchResults }) => {
    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'name',
                Cell: (tableData) => {
                    return (
                        <>
                            <Advertiser id={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status.name} />
                        </>
                    )
                },
            },
            {
                Header: 'Contact',
                accessor: 'contact',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Bizdev Manager',
                accessor: 'admin_bizdev.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser Manager',
                accessor: 'admin_advertiser.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Month Earnings</div>,
                accessor: 'month_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return (
                        <>
                            <Dollars value={value} />
                        </>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={searchResults} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div className="card">
            <div className="card-body p-0">{getReportOutput()}</div>
        </div>
    )
}

export default SearchResults
