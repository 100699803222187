import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import Loading from '../../../../../templates/UI/Loading/Loading'

const TestLinksModal = ({ campaignId, landingPages, isOpen, setIsOpen, campaignData, dataLoaded }) => {
    const [copied, setCopied] = useState(false)

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Test Links For: {campaignData ? campaignData.name : null} </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'auto' }}>
                {campaignData && dataLoaded && landingPages.length > 0 ? (
                    <>
                        {landingPages.map((page, i) => (
                            <div key={i}>
                                {campaignData.type.value !== 'H' ? (
                                    <div className="mb-4 border-bottom">
                                        <h4>Landing Page Name: {page.lp_name}</h4>
                                        <div>
                                            <a href={page.lp_landing_url} target="_blank">
                                                {page.lp_landing_url}
                                            </a>
                                        </div>
                                        <br />
                                        <h5>Notes</h5>
                                        *Please copy and paste the test link into your browser. Simply clicking the link will sometimes not work in testing phase.
                                        <br />
                                        **If you are sharing this link with an advertiser by email, please use one of the following links.
                                        <br />
                                        <br />
                                        https://mbt3st3r1.com/lnk.asp?o={campaignId}&a=1053&c=1&l={page.lp_record_id}
                                        <CopyToClipboard text={`https://mbt3st3r1.com/lnk.asp?o=${campaignId}&a=1053&c=1&l=${page.lp_record_id}`} className="ml-2" onCopy={() => setCopied(true)}>
                                            <Button variant="outline-primary" size="sm">
                                                <FontAwesomeIcon icon="copy" />
                                            </Button>
                                        </CopyToClipboard>
                                        <br />
                                        https://mbt3st3r2.com/lnk.asp?o={campaignId}&a=1053&c=1&l={page.lp_record_id}
                                        <CopyToClipboard text={`https://mbt3st3r2.com/lnk.asp?o=${campaignId}&a=1053&c=1&l=${page.lp_record_id}`} className="ml-2" onCopy={() => setCopied(true)}>
                                            <Button variant="outline-primary" size="sm">
                                                <FontAwesomeIcon icon="copy" />
                                            </Button>
                                        </CopyToClipboard>
                                    </div>
                                ) : (
                                    <div className="mb-4 border-bottom">
                                        <h4>Landing Page Name: {page.lp_name}</h4>
                                        POST: https://api.maxbounty.com/trk/hp
                                        <CopyToClipboard text="https://api.maxbounty.com/trk/hp" className="ml-2" onCopy={() => setCopied(true)}>
                                            <Button variant="outline-primary" size="sm">
                                                <FontAwesomeIcon icon="copy" />
                                            </Button>
                                        </CopyToClipboard>
                                        <hr />
                                        Parameters: <br />
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {page.name && !page.name_value ? (
                                                    <tr>
                                                        <td>name</td>
                                                        <td>{page.name_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.first_name && !page.first_name_value ? (
                                                    <tr>
                                                        <td>first_name</td>
                                                        <td>{page.first_name_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.last_name && !page.last_name_value ? (
                                                    <tr>
                                                        <td>last_name</td>
                                                        <td>{page.last_name_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.phone && !page.phone_value ? (
                                                    <tr>
                                                        <td>phone</td>
                                                        <td>{page.phone_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.e_mail && !page.e_mail_value ? (
                                                    <tr>
                                                        <td>e_mail</td>
                                                        <td>{page.e_mail_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.address1 && !page.address1_value ? (
                                                    <tr>
                                                        <td>address1</td>
                                                        <td>{page.address1_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.address2 && !page.address2_value ? (
                                                    <tr>
                                                        <td>address2</td>
                                                        <td>{page.address2_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.city && !page.city_value ? (
                                                    <tr>
                                                        <td>city</td>
                                                        <td>{page.city_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.country_code && !page.country_code_value ? (
                                                    <tr>
                                                        <td>country_code</td>
                                                        <td>{page.country_code_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.state && !page.state_value ? (
                                                    <tr>
                                                        <td>state</td>
                                                        <td>{page.state_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.zipcode && !page.zipcode_value ? (
                                                    <tr>
                                                        <td>zipcode</td>
                                                        <td>{page.zipcode_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.remote_host && !page.remote_host_value ? (
                                                    <tr>
                                                        <td>remote_host</td>
                                                        <td>{page.remote_host_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub1_label && !page.sub1_value ? (
                                                    <tr>
                                                        <td>sub1</td>
                                                        <td>{page.sub1_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub2_label && !page.sub2_value ? (
                                                    <tr>
                                                        <td>sub2</td>
                                                        <td>{page.sub2_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub3_label && !page.sub3_value ? (
                                                    <tr>
                                                        <td>sub3</td>
                                                        <td>{page.sub3_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub4_label && !page.sub4_value ? (
                                                    <tr>
                                                        <td>sub4</td>
                                                        <td>{page.sub4_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub5_label && !page.sub5_value ? (
                                                    <tr>
                                                        <td>sub5</td>
                                                        <td>{page.sub5_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub6_label && !page.sub6_value ? (
                                                    <tr>
                                                        <td>sub6</td>
                                                        <td>{page.sub6_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub7_label && !page.sub7_value ? (
                                                    <tr>
                                                        <td>sub7</td>
                                                        <td>{page.sub7_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub8_label && !page.sub8_value ? (
                                                    <tr>
                                                        <td>sub8</td>
                                                        <td>{page.sub8_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub9_label && !page.sub9_value ? (
                                                    <tr>
                                                        <td>sub9</td>
                                                        <td>{page.sub9_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub10_label && !page.sub10_value ? (
                                                    <tr>
                                                        <td>sub10</td>
                                                        <td>{page.sub10_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub11_label && !page.sub11_value ? (
                                                    <tr>
                                                        <td>sub11</td>
                                                        <td>{page.sub11_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub12_label && !page.sub12_value ? (
                                                    <tr>
                                                        <td>sub12</td>
                                                        <td>{page.sub12_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub13_label && !page.sub13_value ? (
                                                    <tr>
                                                        <td>sub13</td>
                                                        <td>{page.sub13_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub14_label && !page.sub14_value ? (
                                                    <tr>
                                                        <td>sub14</td>
                                                        <td>{page.sub14_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub15_label && !page.sub15_value ? (
                                                    <tr>
                                                        <td>sub15</td>
                                                        <td>{page.sub15_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub16_label && !page.sub16_value ? (
                                                    <tr>
                                                        <td>sub16</td>
                                                        <td>{page.sub16_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub17_label && !page.sub17_value ? (
                                                    <tr>
                                                        <td>sub17</td>
                                                        <td>{page.sub17_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub18_label && !page.sub18_value ? (
                                                    <tr>
                                                        <td>sub18</td>
                                                        <td>{page.sub18_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub19_label && !page.sub19_value ? (
                                                    <tr>
                                                        <td>sub19</td>
                                                        <td>{page.sub19_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub20_label && !page.sub20_value ? (
                                                    <tr>
                                                        <td>sub20</td>
                                                        <td>{page.sub20_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub21_label && !page.sub21_value ? (
                                                    <tr>
                                                        <td>sub21</td>
                                                        <td>{page.sub21_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub22_label && !page.sub22_value ? (
                                                    <tr>
                                                        <td>sub22</td>
                                                        <td>{page.sub22_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub23_label && !page.sub23_value ? (
                                                    <tr>
                                                        <td>sub23</td>
                                                        <td>{page.sub23_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub24_label && !page.sub24_value ? (
                                                    <tr>
                                                        <td>sub24</td>
                                                        <td>{page.sub24_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub25_label && !page.sub25_value ? (
                                                    <tr>
                                                        <td>sub25</td>
                                                        <td>{page.sub25_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub26_label && !page.sub26_value ? (
                                                    <tr>
                                                        <td>sub26</td>
                                                        <td>{page.sub26_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub27_label && !page.sub27_value ? (
                                                    <tr>
                                                        <td>sub27</td>
                                                        <td>{page.sub27_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub28_label && !page.sub28_value ? (
                                                    <tr>
                                                        <td>sub28</td>
                                                        <td>{page.sub28_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub29_label && !page.sub29_value ? (
                                                    <tr>
                                                        <td>sub29</td>
                                                        <td>{page.sub29_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub30_label && !page.sub30_value ? (
                                                    <tr>
                                                        <td>sub30</td>
                                                        <td>{page.sub30_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub31_label && !page.sub31_value ? (
                                                    <tr>
                                                        <td>sub31</td>
                                                        <td>{page.sub31_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub32_label && !page.sub32_value ? (
                                                    <tr>
                                                        <td>sub32</td>
                                                        <td>{page.sub32_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub33_label && !page.sub33_value ? (
                                                    <tr>
                                                        <td>sub33</td>
                                                        <td>{page.sub33_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub34_label && !page.sub34_value ? (
                                                    <tr>
                                                        <td>sub34</td>
                                                        <td>{page.sub34_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub35_label && !page.sub35_value ? (
                                                    <tr>
                                                        <td>sub35</td>
                                                        <td>{page.sub35_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub36_label && !page.sub36_value ? (
                                                    <tr>
                                                        <td>sub36</td>
                                                        <td>{page.sub36_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub37_label && !page.sub37_value ? (
                                                    <tr>
                                                        <td>sub37</td>
                                                        <td>{page.sub37_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub38_label && !page.sub38_value ? (
                                                    <tr>
                                                        <td>sub38</td>
                                                        <td>{page.sub38_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub39_label && !page.sub39_value ? (
                                                    <tr>
                                                        <td>sub39</td>
                                                        <td>{page.sub39_descr}</td>
                                                    </tr>
                                                ) : null}
                                                {page.sub40_label && !page.sub40_value ? (
                                                    <tr>
                                                        <td>sub40</td>
                                                        <td>{page.sub40_descr}</td>
                                                    </tr>
                                                ) : null}
                                                <tr>
                                                    <td class="smalltext">campaign_id</td>
                                                    <td class="smalltext">Maxbounty Campaign ID</td>
                                                </tr>
                                                <tr>
                                                    <td class="smalltext">publisher_id</td>
                                                    <td class="smalltext">Maxbounty Affiliate ID</td>
                                                </tr>
                                                <tr>
                                                    <td class="smalltext">landing_id</td>
                                                    <td class="smalltext">Landing Page ID</td>
                                                </tr>
                                                <tr>
                                                    <td class="smalltext">s1</td>
                                                    <td class="smalltext">Tracking subcode 1 (optional)</td>
                                                </tr>
                                                <tr>
                                                    <td class="smalltext">s2</td>
                                                    <td class="smalltext">Tracking subcode 2 (optional)</td>
                                                </tr>
                                                <tr>
                                                    <td class="smalltext">s3</td>
                                                    <td class="smalltext">Tracking subcode 3 (optional)</td>
                                                </tr>
                                                <tr>
                                                    <td class="smalltext">s4</td>
                                                    <td class="smalltext">Tracking subcode 4 (optional)</td>
                                                </tr>
                                                <tr>
                                                    <td class="smalltext">s5</td>
                                                    <td class="smalltext">Tracking subcode 5 (optional)</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <hr />
                                        Post example:
                                        <br />
                                        <br />
                                        <code className="text-primary">
                                            Headers: Content-Type:application/json <br />
                                            Body: &#123;
                                            <br />
                                            {page.name && !page.name_value ? (
                                                <>
                                                    {' '}
                                                    &nbsp;"name": "{page.name_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.first_name && !page.first_name_value ? (
                                                <>
                                                    {' '}
                                                    &nbsp;"first_name": "{page.first_name_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.last_name && !page.last_name_value ? (
                                                <>
                                                    {' '}
                                                    &nbsp;"last_name": "{page.last_name_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.phone && !page.phone_value ? (
                                                <>
                                                    {' '}
                                                    &nbsp;"phone": "{page.phone_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.e_mail && !page.e_mail_value ? (
                                                <>
                                                    {' '}
                                                    &nbsp;"e_mail": "{page.e_mail_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.address1 && !page.address1_value ? (
                                                <>
                                                    &nbsp;"address1": "{page.address1_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.address2 && !page.address2_value ? (
                                                <>
                                                    &nbsp;"address2": "{page.address2_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.city && !page.city_value ? (
                                                <>
                                                    &nbsp;"city": "{page.city_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.country_code && !page.country_code_value ? (
                                                <>
                                                    &nbsp;"country_code": "{page.country_code_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.state && !page.state_value ? (
                                                <>
                                                    &nbsp;"state": "{page.state_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.zipcode && !page.zipcode_value ? (
                                                <>
                                                    &nbsp;"zipcode": "{page.zipcode_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.remote_host && !page.remote_host_value ? (
                                                <>
                                                    &nbsp;"remote_host": "{page.remote_host_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub1_label && !page.sub1_value ? (
                                                <>
                                                    &nbsp;"sub1": "{page.sub1_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub2_label && !page.sub2_value ? (
                                                <>
                                                    &nbsp;"sub2": "{page.sub2_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub3_label && !page.sub3_value ? (
                                                <>
                                                    &nbsp;"sub3": "{page.sub3_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub4_label && !page.sub4_value ? (
                                                <>
                                                    &nbsp;"sub4": "{page.sub4_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub5_label && !page.sub5_value ? (
                                                <>
                                                    &nbsp;"sub5": "{page.sub5_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub6_label && !page.sub6_value ? (
                                                <>
                                                    &nbsp;"sub6": "{page.sub6_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub7_label && !page.sub7_value ? (
                                                <>
                                                    &nbsp;"sub7": "{page.sub7_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub8_label && !page.sub8_value ? (
                                                <>
                                                    &nbsp;"sub8": "{page.sub8_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub9_label && !page.sub9_value ? (
                                                <>
                                                    &nbsp;"sub9": "{page.sub9_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub10_label && !page.sub10_value ? (
                                                <>
                                                    &nbsp;"sub10": "{page.sub10_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub11_label && !page.sub11_value ? (
                                                <>
                                                    &nbsp;"sub11": "{page.sub11_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub12_label && !page.sub12_value ? (
                                                <>
                                                    &nbsp;"sub12": "{page.sub12_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub13_label && !page.sub13_value ? (
                                                <>
                                                    &nbsp;"sub13": "{page.sub13_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub14_label && !page.sub14_value ? (
                                                <>
                                                    &nbsp;"sub14": "{page.sub14_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub15_label && !page.sub15_value ? (
                                                <>
                                                    &nbsp;"sub15": "{page.sub15_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub16_label && !page.sub16_value ? (
                                                <>
                                                    &nbsp;"sub16": "{page.sub16_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub17_label && !page.sub17_value ? (
                                                <>
                                                    &nbsp;"sub17": "{page.sub17_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub18_label && !page.sub18_value ? (
                                                <>
                                                    &nbsp;"sub18": "{page.sub18_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub19_label && !page.sub19_value ? (
                                                <>
                                                    &nbsp;"sub19": "{page.sub19_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub20_label && !page.sub20_value ? (
                                                <>
                                                    &nbsp;"sub20": "{page.sub20_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub21_label && !page.sub21_value ? (
                                                <>
                                                    &nbsp;"sub21": "{page.sub21_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub22_label && !page.sub22_value ? (
                                                <>
                                                    &nbsp;"sub22": "{page.sub22_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub23_label && !page.sub23_value ? (
                                                <>
                                                    &nbsp;"sub23": "{page.sub23_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub24_label && !page.sub24_value ? (
                                                <>
                                                    &nbsp;"sub24": "{page.sub24_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub25_label && !page.sub25_value ? (
                                                <>
                                                    &nbsp;"sub25": "{page.sub25_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub26_label && !page.sub26_value ? (
                                                <>
                                                    &nbsp;"sub26": "{page.sub26_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub27_label && !page.sub27_value ? (
                                                <>
                                                    &nbsp;"sub27": "{page.sub27_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub28_label && !page.sub28_value ? (
                                                <>
                                                    &nbsp;"sub28": "{page.sub28_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub29_label && !page.sub29_value ? (
                                                <>
                                                    &nbsp;"sub29": "{page.sub29_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub30_label && !page.sub30_value ? (
                                                <>
                                                    &nbsp;"sub30": "{page.sub30_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub31_label && !page.sub31_value ? (
                                                <>
                                                    &nbsp;"sub31": "{page.sub31_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub32_label && !page.sub32_value ? (
                                                <>
                                                    &nbsp;"sub32": "{page.sub32_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub33_label && !page.sub33_value ? (
                                                <>
                                                    &nbsp;"sub33": "{page.sub33_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub34_label && !page.sub34_value ? (
                                                <>
                                                    &nbsp;"sub34": "{page.sub34_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub35_label && !page.sub35_value ? (
                                                <>
                                                    &nbsp;"sub35": "{page.sub35_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub36_label && !page.sub36_value ? (
                                                <>
                                                    &nbsp;"sub36": "{page.sub36_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub37_label && !page.sub37_value ? (
                                                <>
                                                    &nbsp;"sub37": "{page.sub37_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub38_label && !page.sub38_value ? (
                                                <>
                                                    &nbsp;"sub38": "{page.sub38_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub39_label && !page.sub39_value ? (
                                                <>
                                                    &nbsp;"sub39": "{page.sub39_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {page.sub40_label && !page.sub40_value ? (
                                                <>
                                                    &nbsp;"sub40": "{page.sub40_example}",
                                                    <br />
                                                </>
                                            ) : null}
                                            {campaignId ? (
                                                <>
                                                    &nbsp;"campaign_id": {campaignId},
                                                    <br />
                                                </>
                                            ) : null}
                                            &nbsp;"publisher_id": &#123;INSERT AFFILIATE ID HERE&#125;
                                            <br />
                                            {page.lp_record_id ? (
                                                <>
                                                    &nbsp;"landing_id": {page.lp_record_id},
                                                    <br />
                                                </>
                                            ) : null}
                                            &nbsp;"s1": "subcode 1 example"
                                            <br />
                                            &#125;
                                        </code>
                                    </div>
                                )}
                            </div>
                        ))}
                    </>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
        </Modal>
    )
}

export default TestLinksModal
