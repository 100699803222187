import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const ComplianceDropdown = (props) => {
    const auth = useAuth()
    const showBatchReverse = useProtectedContent(auth.permissions, 'compliance_batch_reverse')
    const showBatchModify = useProtectedContent(auth.permissions, 'compliance_batch_modify')
    const showBlacklist = useProtectedContent(auth.permissions, 'compliance_blacklist')
    const showTicketManager = useProtectedContent(auth.permissions, 'ticket_manager')

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle">
                Compliance
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-dark">
                <Link className="dropdown-item" to="/compliance/applications" onClick={toggle}>
                    Affiliate Applications
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/compliance/fraudReport" onClick={toggle}>
                    Fraud Report
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/compliance/affiliateAnalyzer" onClick={toggle}>
                    Affiliate Analyzer
                </Link>
                {showTicketManager && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-separator" />
                        <Link className="dropdown-item" to="/admin/tickets/manage" onClick={toggle}>
                            Ticket Manager
                        </Link>
                    </>
                )}
                {showBatchReverse && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/compliance/reverseLeads" onClick={toggle}>
                            Batch Reverse Leads
                        </Link>
                    </>
                )}
                {showBatchModify && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/compliance/modifyLeads" onClick={toggle}>
                            Batch Modify Leads
                        </Link>
                    </>
                )}

                <DropdownItem divider className="m-0 dropdown-separator" />
                <Link className="dropdown-item" to="/compliance/tipaltiMissingDocs" onClick={toggle}>
                    Affiliates Missing Tipalti Docs
                </Link>

                <DropdownItem divider className="m-0 dropdown-separator" />
                <Link className="dropdown-item" to="/compliance/useragentWatchlist" onClick={toggle}>
                    Affiliate User Agent Watchlist
                </Link>
                {showBlacklist && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/compliance/blacklist" onClick={toggle}>
                            Manage Blacklists
                        </Link>
                    </>
                )}
            </DropdownMenu>
        </Dropdown>
    )
}

export default ComplianceDropdown
