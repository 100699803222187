import React, { useState, useEffect } from 'react'
import Loading from '../../../templates/UI/Loading/Loading'

import { Line } from 'react-chartjs-2'

import SotuMargin from './SotuMargin'
import SotuOffers from './SotuOffers'
import SotuAffPriority from './SotuAffPriority'
import SotuAffEarnings from './SotuAffEarnings'
import SotuMerchants from './SotuMerchants'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

export default function ReportSotu(props) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'sotu_report')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    const showSystemMessage = props.showSystemMessage

    useEffect(() => {
        setDataLoaded(false)
        const mbToken = localStorage.getItem('adm-auth-token')

        let url = `${process.env.REACT_APP_API_URL}/reports/sotu`

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    setDataLoaded(true)
                    setReport(result.report)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                } else {
                    showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            })
            .catch((error) => {
                console.log('Error in ReportAffSignup: ', error)
                showSystemMessage('error', 'Connection error.')
            })
    }, [showSystemMessage]) // Passing an array as second argument = componentDidMount

    let chartLabels = report.map((quarter) => quarter.quarter).reverse()

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h1 className="mb-h1-dark">Margin</h1>
                        </div>
                        <div className="card-body">
                            <Line
                                data={{
                                    labels: chartLabels,
                                    datasets: [
                                        {
                                            label: 'Margin',
                                            data: report.map((quarter) => quarter.margin).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(22, 95, 205)',
                                            borderColor: 'rgb(22, 95, 205)',
                                        },
                                    ],
                                }}
                                options={{
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        ],
                                    },
                                    legend: {
                                        display: false,
                                        position: 'bottom',
                                    },
                                }}
                                height={50}
                            />

                            <table className="table table-striped table-card table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col" className="text-right">
                                            Margin
                                        </th>
                                        <th scope="col" width="20px">
                                            &nbsp;
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded ? (
                                        report.map((quarter, index) => {
                                            return <SotuMargin quarter={quarter} report={report} index={index} />
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-header">
                            <h1 className="mb-h1-dark">Campaigns by Status</h1>
                        </div>
                        <div className="card-body">
                            <Line
                                data={{
                                    labels: chartLabels,
                                    datasets: [
                                        {
                                            label: 'Active',
                                            data: report.map((quarter) => quarter.off_statusA_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(22, 95, 205)',
                                            borderColor: 'rgb(22, 95, 205)',
                                        },
                                        {
                                            label: 'By Request',
                                            data: report.map((quarter) => quarter.off_statusB_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(242, 101, 20)',
                                            borderColor: 'rgb(242, 101, 20)',
                                            hidden: true,
                                        },
                                        {
                                            label: 'Hidden',
                                            data: report.map((quarter) => quarter.off_statusH_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(255, 205, 0)',
                                            borderColor: 'rgb(255, 205, 0)',
                                            hidden: true,
                                        },
                                        {
                                            label: 'Testing',
                                            data: report.map((quarter) => quarter.off_statusT_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(239,0,255)',
                                            borderColor: 'rgb(239,0,255)',
                                            hidden: true,
                                        },
                                    ],
                                }}
                                options={{
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        ],
                                    },
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                }}
                                height={50}
                            />

                            <table className="table table-striped table-card table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col" className="text-right">
                                            Active
                                        </th>
                                        <th scope="col" className="text-right">
                                            By Request
                                        </th>
                                        <th scope="col" className="text-right">
                                            Hidden
                                        </th>
                                        <th scope="col" className="text-right">
                                            Testing
                                        </th>
                                        <th scope="col" width="20px">
                                            &nbsp;
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded ? (
                                        report.map((quarter, index) => {
                                            return <SotuOffers quarter={quarter} report={report} index={index} />
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-header">
                            <h1 className="mb-h1-dark">Advertisers</h1>
                        </div>
                        <div className="card-body">
                            <Line
                                data={{
                                    labels: chartLabels,
                                    datasets: [
                                        {
                                            label: 'With Live Campaigns',
                                            data: report.map((quarter) => quarter.mer_active_live_offers_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(22, 95, 205)',
                                            borderColor: 'rgb(22, 95, 205)',
                                        },
                                        {
                                            label: 'With In-Progress Campaigns',
                                            data: report.map((quarter) => quarter.mer_active_inprogress_offers_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(242, 101, 20)',
                                            borderColor: 'rgb(242, 101, 20)',
                                            hidden: true,
                                        },
                                        {
                                            label: 'With No Campaigns',
                                            data: report.map((quarter) => quarter.mer_active_pipeline_nooffers_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(255, 205, 0)',
                                            borderColor: 'rgb(255, 205, 0)',
                                            hidden: true,
                                        },
                                    ],
                                }}
                                options={{
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        ],
                                    },
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                }}
                                height={50}
                            />

                            <table className="table table-striped table-card table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col" className="text-right">
                                            With Live Campaigns
                                        </th>
                                        <th scope="col" className="text-right">
                                            With In-Progress Campaigns
                                        </th>
                                        <th scope="col" className="text-right">
                                            With No Campaigns
                                        </th>
                                        <th scope="col" width="20px">
                                            &nbsp;
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded ? (
                                        report.map((quarter, index) => {
                                            return <SotuMerchants quarter={quarter} report={report} index={index} />
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h1 className="mb-h1-dark">Affiliates by Priority</h1>
                        </div>
                        <div className="card-body">
                            <Line
                                data={{
                                    labels: chartLabels,
                                    datasets: [
                                        {
                                            label: 'High',
                                            data: report.map((quarter) => quarter.aff_priority1_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(22, 95, 205)',
                                            borderColor: 'rgb(22, 95, 205)',
                                        },
                                        {
                                            label: 'Medium',
                                            data: report.map((quarter) => quarter.aff_priority2_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(242, 101, 20)',
                                            borderColor: 'rgb(242, 101, 20)',
                                            hidden: true,
                                        },
                                        {
                                            label: 'Low',
                                            data: report.map((quarter) => quarter.aff_priority4_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(255, 205, 0)',
                                            borderColor: 'rgb(255, 205, 0)',
                                            hidden: true,
                                        },
                                        {
                                            label: 'Very Low',
                                            data: report.map((quarter) => quarter.aff_priority5_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(239,0,255)',
                                            borderColor: 'rgb(239,0,255)',
                                            hidden: true,
                                        },
                                        {
                                            label: 'New',
                                            data: report.map((quarter) => quarter.aff_priority3_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(35,193,120)',
                                            borderColor: 'rgb(35,193,120)',
                                            hidden: true,
                                        },
                                    ],
                                }}
                                options={{
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        ],
                                    },
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                }}
                                height={50}
                            />

                            <table className="table table-striped table-card table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col" className="text-right">
                                            High
                                        </th>
                                        <th scope="col" className="text-right">
                                            Medium
                                        </th>
                                        <th scope="col" className="text-right">
                                            Low
                                        </th>
                                        <th scope="col" className="text-right">
                                            Very Low
                                        </th>
                                        <th scope="col" className="text-right">
                                            New
                                        </th>
                                        <th scope="col" width="20px">
                                            &nbsp;
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded ? (
                                        report.map((quarter, index) => {
                                            return <SotuAffPriority quarter={quarter} report={report} index={index} />
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-header">
                            <h1 className="mb-h1-dark">Affiliates by Earnings</h1>
                        </div>
                        <div className="card-body">
                            <Line
                                data={{
                                    labels: chartLabels,
                                    datasets: [
                                        {
                                            label: '$1000+',
                                            data: report.map((quarter) => quarter.aff_GTEQ1000_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(22, 95, 205)',
                                            borderColor: 'rgb(22, 95, 205)',
                                        },
                                        {
                                            label: '$500+',
                                            data: report.map((quarter) => quarter.aff_GTEQ500_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(242, 101, 20)',
                                            borderColor: 'rgb(242, 101, 20)',
                                            hidden: true,
                                        },
                                        {
                                            label: '$100+',
                                            data: report.map((quarter) => quarter.aff_GTEQ100_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(255, 205, 0)',
                                            borderColor: 'rgb(255, 205, 0)',
                                            hidden: true,
                                        },
                                        {
                                            label: '$1+',
                                            data: report.map((quarter) => quarter.aff_GT0_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(239,0,255)',
                                            borderColor: 'rgb(239,0,255)',
                                            hidden: true,
                                        },
                                        {
                                            label: '$0',
                                            data: report.map((quarter) => quarter.aff_EQ0_count).reverse(),
                                            fill: false,
                                            backgroundColor: 'rgb(35,193,120)',
                                            borderColor: 'rgb(35,193,120)',
                                            hidden: true,
                                        },
                                    ],
                                }}
                                options={{
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        ],
                                    },
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                }}
                                height={50}
                            />

                            <table className="table table-striped table-card table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col" className="text-right">
                                            $1,000+
                                        </th>
                                        <th scope="col" className="text-right">
                                            $500+
                                        </th>
                                        <th scope="col" className="text-right">
                                            $100+
                                        </th>
                                        <th scope="col" className="text-right">
                                            $1+
                                        </th>
                                        <th scope="col" className="text-right">
                                            $0
                                        </th>
                                        <th scope="col" width="20px">
                                            &nbsp;
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLoaded ? (
                                        report.map((quarter, index) => {
                                            return <SotuAffEarnings quarter={quarter} report={report} index={index} />
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="10" align="center">
                                                <Loading />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
