import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import styles from './BizdevSummary.module.scss'
import AdvertiserStatusDisplay from './components/AdvertiserStatusDisplay'
import Number from '../../../../templates/Number/Number'

export default function BizdevSummary({ manager }) {
    const [scale, setScale] = useState('daily')
    const [dataLoaded, setDataLoaded] = useState(false)

    const [summaryData, setSummaryData] = useState({
        affiliates: {
            high: { affiliate_manager: 0, network: 0 },
            medium: { affiliate_manager: 0, network: 0 },
            new: { affiliate_manager: 0, network: 0 },
            total: { affiliate_manager: 0, network: 0 },
        },
        followupsToday: 0,
        margin: 0.0,
        applications: { approved: 0, processed: 0 },
        added: {
            today: 0,
            yesterday: 0,
            sevenDay: 0,
            thirtyDay: 0,
        },
    })

    const [todayMargin, setTodayMargin] = useState(0)
    const [monthMargin, setMonthMargin] = useState(0)

    useEffect(async () => {
        const fetchSummaryData = async () => {
            const data = await callAPI(`summary/bizdevManager?adminId=${manager.id}`, 'GET', null)
            if (data.success) {
                setSummaryData(data)
            } else {
                console.log(data.message)
            }
        }

        setDataLoaded(false)

        await fetchSummaryData()

        setDataLoaded(true)
    }, [manager])

    return (
        <Card className="mb-3">
            <Card.Title className={`m-3 d-flex ${styles.customFlexDirection}`}>
                <div className="font-weight-bold text-secondary" style={{ fontSize: '1.5rem' }}>
                    Hello <span className="text-dark">{manager.name.split(' ')[0]}</span>
                </div>
            </Card.Title>
            <hr className="my-0" />
            <Card.Body>
                {dataLoaded ? (
                    <>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="font-weight-bold mr-2">Margin</span>
                            </div>
                            <div>
                                Today &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(summaryData.margin.today)} &nbsp;</span>
                                </span>
                            </div>
                            <div>
                                Month &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(summaryData.margin.month)} &nbsp;</span>
                                </span>
                            </div>
                            <div>
                                Year &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(summaryData.margin.year)} &nbsp;</span>
                                </span>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            { summaryData.advertisers.length > 0 &&
                            <>
                                <AdvertiserStatusDisplay item={summaryData.advertisers.find((item) => item.status === 'New Lead' )} manager={manager} />
                                <AdvertiserStatusDisplay item={summaryData.advertisers.find((item) => item.status === 'Negotiation' )} manager={manager} />
                                <AdvertiserStatusDisplay item={summaryData.advertisers.find((item) => item.status === 'Discussion' )} manager={manager} />
                                <AdvertiserStatusDisplay item={summaryData.advertisers.find((item) => item.status === 'Active' )} manager={manager} />
                            </>
                            }                            
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <div className="mr-3">Accounts Added</div>
                                <div className="border rounded mr-2 d-flex">
                                    <div className="border-right text-center">
                                        <div className="border-bottom px-2 font-weight-bold">{summaryData.added.today}</div>
                                        <div className="px-2">Today</div>
                                    </div>
                                    <div className="border-right text-center">
                                        <div className="border-bottom px-2 font-weight-bold">{summaryData.added.yesterday}</div>
                                        <div className="px-2">Yesterday</div>
                                    </div>
                                    <div className="border-right text-center">
                                        <div className="border-bottom px-2 font-weight-bold">{summaryData.added.sevenDay}</div>
                                        <div className="px-2">Last 7 Days</div>
                                    </div>
                                    <div className="text-center">
                                        <div className="border-bottom px-2 font-weight-bold">{summaryData.added.thirtyDay}</div>
                                        <div className="px-2">Last 30 Days</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mr-3">Close Rate: <span className="font-weight-bold"><Number value={summaryData.performance.close_rate*100} decimals={2}/>%</span> ({summaryData.performance.total_advertisers_who_became_active}/{summaryData.performance.total_advertisers})</div>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </Card.Body>
            <div className={`d-flex justify-content-center p-2 bg-info ${styles.bgInfo25}`}>
                <Link to={`/admin/AAMPerformanceReport?adminId=${manager.id}`} className="text-primary">
                    {' '}
                    View performance report{' '}
                </Link>
            </div>
        </Card>
    )
}
