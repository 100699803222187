import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Loading from '../../../../templates/UI/Loading/Loading'

const CompliancePaymentInfoCheck = ({ modalData, setModalData, manager }) => {
    const [data, setData] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        if (modalData.isOpen) {
            callAPI(`affiliate/${modalData.affiliate.id}/fraud/paymentInformation`, 'GET')
                .then((result) => {
                    if (result['success'] === true) {
                        setData(result)
                        setDataLoaded(true)
                    } else if (result['success'] === false) {
                        // showSystemMessage('error', result.errors.message)
                        setDataLoaded(true)
                    }
                })
                .catch((error) => {
                    console.log(`Error while marking affiliate as reviewed: ${error}`)
                    // showSystemMessage('error', 'An error occurred while marking affiliate as reviewed')
                })
        }
    }, [modalData.isOpen, manager])

    const TableRow = ({ label, maxbounty, tipalti }) => {
        return (
            <tr className={maxbounty !== tipalti ? 'table-warning' : ''}>
                <td>{label}</td>
                <td>{maxbounty}</td>
                <td>{tipalti}</td>
            </tr>
        )
    }

    return (
        <Modal show={modalData.isOpen} onHide={() => setModalData((prev) => ({ ...prev, isOpen: false }))} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Payment Info For Affiliate: <Affiliate aid={modalData.affiliate.id} name={modalData.affiliate.name} status={modalData.affiliate.status} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>MaxBounty</th>
                            <th>{dataLoaded ? data.payment_platform_info.reason : spinner}</th>
                        </tr>
                    </thead>
                    {dataLoaded ? (
                        <>
                            {!data || data.success === false ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>No data.</td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <TableRow label="Name" maxbounty={data.maxbounty_payment_info.name} tipalti={data.payment_platform_info.name} />

                                    <TableRow label="Address 1" maxbounty={data.maxbounty_payment_info.address1} tipalti={data.payment_platform_info.address1} />

                                    <TableRow label="Address 2" maxbounty={data.maxbounty_payment_info.address2} tipalti={data.payment_platform_info.address2} />

                                    <TableRow label="City" maxbounty={data.maxbounty_payment_info.city} tipalti={data.payment_platform_info.city} />

                                    <TableRow label="State" maxbounty={data.maxbounty_payment_info.state} tipalti={data.payment_platform_info.state} />

                                    <TableRow label="Country" maxbounty={data.maxbounty_payment_info.country} tipalti={data.payment_platform_info.country} />

                                    <TableRow label="Zip Code" maxbounty={data.maxbounty_payment_info.zipcode} tipalti={data.payment_platform_info.zipcode} />

                                    <TableRow label="Email" maxbounty={data.maxbounty_payment_info.email} tipalti={data.payment_platform_info.email} />
                                </tbody>
                            )}
                        </>
                    ) : (
                        <tr>
                            <td colSpan={5} align="center">
                                <Loading />
                            </td>
                        </tr>
                    )}
                </Table>
                {data.hold_payment_flag && <div className="text-danger font-weight-bold">THIS AFFILIATE HAS A HOLD PAYMENT FLAG. REMOVE IF THIS AFFILIATE SHOULD BE PAID AND IS NOT FRAUDULENT.</div>}
            </Modal.Body>
        </Modal>
    )
}

export default CompliancePaymentInfoCheck
