import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'

import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'

const EFTCountries = ({ isOpen, handleClose }) => {
    const { dataLoaded, data } = useCallApi(`admin/helpText/5`, 'GET', {}, [])

    let spinner = <FontAwesomeIcon icon="spinner" spin />

    return (
        <Modal show={isOpen} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{dataLoaded ? data.help.help_title : spinner}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{dataLoaded ? <div dangerouslySetInnerHTML={{ __html: data.help.help_text }}></div> : <Loading />}</Modal.Body>
        </Modal>
    )
}

export default EFTCountries
