import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React from 'react'

import useCallApi from '../../../../hooks/useCallApi'
import Dollars from '../../../../templates/Dollars/Dollars'

const AffiliateStats = ({ affiliateId }) => {
    const { data, dataLoaded } = useCallApi(`affiliate/${affiliateId}/affiliateStats`, 'GET', {}, [affiliateId])
    const spinner = <FontAwesomeIcon icon="spinner" spin />

    return (
        <div className={`p-2 align-items-center d-flex justify-content-between`} style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
            <div className="mr-2">
                <span className="float-left">Today </span>
                <span className="ml-3 mr-0 mb-element active">
                    {dataLoaded ? (
                        <span className="name">
                            <Dollars bold value={data.today_earnings.grand_earnings} />{' '}
                        </span>
                    ) : (
                        spinner
                    )}
                </span>
            </div>
            |
            <div className="ml-2">
                <span className="float-left">MTD </span>
                <span className="ml-3 mr-0 mb-element highlight">
                    {dataLoaded ? (
                        <span className="name">
                            <Dollars bold value={data.month_to_date.grand_earnings} />{' '}
                        </span>
                    ) : (
                        spinner
                    )}
                </span>
            </div>
            |
            <div className="ml-2">
                <span className="float-left">YTD </span>
                <span className="ml-3 mr-0 mb-element highlight">
                    {dataLoaded ? (
                        <span className="name">
                            <Dollars bold value={data.year_to_date.grand_earnings} />{' '}
                        </span>
                    ) : (
                        spinner
                    )}
                </span>
            </div>
            |
            <div className="ml-2">
                <span className="float-left">Best Month </span>
                <span className="ml-3 mr-0 mb-element highlight">
                    {dataLoaded ? (
                        <span className="name">
                            <Dollars bold value={data.best_earnings.earnings} />{' '}
                        </span>
                    ) : (
                        spinner
                    )}
                </span>
                <span className="text-secondary ml-1">({dataLoaded ? data.best_earnings.best_month ? moment(data.best_earnings.best_month).utc().format('MMM YYYY') : 'N/A' : 'N/A'})</span>
            </div>
        </div>
    )
}

export default AffiliateStats

// `$${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(bestEarnings.earnings)}`
