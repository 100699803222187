import React, { useEffect, useState } from 'react'

import callAPI from '../../../helpers/callAPI'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import SitesModal from './SitesModal/SitesModal'

const Sites = ({ showSystemMessage }) => {
    const [sites, setSites] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [showSiteModal, setShowSiteModal] = useState(false)
    const [siteId, setSiteId] = useState(0)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        callAPI(`callrouted/sites`, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setSites(result.sites)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching callrouted sites: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching callrouted sites')
            })
    }

    const handleClick = (websiteId) => {
        setSiteId(websiteId)
        setShowSiteModal(true)
    }

    const handleClose = (isEdited = false) => {
        setShowSiteModal(false)
        setSiteId(0)

        if (isEdited) {
            fetchData()
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'website_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Website',
                accessor: 'description',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Main Headline',
                accessor: 'headline1_text',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Ready to build',
                accessor: 'build_website',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'Y' ? 'Yes' : 'No'}</>
                },
            },
            {
                Header: '',
                accessor: 'sites',
                Cell: (tableData) => {
                    return (
                        <button
                            type="button"
                            className="btn btn-warning btn-sm"
                            onClick={() => {
                                handleClick(tableData.data[tableData.row.index].website_id)
                            }}
                        >
                            Edit
                        </button>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={sites} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            {showSiteModal && <SitesModal handleClose={handleClose} isOpen={showSiteModal} siteId={siteId} showSystemMessage={showSystemMessage} />}
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Callrouted Landing Pages</h1>
            </div>
            <div className="card">
                <div className="d-flex justify-content-end p-3 align-items-center">
                    <button
                        type="button"
                        className="btn btn-warning btn-sm"
                        onClick={() => {
                            setShowSiteModal(true)
                        }}
                    >
                        Create Landing Pages
                    </button>
                </div>
                {dataLoaded ? sites.length === 0 ? <div className="p-3">No callrouted landing pages available to show</div> : getReportOutput() : <Loading />}
            </div>
        </div>
    )
}

export default Sites
