import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import CampaignNavbar from '../CampaignNavBar/CampaignNavBar'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import moment from 'moment'
import Dollars from '../../../templates/Dollars/Dollars'

const ServerPostbackCalls = ({ campaignId, showSystemMessage }) => {
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    let apiUrl = `campaign/${campaignId}/serverPostbackCalls?startDate=${initStartDate}&endDate=${initEndDate}`

    const { dataLoaded, data, refetch } = useCallApi(apiUrl, 'GET', {}, [campaignId], showSystemMessage)
    const history = useHistory()
    const [campaign, setCampaign] = useState({})
    const [report, setReport] = useState([])

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        setCampaign(data.campaign)
        setReport(data.log_list)
    }, [data])

    const refreshReport = (e) => {
        e.preventDefault()
        refetch()
        let url = `/campaign/${campaignId}/serverPostbackCalls?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

        history.push(url)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'URL Parameters',
                accessor: 'call_string',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Lead Data',
                accessor: 'lead_data',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Action ID',
                accessor: 'action_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Rate Name',
                accessor: 'rate_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Sale Amount',
                accessor: 'sale_amount',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: 'Debug',
                accessor: 'debug_message',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Test Postbacks</h1>
                <div className="ml-5">
                    <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark mr-2">{dataLoaded ? <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : spinner}</h1>
                            </div>
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                        {/* <DownloadCSV filename={affiliateId ? `Lead Locations - ${affiliate.name} (${affiliateId}) - ${initStartDate}-${initEndDate}` : `Lead Locations - ${campaign.name} (${campaignId}) - ${initStartDate}-${initEndDate}`} headerLabels={CSVHeaders} data={report} dataLabels={CSVDataLabels} /> */}
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default ServerPostbackCalls
