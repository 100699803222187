import React, { useState } from 'react'
import moment from 'moment'

import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import AddToWatchlistModal from './AddToWatchlistModal/AddToWatchlistModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const AffiliateWatchlist = ({ showSystemMessage }) => {

    document.title = 'Affiliate Watchlist'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'compliance_watchlist')

    const { dataLoaded, data, refetch } = useCallApi(`compliance/watchlist`, 'GET', {}, [])

    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedAffiliate, setSelectedAffiliate] = useState({})

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} priority={tableData.data[tableData.row.index].priority} />
                },
            },
            {
                Header: 'Notes',
                accessor: 'reason_for_monitoring',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: '',
                accessor: 'watchlist',
                Cell: (tableData) => {
                    return (
                        <button
                            type="button"
                            className="btn btn-warning btn-sm"
                            onClick={() => {
                                handleClick(tableData.data[tableData.row.index])
                            }}
                        >
                            Edit
                        </button>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.watchlist} columns={columns} />
            </React.Fragment>
        )
    }

    const handleClick = (affiliate) => {
        setSelectedAffiliate(affiliate)
        setShowAddModal(true)
    }

    const handleClose = (isEdited = false) => {
        setShowAddModal(false)
        setSelectedAffiliate({})

        if (isEdited) {
            refetch()
        }
    }

    return (
        <div>
            {showAddModal && <AddToWatchlistModal isOpen={showAddModal} handleClose={handleClose} showSystemMessage={showSystemMessage} affiliate={selectedAffiliate} />}
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Watchlist</h1>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="d-flex justify-content-between p-3 align-items-center">
                            <h6 className="mr-2">** Affiliates in this list will have their full User Agent recorded.</h6>
                            <button
                                type="button"
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    setShowAddModal(true)
                                }}
                            >
                                Add to Watchlist
                            </button>
                        </div>
                        {dataLoaded ? data.watchlist.length === 0 ? <div className="p-3">No affiliates to show in watchlist</div> : getReportOutput() : <Loading />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AffiliateWatchlist
