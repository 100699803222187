import React, { useState } from 'react'
import Loading from '../../../../templates/UI/Loading/Loading'

import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import JAmSummary from '../JAmSummary/JAmSummary'
import Applications from '../Applications/Applications'
import FollowupQueue from '../AdvertiserFollowupQueue/AdvertiserFollowupQueue'
import CampaignRequests from '../CampaignRequests/CampaignRequests'
import OpenIssues from '../OpenIssues/OpenIssues'
import TopAffiliates from '../TopAffiliates/TopAffiliates'
import RecentlyReceivedPayments from '../RecentlyReceivedPayments/RecentlyReceivedPayments'
import NewlyRunCampaigns from '../NewlyRunCampaigns/NewlyRunCampaigns'
import YesterdaysCapHits from '../YesterdaysCapHits/YesterdaysCapHits'
import AffiliateFollowupQueue from '../AffiliateFollowupQueue/AffiliateFollowupQueue'

export default function JuniorAffiliateManager({ manager, setManager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'jr_am_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Junior Affiliate Manager</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <JAmSummary manager={manager} setManager={setManager} showSetManager={true} />
                            <Applications manager={manager} showSystemMessage={showSystemMessage} mode="affiliate_manager" />
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-6">
                                    <AffiliateFollowupQueue manager={manager} limit={10} />
                                    <CampaignRequests manager={manager} showSystemMessage={showSystemMessage} />
                                    <YesterdaysCapHits manager={manager} showSystemMessage={showSystemMessage} />
                                    <OpenIssues manager={manager} showSystemMessage={showSystemMessage} />
                                </div>
                                <div className="col-lg-6">
                                    <TopAffiliates manager={manager} />
                                    <RecentlyReceivedPayments manager={manager} />
                                    <NewlyRunCampaigns />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
