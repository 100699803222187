import React, { useState } from 'react'
import moment from 'moment'

const SotuAffEarnings = (props) => {
    const [open, setOpen] = useState(false)

    const getClass = (delta) => {
        return Math.sign(delta) !== -1 ? 'mb-text-sm text-success' : 'mb-text-sm text-danger'
    }

    const { quarter, aff_GTEQ1000_count, aff_GTEQ500_count, aff_GTEQ100_count, aff_GT0_count, aff_EQ0_count, detailed } = props.quarter

    const nextIndex = props.index + 1

    let aff_GTEQ1000_delta = 0
    let aff_GTEQ500_delta = 0
    let aff_GTEQ100_delta = 0
    let aff_GT0_delta = 0
    let aff_EQ0_delta = 0

    if (props.report[nextIndex]) {
        aff_GTEQ1000_delta = aff_GTEQ1000_count - props.report[nextIndex].aff_GTEQ1000_count
        aff_GTEQ500_delta = aff_GTEQ500_count - props.report[nextIndex].aff_GTEQ500_count
        aff_GTEQ100_delta = aff_GTEQ100_count - props.report[nextIndex].aff_GTEQ100_count
        aff_GT0_delta = aff_GT0_count - props.report[nextIndex].aff_GT0_count
        aff_EQ0_delta = aff_EQ0_count - props.report[nextIndex].aff_EQ0_count
    }

    return (
        <React.Fragment>
            <tr>
                <td className="font-weight-bold">{quarter}</td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_GTEQ1000_count)}
                    <div className={getClass(aff_GTEQ1000_delta)}>
                        ({Math.sign(aff_GTEQ1000_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_GTEQ1000_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_GTEQ500_count)}
                    <div className={getClass(aff_GTEQ500_delta)}>
                        ({Math.sign(aff_GTEQ500_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_GTEQ500_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_GTEQ100_count)}
                    <div className={getClass(aff_GTEQ100_delta)}>
                        ({Math.sign(aff_GTEQ100_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_GTEQ100_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_GT0_count)}
                    <div className={getClass(aff_GT0_delta)}>
                        ({Math.sign(aff_GT0_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_GT0_delta)})
                    </div>
                </td>
                <td className="text-right">
                    {Intl.NumberFormat('en-GB').format(aff_EQ0_count)}
                    <div className={getClass(aff_EQ0_delta)}>
                        ({Math.sign(aff_EQ0_delta) !== -1 && '+'}
                        {Intl.NumberFormat('en-GB').format(aff_EQ0_delta)})
                    </div>
                </td>
                <td>
                    <span className="badge badge-primary" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                        {open ? '-' : '+'}
                    </span>
                </td>
            </tr>
            {open &&
                detailed.map((record, subIndex) => {
                    let subDelta1 = 0
                    let subDelta2 = 0
                    let subDelta3 = 0
                    let subDelta4 = 0
                    let subDelta5 = 0
                    const subNextIndex = subIndex + 1

                    if (detailed[subNextIndex]) {
                        subDelta1 = record.aff_GTEQ1000_count - detailed[subNextIndex].aff_GTEQ1000_count
                        subDelta2 = record.aff_GTEQ500_count - detailed[subNextIndex].aff_GTEQ500_count
                        subDelta3 = record.aff_GTEQ100_count - detailed[subNextIndex].aff_GTEQ100_count
                        subDelta4 = record.aff_GT0_count - detailed[subNextIndex].aff_GT0_count
                        subDelta5 = record.aff_EQ0_count - detailed[subNextIndex].aff_EQ0_count
                    } else {
                        // If there is none, let's jump to the first of the LAST QUARTER
                        if (props.report[nextIndex]) {
                            if (props.report[nextIndex].detailed[0]) {
                                subDelta1 = record.aff_GTEQ1000_count - props.report[nextIndex].detailed[0].aff_GTEQ1000_count
                                subDelta2 = record.aff_GTEQ500_count - props.report[nextIndex].detailed[0].aff_GTEQ500_count
                                subDelta3 = record.aff_GTEQ100_count - props.report[nextIndex].detailed[0].aff_GTEQ100_count
                                subDelta4 = record.aff_GT0_count - props.report[nextIndex].detailed[0].aff_GT0_count
                                subDelta5 = record.aff_EQ0_count - props.report[nextIndex].detailed[0].aff_EQ0_count
                            }
                        }
                    }

                    return (
                        <tr className="text-secondary">
                            <td>
                                <div className="pl-3">{moment(record.date).utc().format('YYYY-MM')}</div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_GTEQ1000_count)}
                                <div className={getClass(subDelta1)}>
                                    ({Math.sign(subDelta1) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta1)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_GTEQ500_count)}
                                <div className={getClass(subDelta2)}>
                                    ({Math.sign(subDelta2) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta2)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_GTEQ100_count)}
                                <div className={getClass(subDelta3)}>
                                    ({Math.sign(subDelta3) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta3)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_GT0_count)}
                                <div className={getClass(subDelta4)}>
                                    ({Math.sign(subDelta4) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta4)})
                                </div>
                            </td>
                            <td className="text-right">
                                {Intl.NumberFormat('en-GB').format(record.aff_EQ0_count)}
                                <div className={getClass(subDelta5)}>
                                    ({Math.sign(subDelta5) !== -1 && '+'}
                                    {Intl.NumberFormat('en-GB').format(subDelta5)})
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    )
                })}
        </React.Fragment>
    )
}

export default SotuAffEarnings
