import React, { Component } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeBranch, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { MoreVertical } from 'react-feather'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import questionTypes from '../Survey/data/avatarQuestionTypes'
import './QuestionSelector.css'

import LogicModal from '../Modals/LogicModal'
import ParamModal from '../Modals/ParamModal'

class QuestionSelector extends Component {
    state = {
        hovered: false,
        dropdownOpen: false,
        logicModalOpen: false,
        paramModalOpen: false,
    }

    componentDidUpdate(prevProps) {
        // If we were dragging, and no we done dragging, set hovered to false for all of these.
        if (prevProps.dragging && this.props.dragging !== prevProps.dragging) {
            this.setState({
                ...this.state,
                hovered: false,
            })
        }
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        })
    }

    handleDragEnter = () => {
        this.setState({
            ...this.state,
            hovered: true,
        })
    }

    handleDragExit = () => {
        this.setState({
            ...this.state,
            hovered: false,
        })
    }

    getClasses = () => {
        let classes = 'mb-question-selector rounded'

        if (this.state.hovered && this.props.draggedQuestionOrderNumber > this.props.order) {
            classes = `${classes} mb-question-selector-hovered above`
        }

        if (this.state.hovered && this.props.draggedQuestionOrderNumber < this.props.order) {
            classes = `${classes} mb-question-selector-hovered below`
        }

        if (this.props.selectedQuestionOrderNumber === this.props.order) {
            classes = `${classes} mb-question-selector-selected`
        }

        if (this.props.conditions.length > 0) {
            classes = `${classes} text-primary`
        }

        return classes
    }

    toggleLogicModal = () => {
        this.setState({
            ...this.state,
            logicModalOpen: !this.state.logicModalOpen,
        })
    }

    toggleParamModal = () => {
        this.setState({
            ...this.state,
            paramModalOpen: !this.state.paramModalOpen,
        })
    }

    getDeleteButton = () => {
        // Can only show the delete button if there are no questions with conditions based on it

        let questionsBasedOnThis = this.props.survey.filter((question) => {
            let merged = [].concat.apply([], question.conditions)
            if (merged.find((condition) => condition.questionId === this.props.questionId)) {
                return true
            } else {
                return false
            }
        })

        let actionsBasedOnThis = this.props.events.filter((action) => {
            let merged = [].concat.apply([], action.conditions)
            if (merged.find((condition) => condition.questionId === this.props.questionId)) {
                return true
            } else {
                return false
            }
        })

        if (questionsBasedOnThis.length === 0 && actionsBasedOnThis.length === 0) {
            return (
                <button className="dropdown-item text-danger" onClick={() => this.props.handleRemoveQuestion(this.props.questionId)}>
                    Delete
                </button>
            )
        } else {
            return (
                <button className="dropdown-item text-secondary" disabled>
                    Delete
                </button>
            )
        }
    }

    render() {
        return (
            <div className="mb-2 d-flex align-items-center">
                <div className={this.getClasses()} draggable={true} id={this.props.questionId} order={this.props.order} onDragOver={(ev) => ev.preventDefault()} onDragStart={this.props.handleDrag} onDrop={this.props.handleDrop} onDragEnter={this.handleDragEnter} onDragExit={this.handleDragExit} onClick={() => this.props.handleSelectQuestion(this.props.order)}>
                    <div className="d-flex align-items-center">
                        {/* The icon on the left */}
                        <div className="border border-dark rounded p-2" style={{ maxWidth: '70px', width: '70px', minWidth: '70px', backgroundColor: '#fafeff' }}>
                            <div className="text-small font-weight-bold">
                                {this.props.type} {this.props.conditions.length > 0 && <FontAwesomeIcon icon={faCodeBranch} />}
                            </div>
                            <div style={{ borderTop: '3px solid RGBA(0,0,0,0.25)', width: '100%' }}></div>
                            <div className="mt-1" style={{ borderTop: '3px solid RGBA(0,0,0,0.25)', width: '50%' }}></div>
                        </div>
                        {/* The Question description */}
                        <div className="pl-2 text-small d-flex">
                            <div className="font-weight-bold">{this.props.order}. </div>
                            <LinesEllipsis text={this.props.question} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
                        </div>
                    </div>
                    {/* The condition badges */}
                    {this.props.conditions.length > 0 && (
                        <div className="text-small">
                            {this.props.conditions.map((condition, index) => {
                                return (
                                    <span key={index}>
                                        {condition.map((singleCondition, index2) => {
                                            const conditionQuestion = this.props.survey.find((question) => question.questionId === singleCondition.questionId)
                                            const conditionQuestionOrder = conditionQuestion.order
                                            return (
                                                <span key={index2}>
                                                    <div className="badge badge-primary">{conditionQuestionOrder}</div>
                                                    {condition.length - 1 !== index2 && <span>+</span>}
                                                </span>
                                            )
                                        })}
                                        {this.props.conditions.length - 1 !== index && <span> or </span>}
                                    </span>
                                )
                            })}
                        </div>
                    )}
                    {this.props.parameters.length > 0 && (
                        <div className="text-small">
                            {this.props.parameters.map((parameter) => {
                                return <span className="badge badge-secondary mr-1">{parameter.name}</span>
                            })}
                        </div>
                    )}
                </div>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} direction="down">
                    <DropdownToggle tag="a" onClick={this.toggle} data-toggle="dropdown" aria-expanded={this.state.dropdownOpen} className="report-more-toggle text-secondary">
                        <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.props.order > 1 && (
                            <React.Fragment>
                                <button className="dropdown-item" onClick={this.toggleLogicModal}>
                                    <FontAwesomeIcon icon={faCodeBranch} /> Condition Logic
                                </button>
                                <DropdownItem divider className="m-0" />
                            </React.Fragment>
                        )}
                        {questionTypes.find((questionType) => questionType.type === this.props.type).paramaterable && (
                            <React.Fragment>
                                <button className="dropdown-item" onClick={this.toggleParamModal}>
                                    <FontAwesomeIcon icon={faDatabase} /> Customer Data
                                </button>
                                <DropdownItem divider className="m-0" />
                            </React.Fragment>
                        )}
                        {this.getDeleteButton()}
                    </DropdownMenu>
                </Dropdown>
                <LogicModal logicModalOpen={this.state.logicModalOpen} toggleLogicModal={this.toggleLogicModal} questionId={this.props.questionId} survey={this.props.survey} order={this.props.order} conditions={this.props.conditions} handleAddLogic={this.props.handleAddLogic} handleAddInnerLogic={this.props.handleAddInnerLogic} handleChangeLogicQuestion={this.props.handleChangeLogicQuestion} handleRemoveLogic={this.props.handleRemoveLogic} handleChangeLogicAnswer={this.props.handleChangeLogicAnswer} showSystemMessage={this.props.showSystemMessage} />
                <ParamModal paramModalOpen={this.state.paramModalOpen} toggleParamModal={this.toggleParamModal} questionId={this.props.questionId} survey={this.props.survey} parameters={this.props.parameters} handleAddParam={this.props.handleAddParam} handleChangeParam={this.props.handleChangeParam} handleRemoveParam={this.props.handleRemoveParam} showSystemMessage={this.props.showSystemMessage} />
            </div>
        )
    }
}

export default QuestionSelector
