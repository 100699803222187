import React, { useState, useEffect } from 'react'
import useCallApi from '../../hooks/useCallApi'

export default function RedirectSetSelect({ setName, setRedirectSetName }) {
    let apiUrl = `campaigns/redirectSets`

    const { dataLoaded, data } = useCallApi(apiUrl, 'GET', {}, [])

    return (
        <select
            name="campaign_approval_questions"
            className="form-control form-control-sm"
            value={setName}
            onChange={(e) => {
                setRedirectSetName(e.target.value)
            }}
        >
            <option value="NONE SET">NONE SET</option>
            {dataLoaded &&
                data.redirect_sets &&
                data.redirect_sets.map((set) => {
                    return (
                        <option value={set.set_name} key={set.set_name}>
                            {set.set_name}
                        </option>
                    )
                })}
        </select>
    )
}
