import React, { useState, useEffect } from 'react'
import { Modal } from 'reactstrap'
import callAPI from '../../../helpers/callAPI'

export default function AddPageModal({ addPageModal, setAddPageModal, data, setData, refetch, showSystemMessage }) {
    const [pageName, setPageName] = useState('')

    const addPage = async (pageName) => {
        if (pageName == '') {
            showSystemMessage('error', 'You must enter a page name.')
        } else {
            if (pageName.length === 0) {
                showSystemMessage('error', 'You must enter a page name.')
            }

            await callAPI(`authorization/page/${pageName}`, 'POST')
                .then((result) => {
                    if (result['success'] === true) {
                        setData({ ...data, portal: result.portal })
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', error.message)
                })
            setAddPageModal(false)
            // Set the page to be added back to zero
            setPageName('')
        }
    }

    return (
        <Modal isOpen={addPageModal} toggle={() => setAddPageModal(!addPageModal)} className="modal-sm">
            <div className="card">
                <div className="card-header">Add Permission</div>
                <div className="card-body">
                    Permission name:
                    <input type="text" className="form-control" onChange={(event) => setPageName(event.target.value)}></input>
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => {
                            addPage(pageName)
                        }}
                    >
                        Add Permission
                    </button>
                </div>
            </div>
        </Modal>
    )
}
