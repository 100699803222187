import React, { useContext } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import AdvertiserReversalRequestContent from '../../../components/AdvertiserReversalRequestContent/AdvertiserReversalRequestContent'
import AdvertiserNavbar from '../AdvertiserNavBar/AdvertiserNavBar'
import { SystemMessageContext } from '../../../context/SystemMessageContext'

export default function ReversalRequest({advertiserId}){
    let location = useLocation()
    const { showSystemMessage } = useContext(SystemMessageContext)

    let urlParams = queryString.parse(location.search)
    let reversalRequestId = urlParams.id ? urlParams.id : 0;

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reversal Request</h1>
                <div className="ml-5">
                    <AdvertiserNavbar advertiserId={advertiserId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <AdvertiserReversalRequestContent advertiserId={advertiserId} reversalRequestId={reversalRequestId} />
                </div>
            </div>
        </div>
    )
}
