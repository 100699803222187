import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import getAffiliateStatus from '../../../helpers/getAffiliateStatus'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'

const ContactCheck = ({ data, dataLoaded }) => {
    return (
        <Card className="mb-4">
            <Card.Header>
                <div>Contact Check</div>
            </Card.Header>
            <div className="m-2 font-weight-bold">Other affiliates with the same contact:</div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Affiliate</th>
                        <th>Status</th>
                        <th>Contact</th>
                    </tr>
                </thead>
                {data.success && data.info.matchingContacts.length > 0 ? (
                    <tbody>
                        {data.info.matchingContacts.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.affiliate_id}</td>
                                    <td>
                                        <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                    </td>
                                    <td>{item.aff_status}</td>
                                    <td>{item.contact}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td colSpan="4">No data found</td>
                        </tr>
                    </tbody>
                )}
            </Table>

            <div className="m-2 font-weight-bold">Other affiliates with the same company name:</div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Affiliate</th>
                        <th>Status</th>
                        <th>Contact</th>
                    </tr>
                </thead>
                {data.success && data.info.matchingCompanies.length > 0 ? (
                    <tbody>
                        {data.info.matchingCompanies.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.affiliate_id}</td>
                                    <td>
                                        <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                    </td>
                                    <td>{item.aff_status}</td>
                                    <td>{item.contact}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td colSpan="4">No data found</td>
                        </tr>
                    </tbody>
                )}
            </Table>
        </Card>
    )
}

export default ContactCheck
