const DeviceOsAllowed = ({ osFiltering, trafficTypes }) => {
    let os = ``

    if (osFiltering.enabled) {
        if (osFiltering.operating_systems.length > 0) {
            let osArray = osFiltering.operating_systems.map((os) => os.device_os)
            os = osArray.join(", ")
        }
    } else {
        if (trafficTypes.mobile_flag && !trafficTypes.desktop_flag) {
            os = 'All mobile operating systems allowed'
        } else if (trafficTypes.desktop_flag && !trafficTypes.mobile_flag) {
            os = 'All desktop operating systems allowed'
        } else {
            os = 'All device operating systems allowed'
        }
    }

    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Device OS's allowed</div>
            </div>
            <div className="card-body p-2">{os}</div>
        </div>
    )
}

export default DeviceOsAllowed
