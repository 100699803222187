import { Modal } from 'reactstrap'
import React, { useState, useEffect } from 'react'
import useCallApi from '../../../hooks/useCallApi'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

export default function CommissionReportDetailsModal({ open, handleClose, password, modalData, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'commission_report')

    const { data, dataLoaded } = useCallApi(`reports/commission/details?adminId=${modalData.adminId}&startDate=${modalData.startDate}&endDate=${modalData.endDate}`, 'POST', { adminId: modalData.adminId, password: password }, [], showSystemMessage)

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Commission</div>,
                accessor: 'commission',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.report} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <Modal isOpen={open} size="lg" toggle={handleClose}>
            <div className="card">
                <div className="card-header">
                    {modalData.name} for period {modalData.startDate} - {modalData.endDate}
                </div>
                <div>{dataLoaded ? getReportOutput() : <Loading />}</div>
            </div>
        </Modal>
    )
}
