import { useState } from "react"
import { Modal } from "react-bootstrap"
import AdminRolePicker from "../../../../components/AdminRolePicker/AdminRolePicker"
import callAPI from "../../../../helpers/callAPI"
import ManagerDropdown from "../../../../templates/ManagerDropdown/ManagerDropdown"
import CustomButton from "../../../../templates/UI/CustomButton/CustomButton"


const OutOfOfficeModal = ({ showSystemMessage, isOpen, handleClose, admin }) => {

    const [submitting, setSubmitting] = useState(false)

    const inputChangeHandler = () => {

    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setSubmitting(true)

        let body = {
            out_of_office: e.target.out_of_office.value
        }

        callAPI(`admin/outofoffice/${admin.admin_id}`, 'PUT', body)
            .then(result => {
                if (result["success"] === true) {
                    showSystemMessage('success', result.message)
                    handleClose(true)
                } else if (result["success"] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                } else {
                    showSystemMessage('error', 'An error occurred while updating out of office status.')
                    setSubmitting(false)
                }
            }).catch((error) => {
                console.log(`Error while updating out of office status: ${error}`)
                showSystemMessage('error', 'An error occurred while updating out of office status.')
                setSubmitting(false)
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Out Of Office</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {admin} */}
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td>Admin ID:</td>
                                <td>{admin.admin_id}</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Status:</td>
                                <td><select className="form-control w-50" defaultValue={admin.status} onChange={inputChangeHandler} required disabled>
                                    <option value="A">Active</option>
                                    <option value="C">Cancelled</option>
                                </select></td>
                            </tr>
                            <tr>
                                <td>Admin Type:</td>
                                <td>
                                    <AdminRolePicker role={admin.role_id} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Full Name:</td>
                                <td><input type="text" className="form-control" value={admin.full_name} disabled /></td>
                            </tr>
                            <tr>
                                <td>Out Of Office?:</td>
                                <td>
                                    <select name="out_of_office" className="form-control w-25" defaultValue={admin.out_of_office}>
                                        <option value="N">No</option>
                                        <option value="Y">Yes</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Manager:</td>
                                <td><ManagerDropdown mgrId={admin.manager_id} disabled /></td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton name="Save" type="submit" spin={submitting} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default OutOfOfficeModal