import React, { useState, useEffect } from 'react';
import callAPI from '../../helpers/callAPI';

export default function EncryptionTool({showSystemMessage}){

    const [decryptedText, setDecryptedText] = useState('')
    const [encryptedText, setEncryptedText] = useState('')
    const [db, setDb] = useState(false)

    const process = async (method) => {
        let result = await callAPI(`encryptionTool/${method}`, 'POST', {sqlFormat: db, data: method === 'encrypt' ? decryptedText : method === 'decrypt' ? encryptedText : ''})
        if (result.success){
            if (method === 'encrypt'){
                setEncryptedText(result.string)
            } else if (method === 'decrypt'){
                setDecryptedText(result.string)
            }
            
        } else {
            showSystemMessage('error', result.errors.message)
        }
        
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Encrypt/Decrypt Data</h1>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div style={{width: '40%'}}>
                                    <div className="text-center mb-2">Plain Text</div>
                                    <textarea className="form-control w-100" rows={20} onChange={(e)=>{setDecryptedText(e.target.value)}} value={decryptedText}></textarea>
                                </div>
                                <div className="mx-2 d-flex align-items-center">
                                    <div className="text-center">
                                        <button className="btn btn-primary" onClick={()=>{ process('encrypt') }}>Encrypt -&gt;</button> <br></br>
                                        <button className="btn btn-primary mt-2" onClick={()=>{ process('decrypt') }}>&lt;- Decrypt</button>
                                        <div className="alert-info mt-2">
                                            <label className="d-flex align-items-center alert alert-secondary">
                                                <input type="checkbox" onClick={(e) => setDb(e.target.checked)} />
                                                <span className="ml-1">SQL Format</span>
                                            </label>                                            
                                        </div>
                                    </div>
                                </div>
                                <div  style={{width: '40%'}}>
                                    <div className="text-center mb-2">Encrypted Text</div>
                                    <textarea className="form-control" rows={20} onChange={(e)=>{setEncryptedText(e.target.value)}} value={encryptedText}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </React.Fragment>
    )
}