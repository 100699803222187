import { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import CampaignNavbar from '../CampaignNavBar/CampaignNavBar'
import BatchUploadBanners from './BatchUploadBanners'
import CreativesModal from './CreativesModal'
import PreviewCreative from './PreviewCreative'
import getCreativeName from '../helpers/getCreativeName'
import getCreativePreview from '../helpers/getCreativePreview'
import downloadCreativeImage from '../helpers/downloadCreativeImage'

const Creatives = ({ campaignId, showSystemMessage }) => {

    const auth = useAuth()
    const showEdit = useProtectedContent(auth.permissions, 'campaign_creatives')

    const { data, dataLoaded, setDataLoaded, setData, refetch } = useCallApi(`campaign/${campaignId}/creatives`, 'GET', {}, [campaignId], showSystemMessage)
    const [name, setName] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [batchIsOpen, setBatchIsOpen] = useState(false)
    const [selectedCreative, setSelectedCreative] = useState(false)
    const [isCreate, setIsCreate] = useState(true)
    const [state, setState] = useState('')
    const [reloadPage, setReloadPage] = useState(false)
    const [list, setList] = useState([])

    if (dataLoaded) {
        document.title = `${data.campaign ? data.campaign.name : ''}: Creatives`
    } else {
        document.title = 'Creatives'
    }

    useEffect(() => {
        const func = async () => {
            await data.creatives.map(async (creative, i) => {
                const preview = await getCreativePreview(creative.creative_type, creative.creative_id, campaignId, data.advertiser.id)
                let tempData = data
                tempData.creatives[i].preview = preview
                if (creative.creative_type !== 'B') {
                    tempData.creatives[i].html_text = preview
                }
                setData(tempData)
                setState(i)
            })
        }

        if (dataLoaded) {
            func()
        }
    }, [data, reloadPage])


    const handleCheckbox = (e) => {
        // e.preventDefault()

        let creativeId = parseInt(e.target.id)

        let newArray = [...list]

        if (newArray.includes(creativeId)) {
            newArray.splice(list.indexOf(creativeId), 1)
        } else {
            newArray.push(creativeId)
        }

        setList(newArray)
    }

    const handleBatchDeactivation = (e) => {
        e.preventDefault()

        callAPI(`campaign/${campaignId}/deactivateBatchCreatives`, 'PUT', { list: list }).then(result => {
            refetch()
            setList([])
            showSystemMessage('success', result.message)
        }).catch(error => {
            showSystemMessage('error', 'Error while batch deactivation.')
        })
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Creatives</h1>
                <div className="ml-5">
                    <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="card">
                {data.success && <BatchUploadBanners data={data} refetch={refetch} showSystemMessage={showSystemMessage} isOpen={batchIsOpen} setIsOpen={setBatchIsOpen} campaignId={campaignId} />}
                {(data.success && isOpen) && <CreativesModal data={data} refetch={refetch} dataLoaded={dataLoaded} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} name={name} isCreate={isCreate} setIsCreate={setIsCreate} creative={selectedCreative} campaignId={campaignId} reloadPage={reloadPage} setReloadPage={setReloadPage} />}
                <div className="d-flex justify-content-between p-3 align-items-center">
                    <h1 className="mb-h1-dark mr-2">Creatives for {dataLoaded && data.success ? data.campaign.name : ''}</h1>
                    {
                        showEdit &&
                        <div className="text-right">
                            <Button
                                variant="warning"
                                className="mr-2"
                                onClick={() => {
                                    setSelectedCreative(false)
                                    setIsCreate(true)
                                    setName(data.campaign.name)
                                    setIsOpen(true)
                                }}
                            >
                                Add Creative
                            </Button>
                            <Button
                                variant="warning"
                                className='mr-2'
                                onClick={() => {
                                    setBatchIsOpen(true)
                                }}
                            >
                                Batch Upload Banners
                            </Button>
                            <Button
                                variant="danger"
                                onClick={handleBatchDeactivation}
                            >
                                Cancel Selected
                            </Button>
                        </div>
                    }
                </div>
                {dataLoaded && data.success ? (
                    <Table striped bordered hover className='m-0'>
                        <tbody>
                            {data.creatives.length > 0 ?
                                data.creatives.map((creative, i) => {
                                    return (
                                        <tr key={creative.creative_id}>
                                            <td width="20%">{getCreativeName(creative.creative_type, creative.width, creative.height, creative.creative_id)}</td>
                                            <td className='d-flex'>

                                                <div className="flex-fill"><PreviewCreative campaignId={campaignId} advertiserId={data.advertiser.id} url={creative.preview} rawUrl={creative.previewRaw} type={creative.creative_type} showSystemMessage={showSystemMessage} /></div>
                                                {
                                                    creative.creative_type === 'B' &&
                                                    <button className='btn btn-link btn-sm' onClick={() => downloadCreativeImage(creative.creative_type, creative.width, creative.height, creative.creative_id, creative.preview)}>
                                                        Download
                                                    </button>
                                                }
                                            </td>
                                            <td>{creative.status_name}</td>
                                            <td>
                                                {showEdit &&
                                                    <Button
                                                        size="sm"
                                                        variant="warning"
                                                        className="mr-2"
                                                        onClick={() => {
                                                            setSelectedCreative(creative)
                                                            setIsCreate(false)
                                                            setName(getCreativeName(creative.creative_type, creative.width, creative.height, creative.creative_id))
                                                            setIsOpen(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                }
                                            </td>
                                            <td>
                                                <input type="checkbox"
                                                    id={creative.creative_id}
                                                    checked={list.includes(creative.creative_id)}
                                                    onChange={handleCheckbox} />
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="4">No creatives found.</td>
                                </tr>
                            }


                        </tbody>
                    </Table>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default Creatives
