import { Card, Table } from 'react-bootstrap'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'

const DomainAffiliates = ({ affiliateData, affiliatesLoaded, lastAction }) => {
    return lastAction === 'P' ? (
        <Card style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
            {affiliatesLoaded ? (
                <Card.Body className="p-0">
                    <Table responsive hover striped className='m-0'>
                        <thead>
                            <tr>
                                <th>Affiliate</th>
                                <th>Priority</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Click Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {affiliateData.affiliates.length === 0 ? <tr><td colSpan={5}>No affiliates found for the domain.</td></tr> : affiliateData.affiliates.map((aff, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Affiliate aid={aff.affiliate_id} name={aff.name} priority={aff.aff_priority} status={aff.aff_status_value} />
                                        </td>
                                        <td>{aff.aff_priority}</td>
                                        <td>{aff.e_mail}</td>
                                        <td>{aff.contact}</td>
                                        <td>{aff.aff_click_count.toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            ) : (
                <Card.Body>No domain selected.</Card.Body>
            )}
        </Card>
    ) : (
        <Card style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
            {affiliatesLoaded ? (
                <Card.Body>
                    {affiliateData.success === true ? (
                        <div>
                            A mass email has been sent out to <b>#pubs_sending_traffic#</b> affiliates who sent traffic today to <b>#form_domain_url#</b> instructing them to go to the affiliate portal and to pull new links. The old links will still work but it is recommended they switch them ASAP.
                            <br />
                            <br />
                            <b>Admin Notes (done in DB and IIS):</b>
                            <br />
                            <br />
                            1. Update mb_domains to set status to B for the domain <b>#form_domain_url#</b>
                            <br />
                            <br />
                            2. Un-park an unused domain in IIS and bind it to the appropriate website (afflat3 or mb01_old_domains)
                            <br />
                            <br />
                            3. Update offers.link_domain to no longer use the domain <b>#form_domain_url#</b>
                            <br />
                            <br />
                        </div>
                    ) : (
                        <>
                            The password you supplied <b>#form_confirmation_code#</b> does not match your OTT password.
                        </>
                    )}
                </Card.Body>
            ) : (
                <Loading />
            )}
        </Card>
    )
}

export default DomainAffiliates
