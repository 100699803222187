import { useState } from 'react'
import { Card, Form, Table, Button } from 'react-bootstrap'

import callAPI from '../../../../../helpers/callAPI'

const Notes = ({ showSystemMessage, campaignId, data, setData, setIsDisabled }) => {
    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <div>Notes</div>
                </div>
            </Card.Header>
            <Table striped bordered>
                <tbody>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Notes For Advertiser Team</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                value={data.notes.advertiser_team}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, notes: { ...data.notes, advertiser_team: e.target.value } })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Notes For Everyone</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                as="textarea"
                                value={data.notes.general}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, notes: { ...data.notes, general: e.target.value } })
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}

export default Notes
