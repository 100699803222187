import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import { useAuth } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import Campaign from '../../../templates/Campaign/Campaign'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

const MaxMoneyModal = ({ showSystemMessage, refetch, promoId, setPromoId, isCreate }) => {
    const { setSelectedStartDate, setQuickDate, setSelectedEndDate, initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { setSelectedStartDate: bestOfsetSelectedStartDate, setQuickDate: bestOfsetQuickDate, setSelectedEndDate: bestOfsetSelectedEndDate, initStartDate: bestOfinitStartDate, initEndDate: bestOfinitEndDate, selectedStartDate: bestOfselectedStartDate, selectedEndDate: bestOfselectedEndDate, quickDate: bestOfquickDate, handleChangeStart: bestOfhandleChangeStart, handleChangeEnd: bestOfhandleChangeEnd, handleQuickDate: bestOfhandleQuickDate } = useDateRangeFilter()

    const [editable, setEditable] = useState(false)
    const { data, setData, dataLoaded } = useCallApi(`maxmoney/promo/${promoId === 0 ? 0 : promoId}`, 'GET', {}, [])
    const auth = useAuth()
    const [formData, setFormData] = useState({
        name: '',
        type: 'OFFER_SPECIFIC',
        earnerThreshold: 0,
        startDate: '',
        endDate: '',
        reward_pct: 0,
        reward_pct_bump: 0,
        network_rate_bump: 0,
        status: 'A',
        campaigns: [],
        fullName: '',
        rewards: [],
        periods: [],
    })

    const [campaign, setCampaign] = useState({})
    const [reward, setReward] = useState({
        reward_threshold: 0,
        reward_pct: 0,
    })
    const [period, setPeriod] = useState({
        start_date: '',
        end_date: '',
    })

    useEffect(() => {
        if (!isCreate && dataLoaded) {
            setFormData({
                name: data.promo.promo_name,
                type: data.promo.promo_type,
                earnerThreshold: data.promo.top_earnings_threshold,
                reward_pct: data.promo.reward_pct * 100,
                reward_pct_bump: data.promo.reward_pct_bump * 100,
                network_rate_bump: data.promo.network_rate_bump * 100,
                status: data.promo.status,
                campaigns: data.campaigns,
                fullName: data.promo.full_name,
                startDate: moment(data.promo.start_date),
                endDate: moment(data.promo.end_date),
                rewards: data.rewards,
                periods: data.periods,
            })
            setQuickDate('custom')
            setSelectedStartDate(moment(data.promo.start_date))
            setSelectedEndDate(moment(data.promo.end_date))
            //if date is before today, not editable
            if (moment(data.promo.start_date).diff(moment(), 'days') > 0) {
                setEditable(true)
            } else {
                setEditable(false)
            }
        }
        setQuickDate('custom')
    }, [data, dataLoaded])

    useEffect(() => {
        if (campaign.id) {
            let tempCampaigns = formData.campaigns
            console.log(tempCampaigns)
            tempCampaigns.push(campaign)
            setFormData({ ...formData, campaigns: tempCampaigns })
        }
    }, [campaign])

    const removeCampaign = (index) => {
        let tempCampaigns = formData.campaigns
        tempCampaigns.splice(index, 1)
        setFormData({ ...formData, campaigns: tempCampaigns })
    }

    useEffect(() => {
        setFormData({ ...formData, startDate: selectedStartDate })
    }, [selectedStartDate])

    useEffect(() => {
        setFormData({ ...formData, endDate: selectedEndDate })
    }, [selectedEndDate])

    const validate = () => {
        let isValidForm = true
        if (formData.name === '') {
            showSystemMessage('error', 'Promo name is required')
            isValidForm = false
            return isValidForm
        } else if (selectedStartDate === '') {
            showSystemMessage('error', 'Start date is required')
            isValidForm = false
            return isValidForm
        } else if (selectedEndDate === '') {
            showSystemMessage('error', 'End date is required')
            isValidForm = false
            return isValidForm
        }
        return isValidForm
    }
    const createPromo = async () => {
        if (validate()) {
            const result = await callAPI(`maxmoney/promo`, 'POST', { ...formData, adminId: auth.user.id, startDate: selectedStartDate, endDate: selectedEndDate })
            if (result.success) {
                showSystemMessage('success', 'Promotion created successfully')
                setPromoId(0)
                refetch()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const editPromo = async () => {
        if (validate()) {
            const result = await callAPI(`maxmoney/promo/${promoId}`, 'PUT', { ...formData, adminId: auth.user.id, startDate: selectedStartDate, endDate: selectedEndDate, reward_pct: formData.reward_pct / 100, reward_pct_bump: formData.reward_pct_bump / 100, network_rate_bump: formData.network_rate_bump / 100 })
            if (result.success) {
                showSystemMessage('success', 'Promotion updated successfully')
                setPromoId(0)
                refetch()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
        console.log(formData)
    }

    const displaySpecifiedOffer = () => {
        return (
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Promo Name: </span>
                    <div className="w-75">
                        <Form.Control type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Promo Type: </span>
                    <div className="w-75">
                        <Form.Control as="select" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })} disabled={!editable}>
                            <option value="OFFER_SPECIFIC">Specified Campaign</option>
                            <option value="NEVER_RUN">Campaign never run by affiliate</option>
                            <option value="BEAT_YOUR_BEST">Beat your best</option>
                        </Form.Control>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Show top earner if above: </span>
                    <div className="w-75">
                        <Form.Control type="number" value={formData.earnerThreshold} onChange={(e) => setFormData({ ...formData, earnerThreshold: e.target.value })} />
                    </div>
                </div>
                {editable ? (
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Campaigns: </span>
                        <div className="w-75">
                            <CampaignSearch setCampaign={setCampaign} />
                            {data.campaigns?.length > 0 &&
                                data.campaigns.map((campaign, index) => (
                                    <div key={index} className="d-flex justify-content-start align-items-center w-100 mb-2">
                                        <Campaign oid={campaign.offer_id} name={campaign.name} />
                                        <Button variant="danger" size="sm" onClick={() => removeCampaign(index)} className="ml-2">
                                            Remove
                                        </Button>
                                    </div>
                                ))}
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Campaigns: </span>
                        <div className="w-75">
                            {formData.campaigns &&
                                formData.campaigns.map((campaign, index) => {
                                    return (
                                        <div key={index} className="d-flex justify-content-start align-items-center w-100 mb-2">
                                            <Campaign oid={campaign.offer_id} name={campaign.name} />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                )}
                {editable ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Reward %: </span>
                            <div className="w-75">
                                <Form.Control type="number" defaultValue={formData.reward_pct } onChange={(e) => setFormData({ ...formData, reward_pct: e.target.value })} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Reward Bump %: </span>
                            <div className="w-75">
                                <Form.Control type="number" defaultValue={formData.reward_pct_bump } onChange={(e) => setFormData({ ...formData, reward_pct_bump: e.target.value })} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Network Rate Bump %: </span>
                            <div className="w-75">
                                <Form.Control type="number" defaultValue={formData.network_rate_bump } onChange={(e) => setFormData({ ...formData, network_rate_bump: e.target.value })} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Reward %: </span>
                            <div className="w-75">{formData.reward_pct }%</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Reward Bump %: </span>
                            <div className="w-75">{formData.reward_pct_bump }%</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Network Rate Bump %: </span>
                            <div className="w-75">{formData.network_rate_bump }%</div>
                        </div>
                    </>
                )}

                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Status: </span>
                    <div className="w-75">
                        <Form.Control as="select" value={formData.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })}>
                            <option value="A">Active</option>
                            <option value="D">Disabled</option>
                        </Form.Control>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Created By: </span>
                    <div className="w-75">{formData.fullName}</div>
                </div>
                {editable ? (
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Dates: </span>
                        <div className="w-75">
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={moment.utc(selectedStartDate).format('YYYY/MM/DD')} selectedEndDate={moment.utc(selectedEndDate).format('YYYY/MM/DD')} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Dates: </span>
                        <div className="w-75">
                            {moment.utc(selectedStartDate).format('YYYY-MM-DD')} - {moment.utc(selectedEndDate).format('YYYY-MM-DD')}
                        </div>
                    </div>
                )}
            </Card.Body>
        )
    }

    const displayNeverRun = () => {
        return (
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Promo Name: </span>
                    <div className="w-75">
                        <Form.Control type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Promo Type: </span>
                    <div className="w-75">
                        <Form.Control as="select" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })} disabled={!editable}>
                            <option value="OFFER_SPECIFIC">Specified Campaign</option>
                            <option value="NEVER_RUN">Campaign never run by affiliate</option>
                            <option value="BEAT_YOUR_BEST">Beat your best</option>
                        </Form.Control>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Show top earner if above: </span>
                    <div className="w-75">
                        <Form.Control type="number" value={formData.earnerThreshold} onChange={(e) => setFormData({ ...formData, earnerThreshold: e.target.value })} />
                    </div>
                </div>

                {editable ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Reward %: </span>
                            <div className="w-75">
                                <Form.Control type="number" defaultValue={formData.reward_pct } onChange={(e) => setFormData({ ...formData, reward_pct: e.target.value })} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Reward %: </span>
                            <div className="w-75">{formData.reward_pct }%</div>
                        </div>
                    </>
                )}
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Status: </span>
                    <div className="w-75">
                        <Form.Control as="select" value={formData.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })}>
                            <option value="A">Active</option>
                            <option value="D">Disabled</option>
                        </Form.Control>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Created By: </span>
                    <div className="w-75">{formData.fullName}</div>
                </div>
                {editable ? (
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Dates: </span>
                        <div className="w-75">
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={moment.utc(selectedStartDate).format('YYYY/MM/DD')} selectedEndDate={moment.utc(selectedEndDate).format('YYYY/MM/DD')} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Dates: </span>
                        <div className="w-75">
                            {moment.utc(selectedStartDate).format('YYYY-MM-DD')} - {moment.utc(selectedEndDate).format('YYYY-MM-DD')}
                        </div>
                    </div>
                )}
            </Card.Body>
        )
    }

    const displayBeatYourBest = () => {
        return (
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Promo Name: </span>
                    <div className="w-75">
                        <Form.Control type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Promo Type: </span>
                    <div className="w-75">
                        <Form.Control as="select" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })} disabled={!editable}>
                            <option value="OFFER_SPECIFIC">Specified Campaign</option>
                            <option value="NEVER_RUN">Campaign never run by affiliate</option>
                            <option value="BEAT_YOUR_BEST">Beat your best</option>
                        </Form.Control>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Show top earner if above: </span>
                    <div className="w-75">
                        <Form.Control type="number" value={formData.earnerThreshold} onChange={(e) => setFormData({ ...formData, earnerThreshold: e.target.value })} />
                    </div>
                </div>
                {editable ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Rewards: </span>
                            <div className="w-75">
                                <div className="d-flex justify-content-start align-items-center w-100 mb-2">
                                    <span className="mr-2">Threshold: </span>
                                    <Form.Control type="number" defaultValue={reward.reward_threshold } onChange={(e) => setReward({ ...reward, reward_threshold: e.target.value })} />
                                    <span className="mr-2">Reward:</span>
                                    <Form.Control type="number" defaultValue={reward.reward_pct } onChange={(e) => setReward({ ...reward, reward_pct: e.target.value })} />
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        onClick={() => {
                                            let tempRewards = formData.rewards
                                            let tempReward = reward
                                            tempReward.reward_pct = tempReward.reward_pct / 100
                                            tempReward.reward_threshold = tempReward.reward_threshold / 100
                                            tempRewards.push(reward)
                                            setFormData({ ...formData, rewards: tempRewards })
                                        }}
                                        className="ml-2"
                                    >
                                        {' '}
                                        Add{' '}
                                    </Button>
                                </div>
                                {formData.rewards &&
                                    formData.rewards.map((reward, index) => {
                                        return (
                                            <div key={index} className="d-flex justify-content-start align-items-center w-100 mb-2">
                                                <span>Threshold: </span>
                                                {reward.reward_threshold }%<span className="ml-2">Reward: </span>
                                                {reward.reward_pct }%
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        let tempRewards = formData.rewards
                                                        tempRewards.splice(index, 1)
                                                        setFormData({ ...formData, rewards: tempRewards })
                                                    }}
                                                    className="ml-2"
                                                >
                                                    {' '}
                                                    Remove{' '}
                                                </Button>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {formData.rewards &&
                            formData.rewards.map((reward, index) => {
                                return (
                                    <div key={index} className="d-flex justify-content-start align-items-center w-100 mb-2 flex-row">
                                        <span>Threshold: </span>
                                        {reward.reward_threshold }%<span className="ml-2">Reward: </span>
                                        {reward.reward_pct }%
                                    </div>
                                )
                            })}
                    </>
                )}
                {editable ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Best of Period: </span>
                            <div className="w-75">
                                <div className="d-flex justify-content-start align-items-center w-100 mb-2">
                                    <DateRangeFilter quickDate={bestOfquickDate} selectedStartDate={moment.utc(bestOfselectedStartDate).format('YYYY/MM/DD')} selectedEndDate={moment.utc(bestOfselectedEndDate).format('YYYY/MM/DD')} handleChangeStart={bestOfhandleChangeStart} handleChangeEnd={bestOfhandleChangeEnd} handleQuickDate={bestOfhandleQuickDate} />
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        onClick={() => {
                                            let tempPeriods = formData.periods
                                            tempPeriods.push({ start_date: bestOfselectedStartDate, end_date: bestOfselectedEndDate })
                                            setFormData({ ...formData, periods: tempPeriods })
                                        }}
                                        className="ml-2"
                                    >
                                        {' '}
                                        Add{' '}
                                    </Button>
                                </div>
                                {formData.periods &&
                                    formData.periods.map((period, index) => {
                                        return (
                                            <div key={index} className="d-flex justify-content-start align-items-center w-100 mb-2">
                                                {moment.utc(period.start_date).format('YYYY-MM-DD')} - {moment.utc(period.end_date).format('YYYY-MM-DD')}
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        let tempPeriods = formData.periods || []
                                                        tempPeriods.splice(index, 1)
                                                        setFormData({ ...formData, periods: tempPeriods })
                                                    }}
                                                    className="ml-2"
                                                >
                                                    {' '}
                                                    Remove{' '}
                                                </Button>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {formData.rewards &&
                            formData.rewards.map((reward, index) => {
                                return (
                                    <div key={index} className="d-flex justify-content-start align-items-center w-100 mb-2 flex-row">
                                        <span>Threshold: </span>
                                        {reward.reward_threshold }%<span className="ml-2">Reward: </span>
                                        {reward.reward_pct }%
                                    </div>
                                )
                            })}
                    </>
                )}
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Status: </span>
                    <div className="w-75">
                        <Form.Control as="select" value={formData.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })}>
                            <option value="A">Active</option>
                            <option value="D">Disabled</option>
                        </Form.Control>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Created By: </span>
                    <div className="w-75">{formData.fullName}</div>
                </div>
                {editable ? (
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Dates: </span>
                        <div className="w-75">
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={moment.utc(selectedStartDate).format('YYYY/MM/DD')} selectedEndDate={moment.utc(selectedEndDate).format('YYYY/MM/DD')} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Dates: </span>
                        <div className="w-75">
                            {moment.utc(selectedStartDate).format('YYYY-MM-DD')} - {moment.utc(selectedEndDate).format('YYYY-MM-DD')}
                        </div>
                    </div>
                )}
            </Card.Body>
        )
    }

    const displayPromo = () => {
        if (formData.type === 'OFFER_SPECIFIC') {
            return displaySpecifiedOffer()
        } else if (formData.type === 'NEVER_RUN') {
            return displayNeverRun()
        } else if (formData.type === 'BEAT_YOUR_BEST') {
            return displayBeatYourBest()
        }
    }
    return (
        <Card>
            <Card.Header closeButton>MaxMoney Promotions</Card.Header>
            {isCreate ? (
                <Card.Body>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Promo Name: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Promo Type: </span>
                        <div className="w-75">
                            <Form.Control as="select" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })}>
                                <option value="OFFER_SPECIFIC">Specified Campaign</option>
                                <option value="NEVER_RUN">Campaign never run by affiliate</option>
                                <option value="BEAT_YOUR_BEST">Beat your best</option>
                            </Form.Control>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Show top earner if above: </span>
                        <div className="w-75">
                            <Form.Control type="number" value={formData.earnerThreshold} onChange={(e) => setFormData({ ...formData, earnerThreshold: e.target.value })} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Promo Dates: </span>
                        <div className="w-75">
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        </div>
                    </div>
                </Card.Body>
            ) : (
                <>{displayPromo()}</>
            )}

            {isCreate ? (
                <Card.Footer className="d-flex justify-content-end">
                    <Button variant="primary" onClick={createPromo} size="sm">
                        Create
                    </Button>
                </Card.Footer>
            ) : (
                <Card.Footer className="d-flex justify-content-end">
                    <Button variant="primary" onClick={editPromo} size="sm">
                        Save
                    </Button>
                </Card.Footer>
            )}
        </Card>
    )
}

export default MaxMoneyModal
