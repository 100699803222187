import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { SystemMessageContext } from '../../../context/SystemMessageContext';
import queryString from 'query-string'

import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter';
import useDateRangeFilter from '../../../hooks/useDateRangeFilter';

import useCallApi from '../../../hooks/useCallApi';
import Dollars from '../../../templates/Dollars/Dollars';
import Number from '../../../templates/Number/Number';
import moment from 'moment'
import Loading from '../../../templates/UI/Loading/Loading';
import { useAuth, useProtectedPage } from '../../../context/AuthContext';
import AffiliateEarningsAnalysis from '../AffiliateEarningsAnalysis/AffiliateEarningsAnalysis';

export default function EarningTransactionReport(){
    document.title = 'Earning Transaction Report'

    const auth = useAuth()
    //useProtectedPage(auth.permissions, 'report_margin_new')

    const { showSystemMessage } = useContext(SystemMessageContext)    

    const history = useHistory()
    const location = useLocation()
    
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    let initUrl = `reports/custom/earningTransactionReport?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

    const {data, dataLoaded, refetch} = useCallApi(initUrl, 'GET', {}, [], showSystemMessage)
    const [selectedDate, setSelectedDate] = useState(false)

    const submitHandler = () => {
        // Redirect and get new data
        let url = `/${initUrl}`
        history.push(url)
    }

    const getReportOutput = () => {
        return (
            <table className="table table-striped table-bordered table-hover mb-0">
                <thead>
                    <tr>
                        <th>Earnings Date</th>                        
                        <th className="text-right">Affiliate Payout</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Report */}
                    {data.report.map( line => {
                        return (                         
                            <tr style={selectedDate === moment(line.date).utc().format('YYYY-MM-DD') ? {backgroundColor: 'rgba(185, 226, 255, 0.5)'} : {}}>
                                <td className="text-left">{moment(line.date).utc().format('YYYY-MM-DD')}</td>                                
                                <td className="text-right"><button className="btn btn-link" onClick={()=>{ setSelectedDate(moment(line.date).utc().format('YYYY-MM-DD')) }}><Dollars value={line.amount} /></button></td>
                            </tr>
                        )
                    })}                   
                </tbody>
                
            </table>
        )
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <span className="mb-h1">Reports</span>
                {/* <HeaderStats showSystemMessage={props.showSystemMessage}></HeaderStats> */}
            </div>
            <div className="row">
                <div className="col-xl-4">
                    <div className="card pb-0">
                        <div className='align-items-center p-3'>
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark">Earning Transaction Report</h1>
                            </div>
                            <div className="d-flex mt-2">
                                <div>
                                    {/* Dates */}
                                    <div className="d-flex">
                                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                    </div>
                                </div>                    
                                <div className="ml-2">
                                    <button className="btn btn-primary btn-sm" onClick={()=>submitHandler()}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            {dataLoaded ? (data.report ? getReportOutput() : <div className="p-2">Click submit to run the analysis.</div>) : <Loading /> }
                        </div>                
                    </div>
                </div>
                <div className="col-xl-8">
                    { selectedDate && <AffiliateEarningsAnalysis date={selectedDate} />}
                </div>
            </div>
        </React.Fragment>
    )
}