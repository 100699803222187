import { Button, Card, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

const AffiliatePriorityEarnings = ({ showSystemMessage }) => {
    document.title = 'Affiliate Margin by Priority'

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const history = useHistory()
    const { data, dataLoaded, refetch } = useCallApi(`affiliates/marginByPriority?startDate=${selectedStartDate}&endDate=${selectedEndDate}`, 'GET', {}, [], showSystemMessage)
    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with tnpmhe SELECTED options
        e.preventDefault()
        let url = `/affiliates/marginByPriority?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

        history.push(url)
        refetch()
    }

    return (
        <div className="row">
            <div className="col-lg-6">
                <Card>
                    <Card.Header className="d-flex justify-content-start align-items-center">
                        Affiliate Margin By Priority
                        <div className="d-flex justify-content-start w-25 ml-3">
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <Button variant="primary" size="sm" onClick={refreshReport}>
                                Refresh
                            </Button>
                        </div>
                    </Card.Header>

                    <div className="alert alert-info m-3">This report should only be run from Jan 1, 2021 onwards as priority changes were not recorded prior to that date.</div>

                    <Table responsive hover bordered>
                        <thead>
                            <tr>
                                <th>Priority</th>
                                <th className="text-right">Aff. Count</th>
                                <th className="text-right">Total Margin</th>
                                <th className="text-right">Marg. / Aff.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataLoaded ? (
                                <>
                                    {data.report.length > 0 ? (
                                        <>
                                            {data.report.map((row, index) => (
                                                <tr key={index}>
                                                    <td width="10%">{row.aff_priority}</td>
                                                    <td className="text-right">{row.aff_count}</td>
                                                    <td className="text-right">
                                                        <Dollars value={row.tot_margin} />
                                                    </td>
                                                    <td className="text-right">
                                                        <Dollars value={row.tot_margin / row.aff_count} />
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td />
                                                <td className="text-right">
                                                    <b>{data.report.reduce((a, b) => a + b.aff_count, 0)}</b>
                                                </td>
                                                <td className="text-right">
                                                    <b>
                                                        <Dollars value={data.report.reduce((a, b) => a + b.tot_margin, 0)} />
                                                    </b>
                                                </td>
                                                <td />
                                            </tr>
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No data found.</td>
                                        </tr>
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="4">
                                        <Loading />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <div className="alert alert-secondary m-3">
                        <b>Legend (based on 30 days earnings)</b>
                        <br />
                        {'1 - High     - Aff.Earnings > $ 2500'}
                        <br />
                        {'2 - Med      - Aff.Earnings > $ 500 and < $ 2499'}
                        <br />
                        {'3 - New      - Aff. is < 30 days old'}
                        <br />
                        {'4 - Low      - Aff.Earnings > $0 and < $ 500'}
                        <br />
                        {'5 - Very Low - Aff.Earnings = $0'}
                        <br />
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default AffiliatePriorityEarnings
