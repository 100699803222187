import moment from 'moment'
import { useEffect } from 'react'
import { Card, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import TransactionDetailsContent from '../../../components/AffiliateTransactionDetailsContent/TransactionDetailsContent'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'

const AffiliateTransactionDetails = ({ transactionId, showSystemMessage }) => {
    const location = useLocation()
    const { dataLoaded, data, refetch } = useCallApi(`affiliate/transaction/info?transactionId=${transactionId}`, 'GET', {}, [transactionId], showSystemMessage)

    useEffect(() => {
        refetch()
    }, [location])

    return (
        <Card>
            <div>
                <Card.Header>Affiliate Transaction Details</Card.Header>
                <div>
                    {dataLoaded && data.success ? (
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr>
                                    <td width="20%">Affiliate: </td>
                                    <td width="80%">
                                        <span>
                                            <Affiliate affiliateId={data.transactionInfo.affiliateId} name={data.transactionInfo.name} />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%">Entry Type: </td>
                                    <td width="80%">
                                        <span>{data.transactionInfo.entry_type}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%">Transaction ID: </td>
                                    <td width="80%">
                                        <span>{data.transactionInfo.entry_id}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%">Entry Date: </td>
                                    <td width="80%">
                                        <span>{moment(data.transactionInfo.entry_date).format('YYYY-MM-DD HH:mm:ss')}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%">Amount: </td>
                                    <td width="80%">
                                        <span>
                                            <Dollars value={data.transactionInfo.amount} />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%">Notes: </td>
                                    <td width="80%">
                                        <span>{data.transactionInfo.notes}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
            {dataLoaded && data.success ? (
                <>
                    <TransactionDetailsContent transactionId={data.transactionInfo.entry_id} showSystemMessage={showSystemMessage} />
                </>
            ) : (
                <Loading />
            )}
        </Card>
    )
}

export default AffiliateTransactionDetails
