import React, { useState, useEffect } from 'react';
import callAPI from '../../../helpers/callAPI';
import useCallApi from '../../../hooks/useCallApi';
import Loading from '../../../templates/UI/Loading/Loading';

export default function PreviewCreative({campaignId, advertiserId, url, type, rawUrl, showSystemMessage}){

    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState({ content: 'Creative not found.' })
    
    useEffect(async ()=>{
        if (type !== 'B'){
            let result = await callAPI(url, 'GET', {}, false)
            if (result.success){
                setData(result)
                setDataLoaded(true)
            } else {
                setDataLoaded(true)
            }
        } else {
            setDataLoaded(true)
        }
    }, [])

    return (
        <div>
            {dataLoaded ?
                type === 'B' ?
                    <img style={{ maxHeight: '250px', maxWidth: '100%' }} src={`${process.env.REACT_APP_API_URL}/${url}`} alt="Creative" crossOrigin={process.env.REACT_APP_PORTAL_URL} />
                : (type === 'T' || type === 'E')?
                    <iframe style={{width: '100%', minHeight: '250px', maxHeight: '500px', border: '1px solid RGBA(0,0,0,0.2)'}} src={`${process.env.REACT_APP_API_URL}/${rawUrl}`}></iframe>
                : type === 'H' ?
                    <iframe style={{width: '100%', minHeight: '250px', maxHeight: '500px', border: '1px solid RGBA(0,0,0,0.2)'}} src={`${process.env.REACT_APP_API_URL}/${rawUrl}`}></iframe>                    
                :
                <div>Error: Unknown creative.</div>
            :
            <Loading></Loading>
            }
        </div>
    )
}