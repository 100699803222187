import React, { useState, useEffect } from 'react'

const PaginationContainer = (props) => {
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [displayedData, setDisplayedData] = useState([])
    const [viewRecords, setViewRecords] = useState(props.recordsPerPage ? props.recordsPerPage : 10)

    const { data, parentClasses, flex, flexSettings } = props

    useEffect(() => {
        // Get the amount of records to display
        let startRecord = (page - 1) * viewRecords
        let endRecord = page * viewRecords

        setDisplayedData(data.slice(startRecord, endRecord))
        setTotalPages(Math.ceil(data.length / viewRecords))
    }, [page, data, viewRecords])

    const getParentClasses = () => {
        let classes = parentClasses
        if (flex) {
            classes = `d-flex flex-wrap ${parentClasses}`
        }
        return classes
    }

    const nextPage = () => {
        if (page < Math.ceil(data.length / viewRecords)) {
            setPage(page + 1)
        }
    }

    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const lastPage = () => {
        setPage(Math.ceil(data.length / viewRecords))
    }

    const firstPage = () => {
        setPage(1)
    }

    const jumpPage = (e) => {
        if (e.target.value > 0 && e.target.value <= Math.ceil(data.length / viewRecords)) {
            setPage(e.target.value)
        } else {
            setPage(1)
        }
    }

    const changeViewRecords = (e) => {
        setViewRecords(e.target.value)
        setPage(1)
    }

    return (
        <React.Fragment>
            <div className="text-center">
                <button className="btn btn-primary mx-1" onClick={firstPage}>
                    {'<<'}
                </button>
                <button className="btn btn-primary mx-1" onClick={prevPage}>
                    {'<'}
                </button>
                <span className="mx-3">
                    Page:{' '}
                    <strong>
                        <input onChange={(e) => jumpPage(e)} className="form-control d-inline ml-1 p-1 text-center font-weight-bold" style={{ maxWidth: '50px', verticalAlign: 'middle' }} value={page} /> of {totalPages}
                    </strong>
                </span>
                <select className="custom-select d-inline mx-3" value={viewRecords} style={{ maxWidth: '150px' }} onChange={(e) => changeViewRecords(e)}>
                    <option value="10">Show 10</option>
                    <option value="20">Show 20</option>
                    <option value="30">Show 30</option>
                    <option value="40">Show 40</option>
                    <option value="50">Show 50</option>
                </select>
                <button className="btn btn-primary mx-1" onClick={nextPage}>
                    {'>'}
                </button>
                <button className="btn btn-primary mx-1" onClick={lastPage}>
                    {'>>'}
                </button>
            </div>
            <div className={getParentClasses()}>
                {displayedData}
                {flex && flexSettings && (
                    /* The below are for layout purposes. It is a flexbox workaround DO NOT DELETE. */
                    <div className="m-3" style={flexSettings}></div>
                )}
            </div>
            <div className="text-center">
                <button className="btn btn-primary mx-1" onClick={firstPage}>
                    {'<<'}
                </button>
                <button className="btn btn-primary mx-1" onClick={prevPage}>
                    {'<'}
                </button>
                <span className="mx-3">
                    Page:{' '}
                    <strong>
                        <input onChange={(e) => jumpPage(e)} className="form-control d-inline ml-1 p-1 text-center font-weight-bold" style={{ maxWidth: '50px', verticalAlign: 'middle' }} value={page} /> of {totalPages}
                    </strong>
                </span>
                <select className="custom-select d-inline mx-3" value={viewRecords} style={{ maxWidth: '150px' }} onChange={(e) => setViewRecords(e.target.value)}>
                    <option value="10">Show 10</option>
                    <option value="20">Show 20</option>
                    <option value="30">Show 30</option>
                    <option value="40">Show 40</option>
                    <option value="50">Show 50</option>
                </select>
                <button className="btn btn-primary mx-1" onClick={nextPage}>
                    {'>'}
                </button>
                <button className="btn btn-primary mx-1" onClick={lastPage}>
                    {'>>'}
                </button>
            </div>
        </React.Fragment>
    )
}

export default PaginationContainer
