import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import ReportTable from '../../templates/ReportTable/ReportTable'
import '../../templates/ReportTable/ReportTable.css'
import Loading from '../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../pages/Reports/Filters/DateRangeFilter/DateRangeFilter'
import useDateRangeFilter from '../../hooks/useDateRangeFilter'
import callAPI from '../../helpers/callAPI'
import Affiliate from '../../templates/Affiliate/Affiliate'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import DownloadCSV from '../../templates/DownloadCSV/DownloadCSV'
import ReferrerDetailsContent from '../ReferrerDetails/ReferrerDetailsContent'
import useQueryParams from '../../hooks/useQueryParams'
import ReportAppliedFilters from '../../templates/ReportAppliedFilters/ReportAppliedFilters'
import Campaign from '../../templates/Campaign/Campaign'

export default function LeadReferrers({ affiliateIdParam, campaignIdParam, showSystemMessage }) {
    const [dataLoaded, setDataLoaded] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [showModal, setShowModal] = useState(false)
    const [detailRefurl, setDetailRefurl] = useState('')
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})
    const [report, setReport] = useState([])
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { affiliateId, campaignId, creativeId, landingPageId, subid1, keyword, buildUrl } = useQueryParams()

    if (affiliate.id){
        document.title = `${affiliate.name}: Lead Referrers`
    } else if (campaign.id){
        document.title = `${campaign.name}: Lead Referrers`
    } else {
        document.title = `Lead Referrers`
    }

    useEffect(() => {
        getReport()
    }, [location])

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = affiliateIdParam ? buildUrl(`/affiliate/${affiliateIdParam}/leadReferrers?startDate=${selectedStartDate}&endDate=${selectedEndDate}`) : buildUrl(`/campaign/${campaignIdParam}/leadReferrers?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        history.push(url)
    }

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = affiliateIdParam ? buildUrl(`affiliate/${affiliateIdParam}/leadReferrers?startDate=${initStartDate}&endDate=${initEndDate}`) : buildUrl(`campaign/${campaignIdParam}/leadReferrers?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.report)
                    if (result.campaign) {
                        setCampaign(result.campaign)
                    }
                    if (result.affiliate) {
                        setAffiliate(result.affiliate)
                    }
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching lead referrers: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching lead referrers')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Referring URL',
                accessor: 'referring_url',
                Cell: ({ cell: { value } }) => {
                    return (
                        <a target="_blank" href={`${value}`}>
                            {value}
                        </a>
                    )
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Count</div>,
                accessor: 'leads',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div className="float-right"></div>,
                accessor: 'n/a',
                className: 'text-right',
                Cell: (tableData) => {
                    // This MUST be an <a></a> instead of a  <Link></Link> due to the way react-router-dom automatically encodes/decodes url strings. Annoying.
                    let encodedString = encodeURIComponent(tableData.data[tableData.row.index].referring_url)
                    return (
                        <a className="btn btn-sm btn-link" onClick={(e) => openModal(e, tableData.data[tableData.row.index].referring_url)} href={ affiliateIdParam ? buildUrl(`/affiliate/${affiliateIdParam}/referrerDetails/?startDate=${selectedStartDate}&endDate=${selectedEndDate}&mode=leads&refurl=${encodedString}`) : buildUrl(`/campaign/${campaignIdParam}/referrerDetails/?startDate=${selectedStartDate}&endDate=${selectedEndDate}&mode=leads&refurl=${encodedString}`)} >
                            By {affiliateIdParam ? 'Campaign' : 'Affiliate'}
                        </a>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const openModal = (e, referringURL) => {
        e.preventDefault()
        setDetailRefurl(referringURL)
        setShowModal(true)
    }

    const CSVHeaders = ['Referring URL', 'Count']
    const CSVDataLabels = ['referring_url', 'clicks']

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Lead Referrers</h1>
                <div className="ml-5">
                    {affiliateIdParam ? <AffiliateNavbar affiliateId={affiliateIdParam} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignIdParam} showSystemMessage={showSystemMessage} /> }
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
                            <ReferrerDetailsContent  affiliateIdParam={affiliateIdParam} campaignIdParam={campaignIdParam} affiliateId={affiliateId} campaignId={campaignId} mode="leads" refurl={encodeURIComponent(detailRefurl)} landingPageId={landingPageId} subid1={subid1} startDate={initStartDate} endDate={initEndDate} />
                        </Modal>
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">
                                            { affiliateIdParam ? <Affiliate aid={affiliateIdParam} name={affiliate.name} priority={affiliate.priority ? affiliate.priority.value : ''} status={affiliate.status ? affiliate.status.value : ''} /> : <Campaign oid={campaignIdParam} name={campaign.name} status={campaign.status ? campaign.status.value : ''} /> }
                                        </h1>
                                    </div>
                                    <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                    <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                        Refresh Report
                                    </button>
                                </div>
                                <div>
                                    <DownloadCSV filename={`Lead Referrers - ${initStartDate}-${initEndDate}`} headerLabels={CSVHeaders} data={report} dataLabels={CSVDataLabels} />
                                </div>
                            </div>
                            {dataLoaded && <ReportAppliedFilters affiliate={affiliate} campaign={campaign} creativeId={creativeId} landingPageId={landingPageId} subid1={subid1} keyword={keyword} />}
                            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
