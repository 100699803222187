import moment from 'moment'
import { Button, Card, Form, FormCheck, Table } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Dollars from '../../../templates/Dollars/Dollars'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import { useEffect, useState } from 'react'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const IncompleteApps = ({ showSystemMessage, defaultFilter }) => {
    document.title = 'Incomplete Applications'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_incomplete_apps')

    const [filter, setFilter] = useState(defaultFilter || 'all')
    const [selectedApps, setSelectedApps] = useState([])
    const [extraText, setExtraText] = useState('')
    const [isInsert, setIsInsert] = useState(false)
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initEndDate, initStartDate } = useDateRangeFilter()
    const { data, dataLoaded, refetch } = useCallApi(`affiliates/incompleteApps?startDate=${selectedStartDate}&endDate=${selectedEndDate}&filter=${filter}`, 'GET', {}, [], showSystemMessage)
    const history = useHistory()

    const selectAll = () => {
        setSelectedApps(data.incompleteApps.map((row) => row.id))
    }

    const unSelectAll = () => {
        setSelectedApps([])
    }

    const deleteSelected = async () => {
        if (selectedApps.length > 0) {
            let result = await callAPI(`affiliates/incompleteApps`, 'DELETE', { ids: selectedApps })
            if (result.success) {
                showSystemMessage('success', 'Successfully deleted.')
                refetch()
                setSelectedApps([])
            } else {
                showSystemMessage('error', result.errors.message)
            }
        } else {
            showSystemMessage('error', 'Please select affiliates to delete.')
        }
    }

    const sendEmails = async () => {
        if (selectedApps.length > 0) {
            let result = await callAPI(`affiliates/incompleteApps/emails`, 'PUT', { ids: selectedApps, extraText, isInsert })
            if (result.success) {
                showSystemMessage('success', 'Successfully emailed.')
                refetch()
                setSelectedApps([])
            } else {
                showSystemMessage('error', result.errors.message)
            }
        } else {
            showSystemMessage('error', 'Please select affiliates to email.')
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Date',
                accessor: 'db_date',
                Cell: ({ cell }) => moment(cell.row.original.db_date).format('YYYY-MM-DD'),
            },
            {
                Header: 'Country',
                accessor: 'country_code',
            },
            {
                Header: 'Name (Contact)',
                accessor: 'name',
                Cell: ({ cell }) => (
                    <span>
                        {cell.row.original.name} <b>({cell.row.original.contact})</b>
                    </span>
                ),
            },
            {
                Header: 'Email',
                accessor: 'e_mail',
            },
            {
                Header: 'Phone',
                accessor: 'mobile_phone',
            },
            {
                Header: 'Score',
                accessor: 'score',
            },
            {
                Header: 'Experience',
                accessor: 'experience_description',
                Cell: ({ cell }) => (
                    <span>
                        [{cell.row.original.experience_level}] {cell.row.original.experience_description}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Status Date',
                accessor: 'status_date',
                Cell: ({ cell }) => moment(cell.row.original.status_date).format('YYYY-MM-DD'),
            },
            {
                Header: 'Aff ID',
                accessor: 'affiliate_id',
            },
        ]

        return (
            <>
                <ReportTable data={data.incompleteApps} columns={columns} />
            </>
        )
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5">
                    <span className="d-flex justify-content-start flex-wrap">
                        <span className="d-flex mr-2">Incomplete Apps</span>
                        <span className="d-flex ml-2 mb-2 flex-wrap">
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <select className="form-control form-control-sm d-inline mr-2" defaultValue={filter} onChange={(e) => setFilter(e.target.value)} style={{ width: '100px' }}>
                                <option value="all">All</option>
                                <option value="Show_Emailed">Emailed</option>
                                <option value="Show_Registered_Affiliates">Registered</option>
                                <option value="Show_Pending">Pending</option>
                            </select>
                            <Button
                                variant="warning"
                                size="sm"
                                onClick={() => {
                                    refetch()
                                    history.push(`/affiliates/incompleteApps?startDate=${selectedStartDate}&endDate=${selectedEndDate}&filter=${filter}`)
                                }}
                            >
                                Refresh
                            </Button>
                        </span>
                        <Button variant="primary" className="ml-2 mb-2" size="sm" onClick={sendEmails}>
                            Email
                        </Button>
                        <Button variant="primary" className="ml-2 mb-2" size="sm" onClick={deleteSelected}>
                            Delete
                        </Button>

                        <Button variant="primary" className="ml-2 mb-2" size="sm" onClick={selectAll}>
                            Select All
                        </Button>
                        <Button variant="primary" className="ml-2 mb-2" size="sm" onClick={unSelectAll}>
                            Unselect All
                        </Button>
                    </span>
                    <span className="d-flex ml-2 align-items-center">
                        <Form.Control
                            as="textarea"
                            className="ml-2"
                            style={{ width: '500px' }}
                            rows={3}
                            placeholder="Custom Email Message"
                            value={extraText}
                            onChange={(e) => {
                                setExtraText(e.target.value)
                            }}
                        />
                        <Form.Check
                            type="checkbox"
                            label="Insert custom Email Message?"
                            className="ml-2"
                            checked={isInsert}
                            onChange={(e) => {
                                setIsInsert(e.target.checked)
                            }}
                        />
                    </span>
                </Card.Title>
            </Card.Header>

            {dataLoaded ? data.incompleteApps.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
        </Card>
    )
}

export default IncompleteApps
