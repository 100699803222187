import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Table } from 'reactstrap'
import EventModal from './EventModal'

export default function MobileAppEvents({ campaignId, campaignName, data, refetch, showSystemMessage, success }) {
    const [Modal, setModal] = useState({ isOpen: false, data: { id: '', is_retirable: false, name: '', hide: false, description: '' }, isCreate: false })

    return (
        <div className="card mb-2">
            {Modal.isOpen && <EventModal campaignName={campaignName} campaignId={campaignId} showSystemMessage={showSystemMessage} isOpen={Modal.isOpen} closeModal={() => setModal({ ...Modal, isOpen: false })} data={Modal.data} isCreate={Modal.isCreate} refetch={refetch} />}
            <Form>
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div>Mobile App Events</div>
                    <Button variant="warning" size="sm" onClick={() => setModal({ isOpen: true, data: { id: '', is_retirable: false, name: '', hide: false, description: '' }, isCreate: true })}>
                        Add Event
                    </Button>
                </div>
                <Table striped bordered hover className="mb-0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Retireable</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {data.mobile_in_app_events.length > 0 ? (
                                <>
                                    {data.mobile_in_app_events.map((event, index) => (
                                        <tr key={index}>
                                            <td>{event.name}</td>
                                            <td>{event.description}</td>
                                            <td>{event.hide ? 'Hidden' : 'Visible'}</td>
                                            <td>{event.is_retireable ? 'Yes' : 'No'}</td>
                                            <td className="text-right">
                                                <Button variant="warning" size="sm" onClick={() => setModal({ isOpen: true, data: event, isCreate: false })}>
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="5">No Mobile App Events</td>
                                </tr>
                            )}
                        </>
                    </tbody>
                </Table>
            </Form>
        </div>
    )
}
