import { useState } from 'react'
import { Form, Modal, Table, Button } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'

const CountryModal = ({ campaignName, tracking, campaignId, showSystemMessage, isOpen, setIsOpen, refetch }) => {
    const [checkAll, setCheckAll] = useState(false)
    const [selectedCountries, setSelectedCountries] = useState(tracking.traffic.country_filtering.countries)

    const { dataLoaded: cLDataLoaded, data: cLData } = useCallApi(`countryList`, 'GET', {}, [], showSystemMessage)

    const save = async () => {
        //save data to db

        const result = await callAPI(`campaign/${campaignId}/countries`, 'PUT', { countries: selectedCountries })
        if (result.success) {
            showSystemMessage('success', 'Countries updated successfully')
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }


    const handleCheckAll = (e) => {

        setCheckAll(e.target.checked)

        let countries = []

        if (e.target.checked) {
            cLData.countries.map(country => {
                countries.push({ country_code: country.country_code })
            })
            setSelectedCountries(countries)
        } else {
            setSelectedCountries([])
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            {cLDataLoaded ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Country list for {campaignName}</Modal.Title>
                    </Modal.Header>
                    <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                        <Table striped bordered hover className="mb-0">
                            <tbody>
                                {/* Show selected ones first */}
                                <tr>
                                    <td>Select/Deselect All</td>
                                    <td><Form.Check name="check_All" type="checkbox"
                                        defaultChecked={checkAll} onChange={handleCheckAll} /></td>
                                </tr>
                                {cLData &&
                                    cLData.countries.map((country, index) => {
                                        if (selectedCountries.findIndex((existingCountry) => existingCountry.country_code === country.country_code) > -1) {
                                            return (
                                                <tr key={country.country_code}>
                                                    <td>{country.country_name}</td>
                                                    <td>
                                                        <Form.Check
                                                            type="checkbox"
                                                            defaultChecked={true}
                                                            onChange={(e) => {
                                                                let countries = selectedCountries
                                                                if (e.target.checked) {
                                                                    countries.push({ country_code: country.country_code })
                                                                } else {
                                                                    let arrayLocation = countries.findIndex((existingCountry) => existingCountry.country_code === country.country_code)
                                                                    if (arrayLocation > -1) {
                                                                        countries.splice(arrayLocation, 1)
                                                                    }
                                                                }

                                                                setSelectedCountries(countries)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    }
                                    )}
                                {/* Unselected ones */}
                                {cLData &&
                                    cLData.countries.map((country, index) => {
                                        if (selectedCountries.findIndex((existingCountry) => existingCountry.country_code === country.country_code) < 0) {
                                            return (
                                                <tr key={country.country_code}>
                                                    <td>{country.country_name}</td>
                                                    <td>
                                                        <Form.Check
                                                            type="checkbox"
                                                            defaultChecked={false}
                                                            onChange={(e) => {
                                                                let countries = selectedCountries
                                                                if (e.target.checked) {
                                                                    countries.push({ country_code: country.country_code })
                                                                } else {
                                                                    let arrayLocation = countries.findIndex((existingCountry) => existingCountry.country_code === country.country_code)
                                                                    if (arrayLocation > -1) {
                                                                        countries.splice(arrayLocation, 1)
                                                                    }
                                                                }

                                                                setSelectedCountries(countries)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    }
                                    )}
                            </tbody>
                        </Table>
                    </div>
                    <Modal.Footer>
                        <Button variant="primary" onClick={save}>
                            Save
                        </Button>
                    </Modal.Footer>
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    )
}

export default CountryModal
