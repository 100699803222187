import { Card, Form, Table } from 'react-bootstrap'
import RedirectSetSelect from '../../../../../components/RedirectSetSelect/RedirectSetSelect'

const RedirectionInfo = ({ campaignId, data, setData, showSystemMessage, setIsDisabled }) => {
    const setRedirectSetName = (setName) => {
        setIsDisabled(false)
        setData({ ...data, redirects: { ...data.redirects, country_redirect_set: setName } })
    }

    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <div>Redirect Info</div>
                </div>
            </Card.Header>
            <Table striped bordered>
                <tbody>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Disable Redirects</span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.redirects.redirect_override === 'Y' ? true : false}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, redirects: { ...data.redirects, redirect_override: e.target.checked ? 'Y' : 'N' } })
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Cancelled Redirect Campaign ID</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                type="number"
                                value={parseInt(data.redirects.expired_redirect_id)}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, redirects: { ...data.redirects, expired_redirect_id: e.target.value } })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Country Filtered Redirect Campaign ID</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                type="number"
                                value={parseInt(data.redirects.ip_filtered_redirect_id)}
                                onChange={(e) => {
                                    setData({ ...data, redirects: { ...data.redirects, ip_filtered_redirect_id: e.target.value } })
                                    setIsDisabled(false)
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Country Redirect Set: </span>
                        </td>
                        <td>
                            <RedirectSetSelect setName={data.redirects.country_redirect_set} setRedirectSetName={setRedirectSetName} />
                        </td>
                    </tr>
                    <tr>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Incentive Redirect Campaign ID</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                type="number"
                                value={parseInt(data.redirects.incentive_redirect_id)}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, redirects: { ...data.redirects, incentive_redirect_id: e.target.value } })
                                }}
                            />
                        </td>
                        <td className="w-25">
                            <span className="d-flex justify-content-end align-items-center">Device Redirect Campaign ID</span>
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                type="number"
                                value={parseInt(data.redirects.device_redirect_id)}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, redirects: { ...data.redirects, device_redirect_id: e.target.value } })
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}

export default RedirectionInfo
