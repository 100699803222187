import React from 'react'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import ReferrerDetailsContent from './ReferrerDetailsContent'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import useQueryParams from '../../hooks/useQueryParams'

const ReferrerDetails = ({ affiliateIdParam, campaignIdParam, startDate, endDate, showSystemMessage }) => {
    let location = useLocation()

    let urlParams = queryString.parse(location.search)
    let refurl = urlParams.refurl ? urlParams.refurl : ''

    const { affiliateId, campaignId, landingPageId, subid1, withLeads } = useQueryParams()

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Click Referrers</h1>
                <div className="ml-5">{affiliateIdParam ? <AffiliateNavbar affiliateId={affiliateIdParam} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignIdParam} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="row">
                <div className="col-lg-5">
                    <ReferrerDetailsContent affiliateIdParam={affiliateIdParam} campaignIdParam={campaignIdParam} affiliateId={affiliateId} campaignId={campaignId} refurl={refurl} withLeads={withLeads} landingPageId={landingPageId} subid1={subid1} startDate={startDate} endDate={endDate} />
                </div>
            </div>
        </div>
    )
}

export default ReferrerDetails
