const CarLimit = (props) => {
    return (
        <div className="mt-3 d-flex align-item-center">
            <div className="d-flex align-items-center">Character limit (0 if no limit):</div>
            <div>
                <input className="form-control ml-2" type="text" size={5} defaultValue={props.charLimit} onChange={props.handleChangeCharLimit(props.questionId)} />
            </div>
        </div>
    )
}

export default CarLimit
