const Dollars = (props) => {
    /*
        Negative numbers will always be red.
        
        Props:
            bold -> bolds text
            cost -> Will show red, even if positive number. Black if 0.
            highlight -> Will highlight in green if positive
    */

    const getClass = (value) => {
        let classes = ''

        if (props.bold) {
            classes = `${classes} font-weight-bold`
        }

        if (value < 0) {
            classes = `${classes} text-danger`
        } else {
            if (props.cost && value !== 0) {
                classes = `${classes} text-danger`
            } else {
                if (props.highlight) {
                    classes = `${classes} text-info`
                }
            }
        }

        return classes
    }

    return <span className={getClass(Math.round(props.value * 100) / 100)}>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.round(props.value * 100) / 100)}</span>
}

export default Dollars
