import { useEffect } from "react"
import useCallApi from "../../../hooks/useCallApi"
import callAPI from "../../../helpers/callAPI"
import Loading from "../../../templates/UI/Loading/Loading"
import PreviewCreative from "../Creatives/PreviewCreative"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { useAuth, useProtectedContent } from "../../../context/AuthContext"
import getCreativeName from "../helpers/getCreativeName"
import getCreativePreview from "../helpers/getCreativePreview"
import downloadCreativeImage from "../helpers/downloadCreativeImage"

export default function CreativesSummary({campaignId, showSystemMessage}){  

    const auth = useAuth()
    const showEdit = useProtectedContent(auth.permissions, 'campaign_creatives')

    const { data, dataLoaded, setData, refetch } = useCallApi(`campaign/${campaignId}/creatives`, 'GET', {}, [campaignId], showSystemMessage)

    useEffect(() => {
        const func = async () => {
            await data.creatives.map(async (creative, i) => {
                const preview = await getCreativePreview(creative.creative_type, creative.creative_id, campaignId, data.advertiser.id)                
                let tempData = data
                tempData.creatives[i].preview = preview
                if (creative.creative_type !== 'B') {
                    tempData.creatives[i].html_text = preview
                }
                setData(tempData)                
            })
        }

        if (dataLoaded) {
            func()
        }
    }, [data])

    return (
        <div className="card mb-2">
            <div className="d-flex align-items-center p-2 card-header justify-content-between">
                <div className="font-weight-bold">Creatives</div>
                { showEdit && <Link className="btn btn-sm btn-warning" to={`/campaign/${campaignId}/creatives`}>Settings</Link> }
            </div>
            <div className="card-body p-2 table-container-scroll" style={{maxHeight: '700px'}}>
                {
                    dataLoaded ?
                        <>
                            {data.creatives.length > 0 &&
                                data.creatives.map((creative, i) => {
                                    if (creative.status_name === 'Active'){
                                        return  <div className="pb-3 mb-3 border-bottom">
                                                    <div className="alert alert-secondary">{getCreativeName(creative.creative_type, creative.width, creative.height, creative.creative_id)}</div>
                                                    <div className="d-flex">
                                                        <div className="flex-fill"><PreviewCreative campaignId={campaignId} advertiserId={data.advertiser.id} url={creative.preview} rawUrl={creative.previewRaw} type={creative.creative_type} showSystemMessage={showSystemMessage} /></div>
                                                        {
                                                            creative.creative_type === 'B' &&
                                                                <button className='btn btn-link btn-sm' onClick={() => downloadCreativeImage(creative.creative_type, creative.width, creative.height, creative.creative_id, creative.preview)}>
                                                                    Download
                                                                </button>
                                                        }                                                    
                                                    </div>
                                                </div>
                                    } else {
                                        return
                                    }
                                })   
                            }
                        </>
                    :
                        <Loading />
                }
            </div>
        </div>
    )
}