import moment from 'moment'
import { Table } from 'react-bootstrap'
import Dollars from '../../../../templates/Dollars/Dollars'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const InvoiceInfo = ({ invoiceFrequency, paymentTerms, balances, limitedCreditFlag }) => {
    const auth = useAuth()
    const showFullBalance = useProtectedContent(auth.permissions, 'advertiser_balances_full')

    const getInvoiceFrequency = (letter) => {
        if (letter === 'W') {
            return 'Weekly'
        } else if (letter === 'S') {
            return 'Semi-Monthly'
        } else if (letter === 'M') {
            return 'Monthly'
        } else if (letter === 'A') {
            return 'Ad-Hoc'
        } else {
            return 'Unknown'
        }
    }

    const isRed = (value) => {
        if (value < 0) {
            return 'text-danger'
        } else {
            return ''
        }
    }

    return (
        <div>
            <div className="p-2 d-flex justify-content-between" style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
                <div className="mr-3">
                    <span className="float-left">Invoice Frequency</span>
                    <span className="float-right font-weight-bold ml-3">{getInvoiceFrequency(invoiceFrequency)}</span>
                </div>
                |
                <div className="ml-3">
                    <span className="float-left">Payment Terms </span>
                    <span className="ml-3 mr-0 font-weight-bold">{paymentTerms === -1 ? 'Pre-Pay' : `Net ${paymentTerms}`}</span>
                </div>
            </div>
            {limitedCreditFlag === 'Y' &&
                (showFullBalance ? (
                    <div className="m-2">
                        <Table striped bordered hover className="mt-2">
                            <thead>
                                <tr>
                                    <th>Source</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Quickbooks balance{balances.balance_date ? ` as of ${moment(balances.balance_date).utc().format('YYYY-MM-DD')}` : ''}</td>
                                    <td align="right" className={isRed(balances.qb_balance * -1)}>                                        
                                        <Dollars value={balances.qb_balance * -1} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Leads{balances.balance_date ? ` as of ${moment(balances.balance_date).utc().format('YYYY-MM-DD')}` : ''}</td>
                                    <td align="right" className={isRed(balances.amount_since_balance_date * -1)}>                                        
                                        <Dollars value={balances.amount_since_balance_date * -1} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Paid invoices after reconciliation</td>
                                    <td align="right" className={isRed(balances.paid_invoices_since_balance_date)}>
                                        <Dollars value={balances.paid_invoices_since_balance_date} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Reversals for previous months done after the reconciliation date</td>
                                    <td align="right">
                                        <Dollars value={balances.reversals_before_balance_date} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Balance {(balances.final_balance - balances.paid_invoices_since_balance_date - balances.reversals_before_balance_date) * -1 < 0 ? <span className="text-danger">(owing)</span> : ''}</td>
                                    <td align="right" className={`font-weight-bold ${isRed((balances.final_balance - balances.paid_invoices_since_balance_date - balances.reversals_before_balance_date) * -1)}`}>
                                        <Dollars value={(balances.final_balance - balances.paid_invoices_since_balance_date - balances.reversals_before_balance_date)*-1} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div className="m-2">
                        <Table striped bordered hover className="mt-2">
                            <tbody>
                                <tr>
                                    <td className="font-weight-bold">Balance {balances.final_balance - balances.paid_invoices_since_balance_date - balances.reversals_before_balance_date > 0 ? <span className="text-danger">(owing)</span> : ''}</td>
                                    <td align="right" className={isRed(balances.final_balance - balances.paid_invoices_since_balance_date - balances.reversals_before_balance_date)}>
                                        {balances.final_balance - balances.paid_invoices_since_balance_date - balances.reversals_before_balance_date > 0 ? '-' : ''} <Dollars value={balances.final_balance - balances.paid_invoices_since_balance_date - balances.reversals_before_balance_date} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                ))}
        </div>
    )
}

export default InvoiceInfo
