import { Card, Table } from "react-bootstrap"
import useCallApi from "../../hooks/useCallApi"
import Loading from "../../templates/UI/Loading/Loading"
import moment from "moment"
import ReportTable from '../../templates/ReportTable/ReportTable'
import React from "react"

const UserClicksAll = ({ source, startDate, endDate }) => {
    const { data, dataLoaded } = useCallApi(`useranalytics?src=${source}&startDate=${startDate}&endDate=${endDate}`, 'GET', {}, [])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Source',
                accessor: 'source',
            },
            {
                Header: 'Platform',
                accessor: 'platform',
            },
            {
                Header: 'Element Type',
                accessor: 'element_type',
            },
            {
                Header: 'Reference Type',
                accessor: 'reference_type',
            },
            {
                Header: 'Reference ID',
                accessor: 'reference_id',
            },
            {
                Header: 'Reference Name',
                accessor: 'reference_name',
            },
            {
                Header: 'Page',
                accessor: 'page',
            },
            {
                Header: 'User',
                accessor: 'user_id',
            },
            {
                Header: 'Remote Host',
                accessor: 'remote_host',
            },
            {
                Header: 'Date',
                accessor: 'db_date',
                Cell: ({ value }) => moment(value).utc().format('YYYY-MM-DD HH:mm:ss')
            },
        ]
        return (
            <React.Fragment>
                <ReportTable
                    data={data.data}
                    columns={columns}
                />
            </React.Fragment>
        )
    }

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">{source}</Card.Header>
            <Card.Body className="p-0 overflow-auto table-container-scroll">
                {(dataLoaded) ?
                    (data.success && data.data.length > 0) ? (                        
                        <>
                        {dataLoaded ? getReportOutput() : <div className="p-3">No stats for the current parameters.</div>}
                        </>
                    ) : (
                        <div className="text-center p-3">No data available</div>

                    ) : (
                        <Loading />
                    )}
            </Card.Body>
        </Card>
    )
}

export default UserClicksAll