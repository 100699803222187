import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import TableContainer from '../TableContainer'
import '../TableContainer.css'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import ReportsMoreAvatar from '../AvatarReportsMore/AvatarReportsMore'
import Dollars from '../../../../templates/Dollars/Dollars'
import callAPI from '../../../../helpers/callAPI'

const ReportProperty = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliate, setAffiliate] = useState({ affiliateId: 0, name: '' })
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])

    const { affiliateId = 0, startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            setDataLoaded(false)

            let fetchUrl = `reports/avatar/property?startDate=${startDate}&endDate=${endDate}&affiliateId=${affiliateId}`

            let result = await callAPI(fetchUrl, 'GET')
            if (result['success'] === true && result.report) {
                setDataLoaded(true)
                setAffiliate({ affiliateId: affiliateId, name: result.affiliate })
                setReport(result.report)
                setTotals(result.totals)
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }, [affiliateId, startDate, endDate])

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={report}
                    totals={totals}
                    columns={[
                        {
                            Header: 'Property',
                            accessor: 'property',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/property/${tableData.data[tableData.row.index].property.id}`}>{tableData.data[tableData.row.index].property.name}</Link>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                            accessor: 'clicks',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/reports/daily?startDate=${startDate}&endDate=${endDate}&propertyId=${tableData.data[tableData.row.index].property.id}`}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].clicks)}`}</Link>
                            },
                        },
                        {
                            Header: 'ConversionFilter',
                            accessor: 'conversions',
                            hide: true,
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                            accessor: 'conversion',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/reports/conversions?propertyId=${tableData.data[tableData.row.index].property.id}&affiliateId=${affiliateId}`}>{Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}</Link>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Aff Leads</div>,
                            accessor: 'payableLeads',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{Intl.NumberFormat('en-GB').format(value)}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Cost</div>,
                            accessor: 'cost',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars cost value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Revenue</div>,
                            accessor: 'revenue',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                            accessor: 'sales',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>eCPA</div>,
                            accessor: 'eCPA',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>epc</div>,
                            accessor: 'epc',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                            accessor: 'margin',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars highlight bold value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Margin %</div>,
                            accessor: 'marginPct',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Contribution %</div>,
                            accessor: 'marginContribution',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right"></div>,
                            accessor: 'leadActionId',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return (
                                    <>
                                        <ReportsMoreAvatar avatarAffiliateSummaryReport avatarAffiliateReport avatarDailyReport avatarConversionReport avatarEventReport avatarSetReport avatarOfferReport subid affiliateId={affiliateId} propertyId={tableData.data[tableData.row.index].property.id} startDate={startDate} endDate={endDate} />
                                    </>
                                )
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">{affiliate.affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliateId}`} forText={affiliate.name} cancelDrilldownUrl={`/avatar/reports/daily?startDate=${props.startDate}&endDate=${props.endDate}&affiliateId=0`}></DrilldownItem>}</div>
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportProperty
