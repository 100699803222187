import React, { Component } from 'react'
import './AvatarReports.css'
import queryString from 'query-string'
import moment from 'moment'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

import ReportSurveyQuestions from './ReportSurveyQuestions/ReportSurveyQuestions'
import ReportConversions from './ReportConversions/ReportConversions'
import ReportAffiliate from './ReportAffiliate/ReportAffiliate'
import ReportSubid from './ReportSubId/ReportSubid'
import ReportDaily from './ReportDaily/ReportDaily'
import ReportOffer from './ReportOffer/ReportOffer'
import ReportSet from './ReportSet/ReportSet'
import ReportProperty from './ReportProperty/ReportProperty'
import ReportResponses from './ReportResponses/ReportResponses'
import ReportEvents from './ReportEvents/ReportEvents'
import ReportAffiliateSummary from './ReportAffiliateSummary/ReportAffiliateSummary'
import ReportPost from './ReportPost/ReportPost'

class Reports extends Component {
    state = {
        dataLoaded: false,
        type: '',
        propertyId: 0,
        surveyId: 0,
        siteId: 0,
        affiliateId: 0,
        offerId: 0,
        subid1: '',
        clientId: '',
        eventId: '',
        startDate: '',
        endDate: '',
        quickDate: 'month',
        selectedType: '',
        selectedStartDate: '',
        selectedEndDate: '',
        filterStartDate: '',
        filterEndDate: '',
        preparingCSV: false,
        CSVData: '',
        page: 1,
        totalPages: 1,
        resultsPerPage: 50,
    }

    componentDidMount() {

        document.title = 'Avatar Reports'

        this._isMounted = true
        let urlParams = queryString.parse(this.props.location.search)
        let startDate
        let endDate
        let quickDate
        let filterStartDate
        let filterEndDate
        let type
        let page
        let propertyId = urlParams.propertyId ? urlParams.propertyId : 0
        let surveyId = urlParams.surveyId ? urlParams.surveyId : 0
        let siteId = urlParams.siteId ? urlParams.siteId : 0
        let affiliateId = urlParams.affiliateId ? urlParams.affiliateId : 0
        let offerId = urlParams.offerId ? urlParams.offerId : 0
        let subid1 = urlParams.subid1 ? urlParams.subid1 : undefined
        let clientId = urlParams.clientId ? urlParams.clientId : ''
        let eventId = urlParams.eventId ? urlParams.eventId : ''

        // Get the report type if specified. Get the offer ID if specified.
        this.props.type ? (type = this.props.type) : (type = 'survey_questions')

        // Get the date from URL. If there's nothing, then use today.
        if (urlParams.startDate && urlParams.endDate) {
            startDate = urlParams.startDate
            endDate = urlParams.endDate
        } else {
            startDate = moment().startOf('month').format('YYYY-MM-DD')
            endDate = moment().endOf('day').format('YYYY-MM-DD')

            // If it's the survey response report, since it's massive, we default it to today only.
            if (type === 'survey_responses') {
                startDate = moment().startOf('day').format('YYYY-MM-DD')
            }
        }

        // Get the filter dates from URL. If there's nothing, let it be empty.
        if (urlParams.filterStartDate && urlParams.filterEndDate) {
            filterStartDate = urlParams.filterStartDate
            filterEndDate = urlParams.filterEndDate
        }

        /* Get the page number from the URL. If none, then use 1. NOTE: The API starts at page 0, so we'll need to subtract 1 before
           fetching from the API */
        if (urlParams.page) {
            page = parseInt(urlParams.page)
        } else {
            page = 1
        }

        let startRow = (page - 1) * this.state.resultsPerPage
        let endRow = page * this.state.resultsPerPage - 1

        // See if the dates are one of the quick dates
        if (startDate === moment().startOf('month').format('YYYY-MM-DD') && endDate === moment().endOf('day').format('YYYY-MM-DD')) {
            quickDate = 'month'
        } else if (startDate === moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') && endDate === moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')) {
            quickDate = 'lastMonth'
        } else if (startDate === moment().startOf('day').format('YYYY-MM-DD') && endDate === moment().startOf('day').format('YYYY-MM-DD')) {
            quickDate = 'today'
        } else if (startDate === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD') && endDate === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD')) {
            quickDate = 'yesterday'
        } else if (startDate === moment().day(1).subtract(1, 'day').format('YYYY-MM-DD') && endDate === moment().day(1).add(5, 'days').format('YYYY-MM-DD')) {
            quickDate = 'thisPayPeriod'
        } else if (startDate === moment().day(1).subtract(8, 'days').format('YYYY-MM-DD') && endDate === moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')) {
            quickDate = 'thisPayPeriod'
        } else {
            quickDate = 'custom'
        }

        // Update the state with the chosen dates
        this.setState({ ...this.state, dataLoaded: true, type: type, propertyId: propertyId, surveyId: surveyId, siteId: siteId, affiliateId: affiliateId, offerId: offerId, subid1: subid1, clientId: clientId, eventId, eventId, startDate: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), endDate: moment(endDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), quickDate: quickDate, selectedType: type, selectedStartDate: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), selectedEndDate: moment(endDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), filterStartDate: filterStartDate ? moment(filterStartDate).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '', filterEndDate: filterEndDate ? moment(filterEndDate).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '', page: page, startRow: startRow, endRow: endRow })
    }

    handleChangeStart = (date) => {
        if (this._isMounted) {
            this.setState({ ...this.state, selectedStartDate: date })
        }
    }

    handleChangeEnd = (date) => {
        if (this._isMounted) {
            this.setState({ ...this.state, selectedEndDate: date })
        }
    }

    handleQuickDate = (event) => {
        let selectedStartDate = this.state.selectedStartDate
        let selectedEndDate = this.state.selectedEndDate

        switch (event.target.value) {
            case 'month':
                selectedStartDate = moment().startOf('month').format('YYYY-MM-DD')
                selectedEndDate = moment().endOf('day').format('YYYY-MM-DD')
                break
            case 'lastMonth':
                selectedStartDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                selectedEndDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                break
            case 'today':
                selectedStartDate = moment().startOf('day').format('YYYY-MM-DD')
                selectedEndDate = moment().endOf('day').format('YYYY-MM-DD')
                break
            case 'yesterday':
                selectedStartDate = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
                selectedEndDate = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
                break
            case 'thisPayPeriod':
                selectedStartDate = moment().day(1).subtract(1, 'day').format('YYYY-MM-DD')
                selectedEndDate = moment().day(1).add(5, 'days').format('YYYY-MM-DD')
                break
            case 'lastPayPeriod':
                selectedStartDate = moment().day(1).subtract(8, 'days').format('YYYY-MM-DD')
                selectedEndDate = moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')
                break
            default:
        }

        if (this._isMounted) {
            this.setState({ ...this.state, quickDate: event.target.value, selectedStartDate: selectedStartDate, selectedEndDate: selectedEndDate })
        }
    }

    /* This will apply the settings based on the selected options */
    handleReport = (e) => {
        e.preventDefault()
        let startDate = moment(this.state.selectedStartDate).startOf('day').format('YYYY-MM-DD')
        let endDate = moment(this.state.selectedEndDate).startOf('day').format('YYYY-MM-DD')
        let propertyId = this.state.propertyId
        let siteId = this.state.siteId
        let surveyId = this.state.surveyId
        let affiliateId = this.state.affiliateId
        let offerId = this.state.offerId
        let subid1 = this.state.subid1
        let clientId = this.state.clientId
        let eventId = this.state.eventId
        let type = this.state.type
        let page = 1
        let startRow = (page - 1) * this.state.resultsPerPage
        let endRow = page * this.state.resultsPerPage - 1
        let filterStartDate = this.state.filterStartDate ? moment(this.state.filterStartDate).startOf('day').format('YYYY-MM-DD') : ''
        let filterEndDate = this.state.filterEndDate ? moment(this.state.filterEndDate).startOf('day').format('YYYY-MM-DD') : ''

        if (this._isMounted) {
            this.setState({ ...this.state, startDate: startDate, endDate: endDate, type: type, page: page, startRow: startRow, endRow: endRow })
        }

        let url = `/avatar/reports/${type}?startDate=${startDate}&endDate=${endDate}`

        if (propertyId > 0) {
            url = url + `&propertyId=${propertyId}`
        }

        if (siteId > 0) {
            url = url + `&siteId=${siteId}`
        }

        if (surveyId > 0) {
            url = url + `&surveyId=${surveyId}`
        }

        if (affiliateId > 0) {
            url = url + `&affiliateId=${affiliateId}`
        }

        if (offerId > 0) {
            url = url + `&offerId=${offerId}`
        }

        if (subid1 > 0) {
            url = url + `&subid1=${subid1}`
        }

        if (clientId !== '') {
            url = url + `&clientId=${clientId}`
        }

        if (eventId !== '') {
            url = url + `&eventId=${eventId}`
        }

        if (filterStartDate !== '') {
            url = url + `&filterStartDate=${filterStartDate}`
        }

        if (filterEndDate !== '') {
            url = url + `&filterEndDate=${filterEndDate}`
        }

        this.props.history.push(url)
    }

    render() {
        let reportToDisplay
        let reportTitle

        switch (this.state.type) {
            case 'survey_questions':
                reportTitle = 'Survey Dropoff Report'
                reportToDisplay = <ReportSurveyQuestions key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} affiliateId={this.state.affiliateId} subid1={this.state.subid1} siteId={this.state.siteId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'conversions':
                reportTitle = 'Conversion Report'
                reportToDisplay = <ReportConversions key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} affiliateId={this.state.affiliateId} offerId={this.state.offerId} subid1={this.state.subid1} clientId={this.state.clientId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'affiliate':
                reportTitle = 'Affiliate Report'
                reportToDisplay = <ReportAffiliate key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} affiliateId={this.state.affiliateId} offerId={this.state.offerId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'offer':
                reportTitle = 'Offer Report'
                reportToDisplay = <ReportOffer key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} affiliateId={this.state.affiliateId} subid1={this.state.subid1} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'subid':
                reportTitle = 'Subid Report'
                reportToDisplay = <ReportSubid key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} affiliateId={this.state.affiliateId} offerId={this.state.offerId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'daily':
                reportTitle = 'Daily Report'
                reportToDisplay = <ReportDaily key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} affiliateId={this.state.affiliateId} offerId={this.state.offerId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'set':
                reportTitle = 'Survey/Set Report'
                reportToDisplay = <ReportSet key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} affiliateId={this.state.affiliateId} offerId={this.state.offerId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'property':
                reportTitle = 'Property Report'
                reportToDisplay = <ReportProperty key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} affiliateId={this.state.affiliateId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'survey_responses':
                reportTitle = 'Survey Response Report'
                reportToDisplay = <ReportResponses key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} affiliateId={this.state.affiliateId} subid1={this.state.subid1} clientId={this.state.clientId} siteId={this.state.siteId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'events':
                reportTitle = 'Event Report'
                reportToDisplay = <ReportEvents key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} affiliateId={this.state.affiliateId} offerId={this.state.offerId} subid1={this.state.subid1} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'affiliateSummary':
                reportTitle = 'Affiliate Summary Report'
                reportToDisplay = <ReportAffiliateSummary key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} affiliateId={this.state.affiliateId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'post':
                reportTitle = 'Post Report'
                reportToDisplay = <ReportPost key={`${this.state.propertyId}-${this.state.surveyId}-${this.state.siteId}-${this.state.startDate}-${this.state.endDate}`} propertyId={this.state.propertyId} surveyId={this.state.surveyId} siteId={this.state.siteId} eventId={this.state.eventId} clientId={this.state.clientId} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            default:
                break
        }

        document.title = `Avatar ${reportTitle}`

        return (
            <React.Fragment>
                <div className="page-header">
                    <h1 className="mb-h1">Avatar Reports</h1>
                </div>
                <div>
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark">{reportTitle}</h1>
                                    </div>

                                    <DateRangeFilter startDate={this.state.startDate} endDate={this.state.endDate} quickDate={this.state.quickDate} selectedStartDate={this.state.selectedStartDate} selectedEndDate={this.state.selectedEndDate} handleChangeStart={this.handleChangeStart} handleChangeEnd={this.handleChangeEnd} handleQuickDate={this.handleQuickDate} />

                                    <div>
                                        <button onClick={(e) => this.handleReport(e)} className="btn btn-sm btn-primary">
                                            Refresh Report
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {}
                        {/* {(this.state.type === 'affiliate_earnings' ) &&
                            <Pagination
                                totalPages={this.state.totalPages}
                                currentPage={this.state.page}
                                handlePageChange={this.handlePageChange} />
                        } */}
                        {this.state.dataLoaded &&
                            /* Display the selected report */
                            reportToDisplay}
                        {/* {(this.state.type === 'affiliate_earnings' ) &&
                            <div className="mt-2">
                                <Pagination
                                    totalPages={this.state.totalPages}
                                    currentPage={this.state.page}
                                    handlePageChange={this.handlePageChange} />
                            </div>
                        } */}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Reports
