import React, { useState } from 'react'

const CallbackType = () => {
    const types = [
        {
            value: 'S',
            text: 'Server (delayed)',
        },
        {
            value: 'L',
            text: 'URL (pixel)',
        },
        {
            value: 'I',
            text: 'HTML/Javascript (iframe)',
        },
    ]

    const [callbackTypes, setCallbackTypes] = useState(types)

    return (
        <React.Fragment>
            {callbackTypes.map((type) => (
                <option key={type.value} value={type.value}>
                    {type.text}
                </option>
            ))}
        </React.Fragment>
    )
}

export default CallbackType
