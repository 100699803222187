import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import EmployeePicker from '../../../components/EmployeePicker/EmployeePicker'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'

export default function MaxMoneyReport({ showSystemMessage, promoId, mgrId, mgrName }) {
    const [employee, setEmployee] = useState({ id: mgrId ? mgrId : 0, name: mgrName ? mgrName : '' })
    const { data, dataLoaded, refetch } = useCallApi(`maxmoney/${promoId}/report${employee.id > 0 ? `?mgrid=${employee.id}` : ''}`, 'GET', {}, [employee], showSystemMessage)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
    }, [employee])

    const getPrevPeriod = (startDate, endDate) => {

        let diff = moment(startDate).diff(moment(endDate), 'days')

        return {
            startDate: moment(startDate).add(diff, 'days').format('MMM/DD'),
            endDate: moment(startDate).format('MMM/DD')
        }
    }

    let totalEarningsPrev = 0
    let totalEarnings = 0
    let totalEarningsDelta = 0
    let totalPromoEarningsPrev = 0
    let totalPromoEarnings = 0
    let totalPromoEarningsDelta = 0
    let totalMargin = 0
    let totalPoints = 0

    return (
        <Card className="mb-3 overflow-auto">
            <Card.Header className="d-flex align-items-center">
                <div>
                    MaxMoney Report for {dataLoaded ? `${data.reportInfo.promo_name} (${moment(data.reportInfo.start_date).format('MMM/DD')} - ${moment(data.reportInfo.end_date).format('MMM/DD')})` : spinner}
                </div>
                <div className='w-25'><EmployeePicker employee={employee} setEmployee={setEmployee} role={[]} className="ml-3" defaultSelf /></div>
            </Card.Header>
            <Table striped bordered className='m-0'>
                {dataLoaded ? (
                    <>
                        {data.reportInfo.promo_type === 'OFFER_SPECIFIC' || data.reportInfo.promo_type === 'NEVER_RUN' ? (
                            <>
                                <thead>
                                    <tr>
                                        <th>Aff. ID</th>
                                        <th>Affiliate Name</th>
                                        <th className="text-right">
                                            Total Earnings
                                            <div className="text-right">
                                                ({getPrevPeriod(data.reportInfo.start_date, data.reportInfo.end_date).startDate} - {getPrevPeriod(data.reportInfo.start_date, data.reportInfo.end_date).endDate})
                                            </div>
                                        </th>
                                        <th className="text-right">
                                            Total Earnings
                                            <div className="text-right">
                                                ({moment(data.reportInfo.start_date).format('MMM/DD')} - {moment(data.reportInfo.end_date).format('MMM/DD')})
                                            </div>
                                        </th>
                                        <th className="text-right">Delta</th>
                                        <th className="text-right">
                                            Promo Earnings
                                            <div className="text-right">
                                                ({getPrevPeriod(data.reportInfo.start_date, data.reportInfo.end_date).startDate} - {getPrevPeriod(data.reportInfo.start_date, data.reportInfo.end_date).endDate})
                                            </div>
                                        </th>
                                        <th className="text-right">
                                            Promo Earnings
                                            <div className="text-right">
                                                ({moment(data.reportInfo.start_date).format('MMM/DD')} - {moment(data.reportInfo.end_date).format('MMM/DD')})
                                            </div>
                                        </th>
                                        <th className="text-right">Delta</th>
                                        <th className="text-right">Margin</th>
                                        <th className="text-right">Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.report.length > 0 ? (
                                        <>{
                                            data.report.map((item, index) => {
                                                totalEarningsPrev += item.prev_earnings
                                                totalEarnings += item.current_earnings
                                                totalEarningsDelta += (item.current_earnings - item.prev_earnings)
                                                totalPromoEarningsPrev += item.prev_promo_earnings
                                                totalPromoEarnings += item.promo_earnings
                                                totalPromoEarningsDelta += (item.promo_earnings - item.prev_promo_earnings)
                                                totalMargin += item.margin
                                                totalPoints += item.mm_points
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.affiliate_id}</td>
                                                        <td>{<Affiliate aid={item.affiliate_id} name={item.name} priority={item.aff_priority} status={item.status} />}</td>
                                                        <td className="text-right">{<Dollars value={item.prev_earnings} />}</td>
                                                        <td className="text-right">{<Dollars value={item.current_earnings} />}</td>
                                                        <td className={(item.current_earnings - item.prev_earnings) > 0 ? 'text-right bgSuccess25' : (item.current_earnings - item.prev_earnings) < 0 ? 'text-right bgDanger25' : 'text-right'}>{<Dollars value={item.current_earnings - item.prev_earnings} />}</td>
                                                        <td className="text-right">{<Dollars value={item.prev_promo_earnings} />}</td>
                                                        <td className="text-right">{<Dollars value={item.promo_earnings} />}</td>
                                                        <td className={(item.promo_earnings - item.prev_promo_earnings) > 0 ? 'text-right bgSuccess25' : (item.promo_earnings - item.prev_promo_earnings) < 0 ? 'text-right bgDanger25' : 'text-right'}>{<Dollars value={item.promo_earnings - item.prev_promo_earnings} />}</td>
                                                        <td className="text-right">{<Dollars value={item.margin} />}</td>
                                                        <td className="text-right">{item.mm_points.toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                            <tr>
                                                <td>Totals:</td>
                                                <td></td>
                                                <td className="font-weight-bold text-right"><Dollars value={totalEarningsPrev} /></td>
                                                <td className="font-weight-bold text-right"><Dollars value={totalEarnings} /></td>
                                                <td className={totalEarningsDelta > 0 ? 'font-weight-bold text-right bgSuccess25' : totalEarningsDelta < 0 ? 'font-weight-bold text-right bgDanger25' : 'font-weight-bold text-right'}><Dollars value={totalEarningsDelta} /></td>
                                                <td className="font-weight-bold text-right"><Dollars value={totalPromoEarningsPrev} /></td>
                                                <td className="font-weight-bold text-right"><Dollars value={totalPromoEarnings} /></td>
                                                <td className={totalPromoEarningsDelta > 0 ? 'font-weight-bold text-right bgSuccess25' : totalPromoEarningsDelta < 0 ? 'font-weight-bold text-right bgDanger25' : 'font-weight-bold text-right'}><Dollars value={totalPromoEarningsDelta} /></td>
                                                <td className="font-weight-bold text-right"><Dollars value={totalMargin} /></td>
                                                <td className="font-weight-bold text-right">{totalPoints.toFixed(2)}</td>
                                            </tr>
                                        </>
                                    ) : (
                                        <td colSpan="10">No data found.</td>
                                    )}
                                </tbody>
                            </>
                        ) : (
                            <>
                                <thead>
                                    <tr>
                                        <th>Aff. ID</th>
                                        <th>Affiliate Name</th>
                                        <th className="text-right">Threshold</th>
                                        <th className="text-right">Current Earnings</th>
                                        <th className="text-right">To Next Tier</th>
                                        <th className="text-right">Earnings / Threshold</th>
                                        <th className="text-right">Reward tier %</th>
                                        <th className="text-right">Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.report.length > 0 ? (
                                        <>
                                            {
                                                data.report.map((item, index) => {
                                                    let toNextTier = 0
                                                    let reward_pct = 0

                                                    data.rewardList.map((reward) => {
                                                        let amount_more_to_earn = ((1 + reward.reward_threshold) * item.byb_threshold) - item.promo_earnings
                                                        if (amount_more_to_earn <= 0) {
                                                            reward_pct = reward.reward_pct * 100
                                                        }
                                                        if (amount_more_to_earn > 0) {
                                                            toNextTier = amount_more_to_earn
                                                        }
                                                    })

                                                    let threshold_achieved = ((item.promo_earnings - item.byb_threshold) / item.byb_threshold) * 100

                                                    totalEarnings += item.promo_earnings
                                                    totalPoints += (reward_pct / 100) * item.promo_earnings

                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.affiliate_id}</td>
                                                            <td>{<Affiliate aid={item.affiliate_id} name={item.name} status={item.status} priority={item.aff_priority} />}</td>
                                                            <td className="text-right">{<Dollars value={item.byb_threshold} />}</td>
                                                            <td className="text-right">{<Dollars value={item.promo_earnings} />}</td>
                                                            <td className={(threshold_achieved > 0 && toNextTier > 0) ? 'text-right bgWarning25' : 'text-right'}>{<Dollars value={toNextTier} />}</td>
                                                            <td className={reward_pct > 0 ? 'text-right bgSuccess25' : threshold_achieved > 0 ? 'text-right bgWarning25' : 'text-right'}>{((item.promo_earnings - item.byb_threshold) / item.byb_threshold) * 100 < 0 ? '0.00' : (((item.promo_earnings - item.byb_threshold) / item.byb_threshold) * 100).toFixed(2)}%</td>
                                                            <td className="text-right">{reward_pct.toFixed(2)}%</td>
                                                            <td className="text-right">{<Dollars value={(reward_pct / 100) * item.promo_earnings} />}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td>Totals:</td>
                                                <td></td>
                                                <td className="text-right"></td>
                                                <td className="text-right font-weight-bold">{<Dollars value={totalEarnings} />}</td>
                                                <td className="text-right"></td>
                                                <td className="text-right"></td>
                                                <td className="text-right"></td>
                                                <td className="text-right font-weight-bold">{<Dollars value={totalPoints} />}</td>
                                            </tr>
                                        </>
                                    ) : (
                                        <td colSpan="8">No data found.</td>
                                    )}
                                </tbody>
                            </>
                        )}
                    </>
                ) : (
                    <tbody>
                        <tr>
                            <td><Loading /></td>
                        </tr>
                    </tbody>
                )}
            </Table>
        </Card>
    )
}
