import { useState } from "react"
import { useAuth } from "../../../../../context/AuthContext"
import EditIssue from "../EditIssue/EditIssue"


const EditIssueWrapper = ({ issueId }) => {
    const auth = useAuth()
    const [manager, setManager] = useState(auth.user)

    document.title = `Edit Issue: ${issueId}`

    const handleClose = () => { }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Edit Issue</h1>
            </div>
            <div>
                <EditIssue issueId={issueId} handleClose={handleClose} manager={manager} type="page" />
            </div>
        </div>
    )
}

export default EditIssueWrapper