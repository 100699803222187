import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { FaCopy, FaPaperPlane } from 'react-icons/fa'

export const MailingsModal = ({ showSystemMessage, isOpen, setIsOpen, mailing, isCreate, deleteMailing, refetch }) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const [testRecipients, setTestRecipients] = useState('')

    const [formData, setFormData] = useState({
        mailing_id: null,
        delay_days: 0,
        from_group: 'S',
        subject: '',
        body: '',
    })

    useEffect(() => {
        if (isCreate) {
            // Initialize empty editor
            setEditorState(EditorState.createEmpty())

            setFormData({
                mailing_id: null,
                delay_days: 0,
                from_group: 'S',
                subject: '',
                body: '',
            })
        } else {
            // Create editor with content
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(mailing.body))))

            setFormData(mailing)
        }
    }, [isCreate, mailing])

    const handleTest = async () => {
        let rawContentState = convertToRaw(editorState.getCurrentContent())

        let convertedContent = draftToHtml(rawContentState)

        formData.body = convertedContent

        let data = {
            from: 'testmail@maxbounty.com',
            subject: formData.subject,
            content: formData.body,
            recipients: testRecipients,
        }

        let testcall = await callAPI('batchmail/test', 'POST', data)

        if (!testcall.success) {
            showSystemMessage('error', testcall.errors.message)
        } else {
            showSystemMessage('success', `Test email sent to: ${testRecipients}`)
        }

        return
    }

    const copyToClipboard = (content) => {
        navigator.clipboard.writeText(content)
    }

    const validateForm = () => {
        if (!(formData.delay_days > 0)) {
            showSystemMessage('error', 'Delay Days must be greater than 0')
            return false
        } else if (formData.subject === '') {
            showSystemMessage('error', 'Subject Line cannot be empty')
            return false
        } else if (formData.body === '') {
            showSystemMessage('error', 'Body cannot be empty')
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        let rawContentState = convertToRaw(editorState.getCurrentContent())

        let convertedContent = draftToHtml(rawContentState)

        formData.body = convertedContent

        if (validateForm()) {
            if (isCreate) {
                const result = await callAPI('marketing/affiliates/mailers', 'POST', formData)
                if (result.success) {
                    showSystemMessage('success', 'Mailing created successfully')
                    setIsOpen(false)
                    refetch()
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            } else {
                const result = await callAPI(`marketing/affiliates/mailers/${formData.mailing_id}`, 'PUT', formData)
                if (result.success) {
                    showSystemMessage('success', 'Mailing updated successfully')
                    setIsOpen(false)
                    refetch()
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            }
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Add New Mailing' : 'Edit Mailing'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center mt-2">
                    <span className="w-50">Mailing Day after signup:</span>
                    <Form.Control type="number" className="w-100" value={formData.delay_days} onChange={(event) => setFormData((prev) => ({ ...prev, delay_days: event.target.value }))} />
                </div>
                <div className="d-flex align-items-center mt-2">
                    <span className="w-50">From:</span>
                    <Form.Control as="select" className="w-100" value={formData.from_group} onChange={(event) => setFormData((prev) => ({ ...prev, from_group: event.target.value }))}>
                        <option value="S">MaxBounty Support</option>
                        <option value="A">MaxBounty Affiliates</option>
                        <option value="P">MaxBounty PayChange</option>
                        <option value="M">Affiliate Manager</option>
                        <option value="H">Adam Harrison</option>
                    </Form.Control>
                </div>
                <div className="d-flex align-items-center mt-2">
                    <span className="w-50">Subject Line:</span>
                    <Form.Control type="text" className="w-100" value={formData.subject} onChange={(event) => setFormData((prev) => ({ ...prev, subject: event.target.value }))} />
                </div>
                {/* <div className="d-flex align-items-center mt-2">
                    <span className="w-50">Body:</span>
                    <Form.Control as="textarea" rows={10} className="w-100" value={formData.body} onChange={(event) => setFormData((prev) => ({ ...prev, body: event.target.value }))} />
                </div> */}
                <div className="alert alert-secondary mt-2 rounded border-0 d-inline-block m-0">
                    <strong>Macros</strong>
                    <table>
                        <tr>
                            <td>First Name</td>
                            <td className="pl-3">#firstName#</td>
                            <td className="pl-3">
                                <button className="btn btn-sm btn-warning" onClick={() => copyToClipboard('#firstName#')}>
                                    <FaCopy />
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="mt-2 border p-2 rounded">
                    <Editor editorState={editorState} onEditorStateChange={setEditorState} />
                </div>
                <div className="card mt-3">
                    <div className="border-bottom">
                        <div className="p-2 d-flex justify-content-between align-items-center font-weight-bold border-bottom">Live Preview</div>
                        <div className="border p-2 rounded m-2">
                            <div className="mailer-preview-bootstrap-fixes">
                                <div style={{ backgroundColor: '#f4f4f4', margin: '0 !important', padding: '0 !important' }}>
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td bgcolor="#e02527" align="center">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style={{ maxWidth: '600px' }}>
                                                    <tr>
                                                        <td align="center" valign="top" style={{ padding: '20px 10px 20px 10px' }}>
                                                            <a href="http://maxbounty.com" target="_blank">
                                                                <img alt="MaxBounty" src="https://www.maxbounty.com/assets/images/maxbounty-logob-w.png" width="200" style={{ display: 'block', width: '200px', maxWidth: '200px', minWidth: '40px', fontFamily: "'Lato', Helvetica, Arial, sans-serif", color: '#ffffff', fontSize: '18px' }} border="0" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#f4f4f4" align="center" style={{ padding: '0px 10px 0px 10px' }}>
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style={{ maxWidth: '600px', marginTop: '20px' }}>
                                                    <tr>
                                                        <td bgcolor="#ffffff" align="left" style={{ padding: '30px', color: '#666666', fontFamily: "'Inter', 'Inter Vars', 'Lato', Helvetica, Arial, sans-serif", fontSize: '16px', fontWeight: '400', lineHeight: 'auto', borderRadius: '4px' }}>
                                                            <p style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }}></p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#f4f4f4" align="center" style={{ padding: '0px 10px 0px 10px' }}>
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style={{ maxWidth: '600px' }}>
                                                    <tr>
                                                        <td bgcolor="#f4f4f4" align="left" style={{ padding: '30px 30px 20px 30px', color: '#666666', fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: '14px', fontWeight: '400', lineHeight: '18px' }}>
                                                            <p style={{ margin: '0' }}>
                                                                <a href="https://blog.maxbounty.com" target="_blank" style={{ color: '#111111', fontWeight: '700', textDecoration: 'underline' }}>
                                                                    Blog
                                                                </a>{' '}
                                                                &nbsp; - &nbsp;
                                                                <a href="https://www.facebook.com/maxbounty/" target="_blank" style={{ color: '#111111', fontWeight: '700', textDecoration: 'underline' }}>
                                                                    Facebook
                                                                </a>{' '}
                                                                &nbsp; - &nbsp;
                                                                <a href="https://www.instagram.com/maxbountyinc" target="_blank" style={{ color: '#111111', fontWeight: '700', textDecoration: 'underline' }}>
                                                                    Instagram
                                                                </a>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td bgcolor="#f4f4f4" align="left" style={{ padding: '00px 30px 20px 30px', color: '#666666', fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: '14px', fontWeight: '400', lineHeight: '18px' }}>
                                                            <p style={{ margin: '0' }}>
                                                                To unsubscribe, log into your{' '}
                                                                <a href="http://affiliates.maxbounty.com" target="_blank" style={{ color: '#111111', fontWeight: '700', textDecoration: 'underline' }}>
                                                                    dashboard
                                                                </a>{' '}
                                                                to change your communications preferences.
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td bgcolor="#f4f4f4" align="left" style={{ padding: '0px 30px 30px 30px', color: '#666666', fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: '14px', fontWeight: '400', lineHeight: '18px' }}>
                                                            <p style={{ margin: '0' }}>MaxBounty - 1730 St. Laurent Blvd, Ottawa, ON</p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Test recipients separated by comma. Ex: youremail@email.com, support@maxbounty.com"
                                onChange={(e) => {
                                    setTestRecipients(e.target.value)
                                }}
                            ></input>
                            <button className="btn btn-warning ml-2" style={{ width: '100px' }} onClick={handleTest}>
                                <FaPaperPlane /> Send Test
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="warning" size="sm" onClick={handleSubmit}>
                        Create
                    </Button>
                ) : (
                    <Button variant="warning" size="sm" onClick={handleSubmit}>
                        Save
                    </Button>
                )}
                {!isCreate && (
                    <Button variant="danger" size="sm" onClick={() => deleteMailing(formData.mailing_id)}>
                        Delete
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}
