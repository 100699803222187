import callAPI from '../../../../../../helpers/callAPI'

const AppVerifyModal = ({ handleClose, affiliateId, affiliateName, showSystemMessage }) => {
    const submitForm = (event) => {
        event.preventDefault()

        callAPI(`affiliate/${affiliateId}/verifyApplication`, 'PUT')
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Application Verified')
                    handleClose(null, true)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'An error occurred while verifying the application')
            })
    }

    return (
        <div className="card">
            <div className="card-header">Application Verification</div>
            <div className="card-body">
                <form onSubmit={submitForm} method="POST">
                    <table className="table table-card table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td>Are you sure you want to verify {affiliateName}?</td>
                            </tr>
                            <tr>
                                <td>
                                    <button type="submit" className="btn btn-primary">
                                        Verify
                                    </button>
                                    &nbsp; &nbsp;
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    )
}

export default AppVerifyModal
