import MerchantCampaignPullsFull from '../MerchantCampaignPullsFull/MerchantCampaignPullsFull'

const MerchantCampaignPullsWrapper = ({ affiliateId, showSystemMessage }) => {
    // const { affiliateId, campaignId } = useQueryParams()

    document.title = "Review Campaign Request"

    const handleClose = () => { }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertiser/Campaign Pulls</h1>
            </div>
            <div>
                <MerchantCampaignPullsFull affiliateId={affiliateId} showSystemMessage={showSystemMessage} type="page" />
            </div>
        </div>
    )
}

export default MerchantCampaignPullsWrapper