import { useState } from 'react'
import React from 'react'
import { Button, Card, Form, Table } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import KeywordsModal from './KeywordsModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const CampaignKeywords = ({ showSystemMessage }) => {

    document.title = 'Campaign Keywords'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_keywords')

    const [tempValue, setTempValue] = useState('')
    const [tempId, setTempId] = useState('')
    const handleInputChange = (itemId, e) => {
        setTempId(itemId)
        setTempValue(e.target.value)
    }

    const [isOpen, setIsOpen] = useState(false)
    const { data, setData, dataLoaded, refetch } = useCallApi(`campaigns/keywords`, 'GET', {}, [], showSystemMessage)

    const updateKeyword = async (keywordId, keyword) => {
        const response = await callAPI(`campaigns/updateKeyword/${keywordId}`, 'PUT', { keyword })
        if (response.success) {
            refetch()
            showSystemMessage('success', 'Keyword updated successfully')
        } else {
            showSystemMessage('danger', 'Error updating keyword')
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'keyword_id',
            },
            {
                Header: 'Keyword',
                accessor: 'keyword',
                Cell: (tableData) => {
                    return (
                        <div className="d-flex">
                            <Form.Control size="sm" className="mr-2" type="text" value={tableData.data[tableData.row.index].keyword_id === tempId ? tempValue : tableData.data[tableData.row.index].keyword} onChange={(e) => handleInputChange(tableData.data[tableData.row.index].keyword_id, e)} autoFocus={tableData.data[tableData.row.index].keyword_id === tempId} />
                            <Button variant="warning" onClick={() => updateKeyword(tableData.data[tableData.row.index].keyword_id, tempValue)} size="sm">
                                Update
                            </Button>
                        </div>
                    )
                },
            },
            {
                Header: () => <div className="text-right">Campaign Count</div>,
                className: 'text-right',
                accessor: 'offer_cnt',
            },
        ]
        return (
            <>
                <ReportTable columns={columns} data={data.keywordsList} />
            </>
        )
    }

    return (
        <div>           
            <div className="row">
                <div className="col-lg-6">
                    <Card className="mb-3">
                        <KeywordsModal refetch={refetch} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} />
                        <div className="d-flex justify-content-between p-3 align-items-center">
                            <h1 className="mb-h1-dark">Manage Campaign Keywords</h1>
                            <Button className="ml-2" onClick={() => setIsOpen(true)} variant="warning" size="sm">
                                Create Keyword
                            </Button>
                        </div>
                        {dataLoaded ? data.keywordsList.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default CampaignKeywords
