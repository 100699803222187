import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import useCallApi from '../../../hooks/useCallApi'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import StatusCircle from '../../../templates/StatusCircle/StatusCircle'
import Loading from '../../../templates/UI/Loading/Loading'

const ReportStaffDirectory = ({ showSystemMessage }) => {

    document.title = 'Staff Directory'

    const [mode, setMode] = useState('A')
    const { data, dataLoaded } = useCallApi(`admin/listadmins?mode=${mode}&from=directory`, 'GET', {}, [mode], showSystemMessage)

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'staff_directory_report')

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'admin_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (tableData) => {
                    return (
                        <div
                            className="p-1">
                            {tableData.data[tableData.row.index].full_name}
                        </div>
                    )
                },
            },
            {
                Header: 'Email',
                accessor: 'e_mail',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? value : <span className="text-danger">ROLE NOT SET</span>}</>
                },
            },
            {
                Header: 'Office Phone',
                accessor: 'phone',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Mobile Phone',
                accessor: 'mobile_phone',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Out of office',
                accessor: 'out_of_office',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'Y' ? <div className="ml-2" data-tip="Out of office"><StatusCircle type="danger" /></div> : ''}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={data.adminsList}
                    columns={columns}
                />
            </React.Fragment>
        )
    }

    return (
        <div className="row">
            <div className="col-lg-8">
                <Card>
                    <div className="card-body m-0 p-0">
                        <ReactTooltip />
                        {dataLoaded ? data.adminsList.length === 0 ? <div className="p-3">No admins found.</div> : getReportOutput() : <Loading></Loading>}
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default ReportStaffDirectory
