import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import callAPI from '../../../helpers/callAPI'
import getSourceQuestions from '../../../helpers/getSourceQuestions'

import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import { Button, Modal } from 'react-bootstrap'

const RequestToRunCampaign = ({ affiliateId, showSystemMessage, handleClose }) => {
    const [campaign, setCampaign] = useState({ id: 0, name: '' })
    const [campaignInfo, setCampaignInfo] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)
    const [selectedTrafficType, setSelectedTrafficType] = useState('')
    const [saving, setSaving] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const handleRequest = (e) => {
        e.preventDefault()

        setSaving(true)

        console.log(1)

        let postBody = {
            offer_id: campaign.id,
            estimated_leads: e.target.leads_per_day.value,
            traffic_type: selectedTrafficType,
            description: e.target.description.value,
            source_answer_1: selectedTrafficType === '' ? '' : e.target.source_answer_1.value,
            source_answer_2: selectedTrafficType === '' ? '' : e.target.source_answer_2.value,
            source_answer_3: selectedTrafficType === '' ? '' : e.target.source_answer_3.value,
            custom_creative: e.target.custom_creative.value,
        }

        console.log(2)

        let url = `affiliate/${affiliateId}/campaignRequest`

        callAPI(url, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setSaving(false)
                    handleClose()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSaving(false)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while saving campaign request')
                setSaving(false)
            })
    }

    const handleSaveOffer = (offerId) => {
        setCampaign({ id: offerId, name: '' })
        getCampaignInfo(campaign.id)
    }

    const getCampaignInfo = (offerId) => {
        let url = `campaign/${offerId}/campaignApproval?aid=${affiliateId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setCampaignInfo(result.offerInfo)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => showSystemMessage('error', 'Error while fetching campaign approval info'))
    }

    const handleTrafficTypeChange = (e) => {
        e.preventDefault()
        setSelectedTrafficType(e.target.value)
    }

    const getQuestions = () => {
        return getSourceQuestions(selectedTrafficType)
    }

    const getAnswer2Field = () => {
        if (selectedTrafficType === 'C') {
            return (
                <select name="source_answer_2" className="form-control w-50">
                    <option value="">Select</option>
                    <option value="Direct link">Direct Link</option>
                    <option value="Custom landing page">Custom Landing Page</option>
                </select>
            )
        } else if (selectedTrafficType === 'S') {
            return (
                <select name="source_answer_2" className="form-control w-50">
                    <option value="">Select</option>
                    <option value="Banners">Banners</option>
                    <option value="Text ads">Text ads</option>
                </select>
            )
        } else if (selectedTrafficType === 'R') {
            return (
                <select name="source_answer_2" className="form-control w-50">
                    <option value="">Select</option>
                    <option value="Direct link">Direct Link</option>
                    <option value="Custom landing page">Custom Landing Page</option>
                </select>
            )
        } else if (selectedTrafficType === 'L') {
            return (
                <select name="source_answer_2" className="form-control w-50">
                    <option value="">Select</option>
                    <option value="Paid ads">Paid ads</option>
                    <option value="Profile/fanpage traffic">Profile/fanpage traffic</option>
                </select>
            )
        } else if (selectedTrafficType === 'N') {
            return <input type="text" name="source_answer_2" className="form-control w-50" />
        } else if (selectedTrafficType === 'M') {
            return (
                <select name="source_answer_2" className="form-control w-50">
                    <option value="">Select</option>
                    <option value="InAppAds">In app ads</option>
                    <option value="MobilePops">Mobile pops</option>
                    <option value="MobileDisplay">Mobile display</option>
                    <option value="MobileVideoAds">Mobile video ads</option>
                    <option value="AbstractAds">Abstract ads</option>
                    <option value="PushNotifications">Push notifications</option>
                    <option value="AppWalls">App Walls</option>
                    <option value="OverlayAds">Overlay ads</option>
                </select>
            )
        } else if (selectedTrafficType === 'E') {
            return (
                <select name="source_answer_2" className="form-control w-50">
                    <option value="">Select</option>
                    <option value="AutoResponder">Auto-responder</option>
                    <option value="SoloEmailBlast">Solo e-mail blast</option>
                    <option value="NewsletterAd">Newsletter ad</option>
                </select>
            )
        } else if (selectedTrafficType === 'I') {
            return (
                <select name="source_answer_2" className="form-control w-50">
                    <option value="">Select</option>
                    <option value="Offerwall">Offerwall</option>
                    <option value="IncentiveSite">Incentive site</option>
                </select>
            )
        }
    }

    let formContent = <></>

    if (dataLoaded) {
        if (campaignInfo.status === 'Active') {
            formContent = (
                <>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>This is an Active status campaign, a Request to Run is not required</td>
                    </tr>
                </>
            )
        } else if (campaignInfo.status === 'Hidden') {
            formContent = (
                <>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>This is a hidden campaign. Please speak to the Advertising Manager.</td>
                    </tr>
                </>
            )
        } else if (campaignInfo.override_status === 'A') {
            formContent = (
                <>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Affiliate has an Active campaign override.</td>
                    </tr>
                </>
            )
        } else if (campaignInfo.override_status === 'R') {
            formContent = (
                <>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Affiliate has a Rejected campaign override.</td>
                    </tr>
                </>
            )
        } else {
            formContent = (
                <>
                    <tr>
                        <td>Status:</td>
                        <td>{campaignInfo.status}</td>
                    </tr>
                    <tr>
                        <td>Advertiser Manager:</td>
                        <td>{campaignInfo.mer_mgr_name}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            Fill in this form to request access to this campaign.
                            <br />
                            NOTE: Please answer all questions completely and accurately or your request will be denied.
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>How many leads per day will you send? </td>
                        <td>
                            <input type="text" name="leads_per_day" defaultValue="0" className="form-control w-50" />
                        </td>
                    </tr>

                    <tr>
                        <td>Will you use existing campaign creative or make your own?</td>
                        <td>
                            <select name="custom_creative" className="form-control w-50">
                                <option value="M">Make My Own</option>
                                <option value="E">Existing Creative</option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td>What type of traffic will you use? Traffic types not listed here are NOT ALLOWED.</td>
                        <td>
                            <select className="form-control w-50" onChange={handleTrafficTypeChange}>
                                <option value="">Choose Traffic Type</option>
                                {campaignInfo.flags.contextual_flag === true && <option value="C">Contextual</option>}
                                {campaignInfo.flags.site_traffic_flag === true && <option value="S">Display</option>}
                                {campaignInfo.flags.search_flag === true && <option value="R">Search</option>}
                                {campaignInfo.flags.social_media_flag === true && <option value="L">Social</option>}
                                {campaignInfo.flags.native_flag === true && <option value="N">Native</option>}
                                {campaignInfo.flags.mobile_flag === true && <option value="M">Mobile Ads</option>}
                                {campaignInfo.flags.allow_solo_emails_flag === true && <option value="E">Email</option>}
                                {campaignInfo.flags.allow_incentive_flag === true && <option value="I">Incentive</option>}
                                {campaignInfo.flags.brand_bid_allow_flag === true && <option value="B">Brand Bidding</option>}
                            </select>
                        </td>
                    </tr>

                    {selectedTrafficType !== '' && selectedTrafficType !== 'B' && (
                        <>
                            {' '}
                            <tr>
                                <td>{getQuestions().question1}</td>
                                <td>
                                    <input type="text" className="form-control w-50" name="source_answer_1" />
                                </td>
                            </tr>
                            <tr>
                                <td>{getQuestions().question2}</td>
                                <td>{getAnswer2Field()}</td>
                            </tr>
                            <tr>
                                <td>{getQuestions().question3}</td>
                                <td>
                                    <input type="text" className="form-control w-50" name="source_answer_3" />
                                </td>
                            </tr>
                        </>
                    )}

                    {selectedTrafficType === 'B' && (
                        <tr>
                            <td colSpan={2}>There are no specific questions for this traffic type</td>
                        </tr>
                    )}

                    <tr>
                        <td>Fully describe how you plan to run this campaign:</td>
                        <td>
                            <textarea name="description" cols={65} rows={5} className="form-control" />
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>
                            <button type="submit" className="btn btn-primary">
                                {saving ? spinner : 'Send Request to Advertising Manager'}
                            </button>
                        </td>
                    </tr>
                </>
            )
        }
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Request to Run Campaign for : {affiliateId}</Modal.Title>
            </Modal.Header>
            <div className="card">
                <form onSubmit={handleRequest} method="POST" className="form-group">
                    <div className="card-body p-3">
                        <b>Affiliate ID: </b>
                        {affiliateId}

                        <table className="table table-card table-borderless">
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr className="align-items-center">
                                    <td width="50%">Campaign:</td>
                                    <td className="pt-3 align-items-center d-flex justify-content-between">
                                        <div className="w-75">
                                            <CampaignSearch campaign={{ id: campaign.id, name: campaign.name }} setCampaign={setCampaign} />
                                        </div>
                                        <Button variant="primary" size="sm" onClick={() => handleSaveOffer(campaign.id)}>
                                            Submit
                                        </Button>
                                    </td>
                                </tr>
                                {dataLoaded && formContent}
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default RequestToRunCampaign

//Replace
