import moment from 'moment'
import { Link } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const PayoutInstructions = ({}) => {

    document.title = 'Payout Instructions'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'payout_instructions')

    const { dataLoaded, data } = useCallApi(`admin/performanceCandidateList`, 'GET', {}, [])

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Payout Processing</h1>
            </div>
            <div className="card">
                <div className="p-3">
                    <table className="m-0">
                        <tbody>
                            <tr>
                                <td>
                                    <b>
                                        <u>Weeklies - to be done on Wednesdays</u>
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Step 1 - Generate Payment Files</b>
                                    <br />- If they haven't come via e-mail, or you need to regenerate them, click on{' '}
                                    <a href="process_new_weekly_payments.cfm" target="_blank" className="afflink">
                                        this link
                                    </a>
                                    <br />
                                    <br />
                                    <br />
                                    <b>Step 2 - Payments via Tipalti </b>
                                    <br />
                                    - Upload and process the Tipalti file in the Tipalti interface
                                    <br />
                                    <br />
                                    <br />
                                    <b>Step 3 - Payments via Payoneer </b>
                                    <br />
                                    - Upload and process the Payoneer file in the Payoneer interface
                                    <br />
                                    <br />
                                    <br />
                                    <b>Step 4 - Update Balances</b>
                                    <br />
                                    - Login to SQL Server and get into MaxBounty database in SQL Server Management Studio
                                    <br />- Run the SQL script{' '}
                                    <b>
                                        <u>ONCE</u>
                                    </b>{' '}
                                    sent via e-mail in query window
                                    <br />
                                    <br />
                                    <br />
                                    <b>Step 5 - Send Notices</b>
                                    <br />- Send the{' '}
                                    <Link to={`/affiliates/paymentNotices`} target="_blank">
                                        payment notifications
                                    </Link>
                                    <br />
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td className="smalltext" align="left">
                                    <b>
                                        <u>Mid Month - After Reversals, Before 15th of the month</u>
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Step 1 - Verify Performance Bonuses</b>
                                    <br />
                                    Look through affiliate stats to identify anyone who has qualified for the performance bonus. If they have, manually add the bonus to their account and notify the AM so they can be congratulated.
                                    <br /> Here are the candidates this month:
                                    <br />
                                    <br />
                                    {dataLoaded ? (
                                        data.list &&
                                        data.list.map((item) => {
                                            return (
                                                <>
                                                    <span className="text-small mr-3">-{item.affiliate_id}</span>:
                                                    <Affiliate aid={item.affiliate_id} name={item.name} status={item.status} priority={item.aff_priority} />
                                                    <br />
                                                </>
                                            )
                                        })
                                    ) : (
                                        <Loading />
                                    )}
                                    <br />
                                    <br />
                                    <b>Step 2 - Process Referral Income</b>
                                    <br />
                                    Process the referral income for affiliates by running this procedure in a SQL Server Management Studio window:
                                    <br />
                                    <br />
                                    <font face="courier">
                                        exec process_affiliate_referral '{moment().subtract(1, 'month').startOf('month').format('YYYY/MM/DD')}' , '{moment().subtract(1, 'month').endOf('month').format('YYYY/MM/DD')}'
                                    </font>
                                    <br />
                                    <br />
                                    <br />
                                    <b>Step 3 - Set Payment Flags</b>
                                    <br />
                                    Run the script to set the Received Payment Flag in a SQL Server Management Studio window
                                    <br />
                                    <font face="courier">exec set_paymentreceived_flag</font>
                                    <br />
                                    <br />
                                    <br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PayoutInstructions
