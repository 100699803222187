import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import CampaignSearch from '../../../../../components/CampaignSearch/CampaignSearch'
import callAPI from '../../../../../helpers/callAPI'
import CustomButton from '../../../../../templates/UI/CustomButton/CustomButton'
import Loading from '../../../../../templates/UI/Loading/Loading'

const EditPopularCampaign = (props) => {
    const [dataLoaded, setDataLoaded] = useState(true)
    const [popularId, setPopularId] = useState()
    const [active, setActive] = useState('Y')
    const [campaign, setCampaign] = useState()
    const [submitting, setSubmitting] = useState(false)

    const { showSystemMessage, campaignId, handleClose } = props

    let createCampaign = false

    if (campaignId === 0) {
        createCampaign = true
    }

    useEffect(() => {
        if (createCampaign) {
            setDataLoaded(true)
            return
        }

        getPopularCampaignInfo()
    }, [])

    const getPopularCampaignInfo = () => {
        let url = `campaigns/popular/${campaignId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setActive(result.status === 'Active' ? 'Y' : 'N')
                    setPopularId(result.id)
                    setCampaign({ id: result.campaign.id, name: result.campaign.name })
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching popular campaign info.')
            })
    }

    const submitForm = (actionType) => {
        setSubmitting(true)

        if (campaign.id === 0 || !campaign.id) {
            showSystemMessage('error', 'You must choose a campaign')
            return
        }

        if (actionType === 'create' || actionType === 'save') {
            let url = `campaigns/popular/${campaign.id}`

            let postBody = {
                actionType: actionType,
                active: active,
                popularId: actionType !== 'create' ? popularId : 0,
            }

            callAPI(url, 'POST', { campaignInfo: postBody })
                .then((result) => {
                    if (result['success'] === true) {
                        showSystemMessage('success', result.message)
                        handleClose(true)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                        setSubmitting(false)
                    }
                })
                .catch((error) => {
                    console.log(`Error while adding popular campaign: ${error}`)
                    showSystemMessage('error', 'Connection Error')
                    setSubmitting(false)
                })
        } else if (actionType === 'delete') {
            let url = `campaigns/popular/${campaign.id}`

            let postBody = {
                popularId: popularId,
            }

            callAPI(url, 'DELETE', { campaignInfo: postBody })
                .then((result) => {
                    if (result['success'] === true) {
                        showSystemMessage('success', result.message)
                        handleClose(true)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                        setSubmitting(false)
                    }
                })
                .catch((error) => {
                    console.log(`Error while adding popular campaign: ${error}`)
                    showSystemMessage('error', 'Connection Error')
                    setSubmitting(false)
                })
        }
    }

    const handleActiveChange = (e) => {
        e.preventDefault()
        setActive(e.target.value)
    }

    let buttons

    if (createCampaign) {
        buttons = (
            <CustomButton
                type="button"
                name="Create"
                onClick={() => {
                    submitForm('create')
                }}
                spin={submitting}
            />
        )
    } else {
        buttons = (
            <>
                <CustomButton
                    type="button"
                    name="Save Changes"
                    onClick={() => {
                        submitForm('save')
                    }}
                    spin={submitting}
                />
                <span className="mr-3"></span>
                <CustomButton
                    type="submit"
                    variant="danger"
                    name="Delete"
                    onClick={() => {
                        submitForm('delete')
                    }}
                    spin={submitting}
                />
            </>
        )
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>{createCampaign ? 'Create Popular Campaign' : 'Edit Popular Campaign'}</Modal.Title>
            </Modal.Header>
            <div className="card">
                <div className="card-body p-3">
                    {dataLoaded ? (
                        <div>
                            <div className="row mb-2">
                                <div className="col-3 pt-2">Campaign</div>
                                <div className="col-9">
                                    <CampaignSearch setCampaign={setCampaign} campaign={campaign} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">Active</div>
                                <div className="col-9 row">
                                    <div className="col-4">
                                        <select className="form-control" name="active" onChange={handleActiveChange} required value={active}>
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-3">&nbsp;</div>
                                <div className="col-9">
                                    {buttons}
                                    <button className="btn btn-secondary ml-3" onClick={() => handleClose()}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditPopularCampaign
