import React, { useState, useEffect } from 'react'
import useCallApi from '../../hooks/useCallApi'
import Loading from '../../templates/UI/Loading/Loading'
import moment from 'moment'

export default function MaxMoneyPromoDropdown({ promoId, setPromoId }) {
    // Get the marketing campaigns using useCallAPI
    const { dataLoaded, data, setData, refetch } = useCallApi(`maxmoney`, 'GET', {}, [])

    return dataLoaded ? (
        <select
            className="form-control"
            onChange={(e) => {
                setPromoId(parseInt(e.target.value))
            }}
        >
            <option value=""></option>
            {[...data.ongoing, ...data.future, ...data.past].map((promo) => {
                return (
                    <option value={promo.promo_id}>
                        {promo.promo_name} - {promo.promo_type} - ({moment(promo.start_date).format('YYYY-MM-DD')} - {moment(promo.end_date).format('YYYY-MM-DD')})
                    </option>
                )
            })}
        </select>
    ) : (
        <Loading />
    )
}
