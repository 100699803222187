import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import callAPI from '../../helpers/callAPI'

const ResetPassword = ({ token, showSystemMessage }) => {
    const [formState, setFormState] = useState({ password: '', confirmPassword: '' })
    const [isInvalid, setIsInvalid] = useState(false)
    const [adminId, setAdminId] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleChange = (e, key) => {
        setFormState({ ...formState, [key]: e.target.value })
    }

    const handleFormSubmit = async () => {
        setLoading(true)
        if (formState.password === formState.confirmPassword) {
            let data = await callAPI(`admin/resetpassword`, 'POST', { adminId: adminId, password: formState.password, token: token })

            if (data['success'] == true) {
                showSystemMessage('success', 'Password has been reset successfully')
                setSuccess(true)
            } else {
                if (data['errors'].message) {
                    showSystemMessage('error', data['errors'].message)
                } else {
                    showSystemMessage('error', 'Something went wrong')
                }
            }
        } else {
            showSystemMessage('error', 'Passwords do not match')
        }
        setLoading(false)
    }

    const checkInvalid = async () => {
        const data = await callAPI(`admin/resetpassword?token=${token}`, 'GET')
        if (data['success'] == false) {
            setIsInvalid(true)
        } else if (data['success'] == true) {
            setIsInvalid(false)
            setAdminId(data['admin_id'])
        }
    }

    useEffect(() => {
        checkInvalid()
    }, [])

    return (
        <div className="login-background d-flex">
            <div className="login-content rounded">
                <div className="text-white">
                    <img className="mb-4 rounded login-logo" src={maxbountyLogoSquare} alt="MaxBounty" width="80px" height="80px" />
                    {isInvalid ? (
                        <p>
                            Link invalid. Please submit another reset password request <Link to="/forgotpassword">here</Link>.
                        </p>
                    ) : (
                        <>
                            <p>Password Reset</p>
                            <input
                                value={formState.password}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleFormSubmit()
                                    }
                                }}
                                onChange={(e) => handleChange(e, 'password')}
                                className="form-control login-input-text mb-2"
                                type="password"
                                placeholder="New Password"
                                name="password"
                                disabled={success}
                            />
                            <input
                                value={formState.confirmPassword}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleFormSubmit()
                                    }
                                }}
                                onChange={(e) => handleChange(e, 'confirmPassword')}
                                className="form-control login-input-text mb-2"
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                disabled={success}
                            />
                            {loading ? (
                                <button className="btn btn-primary mt-4 mb-2 w-100" disabled>
                                    <FontAwesomeIcon icon="spinner" spin />
                                </button>
                            ) : (
                                <button onClick={handleFormSubmit} className="btn btn-primary mt-4 mb-2 w-100" disabled={success}>
                                    Submit
                                </button>
                            )}
                        </>
                    )}
                    <Link className="text-secondary" to="/">
                        Back to login page
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
