import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import useCallApi from '../../hooks/useCallApi'

export default function AdvertiserSearch({ advertiser, setAdvertiser, setFormData, order }) {
    /*
        Props:
        1. advertiser OBJECT {id: 0, name: ''}
        2. setAdvertiser FUNCTION - Sets an advertiser object {id: 0, name: ''} in the parent component
        3. setFormData FUNCTION  - Modifies an object from the parent component by adding an advertiser object to it {...object, advertiser: {id:0,  name:''} }
        4. order is used if there are 2 instances of the affiliate search one atop of the other. It is for setting z-indexes. Higher order = higher z value. Use 1, 2, 3...
    */

    // Get the advertisers
    let apiUrl = `advertisers/search?status=A,N,D,G`

    const { dataLoaded, data, refetch, setData } = useCallApi(apiUrl, 'GET', {}, [])
    const [showInput, setShowInput] = useState(advertiser ? false : true)

    useEffect(() => {
        // If the affiliate object toggles between null and an object after the component mounts, show or hide the input
        if (advertiser) {
            setShowInput(false)
        } else {
            setShowInput(true)
        }
    }, [advertiser])

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
    }

    const handleOnHover = (result) => {
        // the item hovered
    }

    const handleOnSelect = (item) => {
        // This can be used to directly modify an advertiser object from the parent
        if (setAdvertiser) {
            setAdvertiser(item)
        }

        // This can be used if the advertiser data needs to be ADDED to a data object from the parent
        if (setFormData) {
            setFormData((prevData) => ({ ...prevData, advertiser: item }))
        }
    }

    const handleOnFocus = () => {}

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>
                    {item.name} ({item.id})
                </span>
            </>
        )
    }

    return (
        <>
            {showInput ? (
                dataLoaded ? (
                    <ReactSearchAutocomplete
                        items={data.search_results}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                        formatResult={formatResult}
                        maxResults={100}
                        fuseOptions={{ keys: ['name', 'id'] }}
                        styling={{
                            height: '27px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            border: '1px solid #ced4da',
                            fontSize: '1rem',
                            zIndex: order ? order * 999 : 999,
                        }}
                    />
                ) : (
                    // Loading
                    <div
                        style={{
                            height: '29px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            border: '1px solid #ced4da',
                            padding: '3px',
                        }}
                    >
                        <FontAwesomeIcon icon="spinner" spin />
                    </div>
                )
            ) : (
                <div className="d-flex align-items-center">
                    <div>
                        {advertiser.name} ({advertiser.id})
                    </div>
                    <div className="pl-3">
                        <button className="btn btn-warning btn-sm" onClick={() => setShowInput(true)}>
                            Change
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
