import moment from 'moment'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Dollars from '../../../templates/Dollars/Dollars'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import { FaExternalLinkAlt } from 'react-icons/fa'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const NewCampaigns = ({ showSystemMessage }) => {
    document.title = 'New Campaigns'
    // const { data, dataLoaded } = useCallApi('admin/vacation', 'GET', {}, [], showSystemMessage)
    const { data, dataLoaded } = useCallApi('advertisers/newCampaigns', 'GET', {}, [], showSystemMessage)

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'campaign.id',
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].advertiser.id} name={tableData.data[tableData.row.index].advertiser.name} />
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign.name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status} />
                },
            },
            {
                Header: 'Launch Date',
                accessor: 'launch_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY-MM-DD')}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'campaign.status',
            },
            {
                Header: 'Rate',
                accessor: 'rate',
            },
            {
                Header: '60d Margin',
                accessor: 'sixty_day_margin',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: '7d Margin',
                accessor: 'seven_day_margin',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: '1d Margin',
                accessor: 'one_day_margin',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: 'Preview',
                accessor: 'preview_url',
                Cell: ({ cell: { value } }) => {
                    return (
                        <Link to={{ pathname: value }} target="_blank">
                            Preview &nbsp;
                            <FaExternalLinkAlt color="#007bff" />
                        </Link>
                    )
                },
            },
        ]

        return <ReportTable columns={columns} data={data.campaigns} />
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">New Campaigns</h1>
            </div>
            <Card>
                <Card.Header>
                    <h1 className="mb-h1-dark">{dataLoaded ? data.campaigns.length : ''} Campaigns launched in the last 60 days</h1>
                </Card.Header>
                {dataLoaded ? data.campaigns.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
            </Card>
        </div>
    )
}

export default NewCampaigns
