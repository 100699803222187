import React, { useState, useEffect } from 'react'
import useCallApi from '../../hooks/useCallApi'

export default function CampaignApprovalQuestionsSelect({ questionId, setQuestionId }) {
    let apiUrl = `campaigns/approvalQuestions`

    const { dataLoaded, data } = useCallApi(apiUrl, 'GET', {}, [])

    return (
        <select
            name="campaign_approval_questions"
            className="form-control form-control-sm"
            value={questionId}
            onChange={(e) => {
                setQuestionId(parseInt(e.target.value))
            }}
        >
            <option value=""></option>
            {dataLoaded &&
                data.approval_questions &&
                data.approval_questions.map((question) => {
                    return (
                        <option key={question.question_id} value={question.question_id}>
                            {question.question_text}
                        </option>
                    )
                })}
        </select>
    )
}
