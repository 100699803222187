import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Loading from '../../../templates/UI/Loading/Loading'
import Pill from '../../../templates/UI/Pill/Pill'
import callAPI from '../../../helpers/callAPI'
import AffiliateContactInfo from '../../../pages/Affiliate/AffiliateInfo/AffiliateContactInfo/AffiliateContactInfo'
import AffiliateStats from '../../../pages/Affiliate/AffiliateInfo/AffiliateStats/AffiliateStats'
import moment from 'moment'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import AffiliatePaymentInfo from '../../../pages/Affiliate/AffiliateInfo/AffiliatePaymentInfo/AffiliatePaymentInfo'

const AffiliateInformation = ({ affiliateId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliateInfo, setAffiliateInfo] = useState([])

    useEffect(() => {
        getAffiliateInfo()
    }, [affiliateId])

    const getAffiliateInfo = () => {
        let url = `affiliate/${affiliateId}/summary`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setAffiliateInfo(result.affiliate_details)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while fetching affiliate info')
            })
    }

    return (
        <div className="card mb-3">
            <div className="card-header">Affiliate Info</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    <div>
                        <div className="d-flex border-bottom justify-content-between">
                            <div className="d-flex align-items-center p-2">
                                <h5 className="font-weight-bold m-0 p-0">
                                    <Affiliate name={affiliateInfo.name} aid={affiliateId} status={affiliateInfo.status} priority={affiliateInfo.aff_priority} />
                                </h5>
                                <div className="ml-1">({affiliateId})</div>
                                <div className="float-left text-secondary"> &nbsp;/ {affiliateInfo.manager_name}</div>                            
                            </div>
                            <div className="p-2">
                                <span>Registered: {moment(affiliateInfo.status_date).utc().format('YYYY-MM-DD')}</span>                                
                            </div>
                        </div>                        
                        <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
                            <div>
                                <Pill text={affiliateInfo.status} />
                                <Pill text={affiliateInfo.aff_priority} borderColor={affiliateInfo.aff_priority === 'High' ? '#17a2b8' : affiliateInfo.aff_priority === 'Medium' ? '#c79706': affiliateInfo.aff_priority === 'Low' || affiliateInfo.aff_priority === 'Very Low' ? '#dc3545' : '#343a40'} textColor={affiliateInfo.aff_priority === 'High' ? '#17a2b8' : affiliateInfo.aff_priority === 'Medium' ? '#c79706': affiliateInfo.aff_priority === 'Low' || affiliateInfo.aff_priority === 'Very Low' ? '#dc3545' : '#343a40'} />
                            </div>
                            <div className="align-middle text-secondary text-center" style={{ maxWidth: '250px' }}>
                                {affiliateInfo.state}, {affiliateInfo.country}
                                <br></br>
                                {affiliateInfo.time_zone} ({affiliateInfo.contact_time.text})
                            </div>
                            <div>
                                {affiliateInfo.is_network_flag == 'N' ? <Pill text="Network" /> : ''}
                                {affiliateInfo.incentive_flag == 'N' ? <Pill text="Incentive" /> : ''}
                            </div>
                        </div>
                        <AffiliateContactInfo imType={affiliateInfo.im_type} imHandle={affiliateInfo.im_handle} mobile={affiliateInfo.mobile_phone} email={affiliateInfo.e_mail} url={affiliateInfo.url} showSystemMessage={showSystemMessage} />
                        <AffiliatePaymentInfo payment={affiliateInfo.payment} />
                        <AffiliateStats affiliateId={affiliateId} />                        

                        {affiliateInfo.extra_info && <div className="text-secondary font-italic p-2 border-top text-wrap">"{affiliateInfo.extra_info}"</div>}

                        <div className="d-flex justify-content-between p-2 border-top">
                            <Link to={`/affiliate/${affiliateId}/fraudcheck`}>Fraud</Link>
                            <Link to={`/affiliate/${affiliateId}/clickReferrers?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`}>Referring URLs</Link>
                            <Link to={`/reports/stats/conversions?affiliateId=${affiliateId}&navigation=affiliate`}>Conversions</Link>
                            <Link to={`/affiliate/${affiliateId}/leadUserAgents?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`}>Lead Agents</Link>
                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default AffiliateInformation
