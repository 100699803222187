import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Sun, Moon } from 'react-feather'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import styles from './TeamMemberCard.module.scss'
import Dollars from '../Dollars/Dollars'
import Number from '../Number/Number'
import StatusCircle from '../StatusCircle/StatusCircle'
import ReactTooltip from 'react-tooltip'

const TeamMemberCard = (props) => {
    const { manager, teamType } = props

    let adminImage = manager.admin.image

    let dashboard

    if (teamType === 'affiliate') {
        dashboard = manager.admin.role === 'AM_SENIOR' ? 'seniorAM' : manager.admin.role === 'AM_JUNIOR' ? 'juniorAM' : 'juniorAMTeamLead'
    } else if (teamType === 'advertiser') {
        if (manager.admin.role === 'ADOPS_TEAMLEAD') {
            dashboard = 'adopsTeamlead'
        } else if (manager.admin.role === 'ADOPS_MANAGER') {
            dashboard = 'adops'
        } else {
            dashboard = 'advertiserMgr'
        }
    } else if (teamType === 'compliance') {
        if (manager.admin.role === 'COMPLIANCE') {
            dashboard = 'compliance'
        } else if (manager.admin.role === 'COMPLIANCE_DIRECTOR') {
            dashboard = 'complianceDirector'
        } else {
            dashboard = 'compliance'
        }
    } else if (teamType === 'bizdev') {
        if (manager.admin.role === 'BIZDEV') {
            dashboard = 'bizdev'
        } else if (manager.admin.role === 'BIZDEV_DIRECTOR') {
            dashboard = 'bizdev'
        } else {
            dashboard = 'bizdev'
        }
    }

    if (adminImage === '' || adminImage === 'TBD' || adminImage === 'NA' || !adminImage || adminImage === 'test') {
        adminImage = 'noImage.jpg'
    }

    return (
        <tr>
            <td className="border-right">
                <div className="d-flex justify-content-between align-items-center">
                    <ReactTooltip />
                    <div>
                        <Link to={`/dashboard/${dashboard}?adminId=${manager.admin.id}`}>
                            <button className="btn btn-sm btn-link" type="button">
                                <img className={styles.headshot} src={`${process.env.REACT_APP_RESOURCE_URI}/images/headshots/${adminImage}`} alt={`${manager.admin.name}`} />
                            </button>
                            {manager.admin.name}
                        </Link>
                        {manager.admin.role === 'AM_SENIOR' ? ' (Sr)' : manager.admin.role === 'AM_JUNIOR' ? ' (Jr)' : manager.admin.role === 'AM_JR_TEAMLEAD' ? ' (Jr TL)' : ''}
                        {(manager.admin.role === 'ADOPS_TEAMLEAD' || manager.admin.role === 'ADOPS_MANAGER') && <span className="ml-2">(Adops)</span>}
                    </div>
                    <div className="d-flex">
                        {
                            manager.vacation_status === 'Y' &&
                            <div className="mr-2 pr-1" data-tip="On Vacation">🌴</div>
                        }
                        {manager.out_of_office === 'Y' && (
                            <div data-tip="Out of office">
                                <StatusCircle type="danger" />
                            </div>
                        )}
                    </div>                    
                </div>
            </td>
            {teamType === 'advertiser' || (teamType === 'affiliate' && manager.admin.role === 'AM_SENIOR') ? (
                <td className="border-right">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div>Today</div>
                            <div>
                                <span className="mb-element active">
                                    <span className="name">
                                        <Dollars bold value={manager.margin ? manager.margin.today : 0} />
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div>
                            <div>Yesterday</div>
                            <div>
                                <span className="mb-element active">
                                    <span className="name">
                                        <Dollars bold value={manager.margin ? manager.margin.yesterday : 0} />
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </td>
            ) : teamType === 'affiliate' && (manager.admin.role === 'AM_JUNIOR' || manager.admin.role === 'AM_JR_TEAMLEAD') ? (
                <td className="border-right">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div>
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">This Month</Tooltip>}>
                                    <span>H/M Transfers</span>
                                </OverlayTrigger>
                            </div>
                            <div>
                                <span className="mb-element purple-highlight font-weight-bold">
                                    <span className="name">{manager.stats.high_medium_transfers_this_month}</span>
                                </span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Today</Tooltip>}>
                                    <span>Approved</span>
                                </OverlayTrigger>
                            </div>
                            <div>
                                <span className="mb-element purple-highlight font-weight-bold">
                                    <span className="name">{Intl.NumberFormat('en-GB').format(manager.stats.approved_applications_today)}</span>
                                </span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">30 days</Tooltip>}>
                                    <span>Affs with leads</span>
                                </OverlayTrigger>
                            </div>
                            <div>
                                <span className="mb-element purple-highlight font-weight-bold">
                                    <span className="name">{Intl.NumberFormat('en-GB').format(manager.stats.affiliates_with_leads)}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </td>
            ) : (
                ''
            )}
            <td className="border-right">
                <div className="d-flex align-items-center">
                    <div className="d-flex mx-4">
                        <div className="mr-2 text-primary font-weight-bold">
                            <Link to={teamType === 'affiliate' ? `/affiliates/followups?adminId=${manager.admin.id}` : `/advertisers/followups?adminId=${manager.admin.id}`}>{manager.followups}</Link>
                        </div>
                        {teamType === 'advertiser' ? <Link to={`/advertisers/followupSummaryReport?adminId=${manager.admin.id}`}>Followups</Link> : <Link to={`/affiliates/followupSummaryReport?adminId=${manager.admin.id}`}>Followups</Link>}
                    </div>
                    <div className="d-flex mx-4">
                        <div className="mr-2 font-weight-bold">{teamType === 'affiliate' ? <Link to={`/affiliates/pitchReport?adminId=${manager.admin.id}`}>{manager.pitches}</Link> : teamType === 'bizdev' || teamType === 'advertiser' ? <Link to={`/advertisers?adminId=${manager.admin.id}&status=A`}>{manager.advertisers}</Link> : <></>}</div>
                        <div>{teamType === 'affiliate' ? 'Pitches' : teamType === 'advertiser' || teamType === 'bizdev' ? 'Advertisers' : ''}</div>
                    </div>
                    {teamType === 'bizdev' && (
                        <div className="d-flex mx-4">
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">New Lead, Discussion, Negotiation, Active</Tooltip>}>
                                <div className="d-flex align-items-center">
                                    <div className="border rounded mr-2 d-flex">
                                    <div className="text-center">
                                            <div className="border-bottom px-2 font-weight-bold">{manager.advertiser_statuses.new_lead}</div>
                                            <div className="px-2">L</div>
                                        </div>
                                        <div className="border-right text-center">
                                            <div className="border-bottom px-2 font-weight-bold">{manager.advertiser_statuses.discussion}</div>
                                            <div className="px-2">D</div>
                                        </div>
                                        <div className="border-right text-center">
                                            <div className="border-bottom px-2 font-weight-bold">{manager.advertiser_statuses.negotiation}</div>
                                            <div className="px-2">N</div>
                                        </div>
                                        <div className="border-right text-center">
                                            <div className="border-bottom px-2 font-weight-bold">{manager.advertiser_statuses.active}</div>
                                            <div className="px-2">A</div>
                                        </div>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </div>
                    )}
                    {teamType === 'bizdev' && (
                        <div className="d-flex mx-4">
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Today, Yesterday, 7 Days, 30 Days</Tooltip>}>
                                <div className="d-flex align-items-center">
                                    <div className="border rounded mr-2 d-flex">
                                        <div className="border-right text-center">
                                            <div className="border-bottom px-2 font-weight-bold">{manager.added.today}</div>
                                            <div className="px-2">T</div>
                                        </div>
                                        <div className="border-right text-center">
                                            <div className="border-bottom px-2 font-weight-bold">{manager.added.yesterday}</div>
                                            <div className="px-2">Y</div>
                                        </div>
                                        <div className="border-right text-center">
                                            <div className="border-bottom px-2 font-weight-bold">{manager.added.sevenDay}</div>
                                            <div className="px-2">W</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="border-bottom px-2 font-weight-bold">{manager.added.thirtyDay}</div>
                                            <div className="px-2">M</div>
                                        </div>
                                    </div>
                                    <div>Accounts Added</div>
                                </div>
                            </OverlayTrigger>
                        </div>
                    )}
                    {teamType === 'bizdev' && (
                    <div className="d-flex mx-4">
                        <div className="mr-2">
                            <span className="font-weight-bold"><Number value={manager.close_rate*100} decimals={2} />%</span> ({manager.total_advertisers_who_became_active}/{manager.total_advertisers})
                        </div>
                        <div>Close Rate</div>
                    </div>
                    )}
                    <div className="d-flex mx-4">
                        <div className="mr-2 text-primary font-weight-bold">
                            <Link to={`/admin/${manager.admin.id}/pageViews?startDate=${moment().format('YYYY-MM-DD')}`}>{manager.page_views}</Link>
                        </div>
                        <div>Page Views</div>
                    </div>
                </div>
            </td>
            <td>
                <div>
                    <Sun size={20} className="pr-2 font-weight-bold" color="#EEBC1D" fill="#EEBC1D" data-tip="First Page View Yesterday" />
                    {manager.activity.first_page_view_today ? moment.utc(manager.activity.first_page_view_today).format('HH:mm') : '-'}
                </div>
                <div>
                    <Moon size={20} className="pr-2 font-weight-bold" color="#EEBC1D" fill="#EEBC1D" data-tip="Last Page View Yesterday" />
                    {manager.activity.last_page_view_today ? moment.utc(manager.activity.last_page_view_today).format('HH:mm') : '-'}
                </div>
            </td>
            {(teamType === 'affiliate' || teamType === 'advertiser' || teamType === 'bizdev') && <td className="text-center border-left">{teamType === 'affiliate' ? <Link to={`/affiliates/top?adminId=${manager.admin.id}`}>View</Link> : teamType === 'advertiser' || teamType === 'bizdev' ? <Link to={`/campaigns/top?adminId=${manager.admin.id}`}>View</Link> : <></>}</td>}
        </tr>
    )
}

export default TeamMemberCard
