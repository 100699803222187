import { useState } from 'react'
import { Modal } from 'react-bootstrap'

import CampaignSearch from '../../../../components/CampaignSearch/CampaignSearch'
import callAPI from '../../../../helpers/callAPI'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'

const AddHighlightedCampaignModal = ({ isOpen, handleClose, mgrId, showSystemMessage }) => {
    const [formData, setFormData] = useState({})
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitting(true)

        const putBody = {
            mgrId: mgrId,
            campaignId: formData.campaign.id,
        }

        callAPI(`campaigns/highlighted`, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    handleClose(true)
                } else if (result['success'] === false) {
                    console.log('error message: ', result.errors.message)
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                    return
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error adding highlighted campaign')
                setSubmitting(false)
                return
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Highlighted Campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td width="30%">Select Campaign:</td>
                                <td>
                                    <CampaignSearch setFormData={setFormData} />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <CustomButton name="Save" variant="primary" type="submit" spin={submitting} disabled={Object.keys(formData).length === 0} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddHighlightedCampaignModal
