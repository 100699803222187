import moment from 'moment';
import { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useAuth, useProtectedPage } from '../../context/AuthContext';
import { SystemMessageContext } from "../../context/SystemMessageContext";
import useCallApi from '../../hooks/useCallApi';
import useDateRangeFilter from '../../hooks/useDateRangeFilter';
import ReportTable from '../../templates/ReportTable/ReportTable';
import Loading from '../../templates/UI/Loading/Loading';
import DateRangeFilter from '../Reports/Filters/DateRangeFilter/DateRangeFilter';

const ApiLogs = ({ defaultSourceName = '' }) => {

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'api_logs')

    document.title = 'Api Logs'
    const [sourceName, setSourceName] = useState(defaultSourceName);
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter("month")

    const { showSystemMessage } = useContext(SystemMessageContext);
    const { data, dataLoaded, refetch } = useCallApi(`apiLogs?sourceName=${sourceName}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`, "GET", {}, []);

    const { data: sourcesdata, dataLoaded: sourcesDataLoaded } = useCallApi("apiLogs/sources", "GET", {}, []);

    const getReportOutput = () => {
        let columns = [

            {
                Header: 'Inf. ID',
                accessor: 'infrastructure_id'
            },
            {
                Header: 'Source',
                accessor: 'source'
            },
            {
                Header: 'File Name',
                accessor: 'filename'
            },
            {
                Header: 'Identifier',
                accessor: 'identifier'
            },            
            {
                Header: 'Message',
                accessor: 'message'
            },
            {
                Header: 'Admin ID',
                accessor: 'admin_id'
            },
            {
                Header: 'Aff. ID',
                accessor: 'affiliate_id'
            },
            {
                Header: 'Offer ID',
                accessor: 'offer_id'
            },
            {
                Header: 'Adv. ID',
                accessor: 'merchant_id'
            },
            {
                Header: 'Date',
                //no accessor so that filter can search job id's
                //accessor: 'db_date'
                Cell: ({ row }) => {
                    return moment(row.original.db_date).utc().format('YYYY-MM-DD HH:mm:ss')
                }
            },
            {
                Header: 'Audit ID',
                accessor: 'audit_id'
            }

        ];
        return (
            <>
                <ReportTable data={data.logs} columns={columns} />
            </>
        )
    }

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">System</h1>
            </div>
            <Card className="mb-3 col-lg-10 pr-0 pl-0">
                <Card.Header className="d-flex justify-content-start align-items-center">
                    <span className="mr-2">Api Error Logs</span>
                    <>
                        <div className="mr-4">
                            { sourcesDataLoaded &&
                                <select className='form-control form-control-sm' onChange={(e)=>setSourceName(e.target.value)} defaultValue={sourceName !== '' && sourceName}>
                                    <option value="">All Sources</option>
                                    {
                                        sourcesdata.sources.map(item => {
                                            return <option value={`${item}`}>{item}</option>
                                        })
                                    }
                                </select>  
                            }                                                      
                        </div>
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <div className="d-flex align-items-center">
                            <button onClick={refetch} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                    </>
                </Card.Header>
                <div className="table-responsive">

                    {/* {dataLoaded && data.success && getReportOutput()} */}

                    {dataLoaded ? data.logs.length === 0 ? <div className='p-3'>No data for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </Card>
        </>
    );
}

export { ApiLogs };
