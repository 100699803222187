import React, { Component } from 'react'

class ExtraParamItem extends Component {
    state = {}
    render() {
        const { paramId, fieldName, value } = this.props

        return (
            <tr>
                <td valign="middle">Extra Parameter:</td>
                <td>
                    <div className="d-flex">
                        <input className="form-control m-0 d-inline" type="text" placeholder="Parameter Name" defaultValue={fieldName} onChange={(e) => this.props.handleChangeExtraParam(e, paramId, 'fieldName')} />
                        <input className="form-control m-0 d-inline ml-2" type="text" placeholder="Value" defaultValue={value} onChange={(e) => this.props.handleChangeExtraParam(e, paramId, 'value')} />
                    </div>
                </td>
                <td>
                    <span className="badge badge-danger" style={{ cursor: 'pointer' }} onClick={(e) => this.props.handleRemoveExtraParam(e, paramId)}>
                        X
                    </span>
                </td>
            </tr>
        )
    }
}

export default ExtraParamItem
