import React, { useState, useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';

export default function CopyToClipboard({content, className}){

    const copyToClipboard = (content) => {        
        navigator.clipboard.writeText(content).then(() => {
            return true
        }, () => {
            return false
        });
    }

    let classes = 'btn btn-outline-primary btn-sm'

    if (className){
        classes = className
    }

    return (
        <button className={classes} onClick={() => copyToClipboard(content)}>
            <FaCopy onClick={() => copyToClipboard(content)} />
        </button>
    )
}