import React from 'react'
import { Link } from 'react-router-dom'
import { X } from 'react-feather'

const DrilldownItem = (props) => {
    return (
        <div className="alert alert-primary mx-2">
            {props.for}: {props.forUrl ? <Link to={props.forUrl}>{props.forText}</Link> : props.forText}
            {props.cancelDrilldownUrl && (
                <Link className="ml-2" to={props.cancelDrilldownUrl}>
                    <X className="text-danger" size={20} />
                </Link>
            )}
        </div>
    )
}

export default DrilldownItem
