export default {
    campaignStatuses: [
        {
            value: 'A',
            text: 'Active',
        },
        {
            value: 'P',
            text: 'Pending',
        },
        {
            value: 'T',
            text: 'Testing',
        },
        {
            value: 'S',
            text: 'Submitted',
        },
        {
            value: 'H',
            text: 'Hidden',
        },
        {
            value: 'B',
            text: 'By Request',
        },
        {
            value: 'I',
            text: 'Invisible',
        },
        {
            value: 'C',
            text: 'Cancelled',
        },
    ],
}
