import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import TableContainer from '../TableContainer'
import '../TableContainer.css'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import ReportsMoreAvatar from '../AvatarReportsMore/AvatarReportsMore'
import Dollars from '../../../../templates/Dollars/Dollars'
import callAPI from '../../../../helpers/callAPI'

const ReportDaily = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [property, setProperty] = useState({ propertyId: 0, name: '' })
    const [site, setSite] = useState({ siteId: 0, name: '' })
    const [survey, setSurvey] = useState({ surveyId: 0, name: '' })
    const [affiliate, setAffiliate] = useState({ affiliateId: 0, name: '' })
    const [offer, setOffer] = useState({ offerId: 0, name: '' })
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])

    const { propertyId = 0, siteId = 0, surveyId = 0, affiliateId = 0, offerId = 0, startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            setDataLoaded(false)

            let fetchUrl = `reports/avatar/daily?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&offerId=${offerId}`
            let result = await callAPI(fetchUrl, 'GET')
            if (result['success'] === true && result.report) {
                setDataLoaded(true)
                setProperty({ propertyId: propertyId, name: result.property })
                setSite({ siteId: siteId, name: result.site })
                setSurvey({ surveyId: surveyId, name: result.survey })
                setAffiliate({ affiliateId: affiliateId, name: result.affiliate })
                setOffer({ offerId: offerId, name: result.offer })
                setReport(result.report)
                setTotals(result.totals)
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }, [propertyId, siteId, surveyId, affiliateId, offerId, startDate, endDate])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Property',
                accessor: 'property',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Site',
                accessor: 'site',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Survey',
                accessor: 'survey',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Completions/Starts</div>,
                accessor: 'surveyStats',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                accessor: 'clicks',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{Intl.NumberFormat('en-GB').format(value)}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                accessor: 'conversions',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Link to={`/avatar/reports/conversions?startDate=${tableData.data[tableData.row.index].date}&endDate=${tableData.data[tableData.row.index].date}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}`}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}`}</Link>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Aff leads</div>,
                accessor: 'payableLeads',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{Intl.NumberFormat('en-GB').format(value)}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Cost</div>,
                accessor: 'cost',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars cost value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Revenue</div>,
                accessor: 'revenue',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                accessor: 'sales',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>eCPA</div>,
                accessor: 'eCPA',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>epc</div>,
                accessor: 'epc',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                headerClassName: 'text-right',
                className: 'text-right',
                sortType: 'basic',
                Cell: ({ cell: { value } }) => {
                    return <Dollars bold highlight value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Margin %</div>,
                accessor: 'marginPct',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Contribution %</div>,
                accessor: 'marginContribution',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div className="float-right"></div>,
                accessor: 'leadActionId',
                className: 'text-right',
                Cell: (tableData) => {
                    return (
                        <>
                            <ReportsMoreAvatar avatarAffiliateSummaryReport subid avatarConversionReport avatarEventReport avatarAffiliateReport avatarOfferReport avatarSurveyQuestionReport avatarSurveyResponseReport affiliateId={affiliateId} offerId={offerId} propertyId={propertyId} siteId={siteId} surveyId={surveyId} startDate={tableData.data[tableData.row.index].date} endDate={tableData.data[tableData.row.index].date} />
                        </>
                    )
                },
            },
        ]

        return <TableContainer data={report} totals={totals} columns={columns} />
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {property.propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name}></DrilldownItem>}
                {site.siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name} cancelDrilldownUrl={`/avatar/reports/daily?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=0&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}`}></DrilldownItem>}
                {survey.surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name} cancelDrilldownUrl={`/avatar/reports/daily?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=0&affiliateId=${affiliate.affiliateId}`}></DrilldownItem>}
                {affiliate.affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliateId}`} forText={affiliate.name} cancelDrilldownUrl={`/avatar/reports/daily?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=0`}></DrilldownItem>}
                {offer.offerId > 0 && <DrilldownItem for="Offer" forUrl={`/offer/${offerId}`} forText={offer.name} cancelDrilldownUrl={`/avatar/reports/conversions?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&offerId=0`}></DrilldownItem>}
            </div>
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportDaily
