import { useState } from 'react'
import AdvertiserSearch from '../../../components/AdvertiserSearch/AdvertiserSearch'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import callAPI from '../../../helpers/callAPI'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ReverseLeads = ({ showSystemMessage }) => {

    document.title = 'Batch Reverse Leads'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'compliance_batch_reverse')

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const [campaign, setCampaign] = useState({})
    const [advertiser, setAdvertiser] = useState({})
    const [leadsToBeReversed, setLeadsToBeReversed] = useState(0)
    const [ticketId, setTicketId] = useState(0)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()

        setSubmitting(true)

        let list = e.target.reversal_data.value

        let reversalList = []

        if (list.trim() === '') {
            return
        }

        if (list.includes(',')) {
            reversalList = list.split(',')
        } else if (list.includes('\n')) {
            reversalList = list.split('\n')
        } else {
            reversalList = list.split(' ')
        }

        reversalList = reversalList.map((item) => item.toString().trim())

        let postBody = {
            reversal_list: reversalList,
            subject_line: e.target.subject_line.value,
            priority: e.target.priority.value,
            run_reversals: e.target.run_reversals.checked,
            advertiser_id: advertiser.id,
            campaign_id: campaign.id,
            is_postback: e.target.is_postback.value,
            reversal_type: e.target.reversal_type.value,
            reverse_leads_for: e.target.reverse_leads_for.value,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
        }

        callAPI(`compliance/reverseLeads`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setTicketId(result.ticket_id ? result.ticket_id : 0)
                    setLeadsToBeReversed(result.leads_to_be_reversed ? result.leads_to_be_reversed : 0)
                    {
                        result.ticket_id && showSystemMessage('success', 'Ticket created')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while reversing leads')
            })

        setSubmitting(false)
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Batch Reverse Leads</h1>
            </div>
            <div className="card">
                <div className="card-body p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex">
                            {/* <div className="d-flex justify-content-between p-3">
                                <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            </div> */}
                        </div>
                        <input type="hidden" name="is_postback" value="yes" />
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td width="10%">
                                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                    </td>
                                    <td width="15%">
                                        <select name="reverse_leads_for" className="form-control" required>
                                            <option value="">Reverse Leads For</option>
                                            <option value="campaign">Campaign</option>
                                            <option value="advertiser">Advertiser</option>
                                        </select>
                                    </td>
                                    <td className="text-right">Campaign: </td>
                                    <td width="15%">
                                        <CampaignSearch setCampaign={setCampaign} />
                                    </td>
                                    <td className="text-right">Advertiser: </td>
                                    <td width="15%">
                                        <AdvertiserSearch setAdvertiser={setAdvertiser} />
                                    </td>
                                    <td width="20%">
                                        <select name="reversal_type" className="form-control" required>
                                            <option value="">Reverse Leads By</option>
                                            <option value="IP">Lead IP</option>
                                            <option value="LD">Lead Data</option>
                                            <option value="CL">Click ID</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        Copy and paste the data to use to find the leads you wish to reverse in this box (max 1000)
                                    </td>
                                    <td colSpan="3" className="text-center">
                                        Results of Lead Reversal Process
                                    </td>
                                </tr>
                                <tr className="align-top">
                                    <td colSpan="3">
                                        <textarea cols={40} rows={15} name="reversal_data" className="form-control" defaultValue="" required />
                                    </td>
                                    <td colSpan="3" style={{ verticalAlign: 'top!important' }}>
                                        <table className="align-top">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <b>Subject Line:</b>
                                                    </td>
                                                    <td>
                                                        <input name="subject_line" size="80" maxsize="150" placeholder="Email subject" className="form-control" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Priority:</b>
                                                    </td>
                                                    <td>
                                                        <select name="priority" className="form-control w-50">
                                                            <option value="Normal">Normal</option>
                                                            <option value="Urgent">Urgent</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Leads to be reversed:</b>
                                                    </td>
                                                    <td>{leadsToBeReversed}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Ticket ID:</b>
                                                    </td>
                                                    <td>{ticketId === 0 ? 'Not Yet Issued' : ticketId}</td>
                                                </tr>
                                                {ticketId !== 0 && (
                                                    <tr>
                                                        <td colSpan={2}>You will receive an email when your ticket is processed.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="1">
                                        <button type="submit" className="btn btn-primary" disabled={submitting}>
                                            Reverse Leads {submitting && <FontAwesomeIcon icon="spinner" spin />}
                                        </button>
                                    </td>
                                    <td colSpan="2" className="align-items-center">
                                        <input type="checkbox" name="run_reversals" />
                                        &nbsp;Check this box if you are ready to run final reversal
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ReverseLeads
