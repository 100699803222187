import React, { useState, useEffect } from 'react'

import Loading from '../../../../templates/UI/Loading/Loading'

import TopCampaignsOfTheWeek from '../TopCampaignsOfTheWeek/TopCampaignsOfTheWeek'
import OpenIssues from '../OpenIssues/OpenIssues'
import BizdevSummary from '../BizdevSummary/BizdevSummary'
import CreditChecks from '../CreditChecks/CreditChecks'
import BizdevFollowupQueue from '../BizdevFollowupQueue/BizdevFollowupQueue'
import { useAuth, useProtectedPage } from '../../../../context/AuthContext'

export default function BizdevManager({ manager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'bizdev_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Business Development Manager</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <BizdevSummary manager={manager} showSetManager={true} />
                            <BizdevFollowupQueue manager={manager} type="advertiser" />
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-6">
                                    <TopCampaignsOfTheWeek showSystemMessage={showSystemMessage} />
                                    <OpenIssues manager={manager} showSystemMessage={showSystemMessage} />
                                </div>
                                <div className="col-lg-6">
                                    <CreditChecks manager={manager} showSystemMessage={showSystemMessage} />
                                    {/* <div className="card p-3">Campaign Requests</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
