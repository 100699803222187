import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Loading from '../../../../templates/UI/Loading/Loading'
import Pill from '../../../../templates/UI/Pill/Pill'

import getFollowupResult from '../../../../helpers/getFollowupResult'
import '../../../../theme/classes.scss'
import { colors } from '../../../../theme/variables'

const AffiliateFollowupQueue = ({ manager, limit, type }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const data = await callAPI(`affiliates/followups/${manager.id}`, 'GET', null)
            if (data.success) {
                let result = data.affiliates.slice(0, 20)
                if (limit && !isNaN(limit)) {
                    result = data.affiliates.slice(0, limit)
                }
                result.inQueue = data.affiliates.length
                setData(result)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchData()
    }, [manager])

    return (
        <Card className="mb-3 p-0 overflow-auto">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>
                    <span className="mr-1">Affiliate Followup Queue</span>
                </span>
                <span>
                    In Queue <Link to={`/${`affiliates/followups?adminId=${manager.id}`}&startDate=2003-01-01&endDate=${moment().format('YYYY-MM-DD')}`}>{data.inQueue}</Link>
                </span>
            </Card.Header>
            <Card.Body className="p-0">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th>Priority</th>
                            <th>Exp</th>
                            <th>Country</th>
                            <th>Next</th>
                            <th className="text-right">Last</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.map((row) => {
                                            let pastDate = false

                                            let todayDate = moment()
                                            let lastFollowupDate = moment(row.followups.last_followup.followup_date)

                                            let diffDays = todayDate.diff(lastFollowupDate, 'days')

                                            if ((row.affiliate.priority_value === 1 && diffDays > 7) || (row.affiliate.priority_value !== 1 && diffDays > 14)) {
                                                pastDate = true
                                            }

                                            return (
                                                <tr key={row.affiliate.id} className={pastDate ? 'bgDanger25' : null}>
                                                    <td xs={3}>{row.affiliate.id}</td>

                                                    <td xs={6}>
                                                        <span className={`mb-element ${row.affiliate.status === 'C' ? 'cancelled' : null} ${row.affiliate.status <= 2 ? 'highlight' : null}`}>
                                                            <Affiliate aid={`${row.affiliate.id}`} name={`${row.affiliate.name}`} status={`${row.affiliate.status}`} priority={`${row.affiliate.priority}`} nextFollowupDate={row.followups.next_followup_date} />
                                                        </span>
                                                        {(row.affiliate.experience_level.value === 3 || row.affiliate.experience_level.value === 2) && row.affiliate.country_tier === 1 ? <span className="ml-2">⭐</span> : ''}
                                                    </td>

                                                    <td xs={6}>
                                                        <span to="/followup">{row.affiliate.priority}</span>
                                                    </td>

                                                    <td xs={6}>
                                                        <span className={(row.affiliate.experience_level.value === 3 || row.affiliate.experience_level.value === 2) && row.affiliate.country_tier === 1 ? 'text-success font-weight-bold' : ''}>{row.affiliate.experience_level.name}</span>
                                                    </td>

                                                    <td>
                                                        <span className={row.affiliate.country_tier === 1 ? 'text-success font-weight-bold' : ''}>{row.affiliate.country_code}</span>
                                                    </td>
                                                    <td xs={3}>
                                                        <span to="/followup">{moment(row.followups.next_followup_date).utc().format('YYYY-MM-DD HH:mm')}</span>
                                                    </td>
                                                    <td className="text-right" xs={3}>
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="bottom"
                                                            overlay={
                                                                <Popover id="popover-positioned-bottom" arrowprops={{ style: { display: 'none' } }} style={{ minWidth: '300px', maxWidth: '500px' }}>
                                                                    <Popover.Title className="d-flex justify-content-between" style={{ fontSize: '14px' }}>
                                                                        <div>
                                                                            <div className="mr-2" style={{ width: 'max-content' }}>
                                                                                {moment(row.followups.last_followup.followup_date).utc().format('YYYY-MM-DD HH:mm')}
                                                                            </div>
                                                                            <div className="mr-2 text-muted" style={{ width: 'max-content' }}>
                                                                                {row.followups.last_followup.admin.name}
                                                                            </div>
                                                                        </div>
                                                                        <span>
                                                                            <Pill text={getFollowupResult(row.followups.last_followup.result)} borderColor={colors.success} />
                                                                        </span>
                                                                    </Popover.Title>
                                                                    <Popover.Content>{row.followups.last_followup.note}</Popover.Content>
                                                                </Popover>
                                                            }
                                                        >
                                                            <Link to={`/affiliate/${row.affiliate.id}`}>
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </Link>
                                                        </OverlayTrigger>
                                                        {/*  */}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={7} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default AffiliateFollowupQueue
