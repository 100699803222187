import React, { useState, useEffect } from 'react'
import { MoreVertical } from 'react-feather'

import TableContainer from '../TableContainer'
import '../TableContainer.css'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Campaign from '../../../../templates/Campaign/Campaign'
import ReportsMoreAvatar from '../AvatarReportsMore/AvatarReportsMore'
import Dollars from '../../../../templates/Dollars/Dollars'
import callAPI from '../../../../helpers/callAPI'

const ReportConversions = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [property, setProperty] = useState({ propertyId: 0, name: '' })
    const [site, setSite] = useState({ siteId: 0, name: '' })
    const [survey, setSurvey] = useState({ surveyId: 0, name: '' })
    const [affiliate, setAffiliate] = useState({ affiliateId: 0, name: '' })
    const [offer, setOffer] = useState({ offerId: 0, name: '' })
    const [report, setReport] = useState([])

    const { propertyId = 0, siteId = 0, surveyId = 0, affiliateId = 0, offerId = 0, subid1 = '', startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            setDataLoaded(false)

            let fetchUrl = `reports/avatar/conversions?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&offerId=${offerId}&subid1=${subid1 === 'undefined' ? '' : subid1}`

            let result = await callAPI(fetchUrl, 'GET')
            if (result['success'] === true && result.report) {
                setDataLoaded(true)
                setProperty({ propertyId: propertyId, name: result.property })
                setSite({ siteId: siteId, name: result.site })
                setSurvey({ surveyId: surveyId, name: result.survey })
                setAffiliate({ affiliateId: affiliateId, name: result.affiliate })
                setOffer({ offerId: offerId, name: result.offer })
                setReport(result.report)
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }, [propertyId, siteId, surveyId, affiliateId, offerId, subid1, startDate, endDate])

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={report}
                    columns={[
                        {
                            Header: 'Affiliate ID',
                            accessor: 'affiliate.id',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'AffiliateFilter',
                            accessor: 'affiliate.name',
                            hide: true,
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Affiliate',
                            accessor: 'affiliate',
                            Cell: (tableData) => {
                                return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status} priority={tableData.data[tableData.row.index].affiliate.priority} />
                            },
                        },
                        {
                            Header: 'Offer ID',
                            accessor: 'offer.id',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'OfferFilter',
                            accessor: 'offer.name',
                            hide: true,
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Offer',
                            accessor: 'offer',
                            Cell: (tableData) => {
                                return <Campaign oid={tableData.data[tableData.row.index].offer.id} name={tableData.data[tableData.row.index].offer.name} status={tableData.data[tableData.row.index].offer.status} />
                            },
                        },
                        {
                            Header: 'Property',
                            accessor: 'property',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Site',
                            accessor: 'site',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Survey',
                            accessor: 'survey',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Event ID',
                            accessor: 'event.id',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Event',
                            accessor: 'event.name',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Scrub',
                            accessor: 'scrub',
                            Cell: ({ cell: { value } }) => {
                                return <div className={value === 'Yes' ? 'text-danger' : ''}>{value}</div>
                            },
                        },
                        {
                            Header: 'Status',
                            accessor: 'status',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Payout',
                            accessor: 'payoutType',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'SubId1',
                            accessor: 'subId1',
                            Cell: ({ cell: { value } }) => {
                                return <input className="form-control form-control-sm" disabled type="text" style={{ maxWidth: '150px' }} value={value} />
                            },
                        },
                        {
                            Header: 'Data',
                            accessor: 'data',
                            Cell: ({ cell: { value } }) => {
                                return <input className="form-control form-control-sm" disabled type="text" style={{ maxWidth: '150px' }} value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Cost</div>,
                            accessor: 'cost',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars cost value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Revenue</div>,
                            accessor: 'revenue',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Date</div>,
                            accessor: 'date',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right"></div>,
                            accessor: 'leadActionId',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return (
                                    <>
                                        <ReportsMoreAvatar searchId avatarPostReport avatarSurveyResponseReport actionId={tableData.data[tableData.row.index].leadActionId} surveyId={surveyId} clientId={tableData.data[tableData.row.index].clientId} startDate={startDate} endDate={endDate} />
                                    </>
                                )
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="mx-2 alert alert-warning text-small">
                This report provides information on which affiliate brought us earnings from Avatar surveys. Leads here are NOT always payable to the affiliate displayed. For more information, click the <MoreVertical size={20} /> icon and view the actual conversion that was generated.
            </div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {property.propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name}></DrilldownItem>}
                {site.siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name}></DrilldownItem>}
                {survey.surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name}></DrilldownItem>}
                {affiliate.affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliate.affiliateId}`} forText={affiliate.name} cancelDrilldownUrl={`/avatar/reports/conversions?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=0&offerId=${offer.offerId}&subid1=${subid1}`}></DrilldownItem>}
                {offer.offerId > 0 && <DrilldownItem for="Offer" forUrl={`/offer/${offer.offerId}`} forText={offer.name} cancelDrilldownUrl={`/avatar/reports/conversions?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&offerId=0&subid1=${subid1}`}></DrilldownItem>}
                {subid1 !== '' && subid1 !== 'undefined' && <DrilldownItem for="Subid1" forText={subid1} cancelDrilldownUrl={`/avatar/reports/conversions?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&offerId=${offer.offerId}&subid1=`}></DrilldownItem>}
            </div>
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportConversions
