import React, { Component } from 'react'
import callAPI from '../../helpers/callAPI'

class ManagerDropdown extends Component {
    _isMounted = false

    state = {
        managers: [],
        mgrId: '',
    }

    componentDidMount() {
        this._isMounted = true

        //this.props.mgrType ? this.setState({mgrType: this.props.mgrType}) : this.setState({mgrType: ''}) ;
        //console.log(this.props.mgrType)
        // Fetch all managers
        const fetchData = async () => {
            const result = await callAPI(`managers_list?mgrType=${this.props.mgrType}`, 'GET')
            if (result['success'] === true) {
                if (this._isMounted) {
                    this.setState({
                        managers: result.managers,
                    })
                }
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    this.props.showSystemMessage('error', 'An error occurred while fetching managers.')
                }
            } else {
                this.props.showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }

    handleManagerDropdownChange = (event) => {
        event.preventDefault()
        event.target.setCustomValidity('')
        if (this._isMounted) {
            this.setState({ mgrId: event.target.value })
        }
        this.props.mgrSelected(event.target.value)
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        let title

        if (this.props.mgrType === 'merchant') {
            title = 'All MM'
        } else if (this.props.mgrType === 'bizdev') {
            title = 'All BizDev'
        } else if (this.props.mgrType === 'affiliate') {
            title = 'All AM'
        } else if (this.props.mgrType === 'merchantAndBizdev') {
            title = 'All MM & BizDev'
        }
        return (
            <div>
                <div className="mr-2">
                    {/* Lifting State Up to Reports.js */}
                    <select className="custom-select custom-select-sm" onChange={this.handleManagerDropdownChange} value={this.props.mgrId} name="manager" required disabled={this.props.disabled ? this.props.disabled : false}>
                        <option defaultValue value="0">
                            {title}
                        </option>

                        {this.state.managers.map((manager) => (
                            <option value={manager.admin_id} key={manager.admin_id}>
                                {manager.full_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        )
    }
}

export default ManagerDropdown
