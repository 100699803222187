import { Modal } from 'react-bootstrap'

import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'

const DuplicateModal = ({ affiliateId, isOpen, handleClose }) => {
    const { dataLoaded, data } = useCallApi(`affiliate/${affiliateId}/fraud/duplicateApplication`, 'GET', {}, [])

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Duplicate Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>{dataLoaded ? <div className="overflow-auto p-2 min-vh-50" dangerouslySetInnerHTML={{ __html: data.dup_reason }} name="dup_reason"></div> : <Loading />}</Modal.Body>
        </Modal>
        // <div className="card">
        //     <div className="card-header">Duplicate Application</div>
        //     <div className="card-body">{dataLoaded ? <div className="overflow-auto p-2 min-vh-50" dangerouslySetInnerHTML={{ __html: data.dup_reason }} name="dup_reason"></div> : <Loading />}</div>
        // </div>
    )
}

export default DuplicateModal
