import React, { Component } from 'react'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'

class ParamDropdown extends Component {
    state = {
        dataLoaded: false,
        params: [],
    }

    componentDidMount() {
        const fetchData = async () => {
            const result = await callAPI(`avatar/customerParameters`, 'GET')
            if (result['success'] === true) {
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    params: result.parameters,
                })
            } else if (result['success'] === false) {
                this.setState({
                    dataLoaded: true,
                })
            } else {
                this.props.showSystemMessage('error', result.errors.message)
                this.setState({
                    dataLoaded: true,
                })
            }
        }
        fetchData()
    }

    render() {
        return this.state.dataLoaded ? (
            <select name="site-dropdown" className="form-control" defaultValue={this.props.parameterId} onChange={(e) => this.props.handleChangeParam(e, this.props.questionId, this.props.index)}>
                <option></option>
                {this.state.params.length > 0 &&
                    this.state.params.map((param) => {
                        return <option value={param.parameterId}>{param.name}</option>
                    })}
            </select>
        ) : (
            <Loading />
        )
    }
}

export default ParamDropdown
