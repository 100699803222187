import { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import useCallApi from '../../../hooks/useCallApi'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import CampaignNavbar from '../CampaignNavBar/CampaignNavBar'
import AffiliateSearch from '../../../components/AffiliateSearch/AffiliateSearch'
import callAPI from '../../../helpers/callAPI'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const AddLeadsAvatar = ({ campaignId, showSystemMessage }) => {
    // const { dataLoaded, data } = useCallApi(`campaign/${campaignId}`, 'GET', {}, [campaignId])

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'avatar_add_leads')

    const [attributedCampaign, setAttributedCampaign] = useState({ id: -1, name: '' })
    const [affiliate, setAffiliate] = useState({ id: -1, name: '' })
    const [attributedAffiliate, setAttributedAffiliate] = useState({ id: -1, name: '' })
    const [campaign, setCampaign] = useState({})
    const [propertyId, setPropertyId] = useState(0)
    const [rates, setRates] = useState([])
    const [rate, setRate] = useState([])
    const [setInfo, setSetInfo] = useState([])
    const [results, setResults] = useState([])
    const [offerType, setOfferType] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    const [submitting, setSubmitting] = useState('ready')

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    if (campaign.id){
        document.title = `${campaign.name}: Add Leads Avatar`
    } else {
        document.title = 'Add Leads Avatar'
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.persist()

        setSubmitting('submitting')

        if (attributedCampaign.id <= 0) {
            showSystemMessage('error', 'Attributed Campaign ID is missing')
            setSubmitting('ready')
            return
        }

        if (affiliate.id <= 0) {
            showSystemMessage('error', 'Affiliate ID is missing')
            setSubmitting('ready')
            return
        }

        const validated = validate(e)

        if (!validated) {
            e.target.reportValidity()
            setSubmitting('ready')
            return
        }

        let postBody = {
            affiliate_id: affiliate.id,
            attributed_affiliate_id: attributedAffiliate.id,
            attributed_campaign_id: attributedCampaign.id,
            attributed_campaign_name: attributedCampaign.name,
            sale_amount: offerType === 'P' || offerType === 'M' ? e.target.sale_amount.value : 0,
            rate_name: rate,
            offer_type: offerType,
            leads: e.target.lead_count.value,
            property_id: propertyId,
            confirmLeads: e.target.confirm_lead_count.value,
            commissionAmount: offerType === 'C' ? e.target.commission_amount.value : 0,
            surveySet: e.target.survey_set.value,
        }

        callAPI(`campaign/${campaignId}/addLeadsAvatar`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setResults(result.results)
                    setSubmitting('success')        
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting('ready')
                }
            })
            .catch((error) => {
                console.log(`Error while adding avatar leads: ${error}`)
                showSystemMessage('error', 'An error occurred while adding avatar leads')
                setSubmitting('ready')
            })
    }

    useEffect(async () => {
        // get campaign info, property id
        let campaignResult = await callAPI(`campaign/${campaignId}`, 'GET')
        let propertyResult = await callAPI(`campaign/${campaignId}/property`, 'GET')

        if (!campaignResult.success || !propertyResult.success) {
            showSystemMessage('error', 'There was an error while fetching the data. Please try again.')
        }

        if (campaignResult.success) {
            setCampaign(campaignResult.campaign)
        }

        if (propertyResult.success) {
            setPropertyId(propertyResult.property_id)
        }

        setDataLoaded(true)
    }, [campaignId])

    const handleOfferSelection = async (item) => {
        setAttributedCampaign(item)
        setResults([])

        let ratesResult = await callAPI(`campaign/${item.id}/rates`, 'GET')
        let setResult = await callAPI(`campaign/set/${propertyId}`, 'GET')

        if (!ratesResult.success || !setResult.success) {
            showSystemMessage('error', 'There was an error while fetching the data. Please try again.')
        }

        if (ratesResult.success) {
            setRates(ratesResult.rates)
        }

        if (setResult.success) {
            setSetInfo(setResult.set_info)
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        setOfferType(e.target.selectedOptions[0].getAttribute('data_offer_type'))
        setRate(e.target.value)
    }

    const validate = (e) => {
        e.target.confirm_lead_count.setCustomValidity('')

        if (e.target.lead_count.value !== e.target.confirm_lead_count.value) {
            e.target.confirm_lead_count.setCustomValidity('Lead counts must be same')
            return false
        }
        return true
    }

    const getAmountFields = (offerType) => {
        if (offerType === 'P' || offerType === 'M') {
            return (
                <tr>
                    <td>Sale Amount</td>
                    <td>
                        <input className="form-control" name="sale_amount" placeholder={0} required />
                    </td>
                </tr>
            )
        } else if (offerType === 'C') {
            return (
                <>
                    <tr>
                        <td>Sale Amount</td>
                        <td>
                            <input className="form-control" name="sale_amount" placeholder={0} required />
                        </td>
                    </tr>
                    <tr>
                        <td>Commission Amount</td>
                        <td>
                            <input className="form-control" name="commision_amount" placeholder={0} required />
                        </td>
                    </tr>
                </>
            )
        }
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Add Avatar Leads</h1>
                <div className="ml-5">{dataLoaded && <CampaignNavbar campaignId={campaignId} campaignData={campaign} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="d-flex p-3 align-items-center">
                            <h1 className="mb-h1-dark mr-2">Add leads and attribute to Avatar offer: {dataLoaded ? <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : spinner}</h1>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <table className="table table-card m-0 table-borderless">
                                    {dataLoaded ? (
                                        propertyId > 0 ? (
                                            <tbody>
                                                <tr>
                                                    <td width="30%">Campaign</td>
                                                    <td className="w-50">
                                                        <CampaignSearch campaign={attributedCampaign} setCampaign={handleOfferSelection} order={9} />
                                                    </td>
                                                </tr>
                                                {attributedCampaign.id >= 0 && results.length === 0 ? (
                                                    <>
                                                        <tr>
                                                            <td>Payable Affiliate (Avatar Noclick = 573358)</td>
                                                            <td>
                                                                <AffiliateSearch setAffiliate={setAffiliate} order={2} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Attributed Affiliate</td>
                                                            <td>
                                                                <AffiliateSearch setAffiliate={setAttributedAffiliate} order={1} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rate Name</td>
                                                            <td className="w-50">
                                                                <select name="rate_name" className="form-control w-50" onChange={handleChange} required>
                                                                    <option value=""></option>
                                                                    {rates.map((rate) => (
                                                                        <option data_offer_type={rate.offer_type} value={rate.rate_name} key={rate.rate_name}>{`${rate.rate_name} - (${rate.affiliate_rate} / ${rate.network_rate})`}</option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lead Type</td>
                                                            <td>
                                                                <select name="lead_type" className="form-control w-50" required>
                                                                    <option value=""></option>
                                                                    <option value="N">Network</option>
                                                                    <option value="A">Affiliate</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Site/Survey set</td>
                                                            <td className="w-50">
                                                                <select name="survey_set" className="form-control" required>
                                                                    <option value=""></option>
                                                                    {setInfo.map((set) => (
                                                                        <option value={`${set.site_id}-${set.survey_id}`} key={`${set.site_id}-${set.survey_id}`}>
                                                                            Site: {`${set.meta_title} / Survey: ${set.survey_description}`}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Leads to Add</td>
                                                            <td>
                                                                <input className="form-control" name="lead_count" placeholder="0" autoComplete="off" onChange={(e) => e.target.setCustomValidity('')} required />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Confirm Leads to Add</td>
                                                            <td>
                                                                <input type="number" className="form-control" name="confirm_lead_count" placeholder="0" autoComplete="off" onChange={(e) => e.target.setCustomValidity('')} required />
                                                            </td>
                                                        </tr>
                                                        {getAmountFields(offerType)}
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>
                                                                {
                                                                    submitting === 'ready' ?
                                                                        <input className="btn btn-primary" type="submit" />
                                                                    : submitting === 'submitting' ?
                                                                        <button className="btn btn-primary" disabled={true}>Submit Query <FontAwesomeIcon icon="spinner" spin /></button>
                                                                    : submitting === 'success' ?
                                                                        <button className="btn btn-primary" disabled={true}>Submit Query</button>
                                                                    :
                                                                        <input className="btn btn-primary" type="submit" />
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : results.length > 0 ? (
                                                    <div>
                                                        {results.map((result, index) => (
                                                            <div key={index}>
                                                                {result.returnCode === '0' ? <span className="text-success">Lead {index + 1} added successfully </span> : <span className="text-danger">Lead {index + 1} RESULTED IN ERROR</span>}
                                                                <br />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </tbody>
                                        ) : (
                                            <div>Cannot attribute leads to this campaign. You must use the campaign specified in the main Avatar property.</div>
                                        )
                                    ) : (
                                        <Loading />
                                    )}
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddLeadsAvatar
