import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

import callAPI from '../../../helpers/callAPI'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import DownloadCSV from '../../../templates/DownloadCSV/DownloadCSV'

const ReportDormantBalances = ({ showSystemMessage }) => {
    document.title = 'Dormant Balances'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_dormant_balance_report')

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([{}])
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        getReport()
    }, [location])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Recovery Date',
                accessor: 'recovery_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD')}</>
                },
            },
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} priority={tableData.data[tableData.row.index].priority} />
                },
            },
            {
                Header: <div className="text-right">Adjusting Amount</div>,
                accessor: 'adjusting_entry_amount',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: <div className="text-right">Recovery Amount</div>,
                accessor: 'mb_sale_recover_amount',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: 'Notes',
                accessor: 'notes',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} totals={totals} />
            </React.Fragment>
        )
    }

    const refreshReport = (e) => {
        e.preventDefault()

        let url = `/affiliates/dormant/balances?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

        history.push(url)
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = `affiliates/dormant/balances?startDate=${initStartDate}&endDate=${initEndDate}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.list)
                    setTotals(result.totals)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log('Error while fetching dormant affiliates balance: ', error)
                showSystemMessage('error', 'An error occurred while fetching dormant affiliates balance')
            })
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reports</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3 align-items-center">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark">Dormant Affiliates Balance</h1>
                            </div>
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <div className="d-flex align-items-center">
                                <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                    Refresh Report
                                </button>
                            </div>
                        </div>
                        <div>{dataLoaded && report && <DownloadCSV filename={'Dormant Affiliates Balance'} headerLabels={['Recovery Date', 'Affiliate', 'Adjusting Amount', 'Recovery Amount', 'Notes']} data={report} dataLabels={['recovery_date', 'name', 'adjusting_entry_amount', 'mb_sale_recover_amount', 'notes']} />}</div>
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default ReportDormantBalances
