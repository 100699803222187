import { useAuth, useProtectedContent } from '../../context/AuthContext'

export default function MailerFromSelect({ from, setFrom }) {
    const auth = useAuth()
    const showSenders = useProtectedContent(auth.permissions, 'mailer_sender_unrestricted')

    return (
        <select className="form-control" onChange={(e) => setFrom(e.target.value)} value={from}>
            <option value=""></option>
            <option value={auth.user.id}>{auth.user.name}</option>
            {
                showSenders &&
                <>
                    <option value="0">Account Manager</option>
                    <option value="16">Adam Harrison - Director of Affiliate Relations</option>
                    <option value="48">Jordon Schneiderman - Director of Advertiser Relations</option>
                </>
            } 
            {
                (showSenders || (auth.user.role === 'MERMGR' || auth.user.role === 'ADOPS_MANAGER' ||  auth.user.role === 'ADOPS_TEAMLEAD' )) &&
                <>                    
                    <option value="affiliates@maxbounty.com">affiliates@maxbounty.com</option>
                    <option value="merchants@maxbounty.com">merchants@maxbounty.com</option>
                    <option value="support@maxbounty.com">support@maxbounty.com</option>                    
                    <option value="compliance@maxbounty.com">compliance@maxbounty.com</option>
                    <option value="paychange@maxbounty.com">paychange@maxbounty.com</option>
                </>
            }
        </select>
    )
}
