import React, { Component } from 'react'
import axios from 'axios'
import { Grid } from '@material-ui/core'

import JobTable from './templates/JobTable'
import moment from 'moment'
import './JobDashboard.css'

class JobDashboard extends Component {
    state = {
        authenticated: false,
        data: [],
    }

    componentDidMount() {
        console.log(window.location)

        const params = new URLSearchParams(window.location.search)
        const user = params.get('user')
        const pwd = params.get('pwd')

        axios
            .get(`${process.env.REACT_APP_API_URL}/job_dashboard_authentication?user=${user}&pwd=${pwd}`, {
                withCredentials: true,
            })
            .then((res) => {
                const result = res.data

                if (result['success']) {
                    this.setState({ authenticated: true })
                    this.getJobs()
                } else {
                    console.log('Authentication failed.')
                }
            })
            .catch((error) => {
                console.log('There was an error while fetching the jobs: ', error)
            })
    }

    getJobs() {
        return axios
            .get(`${process.env.REACT_APP_API_URL}/job_dashboard`, {
                withCredentials: true,
            })
            .then((res) => {
                let results = res.data
                results.forEach((x) => {
                    // Reformating dates
                    x.job_start = moment.utc(x.job_start).format('YYYY-MM-DD HH:mm:ss')
                    x.job_end = x.job_end ? moment.utc(x.job_end).format('YYYY-MM-DD HH:mm:ss') : '-'
                })
                this.setState({ data: results }, () => {
                    console.log('this.state: ', this.state)
                })
            })
            .catch((error) => {
                console.log('There was an error while fetching the jobs: ', error)
            })
    }

    render() {
        return (
            <React.Fragment>
                {this.props.auth ? (
                    <div className="page-header">
                        <h1 className="mb-h1">Job Dashboard</h1>
                    </div>
                ) : (
                    <header className="jobDashboard-header">
                        <h1>MB Job Log Dashboard</h1>
                    </header>
                )}

                <div>
                    <div className="card">
                        <div className="card-body">
                            {this.state.authenticated ? (
                                <Grid container>
                                    <Grid style={{ margin: '5%' }} item xs={12}>
                                        <JobTable
                                            data={this.state.data}
                                            columns={[
                                                {
                                                    Header: 'ID',
                                                    accessor: 'ID',
                                                },
                                                {
                                                    Header: 'Title',
                                                    accessor: 'job_name',
                                                },
                                                {
                                                    Header: 'Start',
                                                    accessor: 'job_start',
                                                    /* Cell: ({ cell: { value } }) => {
                                    const jobstart = moment(value).format("YYYY-MM-DD HH:mm:ss");
                                    return (
                                    <>
                                        {jobstart}
                                    </>
                                    );
                                } */
                                                },
                                                {
                                                    Header: 'End',
                                                    accessor: 'job_end',
                                                    /* Cell: ({ cell: { value } }) => {
                                    const jobend = value ? moment(value).format("YYYY-MM-DD HH:mm:ss"): "-";
                                    return (
                                    <>
                                        {jobend}
                                    </>
                                    );
                                } */
                                                },
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container>
                                    <Grid style={{ margin: '5%' }} item xs={12}>
                                        <div className="jobDashboard-errormessage">Authentication Failed !! Please check your username or password to view data</div>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default JobDashboard
