import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'

const StatsDetails = ({ advertiserId, showSystemMessage }) => {
    const { data, dataLoaded } = useCallApi(`advertiser/${advertiserId}/statsDetails`, 'GET', {}, [advertiserId], showSystemMessage)

    const getStatsFlag = (flag) => {
        if (flag === 'U') {
            return 'Unknown'
        } else if (flag === 'O') {
            return 'Use Our Stats'
        } else if (flag === 'L') {
            return 'Login for Stats'
        } else if (flag === 'M') {
            return 'Get Stats from Merchant'
        }
    }

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">Stats Details</div>
            <div className="card-body">
                {dataLoaded ? (
                    <div>
                        {getStatsFlag(data.stats_info.flag)}
                        {data.stats_info.details !== '' ? (
                            <>
                                <br></br>
                                <br></br>
                                {data.stats_info.details}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default StatsDetails
