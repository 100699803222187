import { useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../../../helpers/callAPI'
import Advertiser from '../../../../../templates/Advertiser/Advertiser'
import Affiliate from '../../../../../templates/Affiliate/Affiliate'
import Campaign from '../../../../../templates/Campaign/Campaign'
import CustomButton from '../../../../../templates/UI/CustomButton/CustomButton'

const FraudPullCheckModal = ({ item, isOpen, handleClose, showSystemMessage, refetch }) => {
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()

        setSubmitting(true)

        callAPI(`admin/affiliatePull/check`, 'POST', { job_id: item.job_id, notes: e.target.notes.value })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    refetch()
                    handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'Error while checking affiliate pull.')
                setSubmitting(false)
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Check Affiliate Pull</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-card table-borderless">
                        <tbody>
                            <tr className="align-items-center">
                                <td>Affiliate: </td>
                                <td>
                                    <Affiliate aid={item.affiliate.id} name={item.affiliate.name} status={item.affiliate.status.value} priority={item.affiliate.priority.value} />
                                </td>
                            </tr>
                            {item.campaign.id && (
                                <tr className="align-items-center">
                                    <td>Campaign: </td>
                                    <td>
                                        <Campaign oid={item.campaign.id} name={item.campaign.name} status={item.campaign.status.value} />
                                    </td>
                                </tr>
                            )}
                            {item.advertiser.id && (
                                <tr className="align-items-center">
                                    <td>Advertiser: </td>
                                    <td>
                                        <Advertiser id={item.advertiser.id} name={item.advertiser.name} />
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>Notes:</td>
                                <td>
                                    <textarea rows={5} cols={20} className="form-control" name="notes" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant="primary" name="Mark as Checked" type="submit" spin={submitting} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default FraudPullCheckModal
