import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import TableContainer from '../TableContainer'
import Loading from '../../../templates/UI/Loading/Loading'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const ReportInvoiceList = (props) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_invoices')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    const { showSystemMessage } = props

    useEffect(() => {
        const mbToken = localStorage.getItem('adm-auth-token')
        setDataLoaded(false)

        let url = `${process.env.REACT_APP_API_URL}/reports/merchants/listinvoices`

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setReport(result.report)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error while fetching the data. Please try again')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error in ReportInvoiceList: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }, [showSystemMessage])

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    reportType="invoices list"
                    data={report}
                    columns={[
                        {
                            Header: 'Type',
                            accessor: 'period_type',
                            Cell: ({ cell: { value } }) => {
                                let periodType = 'monthly'

                                if (value === 10) {
                                    periodType = 'Pre-Payment'
                                } else if (value === 20) {
                                    periodType = 'Weekly'
                                } else if (value === 30) {
                                    periodType = 'Semi-Monthly'
                                }

                                return <>{periodType}</>
                            },
                        },
                        {
                            Header: 'Invoice Date',
                            accessor: 'invoice_date',
                            Cell: ({ cell: { value } }) => {
                                return <>{moment(value).format('YYYY/MM/DD')}</>
                            },
                        },
                        {
                            Header: 'Period',
                            accessor: 'period_start',
                            Cell: (tableData) => {
                                let value = ''
                                let tableDataRef = tableData.data[tableData.row.index]
                                if (tableDataRef.period_type !== 10) {
                                    value = `${tableDataRef.period_start} - ${tableDataRef.period_end}`
                                }
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Merchant',
                            accessor: 'name',
                            Cell: (tableData) => {
                                let tableDataRef = tableData.data[tableData.row.index]
                                return <>{<Link to={`/merfollowup?mid=${tableDataRef.merchant_id}`}>{tableDataRef.name}</Link>}</>
                            },
                        },
                        {
                            Header: '',
                            accessor: 'mer_email',
                            Cell: (tableData) => {
                                let tableDataRef = tableData.data[tableData.row.index]
                                if (tableDataRef.mer_email !== tableDataRef.accounting_email) {
                                    return <a href={`mailto:${tableDataRef.mer_email}; ${tableDataRef.accounting_email}?cc:${tableDataRef.mm_email}`}>Email</a>
                                } else {
                                    return <a href={`mailto:${tableDataRef.mer_email}?cc=${tableDataRef.mm_email}`}>Email</a>
                                }
                            },
                        },
                        {
                            Header: 'Merchant Mgr',
                            accessor: 'full_name',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Stats',
                            accessor: 'stats_flag',
                            Cell: ({ cell: { value } }) => {
                                let flag = ''
                                if (value === 'U') {
                                    flag = 'Unknown'
                                } else if (value === 'O') {
                                    flag = 'Ours'
                                } else if (value === 'L') {
                                    flag = 'Login'
                                } else if (value === 'M') {
                                    flag = ' Merchant'
                                }
                                return <>{flag}</>
                            },
                        },
                        {
                            Header: 'Reversals',
                            accessor: 'reversals_flag',
                            Cell: ({ cell: { value } }) => {
                                let flag = ''

                                if (value === 'U') {
                                    flag = 'Unknown'
                                } else if (value === 'N') {
                                    flag = 'None'
                                } else if (value === 'L') {
                                    flag = 'Login'
                                } else if (value === 'M') {
                                    flag = 'Merchant'
                                } else if (value === 'F') {
                                    flag = 'Ad-Hoc'
                                }
                                return <>{flag}</>
                            },
                        },
                        {
                            Header: 'Inv #',
                            accessor: 'invoice_num',
                            Cell: ({ cell: { value } }) => {
                                if (value > 0) {
                                    return <>{value}</>
                                } else {
                                    return <>&nbsp;</>
                                }
                            },
                        },
                        {
                            Header: '',
                            accessor: 'period_end',
                            Cell: (tableData) => {
                                let tableDataRef = tableData.data[tableData.row.index]

                                if (tableDataRef.period_type !== 10) {
                                    let startDate = moment(tableDataRef.period_start).format('YYYY-MM-DD')
                                    let endDate = moment(tableDataRef.period_end).format('YYYY-MM-DD')

                                    return <Link to={`/invoice_report?mid=${tableDataRef.merchant_id}&startDate=${startDate}&endDate=${endDate}`}>Stats</Link>
                                }
                                return <Link to={`/completeinvoice?id=${tableDataRef.merchant_id}&inv=${moment(tableDataRef.inv_date).format('YYYY/MM/DD')}&pt=${tableDataRef.period_type}`}>{tableDataRef.status === 'P' ? 'Complete Invoice' : 'Receive Payment'}</Link>
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }
    return <React.Fragment>{dataLoaded ? report.length === 0 ? <div className="p-3">No stats for this parameters.</div> : getReportOutput() : <Loading></Loading>}</React.Fragment>
}

export default ReportInvoiceList
