import { Card, Table } from 'react-bootstrap'
import Affiliate from '../../../templates/Affiliate/Affiliate'

const PromotionalInformation = ({ data }) => {
    let formattedURL = data.info.url.substring(0, 4) === 'http' ? data.info.url : data.info.url.substring(0, 5) === 'https' ? data.info.url : `https://${data.info.url}`

    return (
        <Card className="mb-4">
            <Card.Header>
                <div>Site/Promotional Information</div>
            </Card.Header>
            {data.success ? (
                <table className='table table-borderless table-striped'>
                    <tbody>
                        <tr>
                            <td className="font-weight-bold">Site URL:</td>
                            <td>
                                <a className="mr-2" href={formattedURL} target="_blank">
                                    {formattedURL}
                                </a>
                                <a target="_blank" href={`http://www.whois.sc/${data.info.url}`}>
                                    WHOIS
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Incentives: </td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Promotional Information: </td>
                            <td>{data.info.site_description}</td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <div colSpan="4">No data found</div>
            )}
            <div className="m-2 font-weight-bold">Other affiliates (up to 10) listing this site as theirs:</div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Affiliate</th>
                        <th>Status</th>
                        <th>Contact</th>
                    </tr>
                </thead>

                {data.info.matchingUrls.length === 0 ?
                    <tbody>
                        <tr>
                            <td colSpan={4}>No data found</td>
                        </tr></tbody>
                    : <tbody>
                        {data.info.matchingUrls.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.affiliate_id}</td>
                                    <td>
                                        <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                    </td>
                                    <td>{item.aff_status}</td>
                                    <td>{item.contact}</td>
                                </tr>
                            )
                        })}
                    </tbody>}
            </Table>
        </Card>
    )
}

export default PromotionalInformation
