import React, { useRef, useState } from 'react'
import { Modal } from 'reactstrap'
import { useAuth, useProtectedPage } from '../../context/AuthContext'
import callAPI from '../../helpers/callAPI'
import useCallApi from '../../hooks/useCallApi'
import Loading from '../../templates/UI/Loading/Loading'

import AddPageModal from './components/AddPageModal'
import EndpointAuthorization from './components/EndpointAuthorization'
import PageAuthorization from './components/PageAuthorization'

export default function AuthSettings({ showSystemMessage }) {

    document.title = 'Permission Settings'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'manage_permissions')

    const portalRef = useRef([])
    const apiRef = useRef([])

    const [authMode, setAuthMode] = useState('portal') //portal vs api
    const [portalAuthorization, setPortalAuthorization] = useState(false)
    const [apiAuthorization, setApiAuthorization] = useState(false)
    const [addPageModal, setAddPageModal] = useState(false)
    const [addResourceModal, setAddResourceModal] = useState(false)

    const [pageName, setPageName] = useState('')

    const [resourceName, setResourceName] = useState('')
    const [resourceURI, setResourceURI] = useState('')

    let statLabelClasses = 'btn btn-secondary'

    const { data, dataLoaded, refetch, setData } = useCallApi('authorization', 'GET', {}, [])
    const { data: roleData, dataLoaded: roleDataLoaded } = useCallApi('authorization/roles', 'GET', {}, [])

    const addDeleteRole = async (action, authMode, resourceId, roleId) => {
        /*
            action = add / delete
            authMode = api / portal
            resourceId = int
            roleId = int
        */
        let method = false
        if (action == 'add') {
            method = 'POST'
        } else if (action == 'delete') {
            method = 'DELETE'
        } else {
            return showSystemMessage('error', 'Invalid action for role. Delete or Add?')
        }

        await callAPI(`authorization/role/${roleId}/${authMode}/${resourceId}`, method)
            .then((result) => {
                if (result['success'] === true) {
                    if (authMode == 'api') {
                        setData({ ...data, api: result.api })
                    } else if (authMode == 'portal') {
                        setData({ ...data, portal: result.portal })
                    }
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', error.message)
            })
    }

    const changePrivilege = async (authMode, resourceId, roleId, privilege, permissionId) => {
        // If privilegeId, then that means it exists. Delete that record to remove that privilege
        if (permissionId > 0) {
            await callAPI(`authorization/privilege/${permissionId}/${authMode}`, 'DELETE')
                .then((result) => {
                    if (result['success'] === true) {
                        if (authMode == 'api') {
                            setData({ ...data, api: result.api })
                        } else if (authMode == 'portal') {
                            setData({ ...data, portal: result.portal })
                        }
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', error.message)
                })
        } else {
            // If permissionId is false, then that means we need to add the privilege, for this resource, and role
            await callAPI(`authorization/privilege/${privilege}/${authMode}/${roleId}/${resourceId} `, 'POST')
                .then((result) => {
                    if (result['success'] === true) {
                        if (authMode == 'api') {
                            setData({ ...data, api: result.api })
                        } else if (authMode == 'portal') {
                            setData({ ...data, portal: result.portal })
                        }
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', error.message)
                })
        }
    }

    const deleteAuth = async (authMode, id) => {
        if (isNaN(id)) {
            showSystemMessage('error', 'You must choose a valid ID.')
        }

        let type = ''
        if (authMode === 'portal') {
            type = 'page'
        } else {
            type = 'resource'
        }

        await callAPI(`authorization/${type}/${id}`, 'DELETE')
            .then((result) => {
                if (result['success'] === true) {
                    if (authMode == 'api') {
                        setData({ ...data, api: result.api })
                    } else if (authMode == 'portal') {
                        setData({ ...data, portal: result.portal })
                    }
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', error.message)
            })
    }

    const addEndpoint = async (resourceName, resourceURI) => {
        if (resourceName.length === 0) {
            showSystemMessage('error', 'You must enter an endpoint.')
        }

        await callAPI(`authorization/resource/${resourceName}?uri=${resourceURI}`, 'POST')
            .then((result) => {
                if (result['success'] === true) {
                    setData({ ...data, api: result.api })
                } else if (result['success'] === false) {
                    setData({ ...data, portal: result.portal })
                }
            })
            .catch((error) => {
                showSystemMessage('error', error.message)
            })
        setAddResourceModal(false)
        // Set the page to be added back to zero
        setResourceName('')
        setResourceURI('')
    }

    const addNote = async (authMode, name, notes) => {

        let url = ``

        if (authMode === 'portal') {
            url = `authorization/page/${name}`
        } else {
            url = `authorization/resource/${name}`
        }

        let body = {
            notes: notes
        }

        await callAPI(url, 'PUT', body)
            .then((result) => {
                if (result['success'] === true) {
                    if (authMode == 'api') {
                        setData({ ...data, api: result.api })
                    } else if (authMode == 'portal') {
                        setData({ ...data, portal: result.portal })
                    }
                    showSystemMessage('success', 'Notes Updated.')
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', error.message)
            })
    }

    const handlePortalSelect = (index) => {
        portalRef.current[index].scrollIntoView()
    }

    const handleApiSelect = (index) => {
        apiRef.current[index].scrollIntoView()
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Permission Settings</h1>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="m-2 btn-group btn-group-sm btn-group-toggle" data-toggle="buttons" role="group">
                                <label className={authMode === 'portal' ? statLabelClasses + ' active' : statLabelClasses}>
                                    <input type="radio" name="authModeOption" id="option1" className="change-graph-option" autoComplete="off" value="portal" onClick={() => setAuthMode('portal')} /> Portal
                                </label>
                                <label className={authMode === 'api' ? statLabelClasses + ' active' : statLabelClasses}>
                                    <input type="radio" name="authModeOption" id="option2" className="change-graph-option" autoComplete="off" value="api" onClick={() => setAuthMode('api')} /> API
                                </label>
                                {
                                    (dataLoaded && roleDataLoaded) &&
                                    <>
                                        {authMode === 'portal' && (
                                            <select className="ml-2 form-control form-control-sm" onChange={(e) => handlePortalSelect(e.target.value)}>
                                                {data.portal.map((record, index) => {
                                                    return <option key={index} value={index}>{record.resource}</option>
                                                })}
                                            </select>
                                        )}
                                        {authMode === 'api' && (
                                            <select className="ml-2 form-control form-control-sm" onChange={(e) => handleApiSelect(e.target.value)}>
                                                {data.api.map((record, index) => {
                                                    return <option key={index} value={index}>{record.resource}</option>
                                                })}
                                            </select>
                                        )}
                                    </>
                                }
                            </div>
                            <div>
                                {authMode === 'portal' ? (
                                    <button className="btn btn-warning btn-sm" onClick={() => setAddPageModal(!addPageModal)}>
                                        Add Permission
                                    </button>
                                ) : (
                                    <button className="btn btn-warning btn-sm" onClick={() => setAddResourceModal(!addResourceModal)}>
                                        Add Endpoint
                                    </button>
                                )}
                                <AddPageModal data={data} setData={setData} addPageModal={addPageModal} setAddPageModal={setAddPageModal} refetch={refetch} showSystemMessage={showSystemMessage} />
                                <Modal isOpen={addResourceModal} toggle={() => setAddResourceModal(!addResourceModal)} className="modal-sm">
                                    <div className="card">
                                        <div className="card-header">Add Resource</div>
                                        <div className="card-body">
                                            Resource:
                                            <input type="text" className="form-control" onChange={(event) => setResourceName(event.target.value)}></input>
                                            Resource URI:
                                            <input type="text" className="form-control" onChange={(event) => setResourceURI(event.target.value)}></input>
                                            <button
                                                className="btn btn-primary mt-3"
                                                onClick={() => {
                                                    addEndpoint(resourceName, resourceURI)
                                                }}
                                            >
                                                Add Resource
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div>
                            {dataLoaded && roleDataLoaded ? (
                                <div>
                                    {authMode === 'portal' && (
                                        <table className="table mb-0 w-100">
                                            <thead>
                                                <tr>
                                                    <th>Permission</th>
                                                    <th>Role</th>
                                                    <th>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.portal.map((record, index) => (
                                                    <PageAuthorization parentRef={portalRef} index={index} key={record.id} record={record} roles={roleData.roles} addDeleteRole={addDeleteRole} changePrivilege={changePrivilege} deleteAuth={deleteAuth} addNote={addNote} showSystemMessage={showSystemMessage} />
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    {authMode === 'api' && (
                                        <table className="table mb-0 w-100">
                                            <thead>
                                                <tr>
                                                    <th>Resource</th>
                                                    <th>URI</th>
                                                    <th>Role</th>
                                                    <th>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.api.map((record, index) => (
                                                    <EndpointAuthorization parentRef={apiRef} index={index} key={record.id} record={record} roles={roleData.roles} addDeleteRole={addDeleteRole} changePrivilege={changePrivilege} deleteAuth={deleteAuth} addNote={addNote} showSystemMessage={showSystemMessage} />
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            ) : (
                                <Loading></Loading>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
