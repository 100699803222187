import React, { useState } from 'react'
// import FollowupQueue from '../AdvertiserFollowupQueue/AdvertiserFollowupQueue'

import Loading from '../../../../templates/UI/Loading/Loading'
import AffiliateHealthSummary from '../AffiliateHealthSummary/AffiliateHealthSummary'
import AffiliateTeam from '../AffiliateTeam/AffiliateTeam'
import Applications from '../Applications/Applications'
import CampaignRequests from '../CampaignRequests/CampaignRequests'
import JAmSummary from '../JAmSummary/JAmSummary'
import NewlyRunCampaigns from '../NewlyRunCampaigns/NewlyRunCampaigns'
import RecentlyReceivedPayments from '../RecentlyReceivedPayments/RecentlyReceivedPayments'
import TopAffiliates from '../TopAffiliates/TopAffiliates'

import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import AffiliateFollowupQueue from '../AffiliateFollowupQueue/AffiliateFollowupQueue'

export default function JuniorAffiliateTeamLead({ manager, setManager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'jr_am_team_lead_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Junior Affiliate Team Lead</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <JAmSummary manager={manager} setManager={setManager} showSetManager={false} />
                            <AffiliateHealthSummary showSystemMessage={showSystemMessage} />
                            <CampaignRequests manager={manager} showSystemMessage={showSystemMessage} />
                            <TopAffiliates manager={manager} />
                        </div>
                        <div className="col-lg-8">
                            <AffiliateTeam showSystemMessage={showSystemMessage} mode="junior" />

                            <div className="row">
                                <div className="col-lg-6">
                                    <AffiliateFollowupQueue manager={manager} limit={10} />
                                    <Applications manager={manager} showSystemMessage={showSystemMessage} mode="affiliate_manager" />
                                </div>
                                <div className="col-lg-6">
                                    <RecentlyReceivedPayments manager={manager} />
                                    <NewlyRunCampaigns />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
