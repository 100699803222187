const calcName = (name) => {
    switch (name) {
        case 'TEST':
            return 'Test Job'
        case 'RATE':
            return 'Change Campaign Rate'
        case 'PUBRATE':
            return 'Change Pub Rate'
        case 'CANCEL':
            return 'Cancel Campaign'
        case 'PUBPULL':
            return 'Remove Pub from Campaign'
        case 'MERPULL':
            return 'Remove Pub from Advertiser'
        case 'RELAUNCH':
            return 'Relaunch Campaign'
        default:
            return name
    }
}

export default calcName