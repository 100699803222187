import React, { useState, useEffect } from 'react'

export default function MarketingPlatformList({ data, type, togglePlatformStatus }) {
    return (
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data &&
                    data.map((platform) => {
                        return (
                            <tr>
                                <td>{platform.id}</td>
                                <td>{platform.name}</td>
                                <td className="text-right">
                                    {type === 'active' ? (
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => {
                                                togglePlatformStatus(platform.id, 'C')
                                            }}
                                        >
                                            Deactivate
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-success btn-sm"
                                            onClick={() => {
                                                togglePlatformStatus(platform.id, 'A')
                                            }}
                                        >
                                            Activate
                                        </button>
                                    )}
                                </td>
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    )
}
