import React, { useState } from 'react'
import { FaCopy, FaPaperPlane } from 'react-icons/fa'
import MailerEditor from './MailerEditor'

import { useAuth } from '../../context/AuthContext'
import callAPI from '../../helpers/callAPI'
import MailerFromSelect from './MailerFromSelect'
import MailerTypeSelect from './MailerTypeSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Signature from './Signature'

export default function Mailer({ type, filters, from, content, subject, preheader, showSystemMessage }) {
    const [convertedContent, setConvertedContent] = useState(content ? content : null)
    const [emailPriority, setEmailPriority] = useState(1)
    const [mailerType, setMailerType] = useState('')
    const [fromLine, setFromLine] = useState(from ? from : false)
    const [emailSubject, setEmailSubject] = useState(subject ? subject : '')
    const [emailPreheader, setEmailPreheader] = useState(preheader ? preheader : '')
    const [readyToSend, setReadyToSend] = useState(false)
    const [sendStatus, setSendStatus] = useState(false)
    const [testRecipients, setTestRecipients] = useState('')
    const [composer, setComposer] = useState('WYSIWYG')
    const [customButton, setCustomButton] = useState({ url: 'https://maxbounty.com', text: 'Button Text' })

    const auth = useAuth()

    const copyToClipboard = (content) => {
        navigator.clipboard.writeText(content)
    }

    const handleTest = async () => {
        let data = {
            from: fromLine,
            subject: emailSubject,
            preheader: emailPreheader,
            mailerType: mailerType,
            content: convertedContent,
            recipients: testRecipients,
        }

        let testcall = await callAPI('batchmail/test', 'POST', data)

        if (!testcall.success) {
            showSystemMessage('error', testcall.errors.message)
        } else {
            showSystemMessage('success', `Test email sent to: ${testRecipients}`)
        }

        return
    }

    const handleSend = async (type) => {

        setSendStatus('sending')

        let data = {
            from: fromLine,
            priority: emailPriority,
            mailerType: mailerType,
            subject: emailSubject,
            preheader: emailPreheader,
            content: convertedContent,
            filters: filters,
        }

        let sendCall

        if (type === 'affiliate') {
            sendCall = await callAPI('batchmail/affiliates/send', 'POST', data)
        } else if (type === 'advertiser') {
            sendCall = await callAPI('batchmail/advertisers/send', 'POST', data)
        }

        if (!sendCall.success) {
            showSystemMessage('error', sendCall.errors.message)
        } else {
            showSystemMessage('success', `Mailer submitted.`)
        }

        setReadyToSend(false)
        setSendStatus('sent')

        return
    }

    const handleSetComposer = (type) => {
        setConvertedContent(null)
        setComposer(type)
    }

    const handleCustomButton = () => {
        let html = `
<table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tr>
        <td bgcolor="#ffffff" align="center" style="padding: 20px 30px 30px 30px;">
            <table border="0" cellspacing="0" cellpadding="0">
            <tr>
                <td align="center" style="border-radius: 5px;" bgcolor="#000000"><a href="${customButton.url}" target="_blank" style="font-size: 20px; font-family: Helvetica, Arial, sans-serif; color: #ffffff; text-decoration: none; color: #ffffff; text-decoration: none; padding: 15px 25px; border-radius: 5px; border: 1px solid #000000; display: inline-block;">${customButton.text}</a></td>
            </tr>
            </table>
        </td>
    </tr>
</table>
`

        return html
    }

    return (
        <>
            <div className="card">
                <div className="border-bottom">
                    <div className="p-2 d-flex justify-content-between align-items-center font-weight-bold border-bottom">Email Settings</div>
                    <div className="p-2">
                        <div className="form-inline">
                            <label for="sender" className="mr-2">
                                Sender
                            </label>
                            <div className="mr-5">
                                <MailerFromSelect from={fromLine} setFrom={setFromLine} />
                            </div>
                            <label for="priority" className="mr-2">
                                Email Priority
                            </label>
                            <select
                                className="form-control mr-5"
                                name="email_priority"
                                onChange={(e) => {
                                    setEmailPriority(e.target.value)
                                }}
                            >
                                <option value={1}>Normal</option>
                                <option value={0}>High</option>
                            </select>
                            <label for="sender" className="mr-2">
                                Mailer Type
                            </label>
                            <div className="mr-5">
                                <MailerTypeSelect mailerType={mailerType} setMailerType={setMailerType} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2 border-bottom">
                    <div className="d-flex">
                        <div className="alert alert-secondary mt-2 rounded border-0 d-inline-block m-0">
                            <strong>Macros</strong>
                            <table>
                                <tr>
                                    <td>First Name</td>
                                    <td className="pl-3">#firstName#</td>
                                    <td className="pl-3">
                                        <button className="btn btn-sm btn-warning" onClick={() => copyToClipboard('#firstName#')}>
                                            <FaCopy />
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {composer === 'HTML' && (
                            <div className="alert alert-secondary mt-2 rounded border-0 d-inline-block m-0 ml-2">
                                <strong>HTML Elements</strong>
                                <table>
                                    <tr>
                                        <td>Centered Button</td>
                                        <td className="pl-3">
                                            <input className="form-control" type="text" placeholder="Button Text" onChange={(e) => setCustomButton({ ...customButton, text: e.target.value })}></input>
                                            <input className="form-control mt-2" type="text" placeholder="Button URL" onChange={(e) => setCustomButton({ ...customButton, url: e.target.value })}></input>
                                        </td>
                                        <td className="pl-3">
                                            <button className="btn btn-sm btn-warning" onClick={() => copyToClipboard(handleCustomButton())}>
                                                <FaCopy />
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
                <div className="p-4">
                    <div className="form-inline alert alert-info">
                        <label className="mr-2">Editor mode: </label>
                        <select className="form-control" onChange={(e) => handleSetComposer(e.target.value)}>
                            <option value="WYSIWYG">Visual Editor</option>
                            <option value="HTML">Raw HTML</option>
                        </select>
                        <div>&nbsp;* Changing this setting mid composition will delete anything you've already typed.</div>
                    </div>
                    <input
                        type="text"
                        className="form-control mb-2 form-control-lg"
                        placeholder="Subject"
                        value={subject}
                        onChange={(e) => {
                            setEmailSubject(e.target.value)
                        }}
                    ></input>
                    <input
                        type="text"
                        className="form-control mb-2 form-control-lg"
                        placeholder="Preheader (Optional attention grabbing text under/beside the subject line)"
                        value={preheader}
                        onChange={(e) => {
                            setEmailPreheader(e.target.value)
                        }}
                    ></input>
                    <div className="border p-2 rounded">{composer === 'WYSIWYG' ? <MailerEditor setConvertedContent={setConvertedContent} /> : <textarea style={{ minHeight: '250px' }} className="border-0 w-100" onChange={(e) => setConvertedContent(e.target.value)}></textarea>}</div>
                </div>
                <div className="p-2 text-right">
                    {!readyToSend ? (
                        <button className="btn btn-primary" onClick={() => setReadyToSend(true)}>
                            {sendStatus === 'sent' ? 'Send Again' : 'Ready to Send'}
                        </button>
                    ) : (
                        <button className="btn btn-danger" onClick={() => handleSend(type)} disabled={sendStatus === 'sending' ? true : false}>
                            <FaPaperPlane /> Send Mailer {sendStatus === 'sending' && <FontAwesomeIcon icon="spinner" spin />}
                        </button>
                    )}
                </div>
            </div>
            <div className="card mt-3">
                <div className="border-bottom">
                    <div className="p-2 d-flex justify-content-between align-items-center font-weight-bold border-bottom">Live Preview / Testing</div>
                    <div className="border p-2 rounded m-2">
                        <div>
                            <div style={{ backgroundColor: '#f4f4f4', margin: '0 !important', padding: '0 !important' }}>
                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tr>
                                        <td bgcolor="#e02527" align="center">
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style={{ maxWidth: '600px' }}>
                                                <tr>
                                                    <td align="center" valign="top" style={{ padding: '20px 10px 20px 10px' }}>
                                                        <a href="http://maxbounty.com" target="_blank">
                                                            <img alt="MaxBounty" src="https://www.maxbounty.com/assets/images/maxbounty-logob-w.png" width="200" style={{ display: 'block', width: '200px', maxWidth: '200px', minWidth: '40px', fontFamily: "'Lato', Helvetica, Arial, sans-serif", color: '#ffffff', fontSize: '18px' }} border="0" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td bgcolor="#f4f4f4" align="center" style={{ padding: '0px 10px 0px 10px' }}>
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style={{ maxWidth: '600px', marginTop: '20px' }}>
                                                <tr>
                                                    <td bgcolor="#ffffff" align="left" style={{ padding: '30px', color: '#666666', fontFamily: "'Inter', 'Inter Vars', 'Lato', Helvetica, Arial, sans-serif", fontSize: '16px', fontWeight: '400', lineHeight: 'auto', borderRadius: '4px' }}>
                                                        <p style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: convertedContent }}></p>
                                                        <div style={{ margin: '20px 0px 0px 0px', color: 'black' }}>
                                                            <Signature id={fromLine} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td bgcolor="#f4f4f4" align="center" style={{ padding: '0px 10px 0px 10px' }}>
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style={{ maxWidth: '600px' }}>
                                                <tr>
                                                    <td bgcolor="#f4f4f4" align="left" style={{ padding: '30px 30px 20px 30px', color: '#666666', fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: '14px', fontWeight: '400', lineHeight: '18px' }}>
                                                        <p style={{ margin: '0' }}>
                                                            <a href="https://blog.maxbounty.com" target="_blank" style={{ color: '#111111', fontWeight: '700', textDecoration: 'underline' }}>
                                                                Blog
                                                            </a>{' '}
                                                            &nbsp; - &nbsp;
                                                            <a href="https://www.facebook.com/maxbounty/" target="_blank" style={{ color: '#111111', fontWeight: '700', textDecoration: 'underline' }}>
                                                                Facebook
                                                            </a>{' '}
                                                            &nbsp; - &nbsp;
                                                            <a href="https://www.instagram.com/maxbountyinc" target="_blank" style={{ color: '#111111', fontWeight: '700', textDecoration: 'underline' }}>
                                                                Instagram
                                                            </a>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f4f4f4" align="left" style={{ padding: '00px 30px 20px 30px', color: '#666666', fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: '14px', fontWeight: '400', lineHeight: '18px' }}>
                                                        <p style={{ margin: '0' }}>
                                                            To unsubscribe, log into your{' '}
                                                            <a href="http://affiliates.maxbounty.com" target="_blank" style={{ color: '#111111', fontWeight: '700', textDecoration: 'underline' }}>
                                                                dashboard
                                                            </a>{' '}
                                                            to change your communications preferences.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f4f4f4" align="left" style={{ padding: '0px 30px 30px 30px', color: '#666666', fontFamily: "'Lato', Helvetica, Arial, sans-serif", fontSize: '14px', fontWeight: '400', lineHeight: '18px' }}>
                                                        <p style={{ margin: '0' }}>MaxBounty - 1730 St. Laurent Blvd, Ottawa, ON</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Test recipients separated by comma. Ex: youremail@email.com, support@maxbounty.com"
                                onChange={(e) => {
                                    setTestRecipients(e.target.value)
                                }}
                            ></input>
                            <button className="btn btn-warning ml-2" style={{ width: '100px' }} onClick={handleTest}>
                                <FaPaperPlane /> Send Test
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
