import { useEffect, useState } from 'react'
import { Form, Modal, Table, Button } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'

const EventModal = ({ campaignId, showSystemMessage, isOpen, closeModal, data, isCreate, refetch }) => {
    const [formData, setFormData] = useState(data)

    useEffect(() => {
        setFormData(data)
    }, [data])

    const validate = () => {
        if (formData.name === '' || formData.name === undefined) {
            showSystemMessage('error', 'Event Name is required')
            return false
        }
        if (formData.description === '' || formData.description === undefined) {
            showSystemMessage('error', 'Description is required')
            return false
        }
        return true
    }

    const save = async () => {
        //save data to db
        if (validate()) {
            const result = await callAPI(`campaign/${campaignId}/mobileEvent`, 'PUT', formData)
            if (result.success) {
                showSystemMessage('success', 'Saved Mobile Event')
                refetch()
                closeModal()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const create = async () => {
        //save data to db
        if (validate()) {
            const result = await callAPI(`campaign/${campaignId}/mobileEvent`, 'POST', formData)
            if (result.success) {
                showSystemMessage('success', 'Created Mobile Event')
                refetch()
                closeModal()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const deleteEvent = async () => {
        //save data to db
        const result = await callAPI(`campaign/${campaignId}/mobileEvent`, 'DELETE', formData)
        if (result.success) {
            showSystemMessage('success', 'Deleted Mobile Event')
            refetch()
            closeModal()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Create' : 'Edit'} Mobile Event</Modal.Title>
            </Modal.Header>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Event</span>
                <Form.Control type="text" value={formData.name} maxLength={50} onChange={(e) => { setFormData({ ...formData, name: e.target.value }) }} className="ml-2" disabled={!isCreate} />
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Description</span>
                <Form.Control type="text" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} className="ml-2" />
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Status</span>
                <Form.Control as="select" value={formData.hide} onChange={(e) => setFormData({ ...formData, hide: e.target.value })} className="ml-2">
                    <option value={false}>Visible</option>
                    <option value={true}>Hidden</option>
                </Form.Control>
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Retireable</span>
                <Form.Check type="checkbox" checked={formData.is_retireable} onChange={(e) => setFormData({ ...formData, is_retireable: e.target.checked })} className="ml-2" />
            </div>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="primary" onClick={create}>
                        Create
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={save}>
                            Save
                        </Button>
                        {formData.delete_status === 'Y' && (
                            <Button variant="danger" onClick={deleteEvent}>
                                Delete
                            </Button>
                        )}
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default EventModal
