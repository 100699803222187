import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'

const ParamModal = ({ isOpen, setIsOpen, data, isCreate, showSystemMessage, refetch }) => {
    const [formData, setFormData] = useState(data)

    useEffect(() => {
        setFormData(data)
    }, [data])

    const createParam = async () => {
        const result = await callAPI(`campaign/mobileParameter`, 'POST', formData)
        if (result.success) {
            setIsOpen(false)
            showSystemMessage('success', 'Parameter created successfully')
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const editParam = async () => {
        const result = await callAPI(`campaign/mobileParameter`, 'PUT', formData)
        if (result.success) {
            setIsOpen(false)
            showSystemMessage('success', 'Parameter updated successfully')
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const deleteParam = async () => {
        const result = await callAPI(`campaign/mobileParameter`, 'DELETE', formData)
        if (result.success) {
            setIsOpen(false)
            showSystemMessage('success', 'Parameter deleted successfully')
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Add' : 'Edit'} Parameter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center m-2">
                    <span className="w-25">Parameter</span>
                    <Form.Control type="text" value={formData.param_name} onChange={(e) => setFormData({ ...formData, param_name: e.target.value })} className="ml-2" />
                </div>
                <div className="d-flex align-items-center m-2">
                    <span className="w-25">Description</span>
                    <Form.Control type="text" value={formData.param_description} onChange={(e) => setFormData({ ...formData, param_description: e.target.value })} className="ml-2" />
                </div>
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="primary" onClick={createParam}>
                        Create
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={editParam}>
                            Edit
                        </Button>
                        <Button variant="danger" onClick={deleteParam}>
                            Delete
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default ParamModal
