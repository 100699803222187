import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import DateRangeFilter from '../../pages/Reports/Filters/DateRangeFilter/DateRangeFilter'
import useDateRangeFilter from '../../hooks/useDateRangeFilter'
import DownloadCSV from '../../templates/DownloadCSV/DownloadCSV'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Loading from '../../templates/UI/Loading/Loading'
import callAPI from '../../helpers/callAPI'
import Campaign from '../../templates/Campaign/Campaign'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'

const LeadGeos = ({ affiliateId, campaignId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})
    const [report, setReport] = useState([])
    const history = useHistory()
    const location = useLocation()

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    if (affiliate.id){
        document.title = `${affiliate.name}: Lead Locations`
    } else if (campaign.id){
        document.title = `${campaign.name}: Lead Locations`
    } else {
        document.title = `Lead Locations`
    }

    useEffect(() => {
        getReport()
    }, [location])

    const refreshReport = (e) => {
        e.preventDefault()

        let url = affiliateId ? `/affiliate/${affiliateId}/leadLocations?startDate=${selectedStartDate}&endDate=${selectedEndDate}` : `/campaign/${campaignId}/leadLocations?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

        history.push(url)
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = affiliateId ? `affiliate/${affiliateId}/leadGeos?startDate=${initStartDate}&endDate=${initEndDate}` : `campaign/${campaignId}/leadGeos?startDate=${initStartDate}&endDate=${initEndDate}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    affiliateId ? setAffiliate(result.affiliate) : setCampaign(result.campaign)
                    setReport(result.report)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching leadGeos: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching affiliate lead locations')
            })
    }

    // aff_priority: 2
    // affiliate_id: 606420
    // affiliate_name: "themedia"
    // db_date: "2022-08-02T10:00:09.020Z"
    // incentive_flag: "N"
    // ip_city: "San Antonio"
    // ip_country: "United States of America"
    // ip_domain_name: "googlefiber.net"
    // ip_isp_name: "Google Fiber Inc."
    // ip_region: "Texas"
    // offer_id: 12019
    // offer_name: "BadCreditLoans.com - Rev Share"
    // status: "A"
    // verified_flag: "Y"

    const getReportOutput = () => {
        let columns = [
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: 'item_name',
                Cell: (tableData) => {
                    return affiliateId ? <Campaign oid={tableData.data[tableData.row.index].offer_id} name={tableData.data[tableData.row.index].offer_name} status={tableData.data[tableData.row.index].offer_status} /> : <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].affiliate_name} status={tableData.data[tableData.row.index].affiliate_status} priority={tableData.data[tableData.row.index].priority} />
                },
            },
            {
                Header: 'Date',
                accessor: 'db_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Country',
                accessor: 'ip_country',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Region',
                accessor: 'ip_region',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'City',
                accessor: 'ip_city',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'ISP',
                accessor: 'ip_isp_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Domain',
                accessor: 'ip_domain_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const CSVHeaders = ['Campaign', 'Date', 'Country', 'Region', 'City', 'ISP', 'Domain']
    const CSVDataLabels = ['item_id', 'db_date', 'ip_country', 'ip_region', 'ip_city', 'ip_isp_name', 'ip_domain_name']

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Lead Locations</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliateId ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority.name} status={affiliate.status.name} /> : <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : spinner}</h1>
                            </div>
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                        <DownloadCSV filename={affiliateId ? `Lead Locations - ${affiliate.name} (${affiliateId}) - ${initStartDate}-${initEndDate}` : `Lead Locations - ${campaign.name} (${campaignId}) - ${initStartDate}-${initEndDate}`} headerLabels={CSVHeaders} data={report} dataLabels={CSVDataLabels} />
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default LeadGeos
