import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Modal } from 'react-bootstrap'

const FollowupSchedule = (props) => {
    console.log('asdfassdf', props)
    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Future Followups</Modal.Title>
            </Modal.Header>
            <div className="card">
                <div className="card-body p-2">
                    <div className="p-2">
                        Followups for : <span className="font-weight-bold">{props.managerName}</span>
                    </div>
                    <table className="table table-card">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Followups</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.followupSchedule.map((item) => {
                                let date = `${item.the_year}/${item.the_month}/${item.the_day}`

                                return (
                                    <tr key={date}>
                                        <td>
                                            <Link to={`/${props.followupType === 'affiliate' ? 'affiliates' : 'advertisers' }/followups?adminId=${props.managerId}&startDate=${moment(date).format('YYYY/MM/DD')}&endDate=${moment(date).format('YYYY/MM/DD')}`}>{moment(date).format('YYYY/MM/DD')}</Link>
                                        </td>
                                        <td>{item.follow_cnt}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FollowupSchedule
