import { useState, useCallback } from 'react'
import { Button, Card, FormControl, Form } from 'react-bootstrap'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import callAPI from '../../../helpers/callAPI'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ModifyLeads = ({ showSystemMessage }) => {

    document.title = 'Batch Modify Leads'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'compliance_batch_modify')

    const [campaign, setCampaign] = useState({ id: 0, name: '' })
    const [formData, setFormData] = useState({
        leads: '',
        finalModifications: false,
        leadType: '',
        subjectLine: '',
        priority: 'Normal',
        leadsCount: 0,
        ticketId: 'Not Issued Yet',
    })
    const [submitting, setSubmitting] = useState(false)

    const validate = () => {
        if (formData.leads === '') {
            showSystemMessage('error', 'Please enter leads to modify')
            return false
        }
        if (formData.leadType === '') {
            showSystemMessage('error', 'Please select a lead type')
            return false
        }
        if (formData.subjectLine === '') {
            showSystemMessage('error', 'Please enter a subject line')
            return false
        }
        if (formData.priority === '') {
            showSystemMessage('error', 'Please select a priority')
            return false
        }
        if (formData.leads.toLowerCase().includes('missing') && campaign.id === 0) {
            showSystemMessage('error', 'Please select an offer')
            return false
        }
        return true
    }

    const submit = useCallback(async () => {
        setSubmitting(true)
        if (formData.finalModifications === true) {
            if (validate()) {
                const result = await callAPI('compliance/modifyLeads', 'POST', { ...formData, campaign })
                if (result.success) {
                    showSystemMessage('success', 'Leads modified.')
                    setFormData({
                        ...formData,
                        leadsCount: result.lead_count,
                        ticketId: (
                            <>
                                {result.ticketId} <br /> You will receive an email when your ticket is processed.
                            </>
                        ),
                    })
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            }
        } else {
            showSystemMessage('success', 'Please confirm final modifications to submit changes.')
            setFormData({ ...formData, leadsCount: formData.leads.trim().replace(/"/g, '').split('\n').length })
        }
        setSubmitting(false)
    }, [formData, campaign])

    const handleChange = useCallback(
        (e) => {
            const { name, type, checked, value } = e.target
            if (name === 'pubsUsingDomain' && checked) {
                setFormData({ ...formData, [name]: checked, sendEmail: false })
            } else if (name === 'sendEmail' && checked) {
                setFormData({ ...formData, [name]: checked, pubsUsingDomain: false })
            } else {
                setFormData({ ...formData, [e.target.name]: type === 'checkbox' ? checked : value })
            }
        },
        [formData]
    )

    return (
        <>
            <div className="page-header d-flex align-items-center justify-content-between">
                <h1 className="mb-h1">Batch Modify Leads (Rev-share and flat rate)</h1>
            </div>
            <div className="row">
                <div className="col-xl-6 pb-2">
                    <Card>
                        <Card.Body>
                            <div className="alert alert-secondary">
                                Copy and paste Click IDs for the leads you are modifying in this box. (max 1000)
                                <hr></hr>
                                Formatting:
                                <br></br>
                                Rev-share: click_id, sale amount, status
                                <br></br>
                                Flat Rate: click_id, status
                                <br />
                                <br />
                                If this is for MISSING LEADS - YOU MUST SELECT AN OFFER FROM THE DROPDOWN. Enter <i>missing</i> for the click_id
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="w-25 mr-2">Campaign for missing leads:</span>
                                <span className="w-75">
                                    <CampaignSearch setCampaign={setCampaign} />
                                </span>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                                <span className="w-25 mr-2">Lead Type:</span>
                                <div className="w-75">
                                    <Form.Control as="select" className="w-auto d-inline-block" name="leadType" value={formData.leadType} onChange={handleChange}>
                                        <option value="">Select Lead Type</option>
                                        <option value="Flat">Flat Rate</option>
                                        <option value="Rev-Share">Rev Share</option>
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                                <span className="w-25 mr-2">Email Subject Line:</span>
                                <div className="w-75">
                                    <Form.Control type="text" className="w-75 d-inline-block" placeholder="Email subject" name="subjectLine" value={formData.subjectLine} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="d-flex align-items-center my-2">
                                <span className="w-25 mr-2">Email Priority:</span>
                                <div className="w-75">
                                    <Form.Control as="select" className="w-auto d-inline-block" name="priority" value={formData.priority} onChange={handleChange}>
                                        <option value="Normal">Normal</option>
                                        <option value="Urgent">Urgent</option>
                                    </Form.Control>
                                </div>
                            </div>
                            <Form.Control as="textarea" aria-label="With textarea" name="leads" value={formData.leads} onChange={handleChange} rows={15} />
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-between align-items-center">
                            <Button size="sm" variant="primary" onClick={submit} disabled={submitting}>
                                Modify Leads {submitting && <FontAwesomeIcon icon="spinner" spin />}
                            </Button>
                            <Form.Check type="checkbox" label="Check this box when you are ready to run the final modifications." name="finalModifications" value={formData.finalModifications} onChange={handleChange} />
                        </Card.Footer>
                    </Card>
                </div>
                <div className="col-xl-6 pb-0">
                    <Card>
                        <Card.Body>
                            <b>Leads to be modified:</b> {formData.leadsCount}
                            <br />
                            <br />
                            <b>Ticket ID:</b> {formData.ticketId}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default ModifyLeads
