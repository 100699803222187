import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import useCallApi from '../../hooks/useCallApi'

export default function CampaignSearch({ campaign, setCampaign, setFormData, advertiserId, order, hideChange, clearSearchBox }) {
    /*
        Props:
        1. campaign OBJECT {id: 0, name: ''}
        2. setCampaign FUNCTION - Sets an affiliate object {id: 0, name: ''} in the parent component
        3. setFormData FUNCTION  - Modifies an object from the parent component by adding an campaign object to it {...object, campaign: {id:0,  name:''} }
        3. advertiserId INT - limits the results to a particular advertiser
    */

    // Get the campaigns
    let apiUrl = `campaigns/search/mini`

    if (advertiserId > 0) {
        apiUrl += `&advertiserId=${advertiserId}`
    }

    const { dataLoaded, data, refetch, setData } = useCallApi(apiUrl, 'GET', {}, [])
    const [showInput, setShowInput] = useState(campaign ? false : true)
    const [refresh, setRefresh] = useState(0)

    useEffect(() => {
        // If the campaign object toggles between null and an object after the component mounts, show or hide the input
        if (campaign && campaign.id > 0) {
            setShowInput(false)
        } else {
            setShowInput(true)
        }
    }, [campaign])
    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
    }

    const handleOnHover = (result) => {
        // the item hovered
    }

    const handleOnSelect = (item) => {
        // This can be used to directly modify a campaign object from the parent
        if (setCampaign) {
            setCampaign(item)
        }

        // This can be used if the campaign data needs to be ADDED to a data object from the parent
        if (setFormData) {
            setFormData((prevData) => ({ ...prevData, campaign: item }))
        }

        if (clearSearchBox) {
            setRefresh(refresh + 1)
        }
    }

    const handleOnFocus = () => { }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>
                    {item.name}
                </span>
            </>
        )
    }

    const getProcessedData = () => {
        let newArray = []
        if (dataLoaded) {
            data.search_results.forEach(item => {
                newArray.push({
                    id: item.id,
                    name: `${item.name} (${item.id}) (${item.status.name})`
                })
            })
        }
        return newArray
    }

    return (
        <>
            {showInput ? (
                dataLoaded ? (
                    <ReactSearchAutocomplete
                        key={refresh}
                        items={getProcessedData()}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        formatResult={formatResult}
                        maxResults={100}
                        fuseOptions={{ keys: ['name', 'id'] }}
                        styling={{
                            height: '27px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            border: '1px solid #ced4da',
                            fontSize: '1rem',
                            zIndex: order ? order * 99 : 99,
                        }}                        
                    />
                ) : (
                    <div
                        style={{
                            height: '29px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            border: '1px solid #ced4da',
                            padding: '3px',
                        }}
                    >
                        <FontAwesomeIcon icon="spinner" spin />
                    </div>
                )
            ) : (
                <div className="d-flex align-items-center">
                    <div>
                        {campaign.name ? campaign.name : campaign.id}
                    </div>
                    {!hideChange && (
                        <div className="pl-3">
                            <button className="btn btn-warning btn-sm" onClick={() => setShowInput(true)}>
                                Change
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
