import queryString from 'query-string';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch';
import CountryDropdown from '../../../components/CountryDropdown/CountryDropdown';
import FlagDropdown from '../../../components/FlagDropdown/FlagDropdown';
import { SystemMessageContext } from '../../../context/SystemMessageContext';
import useCallApi from '../../../hooks/useCallApi';
import useDateRangeFilter from '../../../hooks/useDateRangeFilter';
import Affiliate from '../../../templates/Affiliate/Affiliate';
import Number from '../../../templates/Number/Number';
import ReportTable from '../../../templates/ReportTable/ReportTable';
import Loading from '../../../templates/UI/Loading/Loading';
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter';

export default function AffiliateAnalyzer() {

    document.title = 'Affiliate Analyzer'

    const { showSystemMessage } = useContext(SystemMessageContext)

    const history = useHistory()
    const location = useLocation()
    let urlParams = queryString.parse(location.search)
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const [flagOption, setFlagOption] = useState(urlParams.flagOption || "Y")
    const [flagId, setFlagId] = useState(urlParams.flagId || false)
    const [campaign, setCampaign] = useState((urlParams.campaignId && urlParams.campaignName) ? { id: urlParams.campaignId, name: urlParams.campaignName } : false)
    const [exclusive, setExclusive] = useState(urlParams.exclusive || false)
    const [proxyScore, setProxyScore] = useState(urlParams.proxyScore || false)
    const [country, setCountry] = useState(urlParams.country || false)

    let initUrl = `compliance/affiliateAnalyzer?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

    const buildUrl = (url) => {
        if (flagOption) {
            url = url + `&flagOption=${flagOption}`
        }
        if (flagId) {
            url = url + `&flagId=${flagId}`
        }
        if (campaign) {
            url = url + `&campaignId=${campaign.id}&campaignName=${campaign.name}`
        }
        if (exclusive) {
            url = url + `&exclusive=${exclusive}`
        }
        if (proxyScore) {
            url = url + `&proxyScore=${proxyScore}`
        }
        if (country) {
            url = url + `&country=${country}`
        }
        return url
    }

    const { data, dataLoaded, refetch } = useCallApi(buildUrl(initUrl), 'GET', {}, [], showSystemMessage)

    const submitHandler = () => {
        let url = `/${buildUrl(initUrl)}`
        history.push(url)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} priority={tableData.data[tableData.row.index].aff_priority_value} status={tableData.data[tableData.row.index].aff_status_value} affiliateStatus={tableData.data[tableData.row.index].affiliate_offer_status} holdPayment={tableData.data[tableData.row.index].hold_payment} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Leads</div>,
                accessor: 'lead_count',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Number value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Leads with &gt; 80 Proxy</div>,
                accessor: 'proxy_lead_count',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Number value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Proxy %</div>,
                accessor: 'proxy_percentage',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <><Number value={value * 100} />%</>
                },
            }
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={data.affiliates}
                    columns={columns}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Analyzer</h1>
            </div>
            <div className="row">
                <div className="col-xl-3">
                    <div className="card mb-3">
                        <div className="p-2 d-flex justify-content-between align-items-center font-weight-bold border-bottom">
                            Filters
                        </div>
                        <table className="table table-striped table-card m-0">
                            <tbody>                                
                                <tr>
                                    <td className="border-top-0">
                                        Campaign
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <div>
                                                <div className="mb-2">
                                                    <CampaignSearch campaign={campaign ? campaign : false} setCampaign={setCampaign} />
                                                </div>
                                                <div className="alert alert-info mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="promoSwitch1" onClick={() => setExclusive(!exclusive)} checked={exclusive} />
                                                        <label className="custom-control-label" for="promoSwitch1">
                                                            Affiliate has not promoted any other campaigns
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="alert alert-info mb-2">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="proxySwitch" onClick={() => setProxyScore(!proxyScore)} checked={proxyScore} />
                                                        <label className="custom-control-label" for="proxySwitch">
                                                            50% of affiliate's leads have proxy scores of 80 or higher
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Campaign promotion period
                                    </td>
                                </tr>
                                <tr>  
                                    <td>          
                                        <div className="p-2">
                                            <span className='d-flex'>
                                                <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Registration Country</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <CountryDropdown country={country} setCountry={setCountry} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Flag</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="p-2">
                                            <select className="form-control form-control-sm" defaultValue={flagOption} onChange={(e) => setFlagOption(e.target.value)}>
                                                <option value="Y">Has flag</option>
                                                <option value="N">Does not have flag</option>
                                            </select>
                                        </div>
                                        <div className="p-2">
                                            <FlagDropdown flagId={flagId} setFlagId={setFlagId} systemMessage={showSystemMessage} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <div className="p-2">
                                            <button className="btn btn-primary" onClick={() => submitHandler()}>
                                                Apply Filters
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-header"><h1 className="mb-h1-dark">Results</h1></div>
                        <div className="card-body m-0 p-0">
                            {/* ID Affiliate Leads Leads/proxy */}
                            {
                                dataLoaded ? data.success ? getReportOutput() : <div className="p-3">No stats for the current parameters.</div> : <Loading></Loading>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}