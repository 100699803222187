import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useCallApi from '../../../hooks/useCallApi'
import { Card, Button, Form, CardColumns } from 'react-bootstrap'
import moment from 'moment'
import Loading from '../../../templates/UI/Loading/Loading'
import Dollars from '../../../templates/Dollars/Dollars'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'

export default function TwoFASetup({ defaultAdminId, showSystemMessage }) {

    document.title = 'Google 2FA Setup'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'twofasetup')

    const [adminId, setAdminId] = useState(auth.user.id)
    const [data, setData] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    const refetch = async (adminId) => {
        let result = await callAPI(`admin/${adminId}/twoFASetup`, 'GET')
        if (result.success){            
            setData(result)
        } else {
            setData(false)
        }
        setDataLoaded(true)
    }

    useEffect(()=>{
        refetch(adminId)
    }, [adminId])

    const deleteKey = async () => {
        const result = await callAPI(`admin/${adminId}/twoFASetup`, 'DELETE')
        if (result.success) {
            showSystemMessage('success', '2FA key deleted')
            refetch(adminId)
        } else {
            showSystemMessage('error', 'Error deleting 2FA key')
        }
    }

    const updateMasterKey = async () => {
        const result = await callAPI(`admin/${adminId}/twoFASetup/master`, 'PUT')
        if (result.success) {
            showSystemMessage('success', '2FA key updated')
            refetch(adminId)
        } else {
            showSystemMessage('error', 'Error updating 2FA key')
        }
    }

    const [qrCode, setQRCode] = useState('')
    const [enc_otp, setEnc_otp] = useState('')

    const generateQRCode = async () => {
        const result = await callAPI(`admin/${adminId}/twoFASetup/qrcode`, 'GET')
        if (result.success) {
            // showSystemMessage('success', '2FA key updated')
            setQRCode(result.qrCode)
            setEnc_otp(result.encrypted_otp)
        } else {
            showSystemMessage('error', 'Error updating 2FA key')
        }
    }
    
    const [valdiationCode, setValidationCode] = useState(0)

    const testValid = async () => {
        // console.log(enc_otp, valdiationCode)        
        const result = await callAPI(`admin/${adminId}/twoFASetup/validate`, 'PUT', { enc_otp: enc_otp, token: valdiationCode })
        if (result.success) {
            showSystemMessage('success', '2FA key has been updated.')            
        } else {
            showSystemMessage('error', '2FA key is not valid.')            
        }
        refetch(adminId)
    }

    return (
        <div className="col-md-6">
            <Card className="mb-3">
                <Card.Header className=" justify-content-between align-items-center">
                    <span className="d-flex align-items-center">
                        <div className="mr-2">2FA Setup For</div>
                        <div>
                            <AdminPicker setAdminId={setAdminId} adminId={adminId} />
                        </div>
                    </span>
                </Card.Header>
                {dataLoaded ? (
                    <>
                        { data.success ?
                            data.TwoFA ? (
                                <>
                                    <Card.Body>
                                        <div>2FA authentication is enabled for this user.</div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button className="mr-2 mb-2" variant="danger" size="sm" onClick={deleteKey}>
                                            Delete 2FA Key
                                        </Button>
                                        <Button className="mr-2 mb-2" variant="success" size="sm" onClick={updateMasterKey}>
                                            Set User's key to master key
                                        </Button>
                                    </Card.Footer>
                                </>
                            ) : (
                                <>
                                    <Card.Body>
                                        <h4>How to set your 2-factor authentication:</h4>
                                        <u>
                                            <h4 className="text-danger">Warning: DO NOT USE until OTT IS COMPLETELY PHASED OUT. If OTT is still being used, use the OTT version, then run aes_convert_passwords_OTP_key to update the Admin version.</h4>
                                        </u>
                                        {/* Remove this warning when OTT is phased out, CFM uses a different encryption method so this tool only submits otp_key to the administrators table rather than QRKey that cfm provides.*/}
                                        <ol>
                                            <li>Click the Generate QR Code button.</li>
                                            <li>Download Google Authenticator on your mobile device.</li>
                                            <li>Scan the QR code with Google Authenticator.</li>
                                            <li>Type the 6 digit code from Google Authenticator into the "Activate" box.</li>
                                            <li>Click activate.</li>
                                        </ol>
                                        <Card className={qrCode ? 'd-span w-50' : 'd-none'}>
                                            <img src={qrCode} />
                                        </Card>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button className="mr-2 mb-2" variant="warning" size="sm" onClick={generateQRCode}>
                                            Generate QR Code
                                        </Button>
                                        <Button className="mr-2 mb-2" variant="success" size="sm" onClick={updateMasterKey}>
                                            Set User's key to master key
                                        </Button>
                                        {qrCode && (
                                            <>
                                                <Form.Control className="mr-2 mb-2 w-50" type="number" placeholder="0" value={valdiationCode} onChange={(e) => setValidationCode(e.target.value)} />
                                                <Button className="mr-2 mb-2" variant="primary" size="sm" onClick={testValid}>
                                                    Verify 2FA
                                                </Button>
                                            </>
                                        )}
                                    </Card.Footer>
                                </>
                            )
                        :
                            <div className="card-body">User does not exist</div>
                        }
                    </>
                ) : (
                    <Loading />
                )}
            </Card>
        </div>
    )
}
