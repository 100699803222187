import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { useAuth, useProtectedPage } from '../../context/AuthContext'
import callAPI from '../../helpers/callAPI'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Loading from '../../templates/UI/Loading/Loading'
import AddFilterOverride from './AddFilterOverride/AddFilterOverride'

export default function LinkFilterOverrides({ affiliateId, campaignId, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_filter_overrides')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})
    const [overrides, setOverrides] = useState([])
    const [showOverrideModal, setShowOverrideModal] = useState(false)
    const [filterSettings, setFilterSettings] = useState({ ip: false, device: false, os: false })

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        getReport()
    }, [affiliateId])

    const getReport = () => {
        setDataLoaded(false)

        let url

        url = affiliateId ? `affiliate/${affiliateId}/filterOverrides` : `campaign/${campaignId}/filterOverrides`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    affiliateId ? setAffiliate(result.affiliate) : setCampaign(result.campaign)
                    document.title = affiliateId ? `${result.affiliate.name}: Filter Overrides` : `${result.campaign.name}: Filter Overrides`
                    setOverrides(result.overrides)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    document.title = 'Filter Overrides'
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching campaign caps: ${error}`)
                document.title = 'Filter Overrides'
                showSystemMessage('error', 'An error occurred while fetching campaign caps')
            })
    }

    const handleEdit = (itemId, itemName, filters) => {
        if (campaignId) {
            // We are currently editing a campaign. We need to set some affiliate info on edit
            setAffiliate({ id: itemId, name: itemName })
        } else if (affiliateId) {
            setCampaign({ id: itemId, name: itemName })
        }

        setFilterSettings(filters)

        setShowOverrideModal(true)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return affiliateId ? <Campaign oid={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status.value} /> : <Affiliate aid={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status.value} priority={tableData.data[tableData.row.index].priority.value} />
                },
            },
            {
                Header: affiliateId ? 'Campaign Status' : 'Affiliate Status',
                accessor: 'status.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Advertiser Manager' : 'Affiliate Manager',
                accessor: 'admin.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Override Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY/MM/DD')}</>
                },
            },
            {
                Header: 'IP',
                accessor: 'filters.ip',
                conditionalClassName: (value) => (value ? 'bgSuccess25' : ''),
                Cell: ({ cell: { value } }) => {
                    return <>{value ? 'Yes' : 'No'}</>
                },
            },
            {
                Header: 'OS',
                accessor: 'filters.os',
                conditionalClassName: (value) => (value ? 'bgSuccess25' : ''),
                Cell: ({ cell: { value } }) => {
                    return <>{value ? 'Yes' : 'No'}</>
                },
            },
            {
                Header: 'Device',
                accessor: 'filters.device',
                conditionalClassName: (value) => (value ? 'bgSuccess25' : ''),
                Cell: ({ cell: { value } }) => {
                    return <>{value ? 'Yes' : 'No'}</>
                },
            },
            {
                Header: ' ',
                accessor: 'edit',
                Cell: (tableData) => {
                    return (
                        <button
                            className="btn btn-sm btn-warning"
                            onClick={() => {
                                handleEdit(tableData.data[tableData.row.index].id, tableData.data[tableData.row.index].name, tableData.data[tableData.row.index].filters)
                            }}
                        >
                            Edit
                        </button>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={overrides} columns={columns} />
            </React.Fragment>
        )
    }

    const handleClose = (isEdited = false) => {
        // Reset selection

        if (affiliateId) {
            setCampaign({})
        } else if (campaignId) {
            setAffiliate({})
        }

        setFilterSettings({ ip: false, device: false, os: false })

        setShowOverrideModal(false)

        if (isEdited) {
            getReport()
        }
    }

    return (
        <div>
            <AddFilterOverride affiliateId={affiliateId} campaignId={campaignId} rowAffiliate={affiliate} rowCampaign={campaign} showSystemMessage={showSystemMessage} handleClose={handleClose} isOpen={showOverrideModal} filterSettings={filterSettings} />
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Link Filter Overrides</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="d-flex justify-content-between p-3 align-items-center">
                            <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliateId ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : spinner}</h1>
                            <button type="button" className="btn btn-sm btn-warning" onClick={() => setShowOverrideModal(true)}>
                                Add Override
                            </button>
                        </div>
                        {dataLoaded ? overrides.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                    </div>
                </div>
            </div>
        </div>
    )
}
