import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import BlacklistModal from './Components/BlacklistModal'
import EmailBlacklists from './Components/EmailBlacklists'
import IPBlacklists from './Components/IPBlacklist'
import RedirectAffiliateBlacklists from './Components/RedirectAffiliateBlackLists'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

export default function Blacklist({ campaignId, showSystemMessage }) {

    document.title = 'Manage Blacklists'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'compliance_blacklist')

    const { dataLoaded, data, setData, refetch } = useCallApi(`compliance/blacklist`, 'GET', {}, [], showSystemMessage)
    const [isOpen, setIsOpen] = useState(false)
    const [defaultType, setDefaultType] = useState('email')

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center justify-content-between">
                <h1 className="mb-h1">Manage Blacklists</h1>
                <div className="ml-5">
                    <Button size="md" variant="warning" onClick={() => setIsOpen(true)}>
                        Add Blacklisted Item
                    </Button>
                </div>
            </div>

            <div className="row Affiliate">
                {isOpen && <BlacklistModal defaultType={defaultType} isOpen={isOpen} setIsOpen={setIsOpen} showSystemMessage={showSystemMessage} refetch={refetch} />}
                {dataLoaded ? (
                    <React.Fragment>
                        <div className="col-xl-6">
                            <EmailBlacklists showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} refetch={refetch} />
                            <IPBlacklists showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} refetch={refetch} />
                        </div>
                        <div className="col-xl-6 pb-0">
                            <RedirectAffiliateBlacklists showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} refetch={refetch} />
                        </div>
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </div>
        </React.Fragment>
    )
}
