import React, { useEffect, useState } from 'react'
import callAPI from '../../../../helpers/callAPI'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'

const TopCampaignsOfTheWeek = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [campaigns, setCampaigns] = useState([])

    const { showSystemMessage } = props

    useEffect(async () => {
        let url = `advertisers/topCampaigns`

        let result = await callAPI(url, 'GET')

        if (result.success === false) {
            showSystemMessage('error', 'There was an error while fetching top campaigns of the week. Please try again.')
        }

        setCampaigns(result.campaigns)
        setDataLoaded(true)
    }, [])

    return (
        <div className="card mb-3">
            <div className="card-header">
                <div className="font-weight-bold float-left">Top Campaigns of the Week</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped table-card m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Campaign</th>
                            <th>Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            campaigns.length === 0 ? (
                                <tr>
                                    <td colSpan={3}>No stats available </td>
                                </tr>
                            ) : (
                                campaigns.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>
                                                <Campaign oid={item.id} name={item.name} />
                                            </td>
                                            <td>{item.rate.rate}</td>
                                        </tr>
                                    )
                                })
                            )
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TopCampaignsOfTheWeek
