import React, { useState, useEffect } from 'react'

import Loading from '../../../../templates/UI/Loading/Loading'
import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import Applications from '../Applications/Applications'
import ComplianceFollowups from '../ComplianceFollowups/ComplianceFollowups'
import RejectedAppsToReview from '../RejectedAppsToReview/RejectedAppsToReview'
import FraudReport from '../FraudReport/FraudReport'
import QualityCheckAffiliates from '../QualityCheckAffiliates/QualityCheckAffiliates'
import NewestAffiliates from '../NewestAffiliates/NewestAffiliates'
import FraudPulls from '../FraudPulls/FraudPulls'
import FraudWatchCampaigns from '../FraudWatchCampaigns/FraudWatchCampaigns'
import HighMediumUnderJr from '../HighMediumUnderJr/HighMediumUnderJr'
import PendingFirstPayment from '../PendingFirstPayment/PendingFirstPayment'
import FraudWatchAffiliates from '../FraudWatchAffiliates/FraudWatchAffiliates'
import ReversalRequestsDashboard from '../ReversalRequests/ReversalRequestsDashboard'

export default function ComplianceManager({ manager, setManager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'compliance_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Compliance Manager</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <ComplianceFollowups manager={manager} />
                            <Applications manager={manager} showSystemMessage={showSystemMessage} mode="compliance" />
                            <RejectedAppsToReview manager={manager} />
                        </div>
                        <div className="col-lg-8">
                            <FraudReport manager={manager} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <PendingFirstPayment />
                                    <FraudWatchCampaigns manager={manager} />
                                    <FraudWatchAffiliates manager={manager} />
                                    <QualityCheckAffiliates manager={manager} />
                                    <FraudPulls showSystemMessage={showSystemMessage} />
                                </div>
                                <div className="col-lg-6">
                                    <ReversalRequestsDashboard status="C" />
                                    <HighMediumUnderJr manager={manager} />
                                    <NewestAffiliates manager={manager} />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
