import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'

import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import ManagerDropdown from '../../../templates/ManagerDropdown/ManagerDropdown'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import styles from '../../../components/SpecialRates/SpecialRatesForAffiliate.module.scss'
import useQueryParams from '../../../hooks/useQueryParams'
import callAPI from '../../../helpers/callAPI'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import getAffiliateStatus from '../../../helpers/getAffiliateStatus'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'

// Followups list for affiliates and affiliates

const AffiliateFollowupReport = ({ showSystemMessage }) => {
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    document.title = 'Affiliate Followup Report'

    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        getReport()
    }, [location])

    const refreshReport = (e) => {
        e.preventDefault()
        let url = `/affiliates/followups?adminId=${adminId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`

        history.push(url)
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = buildUrl(`affiliates/followups?adminId=${adminId}&startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    // setManagerId(result.admin_id)
                    setReport(result.report)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load followups list.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching followup list: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching followup list: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return <span><Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status.value} priority={tableData.data[tableData.row.index].affiliate.priority.value} />{(tableData.data[tableData.row.index].experience_level.value === 3 || tableData.data[tableData.row.index].experience_level.value === 2) && tableData.data[tableData.row.index].tier === 1 ? <span className="ml-2">⭐</span> : ''}</span>
                },
            },
            {
                Header: 'Status',
                accessor: 'affiliate.status',
                Cell: ({ cell: { value } }) => {
                    return <>{value.name}</>
                },
            },
            {
                Header: 'Experience',
                accessor: 'experience_level.name',
                Cell: (tableData) => {
                    return <span className={ ((tableData.data[tableData.row.index].experience_level.value === 2 || tableData.data[tableData.row.index].experience_level.value === 3) && tableData.data[tableData.row.index].tier === 1)  ? 'text-success font-weight-bold' : ''}>{tableData.data[tableData.row.index].experience_level.name}</span>
                },
            },
            {
                Header: 'Country',
                accessor: 'country',
                Cell: (tableData) => {
                    return <span className={tableData.data[tableData.row.index].tier === 1 ? 'text-success font-weight-bold' : ''}>{tableData.data[tableData.row.index].country}</span>
                },
            },
            {
                Header: 'Follow-up Date',
                accessor: 'next_followup_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm')}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={report}
                    columns={columns}
                    getRowProps={(row) => {

                        let pastDate = false

                        let todayDate = moment().startOf('day')
                        let lastFollowupDate = moment(row.original.last_followup_date)

                        let diffDays = todayDate.diff(lastFollowupDate, 'days')

                        if ((row.original.affiliate.priority.value === 1 && diffDays > 7) || (row.original.affiliate.priority.value !== 1 && diffDays > 14)) {
                            pastDate = true
                        }

                        if (pastDate) {
                            return {
                                className: 'bgDanger25',
                            }
                        }
                    }}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Followup List</h1>
            </div>
            <div className='row'>
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">Followups for: </h1>
                                        <div className="mr-2"><AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_SENIOR', 'AM_JUNIOR', 'AM_JR_TEAMLEAD']} /></div>
                                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                        <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                            Refresh Report
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AffiliateFollowupReport
