import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'
import ReportTable from '../../../../templates/ReportTable/ReportTable'
import Loading from '../../../../templates/UI/Loading/Loading'

export default function IPBlacklists({ showSystemMessage, data, dataLoaded, refetch }) {
    const [deleteIndex, setDeleteIndex] = useState(-1)

    const deleteBlacklist = async (type, notes, filter) => {
        const response = await callAPI(`compliance/blacklist`, 'DELETE', { type, notes, filter })
        if (response.success) {
            showSystemMessage('success', 'IP address deleted successfully')
            setDeleteIndex(-1)
            refetch()
        } else {
            showSystemMessage('error', response.errors.message)
            setDeleteIndex(-1)
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'IP Address',
                accessor: 'remote_host',
                Cell: ({ value }) => {
                    return <>{value}</>
                }
            },
            {
                Header: 'Notes',
                accessor: 'notes',
                Cell: ({ value }) => {
                    return <>{value}</>
                }
            },
            {
                Header: '',
                accessor: 'blacklists',
                Cell: (tableData) => {
                    let index = tableData.row.index

                    return (index === deleteIndex ? <button className='btn btn-danger btn-sm' onClick={() => deleteBlacklist(tableData.data[tableData.row.index].type, tableData.data[tableData.row.index].notes, tableData.data[tableData.row.index].remote_host)}>Confirm</button> : <button className='btn btn-danger btn-sm' onClick={() => setDeleteIndex(tableData.row.index)}>Delete</button>)
                }
            }
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.blacklist.filter(item => item.type === 'ip')} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">IP Blacklists</Card.Header>
            {dataLoaded ? getReportOutput() : (
                <Loading />
            )}
        </Card>
    )
}
