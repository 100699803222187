import Loading from '../../../templates/UI/Loading/Loading'
import { Modal, Form, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'

const KeywordsModal = ({ showSystemMessage, isOpen, setIsOpen, refetch }) => {
    const [keyword, setKeyword] = useState()

    const createKeyword = async () => {
        const result = await callAPI('campaigns/createKeyword', 'POST', { keyword })
        if (result.success) {
            refetch()
            showSystemMessage('success', 'Tracking created successfully')
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create Keyword</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span>Keyword: </span>
                    <div className="w-75">
                        <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={createKeyword}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default KeywordsModal
