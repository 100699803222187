import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'

import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import callAPI from '../../../helpers/callAPI'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'
import AffiliateSearch from '../../AffiliateSearch/AffiliateSearch'

const PaymentDelayOverridesModal = ({ dataLoaded, affiliate, campaign, isOpen, setIsOpen, refetch, showSystemMessage }) => {
    const [formData, setFormData] = useState({})
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        if (affiliate) {
            setFormData({ ...formData, affiliate: affiliate })
        }
        if (campaign) {
            setFormData({ ...formData, campaign: campaign })
        }
    }, [affiliate, campaign])

    const createPaymentDelayOverride = async (e) => {
        setSubmitting(true)
        e.preventDefault()

        let apiUrl = affiliate ? `affiliate/${affiliate.id}/paymentDelayOverrides` : `campaign/${campaign.id}/paymentDelayOverrides`

        const result = await callAPI(apiUrl, 'POST', formData)
        if (result.success) {
            showSystemMessage('success', result.message)
            setIsOpen(false)
            setSubmitting(false)
            refetch()
        } else if (!result.success) {
            showSystemMessage('error', result.errors.message)
            setSubmitting(false)
        } else {
            showSystemMessage('error', 'There was an error creating the payment delay override')
            setSubmitting(false)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Form onSubmit={createPaymentDelayOverride}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create Payment Delay Override for: &nbsp;
                        {dataLoaded && affiliate ? <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : null}
                        {dataLoaded && campaign ? <Campaign oid={campaign.id} name={campaign.name} status={campaign.status.value} /> : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {dataLoaded ? (
                        <>
                            {affiliate ? (
                                <>
                                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                        <span>Affiliate: </span>
                                        <span className="w-75">{affiliate.id}</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                        <span>Campaign: </span>
                                        <div className="w-75">
                                            {' '}
                                            <CampaignSearch setFormData={setFormData} />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                        <span>Campaign: </span>
                                        <span className="w-75">{campaign.id}</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                        <span>Affiliate: </span>
                                        <div className="w-75">
                                            {' '}
                                            <AffiliateSearch setFormData={setFormData} />
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Payment Delay: </span>
                                <div className="w-75 d-flex align-items-center">
                                    {' '}
                                    <Form.Control type="number" onChange={(e) => setFormData({ ...formData, payment_delay: e.target.value })} /> <span className="ml-1">days</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span style={{ width: '20%' }}>Payment Delay Mode: </span>
                                <div className="w-75">
                                    {' '}
                                    <Form.Control
                                        as="select"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                payment_delay_mode: e.target.value,
                                            })
                                        }
                                        required
                                    >
                                        <option value=""></option>
                                        <option value="END_OF_MONTH">From end of month</option>
                                        <option value="LEAD_DATE">From lead date</option>
                                    </Form.Control>
                                </div>
                            </div>
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant="primary" type="submit" name="Create" spin={submitting} />
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default PaymentDelayOverridesModal
