import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

const AuthContext = React.createContext()

// Custom Hook to access auth context
export function useAuth() {
    return useContext(AuthContext)
}

// Custom hook to for protected pages. If authorization fails, it will go to the /noAccess page
export function useProtectedPage(permissions, page) {
    let history = useHistory()

    if (!permissions[page] || !permissions[page].view) {
        history.push('/noAccess')
    }
}

// Custom hook to for protected content. Will return true or false. To be used to show/hide content.
export function useProtectedContent(permissions, page) {
    if (!permissions[page] || !permissions[page].view) {
        return false
    }
    return true
}

// Custom hook to for protected content. Will return true or false. To be used to show/hide editable form fields.
export function useEditableContent(permissions, page) {
    if (!permissions[page] || !permissions[page].edit) {
        return false
    }
    return true
}

// Custom hook to for protected content. Will return true or false. To be used specifically for set manager dropdown where required.
export function useSetManager(permissions, page) {
    if (!permissions[page] || !permissions[page].set_manager) {
        return false
    }
    return true
}

export function AuthProvider({ children, administrator, permissions }) {
    const [user, setUser] = useState({
        user: administrator,
        permissions: permissions,
    })

    useEffect(() => {
        setUser({
            user: administrator,
            permissions: permissions,
        })
    }, [administrator, permissions])

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
}
