import React, { useState, useEffect } from 'react'
import { MoreVertical } from 'react-feather'
import JSONPretty from 'react-json-pretty'

import TableContainer from '../TableContainer'
import '../TableContainer.css'

import './ReportPost.css'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import ReportsMoreAvatar from '../AvatarReportsMore/AvatarReportsMore'
import callAPI from '../../../../helpers/callAPI'

const ReportPost = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [property, setProperty] = useState({ propertyId: 0, name: '' })
    const [site, setSite] = useState({ siteId: 0, name: '' })
    const [survey, setSurvey] = useState({ surveyId: 0, name: '' })
    const [event, setEvent] = useState({ eventId: 0, name: '' })
    const [report, setReport] = useState([])

    const { propertyId = 0, siteId = 0, surveyId = 0, eventId = 0, clientId = 0, startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            setDataLoaded(false)

            let fetchUrl = `reports/avatar/post?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&eventId=${eventId}&clientId=${clientId}`

            let result = await callAPI(fetchUrl, 'GET')
            if (result['success'] === true && result.report) {
                setDataLoaded(true)
                setProperty({ propertyId: propertyId, name: result.property })
                setSite({ siteId: siteId, name: result.site })
                setSurvey({ surveyId: surveyId, name: result.survey })
                setEvent({ eventId: eventId, name: result.event })
                setReport(result.report)
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                showSystemMessage('error', result.errors.message)
            }
            showSystemMessage('error', 'Connection error.')
        }
        fetchData()
    }, [propertyId, siteId, surveyId, eventId, clientId, startDate, endDate])

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={report}
                    columns={[
                        {
                            Header: 'Date',
                            accessor: 'date',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Post Data',
                            accessor: 'post_data',
                            className: 'post-report-cell-width',
                            Cell: ({ cell: { value } }) => {
                                return <>{<JSONPretty data={value}></JSONPretty>}</>
                            },
                        },
                        {
                            Header: 'Response',
                            accessor: 'response_data',
                            className: 'post-report-cell-width',
                            Cell: ({ cell: { value } }) => {
                                return <>{<JSONPretty data={value}></JSONPretty>}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right"></div>,
                            accessor: 'leadActionId',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return (
                                    <>
                                        <ReportsMoreAvatar avatarSurveyResponseReport surveyId={surveyId} startDate={tableData.data[tableData.row.index].date} endDate={tableData.data[tableData.row.index].date} clientId={tableData.data[tableData.row.index].client_id} />
                                    </>
                                )
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="mx-2 alert alert-warning text-small">
                This report provides information on which affiliate brought us earnings from Avatar surveys. Leads here are NOT always payable to the affiliate displayed. For more information, click the <MoreVertical size={20} /> icon and view the actual conversion that was generated.
            </div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {property.propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name}></DrilldownItem>}
                {site.siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name}></DrilldownItem>}
                {survey.surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name}></DrilldownItem>}
                {event.eventId > 0 && <DrilldownItem for="Event" forText={event.name}></DrilldownItem>}
            </div>
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportPost
