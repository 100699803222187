const getCreativeType = (creativeId, creativeType, width = 0, height = 0) => {
    const search_creative_id = '918273'
    const contextual_creative_id = '918271'
    const call_creative_id = '918275'
    const raw_creative_id = '918277'

    if (creativeId == search_creative_id) {
        return 'Search Link'
    } else if (creativeId == contextual_creative_id) {
        return 'Contextual Link'
    } else if (creativeId == call_creative_id) {
        return 'Call'
    } else if (creativeId == raw_creative_id) {
        return 'Raw Link'
    } else if (creativeType === 'B') {
        return `Banner_${creativeId} (${width}x${height})`
    } else if (creativeType === 'E') {
        return `E-Mail_${creativeId}`
    } else if (creativeType === 'H') {
        return `HTML_${creativeId}`
    } else if (creativeType == 'T') {
        return `Text_${creativeId}`
    } else {
        return `Unknown_${creativeId}`
    }
}

module.exports = getCreativeType
