import moment from 'moment'
import { useState, useEffect } from 'react'

import callAPI from '../../../helpers/callAPI'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'

const PreviousCampaignRequests = ({ requestId, affiliateId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [previousRequests, setPreviousRequests] = useState([])

    useEffect(() => {
        getPreviousRequests()
    }, [affiliateId])

    const getPreviousRequests = () => {
        let url = `affiliate/${affiliateId}/campaignRequests/${requestId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success']) {
                    setPreviousRequests(result.previous_requests)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching advertiser campaign pulls')
                setDataLoaded(true)
            })
    }

    const getRejectReason = (reasonNum) => {
        let reason
        if (reasonNum === 1) reason = '(BAD INFO)'
        else if (reasonNum === 2) {
            reason = '(CAP)'
        } else if (reasonNum === 3) {
            reason = '(METHOD)'
        } else if (reasonNum === 4) {
            reason = '(LOW HISTORY)'
        } else if (reasonNum === 5) {
            reason = '(MANY PULLS)'
        } else if (reasonNum === 6) {
            reason = '(UNSUITABLE)'
        } else {
            reason = '(OTHER)'
        }
        return reason
    }

    return (
        <div className="card mb-3">
            <div className="card-header">Last 15 Campaign Requests</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    previousRequests.length === 0 ? (
                        <div className="p-3">No stats available.</div>
                    ) : (
                        <table className="table table-striped table-card m-0">
                            <tbody>
                                {previousRequests.slice(0, 15).map((item, index) => {
                                    let reqStatus = ``

                                    if (item.req_status.toLowerCase() === 'denied') {
                                        reqStatus = `${item.req_status} ${getRejectReason(item.reject_reason)}`
                                    } else {
                                        reqStatus = item.req_status
                                    }

                                    return (
                                        <tr key={`${item.item_id}-${index}`}>
                                            <td>
                                                <Campaign oid={item.item_id} name={item.item_name} status={item.item_status} />
                                            </td>
                                            <td>{reqStatus}</td>
                                            <td className="text-right">{moment(item.request_date).format('YYYY/MM/DD')}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default PreviousCampaignRequests
