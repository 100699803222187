import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'

export const HelpTextModal = ({ showSystemMessage, isOpen, setIsOpen, helpText, isCreate, deleteHelpText, refetch, data }) => {
    const [formData, setFormData] = useState({
        help_id: null,
        help_text: 0,
        help_title: 'S',
    })

    useEffect(() => {
        if (isCreate) {
            setFormData({
                help_id: '',
                help_text: '',
                help_title: '',
            })
        } else {
            setFormData(helpText)
        }
    }, [isCreate, helpText])

    const validateForm = () => {
        let isValid = true
        if (!(formData.help_id >= 0)) {
            showSystemMessage('error', 'Help ID must be greater than 0')
            isValid = false
            return isValid
        } else if (formData.help_title === '') {
            showSystemMessage('error', 'Help Title cannot be empty')
            isValid = false
            return isValid
        } else if (formData.help_text === '') {
            showSystemMessage('error', 'Help Data cannot be empty')
            isValid = false
            return isValid
        }
        // check if id is unique
        if (isCreate) {
            data.helpText.forEach((helpText) => {
                if (helpText.help_id == formData.help_id) {
                    showSystemMessage('error', 'Help ID must be unique')
                    isValid = false
                }
            })
        }

        return isValid
    }

    const handleSubmit = async () => {
        if (validateForm()) {
            if (isCreate) {
                const result = await callAPI('admin/helpText', 'POST', formData)
                if (result.success) {
                    showSystemMessage('success', 'Help Text created successfully')
                    setIsOpen(false)
                    refetch()
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            } else {
                const result = await callAPI(`admin/helpText`, 'PUT', formData)
                if (result.success) {
                    showSystemMessage('success', 'Help Text updated successfully')
                    setIsOpen(false)
                    refetch()
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            }
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Create' : 'Edit'} Help Text</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center mt-2">
                    <span className="w-50">Help ID:</span>
                    <Form.Control type="number" className="w-100" value={formData.help_id} onChange={(event) => setFormData((prev) => ({ ...prev, help_id: event.target.value }))} disabled={!isCreate} />
                </div>
                <div className="d-flex align-items-center mt-2">
                    <span className="w-50">Title:</span>
                    <Form.Control type="text" className="w-100" value={formData.help_title} onChange={(event) => setFormData((prev) => ({ ...prev, help_title: event.target.value }))} />
                </div>
                <div className="d-flex align-items-center mt-2">
                    <span className="w-50">Help Data:</span>
                    <Form.Control as="textarea" className="w-100" value={formData.help_text} onChange={(event) => setFormData((prev) => ({ ...prev, help_text: event.target.value }))} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="primary" size="sm" onClick={handleSubmit}>
                        Create
                    </Button>
                ) : (
                    <Button variant="primary" size="sm" onClick={handleSubmit}>
                        Save
                    </Button>
                )}
                {!isCreate && (
                    <Button variant="danger" size="sm" onClick={() => deleteHelpText(formData.help_id)}>
                        Delete
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}
