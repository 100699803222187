import React, { useEffect, useState } from 'react'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Dollars from '../../../templates/Dollars/Dollars'
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuth, useProtectedPage, useSetManager } from '../../../context/AuthContext'
import moment from 'moment'

const ReportTopAffiliates = ({ showSystemMessage }) => {
    document.title = 'Top Affiliates Last 30 Days'

    let auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliates_top')
    let showManagerDropdown = useSetManager(auth.permissions, 'affiliates_top')

    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const history = useHistory()
    const location = useLocation()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    useEffect(() => {
        getReport()
    }, [location])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} priority={tableData.data[tableData.row.index].affiliate.priority.value} status={tableData.data[tableData.row.index].affiliate.status.value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Month Earnings</div>,
                accessor: 'affiliate_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Month Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: (tableData) => {
                    return  <Link to={`/reports/stats/campaigns?affiliateId=${tableData.data[tableData.row.index].affiliate.id}&startDate=${moment().add(-30, 'days').format('YYYY-MM-DD')}&withAvatar=true`} className="text-primary font-weight-bold">
                                <Dollars value={tableData.data[tableData.row.index].margin} />
                            </Link>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const refreshReport = (e) => {
        e.preventDefault()

        let url = buildUrl(`/affiliates/top?`)
        history.push(url)
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = buildUrl(`reports/affiliates?startDate=${moment().add(-30, 'days').format('YYYY-MM-DD')}&endDate=${moment().endOf('month').format('YYYY-MM-DD')}&withAvatar=true`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.report)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }

                setDataLoaded(true)
            })
            .catch((error) => {
                console.log(`Error while fetching click referrers: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching top affiliates')
            })
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex p-3">
                                <div className="mr-4 d-flex align-items-center">
                                    <h1 className="mb-h1-dark">Top Affiliates Last 30 Days</h1>
                                </div>
                                {showManagerDropdown && (
                                    <>
                                        <div className="mr-4">
                                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                                Refresh Report
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportTopAffiliates
