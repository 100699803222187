import React, { useEffect, useState } from 'react'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Campaign from '../../../templates/Campaign/Campaign'
import moment from 'moment'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
const SupporessionReport = ({ showSystemMessage }) => {

    document.title = 'Suppression List Report'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_suppression_report')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    useEffect(() => {
        getReport()
    }, [])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'offer_id',
            },
            {
                Header: 'Campaign',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].offer_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status} />
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser Mgr',
                accessor: 'full_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Suppression Date',
                accessor: 'suppression_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? moment(value).format('YYYY-MM-DD') : ''}</>
                },
            },
            {
                Header: '',
                accessor: 'status_value',
                Cell: (tableData) => {
                    return <Link to={`/campaign/${tableData.data[tableData.row.index].offer_id}/settings`}>Add Suppression url</Link>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = 'campaigns/suppressionreport'

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.data)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }

                setDataLoaded(true)
            })
            .catch((error) => {
                console.log(`Error while fetching top campaigns: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching top campaigns')
            })
    }

    return (
        <div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <h1 className="mb-h1-dark">Suppression List Report</h1>
                        </div>
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default SupporessionReport
