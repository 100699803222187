import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'

import TableContainer from '../TableContainer'
import '../TableContainer.css'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import ReportsMoreAvatar from '../AvatarReportsMore/AvatarReportsMore'
import Dollars from '../../../../templates/Dollars/Dollars'
import callAPI from '../../../../helpers/callAPI'

const ReportSet = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [property, setProperty] = useState({ propertyId: 0, name: '' })
    const [site, setSite] = useState({ siteId: 0, name: '' })
    const [survey, setSurvey] = useState({ surveyId: 0, name: '' })
    const [affiliate, setAffiliate] = useState({ affiliateId: 0, name: '' })
    const [offer, setOffer] = useState({ offerId: 0, name: '' })
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])

    const { propertyId = 0, siteId = 0, surveyId = 0, affiliateId = 0, offerId = 0, startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            if (propertyId > 0) {
                setDataLoaded(false)

                let fetchUrl = `reports/avatar/set?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&offerId=${offerId}`

                let result = await callAPI(fetchUrl, 'GET')
                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setProperty({ propertyId: propertyId, name: result.property })
                    setSite({ siteId: siteId, name: result.site })
                    setSurvey({ surveyId: surveyId, name: result.survey })
                    setAffiliate({ affiliateId: affiliateId, name: result.affiliate })
                    setOffer({ offerId: offerId, name: result.offer })
                    setReport(result.report)
                    setTotals(result.totals)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            } else {
                setDataLoaded(true)
            }
        }
        fetchData()
    }, [propertyId, siteId, surveyId, affiliateId, offerId, startDate, endDate])

    const getDaysActive = (startDate, endDate) => {
        if (startDate !== '1900-01-01T00:00:00.000Z') {
            let start = moment(startDate)
            let end = moment()
            return end.diff(start, 'days') + 1
        } else {
            return 1
        }
    }

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <TableContainer
                    data={report}
                    totals={totals}
                    columns={[
                        {
                            Header: 'Property',
                            accessor: 'property',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'SiteFilter',
                            accessor: 'site',
                            hide: true,
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Site',
                            accessor: 'site1',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/site/${tableData.data[tableData.row.index].site.id}`}>{tableData.data[tableData.row.index].site.name}</Link>
                            },
                        },
                        {
                            Header: 'SurveyFilter',
                            accessor: 'survey',
                            hide: true,
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: 'Survey',
                            accessor: 'survey2',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/survey/${tableData.data[tableData.row.index].survey.id}`}>{tableData.data[tableData.row.index].survey.name}</Link>
                            },
                        },
                        {
                            Header: 'Status',
                            accessor: 'setStatus',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => (
                                <div data-tip="Total days running" style={{ textAlign: 'right' }}>
                                    Days
                                    <ReactTooltip />
                                </div>
                            ),
                            accessor: 'startDate',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{getDaysActive(value)}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Completions/Starts</div>,
                            accessor: 'surveyStats',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                            accessor: 'clicks',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/reports/daily?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${tableData.data[tableData.row.index].site.id}&surveyId=${tableData.data[tableData.row.index].survey.id}&affiliateId=${affiliateId}`}>{Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].clicks)}</Link>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                            accessor: 'conversions',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return <Link to={`/avatar/reports/conversions?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${tableData.data[tableData.row.index].site.id}&surveyId=${tableData.data[tableData.row.index].survey.id}&affiliateId=${affiliateId}`}>{Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}</Link>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Aff leads</div>,
                            accessor: 'payableLeads',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{Intl.NumberFormat('en-GB').format(value)}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Cost</div>,
                            accessor: 'cost',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars cost value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Revenue</div>,
                            accessor: 'revenue',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                            accessor: 'sales',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>eCPA</div>,
                            accessor: 'eCPA',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>epc</div>,
                            accessor: 'epc',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                            accessor: 'margin',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            sortType: 'basic',
                            Cell: ({ cell: { value } }) => {
                                return <Dollars highlight bold value={value} />
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Margin %</div>,
                            accessor: 'marginPct',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div style={{ textAlign: 'right' }}>Contribution %</div>,
                            accessor: 'marginContribution',
                            headerClassName: 'text-right',
                            className: 'text-right',
                            Cell: ({ cell: { value } }) => {
                                return <>{value}</>
                            },
                        },
                        {
                            Header: () => <div className="float-right"></div>,
                            accessor: 'leadActionId',
                            className: 'text-right',
                            Cell: (tableData) => {
                                return (
                                    <>
                                        <ReportsMoreAvatar avatarPostReport avatarAffiliateSummaryReport avatarSurveyQuestionReport avatarSurveyResponseReport subid avatarConversionReport avatarEventReport avatarOfferReport avatarAffiliateReport avatarDailyReport affiliateId={affiliateId} propertyId={propertyId} siteId={tableData.data[tableData.row.index].site.id} surveyId={tableData.data[tableData.row.index].survey.id} startDate={startDate} endDate={endDate} />
                                    </>
                                )
                            },
                        },
                    ]}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {property.propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name}></DrilldownItem>}
                {site.siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name} cancelDrilldownUrl={`/avatar/reports/daily?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=0&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&offerId=${offer.offerId}`}></DrilldownItem>}
                {survey.surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name} cancelDrilldownUrl={`/avatar/reports/daily?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=0&affiliateId=${affiliate.affiliateId}&offerId=${offer.offerId}`}></DrilldownItem>}
                {affiliate.affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliateId}`} forText={affiliate.name} cancelDrilldownUrl={`/avatar/reports/daily?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=0&offerId=${offer.offerId}`}></DrilldownItem>}
                {offer.offerId > 0 && <DrilldownItem for="Offer" forUrl={`/offer/${offerId}`} forText={offer.name} cancelDrilldownUrl={`/avatar/reports/daily?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&offerId=0`}></DrilldownItem>}
            </div>
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportSet
