export default {
    types: [
        {
            value: 'N',
            text: 'None',
        },
        {
            value: 'A',
            text: 'AOL',
        },
        {
            value: 'M',
            text: 'MSN',
        },
        {
            value: 'S',
            text: 'Skype',
        },
        {
            value: 'Y',
            text: 'Yahoo',
        },
    ],
}
