import React, { useState, useEffect } from 'react'
import useQueryParams from '../../../../hooks/useQueryParams'
import useDateRangeFilter from '../../../../hooks/useDateRangeFilter'

import ReportTable from '../../../../templates/ReportTable/ReportTable'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import { Link } from 'react-router-dom'
import Campaign from '../../../../templates/Campaign/Campaign'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import Number from '../../../../templates/Number/Number'
import ReportsStatsDropdown from '../../Templates/ReportsStatsDropdown/ReportsStatsDropdown'

export default function EarningsReport({ showSystemMessage, setAffiliate, setCampaign, setAdvertiser, setCSVFilename, setCSVHeaderLabels, setCSVDataLabels, setCSVReport }) {
    const { initStartDate, initEndDate } = useDateRangeFilter()
    const { withAvatar, buildUrl, hideNetwork } = useQueryParams()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])

    useEffect(() => {
        if (hideNetwork === 'true') {
            setCSVHeaderLabels(['Affiliate Id', 'Affiliate', 'Campaign ID', 'Campaign', 'Clicks', 'Conversions', 'Conversion Ratio', 'Aff Earnings', 'EPC'])
            setCSVDataLabels(['affiliate_id', 'affiliate_name', 'campaign_id', 'campaign_name', 'clicks', 'conversions', 'conversion_ratio', 'affiliate_earnings', 'epc'])
        } else {
            setCSVHeaderLabels(['Affiliate Id', 'Affiliate', 'Campaign ID', 'Campaign', 'Clicks', 'Conversions', 'Conversion Ratio', 'Net Earnings', 'Aff Earnings', 'Margin', 'EPC', 'nEPC'])
            setCSVDataLabels(['affiliate_id', 'affiliate_name', 'campaign_id', 'campaign_name', 'clicks', 'conversions', 'conversion_ratio', 'network_earnings', 'affiliate_earnings', 'margin', 'epc', 'nepc'])
        }
        getReport()
    }, [])

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = buildUrl(`reports/earnings?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    result.affiliate && setAffiliate(result.affiliate)
                    result.campaign && setCampaign(result.campaign)
                    setReport(result.report)
                    setTotals(result.totals)
                    setCSVReport(result.report)
                    setCSVFilename(`Earnings Report - ${initStartDate}-${initEndDate}`)
                    if (result.campaign) {
                        setCampaign(result.campaign)
                    }
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching earnings report: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching earnings report: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate Id',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate_name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} priority={tableData.data[tableData.row.index].affiliate.priority.value} status={tableData.data[tableData.row.index].affiliate.status.value} />
                },
            },
            {
                Header: 'Campaign Id',
                accessor: 'campaign_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign_name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                accessor: 'clicks',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Link to={buildUrl(`/reports/stats/daily?startDate=${initStartDate}&endDate=${initEndDate}&affiliateId=${tableData.data[tableData.row.index].affiliate_id}&campaignId=${tableData.data[tableData.row.index].campaign_id}`)}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].clicks)}`}</Link>
                }
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                accessor: 'conversions',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Link to={buildUrl(`/reports/stats/conversions?startDate=${initStartDate}&endDate=${initEndDate}&affiliateId=${tableData.data[tableData.row.index].affiliate_id}&campaignId=${tableData.data[tableData.row.index].campaign_id}`)}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}`}</Link>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>CR %</div>,
                accessor: 'conversion_ratio',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{!isNaN(value) ? <Number value={value} decimals={2} /> : 0} %</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Net Earnings</div>,
                accessor: 'network_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Aff Earnings</div>,
                accessor: 'affiliate_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                accessor: 'sales',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>EPC</div>,
                accessor: 'epc',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>nEPC</div>,
                accessor: 'nepc',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: ' ',
                className: 'text-right',
                Cell: (tableData) => {
                    return <ReportsStatsDropdown reportType="earnings" affiliateId={tableData.data[tableData.row.index].affiliate_id} campaignId={tableData.data[tableData.row.index].campaign_id} withAvatar={withAvatar} showSystemMessage={showSystemMessage} />
                },
            },
        ]

        if (hideNetwork == 'true') {
            columns = [
                {
                    Header: 'Affiliate Id',
                    accessor: 'affiliate_id',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                },
                {
                    Header: 'Affiliate',
                    accessor: 'affiliate_name',
                    Cell: (tableData) => {
                        return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} priority={tableData.data[tableData.row.index].affiliate.priority.value} status={tableData.data[tableData.row.index].affiliate.status.value} />
                    },
                },
                {
                    Header: 'Campaign Id',
                    accessor: 'campaign_id',
                    Cell: ({ cell: { value } }) => {
                        return <>{value}</>
                    },
                },
                {
                    Header: 'Campaign',
                    accessor: 'campaign_name',
                    Cell: (tableData) => {
                        return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} />
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                    accessor: 'clicks',
                    className: 'text-right',
                    Cell: (tableData) => {
                        return <Link to={buildUrl(`/reports/stats/daily?startDate=${initStartDate}&endDate=${initEndDate}&affiliateId=${tableData.data[tableData.row.index].affiliate_id}&campaignId=${tableData.data[tableData.row.index].campaign_id}`)}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].clicks)}`}</Link>
                    }
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                    accessor: 'conversions',
                    className: 'text-right',
                    Cell: (tableData) => {
                        return <Link to={buildUrl(`/reports/stats/conversions?startDate=${initStartDate}&endDate=${initEndDate}&affiliateId=${tableData.data[tableData.row.index].affiliate_id}&campaignId=${tableData.data[tableData.row.index].campaign_id}`)}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}`}</Link>
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>CR %</div>,
                    accessor: 'conversion_ratio',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <><Number value={value} decimals={2} /> %</>
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>Aff Earnings</div>,
                    accessor: 'affiliate_earnings',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <Dollars value={value} />
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                    accessor: 'sales',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <Dollars value={value} />
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>EPC</div>,
                    accessor: 'epc',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <Dollars value={value} />
                    },
                },
                {
                    Header: ' ',
                    className: 'text-right',
                    Cell: (tableData) => {
                        return <ReportsStatsDropdown reportType="earnings" affiliateId={tableData.data[tableData.row.index].affiliate_id} campaignId={tableData.data[tableData.row.index].campaign_id} withAvatar={withAvatar} showSystemMessage={showSystemMessage} />
                    },
                },
            ]
        }

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} totals={totals} />
            </React.Fragment>
        )
    }

    return <div>{dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
}
