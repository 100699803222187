import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../helpers/callAPI'
import Advertiser from '../../templates/Advertiser/Advertiser'
import Dollars from '../../templates/Dollars/Dollars'
import CustomButton from '../../templates/UI/CustomButton/CustomButton'

const CreateInvoiceModal = ({ isOpen, handleClose, advertiser, showSystemMessage }) => {
    const [totalLeads, setTotalLeads] = useState(0)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        setDataLoaded(false)

        callAPI(`advertiser/${advertiser.id}/totalLeads`, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setTotalLeads(result.total_leads)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching past 7 days total leads: ${error}`)
                showSystemMessage('error', 'Error while fetching past 7 days total leads')
            })
    }, [])

    const createInvoice = (e) => {
        e.preventDefault()
        setSubmitting(true)

        const postBody = {
            amount: e.target.amount.value,
            ccPayment: e.target.include_cc_payment.checked ? 'Y' : 'N',
        }

        callAPI(`advertiser/${advertiser.id}/createInvoice`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    setSubmitting(false)
                    handleClose(true)
                    showSystemMessage('success', result.message)
                } else if (result['success'] === false) {
                    setSubmitting(false)
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching past 7 days total leads: ${error}`)
                setSubmitting(false)
                showSystemMessage('error', 'Error while fetching past 7 days total leads')
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Create Invoice for - <Advertiser id={advertiser.id} name={advertiser.name} status={advertiser.status} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={createInvoice}>
                    <table className="table table-card table-borderless m-0">
                        <tbody>
                            <tr>
                                <td width="30%">Past 7 day total:</td>
                                <td>{dataLoaded ? <Dollars value={totalLeads} /> : spinner}</td>
                            </tr>
                            <tr>
                                <td>Amount to invoice:</td>
                                <td>
                                    <input type="number" name="amount" className="form-control w-50" required />
                                </td>
                            </tr>
                            <tr>
                                <td>Check if credit card payment required:</td>
                                <td>
                                    <input type="checkbox" name="include_cc_payment" />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <CustomButton type="submit" variant="primary" name="Save" spin={submitting} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateInvoiceModal
