import { Modal, Form } from 'react-bootstrap'
import React, { useState } from 'react'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'

import AffiliateSearch from '../AffiliateSearch/AffiliateSearch'
import CampaignSearch from '../CampaignSearch/CampaignSearch'
import callAPI from '../../helpers/callAPI'
import useCallApi from '../../hooks/useCallApi'
import CustomButton from '../../templates/UI/CustomButton/CustomButton'

const CampaignCountryOverridesModal = ({ showSystemMessage, affiliate, isOpen, setIsOpen, refetch, campaign }) => {
    const { dataLoaded, data } = useCallApi(`countryList`, 'GET', {}, [])
    const [formData, setFormData] = useState({})
    const [submitting, setSubmitting] = useState(false)

    const createOverride = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        const result = await callAPI(affiliate ? `affiliate/${affiliate.id}/campaignCountryOverrides` : `campaign/${campaign.id}/campaignCountryOverrides`, 'POST', formData)
        if (result.success) {
            setFormData({})
            setIsOpen(false)
            showSystemMessage('success', result.message)
            refetch()
        } else if (!result.success) {
            showSystemMessage('error', result.errors.message)
        } else {
            showSystemMessage('error', 'There was an error creating the campaign country override')
        }
        setSubmitting(false)
    }

    return (
        <Modal
            show={isOpen}
            onHide={() => {
                setFormData()
                setIsOpen(false)
            }}
        >
            {isOpen && (
                <Form onSubmit={createOverride}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Country Blacklist for: {affiliate ? <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : <Campaign oid={campaign.id} name={campaign.name} status={campaign.status.value} />}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Affiliate: </span>
                            <span className="w-75">{affiliate ? affiliate.id : <AffiliateSearch setFormData={setFormData} />}</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Campaign: </span>
                            <div className="w-75">{affiliate ? <CampaignSearch setFormData={setFormData} /> : campaign.id}</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span style={{ width: '20%' }}>Country: </span>
                            <div className="w-75">
                                <Form.Control as="select" onChange={(e) => setFormData({ ...formData, country: e.target.value })}>
                                    <option value="">Select a country</option>
                                    {dataLoaded
                                        ? data.countries.map((country, index) => (
                                              <option key={index} value={country.country_code}>
                                                  {country.country_name}
                                              </option>
                                          ))
                                        : null}
                                </Form.Control>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton variant="primary" name="Create" type="submit" spin={submitting} />
                    </Modal.Footer>
                </Form>
            )}
        </Modal>
    )
}

export default CampaignCountryOverridesModal
