import moment from 'moment'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import callAPI from '../../helpers/callAPI'
import useCallApi from '../../hooks/useCallApi'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Loading from '../../templates/UI/Loading/Loading'
import CampaignCountryOverridesModal from '../CampaignCountryOverridesModal/CampaignCountryOverridesModal'
import { useAuth, useProtectedPage } from '../../context/AuthContext'

const CampaignCountryOverrides = ({ affiliateId, campaignId, showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_campaign_country_blacklist')

    const { dataLoaded, data, refetch } = useCallApi(affiliateId ? `affiliate/${affiliateId}/campaignCountryOverrides` : `campaign/${campaignId}/campaignCountryOverrides`, 'GET', {}, [affiliateId])
    const [isOpen, setIsOpen] = useState(false)

    document.title = `${dataLoaded ? `${affiliateId ? data.affiliate.name : data.campaign.name}: ` : ''}Country Blacklist`

    const deleteRecord = (item_id, countryCode) => {
        let body = {
            itemId: item_id,
            countryCode: countryCode,
        }

        callAPI(affiliateId ? `affiliate/${affiliateId}/campaignCountryOverrides` : `campaign/${campaignId}/campaignCountryOverrides`, 'DELETE', body).then((result) => {
            if (result.success) {
                showSystemMessage('success', 'Deleted Successfully')
                refetch()
            } else {
                showSystemMessage('danger', 'Delete Failed')
            }
        })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'item_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return affiliateId ? <Campaign name={tableData.data[tableData.row.index].offer.name} oid={tableData.data[tableData.row.index].offer.id} status={tableData.data[tableData.row.index].offer.status} /> : <Affiliate name={tableData.data[tableData.row.index].affiliate.name} aid={tableData.data[tableData.row.index].affiliate.id} status={tableData.data[tableData.row.index].affiliate.status} priority={tableData.data[tableData.row.index].affiliate.priority} />
                },
            },
            {
                Header: 'Campaign Status',
                accessor: 'item_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Override Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD')}</>
                },
            },
            {
                Header: 'Country',
                accessor: 'country',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: '',
                accessor: 'mgr_name',
                Cell: (tableData) => {
                    return (
                        <>
                            <button className="btn btn-sm btn-danger" onClick={() => deleteRecord(affiliateId ? tableData.data[tableData.row.index].offer.id : tableData.data[tableData.row.index].affiliate.id, tableData.data[tableData.row.index].country_code)}>
                                Delete
                            </button>
                        </>
                    )
                },
            },
        ]

        return <ReportTable data={data.overrides} columns={columns} />
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Campaign Country Blacklist</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <Card>
                        <CampaignCountryOverridesModal refetch={refetch} affiliate={data.affiliate} campaign={data.campaign} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} />
                        <Card.Body className="p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="mb-h1-dark">Campaign Country Blacklist: {dataLoaded ? affiliateId ? <Affiliate aid={data.affiliate.id} name={data.affiliate.name} priority={data.affiliate.priority.value} status={data.affiliate.status.value} /> : <Campaign oid={data.campaign.id} name={data.campaign.name} status={data.campaign.status.value} /> : null}</div>
                                <button className="btn btn-warning btn-sm" onClick={() => setIsOpen(true)}>
                                    Add Blacklist
                                </button>
                            </div>
                            {dataLoaded ? data.overrides.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default CampaignCountryOverrides
