import React from 'react';
import useCallApi from '../../hooks/useCallApi';
import useDateRangeFilter from '../../hooks/useDateRangeFilter';
import DateRangeFilter from '../Reports/Filters/DateRangeFilter/DateRangeFilter';
import UserClicksAll from './UserClicksAll';
import UserClicksByElem from './UserClicksByElem';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useQueryParams from '../../hooks/useQueryParams';

const UserAnalytics = ({ source }) => {
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { data, dataLoaded } = useCallApi(`useranalytics/elements?src=${source}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`, 'GET', {}, []);
    const { buildUrl } = useQueryParams()    
    const history = useHistory()
    const location = useLocation()

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = buildUrl(`${location.pathname}?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        history.push(url)
    }

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">{source} User Analytics</h1>
            </div>
            <div className="card mb-3">
                <div className="card-header">
                    <div className='d-flex'>
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <button onClick={(e)=>refreshReport(e)} className="btn btn-sm btn-primary mr-2">
                            Refresh Report
                        </button>
                    </div>
                </div>
            </div>
            <div className="row mb-3">                
                {dataLoaded && data.success && data.data.map((elem, index) => (
                    <div className="col-3" key={index}>
                        <UserClicksByElem source={source} elem={elem.element} startDate={selectedStartDate} endDate={selectedEndDate} />
                    </div>
                ))}
            </div>        
            <div>
                <UserClicksAll source={source} startDate={selectedStartDate} endDate={selectedEndDate} />
            </div>
        </>
    );
}

export default UserAnalytics;