import React, { useState, useEffect, useContext } from 'react';
import { Card, Modal } from 'react-bootstrap'
import AdvertiserReversalRequestContent from '../../../../components/AdvertiserReversalRequestContent/AdvertiserReversalRequestContent';
import useCallApi from '../../../../hooks/useCallApi';
import { SystemMessageContext } from '../../../../context/SystemMessageContext';
import Loading from '../../../../templates/UI/Loading/Loading';
import Advertiser from '../../../../templates/Advertiser/Advertiser';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function ReversalRequestsDashboard({ manager, status = 'O,R,V,C' }){

    const showSystemMessage = useContext(SystemMessageContext)
    
    const [showModal, setShowModal] = useState(false)
    const [reversalRequestId, setReversalRequestId] = useState(0)
    const [advertiserId, setAdvertiserId] = useState(false)    

    let apiUrl = `advertisers/reversalStatusReport?status=${status}`

    if (manager){
        apiUrl += `&adminId=${manager.id}`
    }

    const { data, dataLoaded, refetch } = useCallApi(apiUrl, 'GET', {}, [manager, status], showSystemMessage)

    const openModal = (e, id) => {
        e.preventDefault();
        setShowModal(true);
        setReversalRequestId(id);
    }

    const closeModal = () => {
        setShowModal(false);
        setReversalRequestId(0);
    }

    return (
        <>
            <Modal show={showModal} size="xl" onHide={() => setShowModal(false)}>
                <AdvertiserReversalRequestContent advertiserId={advertiserId} reversalRequestId={reversalRequestId} refetch={refetch} closeModal={closeModal} />
            </Modal>
            <div className="card mb-3">
                <div className="card-header">
                    <h1 className="mb-h1-dark">Reversal Requests</h1>
                </div>
                <div className="card-body p-0">
                    <table className="table table-striped table-hover w-100 m-0">
                        <thead>
                            <tr className="text-secondary">
                                <th>ID</th>
                                <th>Advertiser</th>
                                <th>Created</th>
                                <th>Status</th>
                                <th>Status Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataLoaded ? (
                                <>
                                    {!data || data.report.length === 0 ? (
                                        <tr>
                                            <td colSpan={6}>No reversal requests.</td>
                                        </tr>
                                    ) : (
                                        data.report.map((reversal) => (
                                            <tr key={reversal.id}>
                                                <td>{reversal.advertiser.id}</td>
                                                <td><Advertiser name={reversal.advertiser.name} id={reversal.advertiser.id} /></td>
                                                <td>{moment(reversal.created_date).utc().format('YYYY-MM-DD')}</td>
                                                <td>{ (reversal.status === 'Open' || reversal.status === 'Reviewing' || reversal.status === 'AAM Review' || reversal.status === 'Compliance Review') ? <span className="font-weight-bold text-info">{reversal.status}</span> : reversal.status === 'Archived' ? <span className="text-danger">{reversal.status}</span> : reversal.status}</td>
                                                <td>{moment(reversal.status_date).utc().format('YYYY-MM-DD')}</td>
                                                <td>
                                                    <Link to={`/advertiser/${reversal.advertiser.id}/reversalRequest?id=${reversal.id}`} className="btn btn-warning btn-sm my-2" onClick={(e)=>{setAdvertiserId(reversal.advertiser.id); openModal(e, reversal.id)}}>View</Link>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={6} align="center">
                                        <Loading />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}