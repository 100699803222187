import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import callAPI from '../../../../helpers/callAPI'

const ReportAffiliateSummary = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [property, setProperty] = useState({ propertyId: 0, name: '' })
    const [site, setSite] = useState({ siteId: 0, name: '' })
    const [survey, setSurvey] = useState({ surveyId: 0, name: '' })
    const [affiliate, setAffiliate] = useState({ affiliateId: 0, name: '' })
    const [columns, setColumns] = useState([])
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])
    const [sortState, setSortState] = useState({ key: 'affiliate.id', direction: 'desc', interest: null })

    const { propertyId = 0, siteId = 0, surveyId = 0, affiliateId = 0, startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            setDataLoaded(false)

            let fetchUrl = `reports/avatar/avatarAffSummReport?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}`
            let result = await callAPI(fetchUrl, 'GET')

            if (result['success'] === true && result.report) {
                setProperty({ propertyId: propertyId, name: result.property })
                setSite({ siteId: siteId, name: result.site })
                setSurvey({ surveyId: surveyId, name: result.survey })
                setAffiliate({ affiliateId: affiliateId, name: result.affiliate })
                setColumns(result.columns)
                setReport(result.report)
                setTotals(result.totals)
                setDataLoaded(true)
            } else if (result['success'] === false) {
                if (result.code !== 401) {
                    showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                }
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
        fetchData()
    }, [propertyId, siteId, surveyId, affiliateId, startDate, endDate])

    const sortBy = (sortKey, interest, subColumn) => {
        let newReport = _.cloneDeep(report)

        let direction = sortState.sort !== sortKey ? 'asc' : sortState.direction == 'asc' ? 'desc' : 'asc'

        let nest = sortKey.split('.')

        let orderedReport = newReport

        if (interest) {
            if (nest[0] == 'report' && nest[1] == 'interest') {
                // This is an interest item.
                // Find the array index where the name matches
                let workingIndex = newReport[0].report.findIndex((obj) => obj['name'] == interest)
                if (workingIndex >= 0) {
                    if (sortState.interest !== interest) {
                        direction = 'desc'
                    }
                    orderedReport = _.orderBy(newReport, (item) => item.report[workingIndex].count, [direction])
                }
            } else if (nest[0] == 'report' && nest[1] == 'question') {
                // This is a question.
                // Find the question.
                let workingIndex = newReport[0].report.findIndex((obj) => obj['questionId'] == interest)
                if (workingIndex >= 0) {
                    if (subColumn) {
                        // There are subColumns
                        // Find the index of the subColumn
                        let subColumnIndex = newReport[0].report[workingIndex].subColumn.findIndex((obj) => obj.name == subColumn)
                        if (sortState.interest !== interest || sortState.subColumn !== subColumn) {
                            direction = 'desc'
                        }
                        orderedReport = _.orderBy(newReport, (item) => item.report[workingIndex].subColumn[subColumnIndex].count, [direction])
                    } else {
                        // We can just sort by the column itself, based on count
                        if (sortState.interest !== interest) {
                            direction = 'desc'
                        }
                        orderedReport = _.orderBy(newReport, (item) => item.report[workingIndex].count, [direction])
                    }
                }
            }
        } else {
            // We are dealing with normal stuff like affiliate ID etc.
            if (nest.length === 1) {
                orderedReport = _.orderBy(newReport, (item) => item[nest[0]], [direction])
            } else if (nest.length === 2) {
                orderedReport = _.orderBy(newReport, (item) => item[nest[0]][nest[1]], [direction])
            } else if (nest.length === 3) {
                orderedReport = _.orderBy(newReport, (item) => item[nest[0]][nest[1]][nest[2]], [direction])
            } else if (nest.length === 4) {
                orderedReport = _.orderBy(newReport, (item) => item[nest[0]][nest[1]][nest[2]][nest[3]], [direction])
            } else if (nest.length === 5) {
                orderedReport = _.orderBy(newReport, (item) => item[nest[0]][nest[1]][nest[2]][nest[3]][nest[4]], [direction])
            } else if (nest.length === 6) {
                orderedReport = _.orderBy(newReport, (item) => item[nest[0]][nest[1]][nest[2]][nest[3]][nest[4]][nest[5]], [direction])
            } else if (nest.length === 7) {
                orderedReport = _.orderBy(newReport, (item) => item[nest[0]][nest[1]][nest[2]][nest[3]][nest[4]][nest[5]][nest[6]], [direction])
            }
        }

        setReport(orderedReport)

        setSortState({ sort: sortKey, direction: direction, interest: interest, subColumn: subColumn })
    }

    const getHeaders = () => {
        // Do we need an extra row for subcolumns?
        let extraRow = columns.findIndex((element) => element.subColumn && element.subColumn.length > 0)

        return (
            <React.Fragment>
                <tr>
                    <th scope="col" onClick={() => sortBy('affiliate.id')} style={{ cursor: 'pointer' }}>
                        Affiliate ID
                    </th>
                    <th scope="col" onClick={() => sortBy('affiliate.name')} style={{ cursor: 'pointer' }}>
                        Affiliate
                    </th>
                    {columns.map((column) => {
                        if (column.questionId > 0) {
                            // This is a question column
                            let colSpan = column.subColumn.length > 0 ? column.subColumn.length : 1
                            if (colSpan === 1) {
                                // There are no subcolumns, we can sort it.
                                return (
                                    <th scope="col" onClick={() => sortBy('report.question', column.questionId)} data-tip={column.name} colSpan={colSpan} style={{ textAlign: 'center', borderLeft: '1px solid RGBA(0,0,0,0.1)', cursor: 'pointer' }}>
                                        Q{column.sort}: {column.name.substring(0, 10)}
                                        {column.name.length > 10 && '...'}
                                    </th>
                                )
                            } else {
                                // There are subcolumns, we need to sort it with the second row (extraRow)
                                return (
                                    <th scope="col" data-tip={column.name} colSpan={colSpan} style={{ textAlign: 'center', borderLeft: '1px solid RGBA(0,0,0,0.1)' }}>
                                        Q{column.sort}: {column.name.substring(0, 10)}
                                        {column.name.length > 10 && '...'}
                                    </th>
                                )
                            }
                        } else {
                            // This is an interest column
                            let name = column.name
                            if (name == 'fraud_ipqs_phone_cnt') {
                                name = 'Phone Fraud'
                            }
                            if (name == 'fraud_ipqs_ip_cnt') {
                                name = 'IP Fraud'
                            }
                            if (name == 'fraud_ipqs_email_cnt') {
                                name = 'Email Fraud'
                            }
                            if (name == 'Z.Starts') {
                                name = 'Starts'
                            }
                            if (name == 'Z.Completions') {
                                name = 'Completions'
                            }
                            return (
                                <th scope="col" onClick={() => sortBy('report.interest', column.name)} style={{ textAlign: 'center', borderLeft: '1px solid RGBA(0,0,0,0.1)', cursor: 'pointer' }}>
                                    {name}
                                </th>
                            )
                        }
                    })}
                </tr>
                {extraRow >= 0 && (
                    <tr>
                        <td></td>
                        <td></td>
                        {columns.map((column) => {
                            if (column.questionId > 0) {
                                if (column.subColumn && column.subColumn.length > 0) {
                                    return column.subColumn.map((subColumn) => {
                                        return (
                                            <td onClick={() => sortBy('report.question', column.questionId, subColumn)} style={{ textAlign: 'center', borderLeft: '1px solid RGBA(0,0,0,0.1)', cursor: 'pointer' }} className="font-weight-bold" data-tip={subColumn}>
                                                {subColumn.substring(0, 10)}
                                                {subColumn.length > 10 && '...'}
                                            </td>
                                        )
                                    })
                                }
                            } else {
                                return <td></td>
                            }
                        })}
                    </tr>
                )}
            </React.Fragment>
        )
    }

    const getRecords = () => {
        return report.map((record) => {
            let startIndex = record.report.findIndex((obj) => obj.name == 'Z.Starts')

            let starts = false
            if (startIndex >= 0) {
                starts = record.report[startIndex].count
            }

            return (
                <tr>
                    <td>{record.affiliate.id}</td>
                    <td>
                        <Affiliate aid={record.affiliate.id} name={record.affiliate.name} status={record.affiliate.status} priority={record.affiliate.priority} />
                    </td>
                    {record.report.map((interestQuestion) => {
                        if (interestQuestion.questionId > 0) {
                            if (interestQuestion.subColumn.length > 0) {
                                // There are multiple subcolumns (choices)
                                return interestQuestion.subColumn.map((subColumn) => {
                                    return (
                                        <td style={{ textAlign: 'right', borderLeft: '1px solid RGBA(0,0,0,0.1)' }}>
                                            {Intl.NumberFormat('en-GB').format(subColumn.count)}
                                            {starts && (
                                                <React.Fragment>
                                                    <br></br> <span className="text-muted">({Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format((subColumn.count / starts) * 100)}%)</span>
                                                </React.Fragment>
                                            )}
                                        </td>
                                    )
                                })
                            } else {
                                // Question without subcolumns (choices)
                                return (
                                    <td style={{ textAlign: 'right', borderLeft: '1px solid RGBA(0,0,0,0.1)' }}>
                                        {Intl.NumberFormat('en-GB').format(interestQuestion.count)}
                                        {starts && (
                                            <React.Fragment>
                                                <br></br> <span className="text-muted">({Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format((interestQuestion.count / starts) * 100)}%)</span>
                                            </React.Fragment>
                                        )}
                                    </td>
                                )
                            }
                        } else {
                            // Regular interest item
                            return (
                                <td style={{ textAlign: 'right', borderLeft: '1px solid RGBA(0,0,0,0.1)' }}>
                                    {Intl.NumberFormat('en-GB').format(interestQuestion.count)}
                                    {starts && (
                                        <React.Fragment>
                                            <br></br> <span className="text-muted">({Intl.NumberFormat('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format((interestQuestion.count / starts) * 100)}%)</span>
                                        </React.Fragment>
                                    )}
                                </td>
                            )
                        }
                    })}
                </tr>
            )
        })
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {property.propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name} cancelDrilldownUrl={`/avatar/reports/affiliateSummary?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=0&siteId=${site.siteId}&surveyId=${survey.surveyId}`}></DrilldownItem>}
                {site.siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name} cancelDrilldownUrl={`/avatar/reports/affiliateSummary?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=0&surveyId=${survey.surveyId}`}></DrilldownItem>}
                {survey.surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name} cancelDrilldownUrl={`/avatar/reports/affiliateSummary?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=0`}></DrilldownItem>}
                {affiliate.affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliateId}`} forText={affiliate.name} cancelDrilldownUrl={`/avatar/reports/affiliateSummary?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=0`}></DrilldownItem>}
            </div>
            {dataLoaded ? (
                report.length === 0 ? (
                    <div className="p-3">No stats for the current parameters.</div>
                ) : (
                    <React.Fragment>
                        <ReactTooltip />
                        <table className="table table-striped table-card table-hover mb-0 text-small">
                            <thead>{getHeaders()}</thead>
                            <tbody>{getRecords()}</tbody>
                        </table>
                    </React.Fragment>
                )
            ) : (
                <Loading></Loading>
            )}
        </div>
    )
}

export default ReportAffiliateSummary
