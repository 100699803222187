const getFollowupResult = (result) => {
    if (result === 'PT') {
        return 'Promise to Run'
    } else if (result === 'SC') {
        return 'Status Call'
    } else if (result === 'EM') {
        return 'Sent E-Mail'
    } else if (result === 'CN') {
        return 'Called NA / LM'
    } else if (result === 'CS') {
        return 'Called Spoke'
    } else if (result === 'IN') {
        return 'IMed NA'
    } else if (result === 'IC') {
        return 'IMed Chatted'
    } else if (result === 'FW') {
        return 'Forwarded'
    } else if (result === 'OT') {
        return 'Other'
    } else if (result === 'RQ') {
        return 'Compliance Request'
    } else if (result === 'CM') {
        return 'Compliance'
    } else if (result === 'QC') {
        return 'Quality Check'
    } else if (result === 'NP') {
        return 'Never Paid'
    } else if (result === 'TM') {
        return 'Traffic Methods'
    } else if (result === 'PM') {
        return 'Promotional Method'
    } else if (result === 'AM') {
        return 'AM information'
    } else if (result === 'CH') {
        return 'Checked'
    } else if (result === 'TR') {
        return 'Transfer to Senior AM'
    } else {
        return `Unknown - ${result}`
    }
}

export default getFollowupResult
