import { Modal, Form, Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import AdminPicker from '../../../../components/AdminPicker/AdminPicker'
import PriorityPicker from '../../../../components/PriorityPicker/PriorityPicker'
import callAPI from '../../../../helpers/callAPI'
import AdvertiserSearch from '../../../../components/AdvertiserSearch/AdvertiserSearch'

let initialData = {
    success: true,
    details: {
        company_name: '',
        main_contact: '',
        city: '',
    },
    reference_one: {
        business_name: '',
        contact: '',
        city_state: '',
        phone: '',
        email: '',
    },
    reference_two: {
        business_name: '',
        contact: '',
        city_state: '',
        phone: '',
        email: '',
    },
    reference_three: {
        business_name: '',
        contact: '',
        city_state: '',
        phone: '',
        email: '',
    },
    reference_four: {
        business_name: '',
        contact: '',
        city_state: '',
        phone: '',
        email: '',
    },
    assign_to: {
        admin: {
            id: '',
            name: '',
        },
    },
    priority: {
        code: '',
        value: '',
    },
    advertiser: {
        id: '',
        name: '',
        status: '',
    },
    past_notes: '',
    new_note: '',
}

const CreditChecksModal = ({ manager, modalData, setModalData, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(initialData)
    const [adminId, setAdminId] = useState({ id: null, name: '' })

    useEffect(() => {
        setData({ ...data, assign_to: { admin: { id: adminId, name: '' } } })
    }, [adminId])

    useEffect(() => {
        const fetchData = async () => {
            if (modalData.type === 'edit' && modalData.id) {
                const data = await callAPI(`advertisers/creditCheck/${modalData.id}`, 'GET', null)
                if (data.success) {
                    setData(data)
                    setModalData((prevState) => ({ ...prevState, isOpen: true }))
                } else {
                    showSystemMessage('error', 'Error fetching Data')
                }
            } else if (modalData.type === 'create') {
                setData(initialData)
                setModalData((prevState) => ({ ...prevState, isOpen: true }))
            }
            setDataLoaded(true)
        }
        fetchData(modalData.id)
    }, [modalData.type])

    const validateData = () => {
        console.log(`adv id: ${JSON.stringify(data.advertiser.id)}`)
        if (!data.details.company_name) {
            showSystemMessage('error', 'Please enter Company Name')
            return false
        } else if (!data.details.main_contact) {
            showSystemMessage('error', 'Please enter a Contact')
            return false
        } else if (!data.details.city) {
            showSystemMessage('error', 'Please enter a City')
            return false
        } else if (!data.reference_one.business_name) {
            showSystemMessage('error', 'Please enter a Reference One Business Name')
            return false
        } else if (!data.reference_one.contact) {
            showSystemMessage('error', 'Please enter a Reference One Contact')
            return false
        } else if (!data.reference_one.city_state) {
            showSystemMessage('error', 'Please enter a Reference One City/State')
            return false
        } else if (!data.reference_one.phone) {
            showSystemMessage('error', 'Please enter a Reference One Phone')
            return false
        } else if (!data.reference_one.email) {
            showSystemMessage('error', 'Please enter a Reference One Email')
            return false
        } else if (!data.priority.code) {
            console.log('priority', data.priority)
            showSystemMessage('error', 'Please select a Priority')
            return false
        } else if (!data.advertiser.id) {
            showSystemMessage('error', 'Please select an Advertiser')
            return false
        } else if (!data.new_note) {
            showSystemMessage('error', 'Please enter a Note')
            return false
        } else if (adminId === null) {
            showSystemMessage('error', 'Please Assign a Manager')
            return false
        } else {
            return true
        }
    }

    const createCreditCheck = async () => {
        if (!validateData()) return
        const result = await callAPI(`advertisers/creditCheck/save`, 'POST', { ...data, mgr_id: manager.id })
        if (result.success) {
            setModalData((prevState) => ({ ...prevState, isOpen: false, type: null }))
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const updateCreditCheck = async () => {
        if (!validateData()) return
        const result = await callAPI(`advertisers/creditCheck/save?id=${modalData.id}`, 'POST', { ...data, mgr_id: manager.id })
        if (result.success) {
            setModalData((prevState) => ({ ...prevState, isOpen: false, type: null }))
        } else {
            showSystemMessage('error', 'Error updating Credit Check')
        }
    }

    const closeCreditCheck = async () => {
        if (!validateData()) return
        const result = await callAPI(`advertisers/creditCheck/close/${modalData.id}`, 'POST')
        if (result.success) {
            setModalData((prevState) => ({ ...prevState, isOpen: false, type: null }))
        } else {
            showSystemMessage('error', 'Error closing Credit Check')
        }
    }

    return (
        <Modal
            show={modalData.isOpen}
            onHide={() => {
                setModalData((prevState) => ({ ...prevState, isOpen: false, type: null }))
            }}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{modalData.type === 'create' ? 'Create New Credit Check' : 'Edit Credit Check'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Modal.Title> Contact Details </Modal.Title>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Company Name : *{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, details: { ...prevState.details, company_name: event.target.value } }))} value={data.details.company_name} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Main Contact: *{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, details: { ...prevState.details, main_contact: event.target.value } }))} value={data.details.main_contact} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            City: *{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, details: { ...prevState.details, city: event.target.value } }))} value={data.details.city} />
                    </div>
                </div>
                <div>
                    <Modal.Title> Reference #1 </Modal.Title>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Business Name: *{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_one: { ...prevState.reference_one, business_name: event.target.value } }))} value={data.reference_one.business_name} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Contact Name: *{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_one: { ...prevState.reference_one, contact: event.target.value } }))} value={data.reference_one.contact} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            City/ State: *{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_one: { ...prevState.reference_one, city_state: event.target.value } }))} value={data.reference_one.city_state} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Phone: *{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_one: { ...prevState.reference_one, phone: event.target.value } }))} value={data.reference_one.phone} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Email: *{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_one: { ...prevState.reference_one, email: event.target.value } }))} value={data.reference_one.email} />
                    </div>
                </div>
                <div>
                    <Modal.Title> Reference #2 </Modal.Title>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Business Name:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_two: { ...prevState.reference_two, business_name: event.target.value } }))} value={data.reference_two.business_name} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Contact Name:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_two: { ...prevState.reference_two, contact: event.target.value } }))} value={data.reference_two.contact} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            City/ State:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_two: { ...prevState.reference_two, city_state: event.target.value } }))} value={data.reference_two.city_state} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Phone:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_two: { ...prevState.reference_two, phone: event.target.value } }))} value={data.reference_two.phone} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Email:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_two: { ...prevState.reference_two, email: event.target.value } }))} value={data.reference_two.email} />
                    </div>
                </div>
                <div>
                    <Modal.Title> Reference #3 </Modal.Title>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Business Name:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_three: { ...prevState.reference_three, business_name: event.target.value } }))} value={data.reference_three.business_name} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Contact Name:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_three: { ...prevState.reference_three, contact: event.target.value } }))} value={data.reference_three.contact} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            City/ State:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_three: { ...prevState.reference_three, city_state: event.target.value } }))} value={data.reference_three.city_state} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Phone:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_three: { ...prevState.reference_three, phone: event.target.value } }))} value={data.reference_three.phone} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Email:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_three: { ...prevState.reference_three, email: event.target.value } }))} value={data.reference_three.email} />
                    </div>
                </div>
                <div>
                    <Modal.Title> Reference #4 </Modal.Title>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Business Name:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_four: { ...prevState.reference_four, business_name: event.target.value } }))} value={data.reference_four.business_name} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Contact Name:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_four: { ...prevState.reference_four, contact: event.target.value } }))} value={data.reference_four.contact} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            City/ State:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_four: { ...prevState.reference_four, city_state: event.target.value } }))} value={data.reference_four.city_state} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Phone:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_four: { ...prevState.reference_four, phone: event.target.value } }))} value={data.reference_four.phone} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Email:{' '}
                        </div>
                        <Form.Control className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, reference_four: { ...prevState.reference_four, email: event.target.value } }))} value={data.reference_four.email} />
                    </div>
                </div>
                <div>
                    <Modal.Title> More Info </Modal.Title>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end mr-2">
                            Assign To: *{' '}
                        </div>
                        <AdminPicker formSize="md" role={['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR', 'MANAGER', 'ACCOUNTING']} adminId={data.assign_to.admin.id ? data.assign_to.admin.id : null} setAdminId={setAdminId} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Priority: *{' '}
                        </div>
                        <PriorityPicker className="ml-2 w-100" selectedPriority={data.priority} setSelectedPriority={(pri) => setData((prevState) => ({ ...prevState, priority: pri }))} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem', marginRight: '.5rem' }} className="d-flex justify-content-end">
                            Advertiser: *{' '}
                        </div>
                        <div className="w-100">
                            <AdvertiserSearch advertiser={data.advertiser ? (data.advertiser.id ? { id: data.advertiser.id, name: data.advertiser.name } : false) : false} setFormData={setData} />
                        </div>
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            Old Notes:{' '}
                        </div>
                        <Form.Control as="textarea" disabled className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, past_notes: event.target.value }))} value={data.past_notes} />
                    </div>
                    <div className="d-flex m-2 align-items-center">
                        <div style={{ width: '15rem' }} className="d-flex justify-content-end">
                            New Note: *{' '}
                        </div>
                        <Form.Control as="textarea" className="ml-2 w-100" size="md" onChange={(event) => setData((prevState) => ({ ...prevState, new_note: event.target.value }))} value={data.new_note} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {modalData.type === 'create' ? (
                    <Button variant="primary" onClick={createCreditCheck}>
                        Create
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={updateCreditCheck}>
                            Save Changes
                        </Button>
                        <Button variant="danger" onClick={closeCreditCheck}>
                            Save and Close Credit Check
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default CreditChecksModal
