import React, { useState, useEffect } from 'react';

import countries from '../../data/countrylist'

export default function CountryDropdown({ country, setCountry }){    
    return (
        <select name="country" className="form-control" onChange={(e) => setCountry(e.target.value)} defaultValue={country} required>
            <option value=""></option>
            {countries.countries.map(
                (country) => (
                    <option key={country.code} value={country.code}>
                        {country.name}
                    </option>
                )
            )}
        </select>
    )
}