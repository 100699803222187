import { Modal } from "react-bootstrap";
import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import Affiliate from "../../../../templates/Affiliate/Affiliate";
import moment from "moment";

const TransfersModal = ({ showTransfersModal, setShowTransfersModal, currentMonth, data }) => {
    const month = useMemo(() => currentMonth ? moment().format('MMMM') : moment().subtract(1, 'month').format('MMMM'), [currentMonth]);


    return (
        <Modal
            show={showTransfersModal}
            onHide={() => setShowTransfersModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Transfers in {month}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Affiliate ID</th>
                            <th>Affiliate</th>
                            <th>Priority</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.transfers?.[currentMonth ? 'this_month' : 'last_month']?.map((transfer, index) => {
                            return (
                                <tr key={index}>
                                    <td>{transfer.affiliate_id}</td>
                                    <td><Affiliate aid={transfer.affiliate_id} name={transfer.name} priority={transfer.affiliate_priority_value} status={transfer.affiliate_status_value} /></td>
                                    <td>{transfer.aff_priority}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
}

export default TransfersModal;