import React, { useState } from 'react'
import { useTable, useFilters, useSortBy } from 'react-table'
import './JobTable.css'

export default function JobTable({ data, columns }) {
    const [filterInput, setFilterInput] = useState('')
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow, setFilter } = useTable(
        {
            columns,
            data,
        },
        useFilters,
        useSortBy
    )

    const handleFilterChange = (e) => {
        const value = e.target.value || undefined
        setFilter('job_name', value)
        setFilterInput(value)
    }

    return (
        <>
            <input value={filterInput} onChange={handleFilterChange} className="jobDashboard" placeholder="Search Job name" />
            <table className="JobTable jobDashboard" {...getTableProps()}>
                <thead className="JobTableHead">
                    <tr>
                        {headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="JobTableBody" {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}
