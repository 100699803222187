import React, { Component } from 'react'

class Final extends Component {
    state = {
        error: false,
    }

    render() {
        let { question, order, questionId, callToAction } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Message</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="mt-4">
                    <div className="mt-3">
                        <input className="form-control mb-button-input-text" type="text" defaultValue={callToAction} placeholder="Custom Call to Action" onChange={this.props.handleChangeCallToAction(questionId)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Final
