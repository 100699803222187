import { useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'
import callAPI from '../../../../../helpers/callAPI'
import convertUrl from '../../../../../helpers/convertUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminPicker from '../../../../../components/AdminPicker/AdminPicker'
import { FaExternalLinkAlt } from 'react-icons/fa'

const ApplicationModalContent = ({ applicationId, showSystemMessage, handleClose, refetch }) => {
    const { data, dataLoaded } = useCallApi(`advertisers/applications/${applicationId}`, 'GET', {}, [applicationId])

    const [submitting, setSubmitting] = useState(false)

    const [managerId, setManagerId] = useState(0)

    // This is for reject/delete permanently
    const handleClick = async (actionType) => {
        setSubmitting(true)

        let url = `advertisers/applications/${applicationId}`

        let requestType

        if (actionType === 'reject') {
            requestType = 'PUT'
        } else if (actionType === 'delete') {
            requestType = 'DELETE'
        }

        let result = await callAPI(url, requestType, {})

        if (result.success) {
            showSystemMessage('success', result.message)
            setSubmitting(false)

            try {
                refetch()
            } catch (err) {}

            handleClose(true, actionType)
        } else if (!result.success) {
            showSystemMessage('error', result.errors.message)
            setSubmitting(false)
        }
    }

    // This means we're creating the advertiser
    const handleSubmit = (e) => {
        setSubmitting(true)

        e.preventDefault()

        let url = `advertisers/applications/${applicationId}`

        let postBody = {
            email: e.target.email.value,
            name: e.target.contact_name.value,
            company: e.target.company.value,
            phone: e.target.phone.value,
            manager_id: managerId,
        }

        callAPI(url, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setSubmitting(false)

                    try {
                        refetch()
                    } catch (err) {}

                    handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                console.log('Error while creating advertiser: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }

    const handleManagerChange = (mgrId) => {
        setManagerId(mgrId)
    }

    return (
        <div className="card">
            <div className="card-header">Advertiser Application</div>
            <div className="card-body">
                {dataLoaded ? (
                    <form onSubmit={handleSubmit}>
                        <table className="table table-borderless m-0">
                            <tbody>
                                <tr>
                                    <td width="30%">Contact Name</td>
                                    <td>{data.application_details.status === 'P' ? <input name="contact_name" defaultValue={data.application_details.name} className="form-control w-50" required /> : data.application_details.name}</td>
                                </tr>
                                <tr>
                                    <td>Advertiser Name</td>
                                    <td>{data.application_details.status === 'P' ? <input name="company" defaultValue={data.application_details.company} className="form-control w-50" required /> : data.application_details.company}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{data.application_details.status === 'P' ? <input name="email" defaultValue={data.application_details.email} className="form-control w-50" required /> : data.application_details.email}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{data.application_details.status === 'P' ? <input name="phone" defaultValue={data.application_details.phone} className="form-control w-50" required /> : data.application_details.phone}</td>
                                </tr>
                                <tr>
                                    <td>Skype</td>
                                    <td>{data.application_details.skype_handle}</td>
                                </tr>
                                <tr>
                                    <td>Website URL</td>
                                    <td>
                                        <a href={`${convertUrl(data.application_details.url)}`} rel="noopener noreferrer" target="_blank">
                                            {data.application_details.url} &nbsp; <FaExternalLinkAlt color="#007bff" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>{data.application_details.status}</td>
                                </tr>
                                <tr>
                                    <td>Source</td>
                                    <td>{data.application_details.source}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Campaign Name</td>
                                    <td>{data.application_details.campaign_name}</td>
                                </tr>
                                <tr>
                                    <td>Rate</td>
                                    <td>{data.application_details.rate}</td>
                                </tr>
                                <tr>
                                    <td>Campaign Type</td>
                                    <td>{data.application_details.campaign_type}</td>
                                </tr>
                                <tr>
                                    <td>Monthly Ad Spend</td>
                                    <td>{data.application_details.monthly_ad_spend}</td>
                                </tr>
                                <tr>
                                    <td>Geographical Areas</td>
                                    <td>{data.application_details.geographical_areas}</td>
                                </tr>
                                <tr>
                                    <td>Ready to receive traffic</td>
                                    <td>{moment(data.application_details.traffic_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                </tr>
                                <tr>
                                    <td>Listed on another network</td>
                                    <td>{data.application_details.listed_on_other_network}</td>
                                </tr>
                                <tr>
                                    <td>Tracking system</td>
                                    <td>{data.application_details.tracking_system}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Message</td>
                                    <td>{data.application_details.message}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>IP</td>
                                    <td>{data.application_details.ip}</td>
                                </tr>
                                <tr>
                                    <td>Referring URL</td>
                                    <td>{data.application_details.remote_URL}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                {data.application_details.status === 'P' && (
                                    <>
                                        <tr>
                                            <td>Set followup for</td>
                                            <td>
                                                <AdminPicker setAdminId={handleManagerChange} adminId={managerId} role={'BIZDEV'} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan={2}>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>
                                                {!submitting ? (
                                                    <button className="btn btn-primary" type="submit">
                                                        Create
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-primary" type="submit" disabled>
                                                        <FontAwesomeIcon icon="spinner" spin /> Create
                                                    </button>
                                                )}

                                                {!submitting ? (
                                                    <button className="btn btn-danger ml-3" type="button" onClick={(e) => handleClick('reject')}>
                                                        Reject
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-danger ml-3" type="button" disabled>
                                                        <FontAwesomeIcon icon="spinner" spin /> Reject
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>
                                                {!submitting ? (
                                                    <button className="btn btn-link" type="button" onClick={(e) => handleClick('delete')}>
                                                        Delete Permanently
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-link" type="button" disabled>
                                                        Delete Permanently
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {(data.application_details.status === 'C' && data.application_details.merchant_id > 0) && (
                                    <>
                                        <tr>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                <Link to={`/advertiser/${data.application_details.merchant_id}`}>View Advertiser</Link>
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </form>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default ApplicationModalContent
