import React, { useState } from 'react'

import Loading from '../../../../templates/UI/Loading/Loading'
import AffiliateEarningChanges from '../AffiliateEarningChanges/AffiliateEarningChanges'
import BookmarkedAffiliates from '../BookmarkedAffiliates/BookmarkedAffiliates'
import CampaignRequests from '../CampaignRequests/CampaignRequests'
import NewlyRunCampaigns from '../NewlyRunCampaigns/NewlyRunCampaigns'
import NewMediumTransfers from '../NewMediumTransfers/NewMediumTransfers'
import OpenIssues from '../OpenIssues/OpenIssues'
import SAmSummary from '../SAmSummary/SAmSummary'
import TopAffiliates from '../TopAffiliates/TopAffiliates'
import YesterdaysCapHits from '../YesterdaysCapHits/YesterdaysCapHits'

import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import AffiliateCampaignPickups from '../AffiliateCampaignPickups/AffiliateCampaignPickups'
import AffiliateFollowupQueue from '../AffiliateFollowupQueue/AffiliateFollowupQueue'
import FraudPulls from '../../components/FraudPulls/FraudPulls'

export default function SeniorAffiliateManager({ manager, setManager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'snr_am_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)
    // console.log(manager)
    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Senior Affiliate Manager</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <SAmSummary manager={manager} setManager={setManager} showSystemMessage={showSystemMessage} />
                            <TopAffiliates manager={manager} />
                            <BookmarkedAffiliates manager={manager} showSystemMessage={showSystemMessage} />
                            <NewMediumTransfers manager={manager} />
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-6">
                                    <AffiliateFollowupQueue manager={manager} limit={10} />
                                    <CampaignRequests manager={manager} showSystemMessage={showSystemMessage} />
                                    <YesterdaysCapHits manager={manager} showSystemMessage={showSystemMessage} />
                                    <OpenIssues manager={manager} showSystemMessage={showSystemMessage} />
                                    <FraudPulls manager={manager} />
                                </div>
                                <div className="col-lg-6">
                                    <AffiliateEarningChanges manager={manager} showSystemMessage={showSystemMessage} />
                                    <NewlyRunCampaigns />
                                    <AffiliateCampaignPickups manager={manager} />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
