import React, { Component } from 'react'
import Loading from '../../../../templates/UI/Loading/Loading'
import './Survey.css'

import questionTypes from './data/avatarQuestionTypes'
import GlobalMacros from './GlobalMacros/GlobalMacros'

class Survey extends Component {
    state = {
        dataLoaded: false,
        step: this.props.step,
        stepList: [],
    }

    componentDidMount() {
        // This is where we will need to grab the survey information based on the props.surveyId
        this.setState({
            ...this.state,
            dataLoaded: true,
            step: this.props.step,
        })
    }

    getQuestion = () => {
        let { step } = this.state
        let questionComponent = (
            <div className="text-center">
                <div className="alert alert-info">Start building the survey by adding a question on the left panel.</div>
            </div>
        )

        if (!isNaN(step)) {
            let question = this.props.survey.find((question) => {
                return question.order === step
            })

            if (question !== undefined) {
                let questionType = questionTypes.find((questionType) => {
                    return questionType.type === question.type
                })

                if (questionType) {
                    questionComponent = questionType.component(question, question.placeholder, this.props.handleChange, this.props.handleChangeDisclaimer, this.props.handleChangeCallToAction, this.props.handleChangeNegCallToAction, this.props.handleChangePlaceholder, this.props.handleChangeCharLimit, this.props.handleAddMultipleChoice, this.props.handleChangeMultipleChoice, this.props.handleRemoveMultipleChoice, this.props.handleCustomEventMap, this.props.handleChangeRedirectList, this.props.handleRemoveImage)
                } else {
                    questionComponent = <div>No question for this type.</div>
                }
            }
        }

        return questionComponent
    }

    render() {
        return this.state.dataLoaded ? (
            <div className="mb-survey d-flex align-items-center">
                <div className="question-container m-auto">
                    {this.getQuestion()}
                    <hr></hr>
                    <div className="mt-2">
                        <GlobalMacros />
                    </div>
                </div>
            </div>
        ) : (
            <div className="h-100">
                <Loading></Loading>
            </div>
        )
    }
}

export default Survey
