import { useEffect, useState } from 'react'

import callAPI from '../../helpers/callAPI'
import { Button, Form, Modal } from 'react-bootstrap'
import Affiliate from '../../templates/Affiliate/Affiliate'
import CampaignSearch from '../CampaignSearch/CampaignSearch'
import AffiliateSearch from '../AffiliateSearch/AffiliateSearch'
import useCallApi from '../../hooks/useCallApi'
import Campaign from '../../templates/Campaign/Campaign'
import CustomButton from '../../templates/UI/CustomButton/CustomButton'

const AvatarScrubOverridesModal = ({ setIsOpen, refetch, affiliate, campaign, showSystemMessage }) => {
    const [rate, setRate] = useState(0)
    const [threshold, setThreshold] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    const [formAffiliate, setFormAffiliate] = useState(false)
    const [formCampaign, setFormCampaign] = useState(false)

    const createOverride = async (e) => {
        setSubmitting(true)
        e.preventDefault()     

        console.log(formAffiliate)

        const res = await callAPI(affiliate.id ? `affiliate/${affiliate.id}/avatarScrubOverrides` : `campaign/${campaign.id}/avatarScrubOverrides`, 'POST', {
            rate: rate,
            threshold: threshold,
            affiliateId: affiliate.id ? affiliate.id : formAffiliate.affiliate.id,
            campaignId: campaign.id ? campaign.id : formCampaign.campaign.id
        })

        if (res.success) {
            setSubmitting(false)
            setIsOpen(false)
            showSystemMessage('success', 'Scrub override added.')
            refetch()
        } else if (!res.success) {
            setSubmitting(false)
            showSystemMessage('error', res.errors.message)
        } else {
            showSystemMessage('error', res.errors.message)
            setSubmitting(false)
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <h1 className="mb-h1-dark">Add Scrub Override for: {affiliate.id ? <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : <Campaign oid={campaign.id} name={campaign.name} />}</h1>
            </Modal.Header>
            <Form onSubmit={createOverride}>
                <Modal.Body>
                    {affiliate.dataLoaded ? (
                        <>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>{affiliate.id ? 'Affiliate:' : 'Campaign:'} </span>
                                <span className="w-75">{affiliate.id ? affiliate.id : campaign.id}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>{affiliate.id ? 'Campaign:' : 'Affiliate:'} </span>
                                <div className="w-75">{affiliate.id ? <CampaignSearch setFormData={setFormCampaign} /> : <AffiliateSearch showSystemMessage setFormData={setFormAffiliate} />}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Threshold: </span>
                                <div className="w-75">
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => {
                                            if (parseInt(e.target.value)) {
                                                setThreshold(e.target.value)
                                            }
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Rate: </span>
                                <div className="w-75">
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => {
                                            if (parseFloat(e.target.value)) {
                                                setRate(e.target.value)
                                            }
                                        }}
                                        required
                                    />
                                </div>
                            </div>                            
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant="primary" type="submit" name="Create" spin={submitting} />
                </Modal.Footer>
            </Form>
        </>
    )
}

export default AvatarScrubOverridesModal
