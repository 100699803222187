import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import Loading from '../../../../templates/UI/Loading/Loading'
import { colors } from '../../../../theme/variables'
import useCallApi from '../../../../hooks/useCallApi'
import React from 'react'
const JobHistoryTable = ({ showSystemMessage }) => {
    //last week
    let lastWeek = moment().subtract(150, 'days').format('YYYY-MM-DD')
    let today = moment().format('YYYY-MM-DD')

    const { data, dataLoaded, refetch } = useCallApi(`jobHistory?startDate=${lastWeek}&endDate=${today}`, "GET", {}, [], showSystemMessage)

    return (
        <>
            <div className="card mb-3 overflow-auto">
        <div className="card-header d-flex justify-content-between">
            <div className="font-weight-bold float-left">System Jobs Log</div>
            <Link to={`/system/jobHistory`}>Full Report</Link>
        </div>
        <div className="card-body p-0">
            <table className="table table-striped table-card m-0">
                <thead>
                    <tr className="text-secondary">
                        <th>Name</th>
                        <th>Status</th>
                        <th>Message</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {dataLoaded ? (
                        data.history === 0 ? (
                            <tr>
                                <td colSpan={4}>No stats available </td>
                            </tr>
                        ) : (
                            data.history.map((item, index) => {
                                if (index > 19) return null
                                return (
                                    <tr key={item.id}>
                                        <td>{item.job_name}</td>
                                        <td><div className={ item.status === 'Error' ? 'badge badge-danger' : item.status === 'Success' ? 'badge badge-success' : item.status === 'Note' ? 'badge badge-warning':'' }>{item.status}</div></td>
                                        <td>{item.message}</td>
                                        <td style={{fontSize: '0.87rem'}}>{moment(item.db_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    </tr>
                                )
                            })
                        )
                    ) : (
                        <tr>
                            <td colSpan={4}>
                                <Loading />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>
        </>
    )
}

export default JobHistoryTable