import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../../helpers/callAPI'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'

const SitesModal = ({ siteId, isOpen, handleClose, showSystemMessage }) => {
    const [siteInfo, setSiteInfo] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState()
    const [logoImage, setLogoImage] = useState()
    const [mobileLogoImage, setMobileLogoImage] = useState()
    const [phoneNumberImage, setPhoneNumberImage] = useState()

    let createSite = false

    if (siteId === 0) {
        createSite = true
    }

    useEffect(() => {
        if (createSite) {
            setDataLoaded(true)
            return
        }

        getSiteInfo()
    }, [siteId])

    const save = async (e) => {
        e.preventDefault()

        const formData = new FormData()

        formData.append('background_image', backgroundImage)
        formData.append('logo_image', logoImage)
        formData.append('mobile_logo_image', mobileLogoImage)
        formData.append('phone_number_image', phoneNumberImage)
        formData.append('description', e.target.description.value)
        formData.append('headline1_text', e.target.headline1_text.value)
        formData.append('headline2_text', e.target.headline2_text.value)
        formData.append('main_text', e.target.main_text.value)
        formData.append('phone_number', e.target.phone_number.value)
        formData.append('build_website', e.target.build_website.value)

        if (createSite) {
            callAPI('callrouted/site', 'POST', formData, true)
                .then((result) => {
                    if (result['success'] === true) {
                        showSystemMessage('success', result.message)
                        handleClose(true)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', 'Error while adding callrouted site')
                })
        } else {
            callAPI(`callrouted/site/${siteId}`, 'PUT', formData, true)
                .then((result) => {
                    if (result['success'] === true) {
                        showSystemMessage('success', result.message)
                        handleClose(true)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', 'Error while editing callrouted site')
                })
        }
    }

    const getSiteInfo = () => {
        callAPI(`callrouted/site/${siteId}`, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setSiteInfo(result.site)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching callrouted site information: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching callrouted site information')
            })
    }

    return (
        <Modal show={isOpen} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Callrouted Landing Page</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={save}>
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td width="25%">Landing Page Title:</td>
                                <td>
                                    <input name="description" className="form-control" defaultValue={createSite ? '' : siteInfo.description} />
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">Headlline 1:</td>
                                <td>
                                    <input name="headline1_text" className="form-control" defaultValue={createSite ? '' : siteInfo.headline1_text} />
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">Headlline 2:</td>
                                <td>
                                    <input name="headline2_text" className="form-control" defaultValue={createSite ? '' : siteInfo.headline2_text} />
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">Main Text:</td>
                                <td>
                                    <textarea name="main_text" className="form-control" defaultValue={createSite ? '' : siteInfo.main_text} rows="10" cols="60" />
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">Phone Number:</td>
                                <td>
                                    <input name="phone_number" className="form-control w-50" defaultValue={createSite ? '' : siteInfo.phone} />
                                </td>
                            </tr>
                            {siteId !== 0 && (
                                <>
                                    <tr>
                                        <td width="25%">Background Image:</td>
                                        <td>
                                            <input type="file" name="bg_image_location" onChange={(e) => setBackgroundImage(e.target.files[0])} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="25%">Logo Image:</td>
                                        <td>
                                            <input type="file" name="logo_image_location" onChange={(e) => setLogoImage(e.target.files[0])} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="25%">Mobile Logo Image:</td>
                                        <td>
                                            <input type="file" name="mobile_logo_image_location" onChange={(e) => setMobileLogoImage(e.target.files[0])} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="25%">Phone Number Image:</td>
                                        <td>
                                            <input type="file" name="phone_number_image_location" onChange={(e) => setPhoneNumberImage(e.target.files[0])} />
                                        </td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <td width="25%">Is the website ready to be built?</td>
                                <td>
                                    <select name="build_website" className="form-control w-25">
                                        <option value="N">No</option>
                                        <option value="Y">Yes</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <CustomButton name="Submit" variant="primary" type="submit" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default SitesModal
