const getCampaignStatus = (status) => {
    switch (status) {
        case 'T':
            return 'Testing'
        case 'H':
            return 'Hidden'
        case 'A':
            return 'Active'
        case 'C':
            return 'Canceled'
        case 'B':
            return 'By Request'
        case 'S':
            return 'Submitted'
        default:
            return 'Unknown'
    }
}

export default getCampaignStatus
