import React from 'react'
import { useAuth } from '../../context/AuthContext'
import './Layout.css'

import Toolbar from './Toolbar/Toolbar'

const Layout = ({ logout, logoutFromAllDevices, children }) => {
    const auth = useAuth()
    const [isOpen, setIsOpen] = React.useState({ left: '-200px' })

    return (
        <>
            <Toolbar isOpen={isOpen} setIsOpen={setIsOpen} mgrName={auth.user.name} logout={logout} logoutFromAllDevices={logoutFromAllDevices} mgrId={auth.user.id}></Toolbar>
            <main onClick={() => setIsOpen({ left: '-200px' })} className="Content">
                {children}
            </main>
            <div className="background"></div>
        </>
    )
}

export default Layout
