import React from 'react'

import Property from '../../Templates/Property/Property'
import Loading from '../../../../templates/UI/Loading/Loading'
import useCallApi from '../../../../hooks/useCallApi'

const DashboardProperties = ({ status, showSystemMessage }) => {
    const { data, dataLoaded } = useCallApi(`avatar/properties`, 'GET', {}, [])

    return dataLoaded ? data.properties.filter((property) => property.status.toLowerCase() === status).map((property) => <Property data={property} showEdit />) : <Loading />
}

export default DashboardProperties
