const getSourceQuestions = (trafficTypeLetter) => {
    let question1 = '',
        question2 = '',
        question3 = ''

    if (trafficTypeLetter === 'I') {
        question1 = 'What incentive do you provide surfers?'
        question2 = 'Is this an offerwall or incentive site?'
        question3 = 'What is the URL of your incentive site?'
    } else if (trafficTypeLetter === 'E') {
        question1 = 'How do you generate your e-mail list?'
        question2 = 'What type of e-mail traffic do you send?'
        question3 = 'What is your list demographic?'
    } else if (trafficTypeLetter === 'S') {
        question1 = 'What is the URL of the site you will use?'
        question2 = 'Will you use banners or text ads?'
        question3 = 'How does traffic get to your website?'
    } else if (trafficTypeLetter === 'C') {
        question1 = 'What traffic network will you use?'
        question2 = 'Will you direct link or use a custom landing page?'
        question3 = 'If you are using a custom landing page, what is its URL?'
    } else if (trafficTypeLetter === 'R') {
        question1 = 'Which search engines will you use?'
        question2 = 'Will you direct link or use a custom landing page?'
        question3 = 'If you are using a custom landing page, what is its URL?'
    } else if (trafficTypeLetter === 'L') {
        question1 = 'Which social sources will you use?'
        question2 = 'Will you use paid ads or a profile/fanpage?'
        question3 = 'If you are a using profile/fanpage, what is its URL?'
    } else if (trafficTypeLetter === 'M') {
        question1 = 'What mobile traffic network will you use?'
        question2 = 'What kind of mobile ads will you run?'
        question3 = 'If you will use a custom LP, provide the link.'
    } else if (trafficTypeLetter === 'N') {
        question1 = 'What native traffic network will you use?'
        question2 = 'What kind of native ads will you run?'
        question3 = 'If you will use a custom LP, provide the link.'
    }
    return { question1, question2, question3 }
}

module.exports = getSourceQuestions
