import moment from "moment"

// This function is supposed to be used for moving to the next business day in case the added day falls on weekend

const addWeekdays = (date) => {

    if (isWeekend(date)) {
        let day = 1     // This is to help breaking the while loop. Basically, we only want to move forward 1 day at a time until weekday is found.

        while (day > 0) {
            date = moment(date).add(1, 'day')
            // Decrease 'day' only if it's a weekday
            if (!isWeekend(date)) {
                day -= 1
            }
        }
    }

    return date
}

const isWeekend = (date) => {
    let dayOfWeek = moment(date).isoWeekday()
    return dayOfWeek === 6 || dayOfWeek === 7
}

export default addWeekdays