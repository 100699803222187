import { Card } from 'react-bootstrap'
import { useState } from 'react'
import EmployeePicker from '../../../components/EmployeePicker/EmployeePicker'
import { Form } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AffiliateSearch from '../../../components/AffiliateSearch/AffiliateSearch'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const CreateAffiliateNotification = ({ showSystemMessage }) => {
    document.title = 'Create Affiliate Notification'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_notification')

    const [employee, setEmployee] = useState({ id: 0, name: '' })
    const [affiliate, setAffiliate] = useState({ id: -1, name: '' })
    const [message, setMessage] = useState('')
    const [priority, setPriority] = useState(0)
    const [notify, setNotify] = useState('N')
    const [selection, setSelection] = useState('network')
    const [dataLoaded, setDataLoaded] = useState(true)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const createNotification = async () => {
        setDataLoaded(false)

        let body = {
            affiliate,
            message,
            priority,
            notify,
            employee,
        }

        const response = await callAPI(`affiliates/affiliateNews`, 'POST', body)
        if (response.success) {
            showSystemMessage('success', 'Notification created successfully')
        } else {
            showSystemMessage('error', response.message)
        }

        setDataLoaded(true)
    }

    const changeSelection = (selection) => {
        if (selection === 'network') {
            setEmployee({
                id: 0,
                name: '',
            })
            setAffiliate({ id: -1, name: '' })
        }

        if (selection === 'manager') {
            setAffiliate({
                id: 0,
                name: '',
            })
        }

        if (selection === 'affiliate') {
            setEmployee({
                id: 0,
                name: '',
            })
            setAffiliate({
                id: 0,
                name: '',
            })
        }

        setSelection(selection)
    }

    return (
        <div className="row">
            <div className="col-lg-6">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <h1 className="mb-h1-dark">Create Affiliate Notification</h1>
                    </Card.Header>
                    <Card.Body>
                        <div className="d-flex mb-1 align-item-center">
                            <div style={{ width: '15rem' }}>For:</div>
                            <div className="form-group">
                                <select className="form-control form-control-sm" onChange={(e) => changeSelection(e.target.value)}>
                                    <option value="network">Entire Network</option>
                                    <option value="manager">Affiliate Manager</option>
                                    <option value="affiliate">Specific affiliate</option>
                                </select>
                            </div>
                        </div>

                        {selection === 'manager' && (
                            <div className="d-flex mb-1">
                                <span style={{ width: '15rem' }}>Manager:</span> <EmployeePicker employee={employee} setEmployee={setEmployee} role={['AM_SENIOR', 'AM_JUNIOR', 'AM_JR_TEAMLEAD']} />
                            </div>
                        )}

                        {selection === 'affiliate' && (
                            <div className="d-flex">
                                <span style={{ width: '15rem' }}>Affiliate:</span>
                                <span className="w-50">
                                    <AffiliateSearch setAffiliate={setAffiliate} />
                                </span>
                            </div>
                        )}

                        <div className="d-flex mt-1">
                            <span style={{ width: '15rem' }}>Priority Level:</span>
                            <Form.Control as="select" className="w-25" value={priority} onChange={(e) => setPriority(e.target.value)}>
                                <option value="0">All</option>
                                <option value="1">High</option>
                                <option value="2">Medium</option>
                                <option value="3">New</option>
                                <option value="4">Low</option>
                                <option value="5">Very Low</option>
                            </Form.Control>
                        </div>
                        <hr />
                        <div className="d-flex">
                            <span style={{ width: '15rem' }}>Notification:</span>
                            <span className="w-50">
                                <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
                            </span>
                        </div>
                        <hr />
                        <div className="d-flex">
                            <span style={{ width: '15rem' }}>Enable pop-down for this alert?</span>
                            <Form.Control as="select" className="w-25" value={notify} onChange={(e) => setNotify(e.target.value)}>
                                <option value="N">No</option>
                                <option value="Y">Yes</option>
                            </Form.Control>
                        </div>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-end">
                        {dataLoaded ? (
                            <button className="btn btn-primary" onClick={createNotification}>
                                Create
                            </button>
                        ) : (
                            <button className="btn btn-secondary" disabled>
                                {spinner}
                            </button>
                        )}
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

export default CreateAffiliateNotification
