import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { SystemMessageContext } from '../../../context/SystemMessageContext';
import queryString from 'query-string'
import AdminPicker from '../../../components/AdminPicker/AdminPicker';
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter';
import useDateRangeFilter from '../../../hooks/useDateRangeFilter';
import Checkbox from '../../Reports/Filters/Checkbox/Checkbox';
import useCallApi from '../../../hooks/useCallApi';
import Dollars from '../../../templates/Dollars/Dollars';
import Number from '../../../templates/Number/Number';
import moment from 'moment'
import Loading from '../../../templates/UI/Loading/Loading';
import { useAuth, useProtectedPage } from '../../../context/AuthContext';
import { DayDropdown, MonthDropdown, YearDropdown } from '../../../components/DateDropdown/DateDropdown';
import useDateDropdown from '../../../hooks/useDateDropdown';

export default function AffiliateEarningsAnalysis({date}){
  

    const { showSystemMessage } = useContext(SystemMessageContext)      

    let initUrl = `reports/custom/affiliateEarningsAnalysis?date=${date}`

    const {data, dataLoaded, refetch} = useCallApi(initUrl, 'GET', {}, [date], showSystemMessage)

    let totals = {
        leads: 0,
        network_earnings: 0,
        aff_payouts: 0,
        margin: 0
    }

    const getReportOutput = () => {
        return (
            <table className="table table-striped table-bordered table-hover mb-0">
                <thead>
                    <tr>
                        <th>Conversion From</th>                        
                        <th className="text-center">Conversions</th>
                        <th className="text-center">Network Earnings</th>
                        <th className="text-center">Affiliate Payout</th>
                        <th className="text-center">Margin</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Report */}
                    {data.report.map( line => {
                        totals.leads = totals.leads + line.tot_leads
                        totals.network_earnings = totals.leads + line.network_earnings
                        totals.aff_payouts = totals.leads + line.aff_payouts
                        totals.margin = totals.leads + line.margin
                        return (                         
                            <tr>
                                <td className="text-left">{moment(`${line.yr}-${line.mth}-01`).utc().format('YYYY-MM')}</td>
                                <td className="text-right"><Number decimals={0} value={line.tot_leads}/></td>
                                <td className="text-right"><Dollars value={line.network_earnings} /></td>
                                <td className="text-right"><Dollars value={line.aff_payouts}/></td>
                                <td className="text-right"><Dollars value={line.margin}/></td>
                            </tr>
                        )
                    })}     
                    {/* Totals */}
                    <tr>
                        <td className="text-left"></td>
                        <td className="text-right font-weight-bold"><Number decimals={0} value={totals.leads}/></td>
                        <td className="text-right font-weight-bold"><Dollars value={totals.network_earnings} /></td>
                        <td className="text-right font-weight-bold"><Dollars value={totals.aff_payouts}/></td>
                        <td className="text-right font-weight-bold"><Dollars value={totals.margin}/></td>
                    </tr>
                </tbody>
                
            </table>
        )
    }

    return (
        <div className="card pb-0">
            <div className='d-flex align-items-center p-3 flex-wrap'>
                <div className="mr-4 d-flex align-items-center">
                    <div className="alert alert-primary mb-0">{date}</div>
                    <div className="ml-2 mb-h1-dark">Conversion breakdown</div>
                </div>
            </div>
            <div>
                {dataLoaded ? (data.report ? getReportOutput() : <div className="p-2">No details.</div>) : <Loading /> }
            </div>                
        </div>
    )
}