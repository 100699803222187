import useCallApi from '../../../hooks/useCallApi'
import { useEffect, useState } from 'react'
import Loading from '../../../templates/UI/Loading/Loading'
import { Button, Card, Table } from 'react-bootstrap'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import CategoryModal from './CategoryModal'
import ResourceModal from './AffiliateResourceModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const AffiliateResources = ({ showSystemMessage }) => {
    document.title = 'Affiliate Resources'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_resources')

    const [isOpen, setIsOpen] = useState({ resources: false, categories: false })
    const [isCreate, setIsCreate] = useState(true)
    const [formData, setFormData] = useState({})
    const { data: resourceData, refetch: resourceRefetch, setData: setResourceData, dataLoaded: resourceDataLoaded } = useCallApi(`affiliates/resources`, 'GET', {}, [])
    const { data: categoryData, refetch: categoryRefetch, setData: setCategoryData, dataLoaded: categoryDataLoaded } = useCallApi(`affiliates/resources/categories`, 'GET', {}, [])

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Manage Affiliate Portal Resources Page</h1>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div>
                        <Card className="mb-3">
                            <CategoryModal showSystemMessage={showSystemMessage} refetch={categoryRefetch} isOpen={isOpen.categories} setIsOpen={(val) => setIsOpen({ ...isOpen, categories: val })} isCreate={isCreate} formData={formData} setFormData={setFormData} />
                            <ResourceModal showSystemMessage={showSystemMessage} refetch={resourceRefetch} categories={categoryData.categories} isOpen={isOpen.resources} setIsOpen={(val) => setIsOpen({ ...isOpen, resources: val })} isCreate={isCreate} formData={formData} setFormData={setFormData} />
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <div as="mb-h1">Affiliate Resource Categories</div>
                                <Button
                                    className="btn btn-warning btn-sm"
                                    onClick={() => {
                                        setIsCreate(true)
                                        setFormData({ order: 0, name: '' })
                                        setIsOpen({ ...isOpen, categories: true })
                                    }}
                                >
                                    Create Category
                                </Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>Order</th>
                                            <th>Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {categoryDataLoaded ? (
                                        <tbody>
                                            {categoryData.categories.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{row.order}</td>
                                                    <td>{row.name}</td>
                                                    <td align="right">
                                                        <Button
                                                            onClick={() => {
                                                                setIsCreate(false)
                                                                setFormData(row)
                                                                setIsOpen({ ...isOpen, categories: true })
                                                            }}
                                                            className="btn-warning btn-sm"
                                                        >
                                                            Edit
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="3">
                                                    <Loading />
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div>
                        <Card className="mb-3 w-100">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <div as="mb-h1-dark">Affiliate Resources</div>
                                <Button
                                    className="btn btn btn-warning btn-sm btn-sm"
                                    onClick={() => {
                                        setIsCreate(true)
                                        setFormData({ id: 0, name: '', category: { order: 1, name: 'General' }, description: '', image: '', url: '', promoCode: '', promoDescription: '' })
                                        setIsOpen({ ...isOpen, resources: true })
                                    }}
                                >
                                    Create Resource
                                </Button>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Categories</th>
                                            <th>Description</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {resourceDataLoaded ? (
                                        <tbody>
                                            {resourceData.resources.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{row.name}</td>
                                                    <td>{row.category.name}</td>
                                                    <td>{row.description}</td>
                                                    <td align="right">
                                                        <Button
                                                            onClick={() => {
                                                                setIsCreate(false)
                                                                setFormData({ id: row.id, name: row.name, category: row.category, description: row.description, image: row.image, url: row.url, promoCode: row.promoCode, promoDescription: row.promoDescription })
                                                                setIsOpen({ ...isOpen, resources: true })
                                                            }}
                                                            className="btn-warning btn-sm"
                                                        >
                                                            Edit
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="3">
                                                    <Loading />
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AffiliateResources
