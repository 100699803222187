import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import callAPI from '../../helpers/callAPI'

const FlagDropdown = ({ showSystemMessage, target, flagId, setFlagId }) => {
    const [flags, setFlags] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        let url = `systemFlags`

        if (target) {
            url += `?target=${target}`
        }

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setFlags(result.flags)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    setDataLoaded(true)
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while getting custom flags')
                setDataLoaded(true)
            })
    }, [])

    return (
        <React.Fragment>
            {dataLoaded ?
                <select
                    className="form-control form-control-sm"                    
                    onChange={(e) => {
                        setFlagId(e.target.value)
                    }}
                >
                    <option value="">None</option>
                    {flags.map((flag) => (
                        <option key={flag.id} value={flag.id} selected={flagId == flag.id ? true : false}>
                            {flag.name}
                        </option>
                    ))}
                </select>
            :
                 <select className="form-control form-control-sm"><option value="">Loading...</option></select>
            }
        </React.Fragment>
    )
}

export default FlagDropdown
