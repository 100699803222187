import { Form, Modal, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import moment from 'moment'
import callAPI from '../../helpers/callAPI'
import CampaignSearch from '../CampaignSearch/CampaignSearch'
import AffiliateSearch from '../AffiliateSearch/AffiliateSearch'
import _ from 'lodash'

const FraudWatchModal = ({ modalData, setModalData, isCampaign, refetch, showSystemMessage }) => {
    const [campaign, setCampaign] = useState({ name: '', id: null })
    const [affiliate, setAffiliate] = useState({ name: '', id: null })

    useEffect(() => {
        setModalData((prevState) => ({
            ...prevState,
            item: { ...prevState.item, campaign: campaign },
        }))
    }, [campaign])

    useEffect(() => {
        setModalData((prevState) => ({
            ...prevState,
            item: { ...prevState.item, affiliate: affiliate },
        }))
    }, [affiliate])

    const updateFraudWatch = async (e) => {
        e.preventDefault()

        let result
        if (isCampaign) {
            result = await callAPI(`fraudWatch/campaign/${modalData.item.id}`, 'PUT', modalData.item)
        } else {
            result = await callAPI(`fraudWatch/affiliate/${modalData.item.id}`, 'PUT', modalData.item)
        }
        if (result.success) {
            setModalData({ isOpen: false })
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while updating fraud watch.')
        }
    }

    const createFraudWatch = async (e) => {
        e.preventDefault()

        if (isCampaign) {
            if (!campaign.id && !modalData.item.campaign.id) {
                showSystemMessage('error', 'Missing campaign')
                return false
            }
        } else {
            if (!affiliate.id && !modalData.item.affiliate.id) {
                showSystemMessage('error', 'Missing affiliate')
                return false
            }
        }

        let result
        if (isCampaign) {
            result = await callAPI(`fraudWatch/campaign`, 'POST', modalData.item)
        } else {
            result = await callAPI(`fraudWatch/affiliate`, 'POST', modalData.item)
        }
        if (result.success) {
            setModalData({ isOpen: false })
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while creating fraud watch.')
        }
    }

    const deleteFraudWatch = async () => {
        let result
        if (isCampaign) {
            result = await callAPI(`fraudWatch/campaign/${modalData.item.id}`, 'DELETE', {})
        } else {
            result = await callAPI(`fraudWatch/affiliate/${modalData.item.id}`, 'DELETE', {})
        }
        if (result.success) {
            setModalData({ isOpen: false })
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while deleting fraud watch.')
        }
    }

    return (
        <Modal show={modalData.isOpen} onHide={() => setModalData({ isOpen: false })}>
            <form onSubmit={modalData.isCreate ? createFraudWatch : updateFraudWatch}>
                <Modal.Header closeButton>
                    <div className="mb-h1-dark">
                        {modalData.isCreate ? 'Create' : 'Edit'} Fraud Watch {isCampaign ? 'Campaign' : 'Affiliate'}
                    </div>
                </Modal.Header>
                {modalData.isOpen && modalData ? (
                    <Modal.Body>
                        <div className="d-flex m-2 align-items-center">
                            <span className="w-25">{isCampaign ? 'Campaign' : 'Affiliate'}</span>
                            {isCampaign ? <div className="w-75">{modalData.isCreate ? <CampaignSearch setCampaign={setCampaign} campaign={modalData.item.campaign.id > 0 ? modalData.item.campaign : false} /> : <Campaign oid={modalData.item.campaign.id} name={modalData.item.campaign.name} status={modalData.item.campaign.status} />}</div> : <div className="w-75">{modalData.isCreate ? <AffiliateSearch setAffiliate={setAffiliate} affiliate={modalData.item.affiliate.id > 0 ? modalData.item.affiliate : false} /> : <Affiliate oid={modalData.item.affiliate.id} name={modalData.item.affiliate.name} status={modalData.item.affiliate.status} />}</div>}
                        </div>
                        <div className="d-flex m-2 align-items-center">
                            <span className="w-25">Reason</span>
                            <Form.Control
                                className="w-75"
                                value={_.unescape(modalData.item.reason)}
                                onChange={(e) =>
                                    setModalData({
                                        ...modalData,
                                        item: { ...modalData.item, reason: e.target.value },
                                    })
                                }
                                required
                            />
                        </div>
                        <div className="d-flex m-2 align-items-center">
                            <span className="w-25">Frequency</span>
                            Check Every &nbsp;
                            <Form.Control
                                type="number"
                                style={{ width: '90px' }}
                                value={modalData.item.frequency}
                                onChange={(e) =>
                                    setModalData({
                                        ...modalData,
                                        item: { ...modalData.item, frequency: e.target.value },
                                    })
                                }
                                min="0"
                                required
                            />{' '}
                            &nbsp;Days
                        </div>
                        <div className="d-flex m-2 align-items-center">
                            <span className="w-25">Start Date</span>
                            <Form.Control
                                type="date"
                                className="w-75"
                                defaultValue={modalData.item.frequency_start_date !== '' ? moment(modalData.item.frequency_start_date).utc().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    setModalData({
                                        ...modalData,
                                        item: {
                                            ...modalData.item,
                                            frequency_start_date: e.target.value,
                                        },
                                    })
                                }
                                required
                            />
                        </div>
                        <div className="d-flex m-2 align-items-center">
                            <span className="w-25">Notes</span>
                            <Form.Control
                                className="w-75"
                                value={_.unescape(modalData.item.notes)}
                                onChange={(e) =>
                                    setModalData({
                                        ...modalData,
                                        item: { ...modalData.item, notes: e.target.value },
                                    })
                                }
                            />
                        </div>
                    </Modal.Body>
                ) : null}
                <Modal.Footer>
                    {modalData.isCreate ? (
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    ) : (
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    )}
                    {modalData.isCreate ? null : (
                        <Button variant="danger" onClick={deleteFraudWatch}>
                            Delete
                        </Button>
                    )}
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default FraudWatchModal
