import callAPI from '../helpers/callAPI.js'
import { useState, useEffect, useCallback } from 'react'

const useCallApi = (url, method, body = {}, dependencies, showSystemMessage) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)
    const [refetchTrigger, setRefetchTrigger] = useState(0)

    const refetch = useCallback(() => setRefetchTrigger(refetchTrigger + 1), [refetchTrigger])

    useEffect(() => {
        const fetchData = async () => {
            setDataLoaded(false) //Added this to show loading spinner while refetch
            const result = await callAPI(url, method, body)

            if (!result.success) {
                if (showSystemMessage !== undefined) {
                    showSystemMessage('error', result.errors.message)
                } else {
                    console.log(`Error: ${result.errors.message}`)
                }
            } else {
                setData(result)
            }

            setDataLoaded(true)
        }
        fetchData()
    }, [...dependencies, refetchTrigger])
    return { dataLoaded, data, refetch, setData, setDataLoaded }
}
export default useCallApi
