import { Card, Table } from "react-bootstrap";
import useCallApi from "../../../hooks/useCallApi";
import moment from "moment";
import { colors } from '../../../theme/variables'
import Loading from '../../../templates/UI/Loading/Loading'
import { DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dollars from "../../../templates/Dollars/Dollars";
import callAPI from "../../../helpers/callAPI";
import { useEffect, useState } from "react";

const AvatarNumbers = () => {
    const [init, setInit] = useState(true)

    const [data, setData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)

    const fetchData = async () => {
        setDataLoaded(false)
        let result = await callAPI(`mbStatus/monitoring/avatarNumbers`, 'GET')
        if (result.success) {
            setData(result)
        }
        setDataLoaded(true)
    }

    useEffect(() => {
        if (init) {
            fetchData()
            setInit(false)
        }
        const interval = setInterval(() => {
            fetchData()
        }, 60000)
        return () => clearInterval(interval)
    }, [])
    return (
        <Card className="mb-3">
            <Card.Header>Avatar (Today vs. Yesterday)</Card.Header>
            <Card.Body>
                {dataLoaded && data ? (
                    <Table className="m-0">
                        <thead>
                            <tr>
                                <th>Domain</th>
                                <th className="text-right">Revenue</th>
                                <th className="text-right">Cost</th>
                                <th className="text-right">Margin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.details.avatarNumbers.map((server, index) => {
                                return (//bold
                                    <tr key={index} style={{fontWeight: index === data.details.avatarNumbers.length -1 ? 'bold' : 'normal'}}>
                                        <td>{server.domain}</td>
                                        <td className='text-right'>
                                            <Dollars value={server.today_av_revenue} /> <br />
                                            [<Dollars value={server.yesterday_av_revenue} />]   
                                        </td>
                                        <td className='text-right'>
                                            <Dollars value={server.today_av_costs} /> <br />
                                            [<Dollars value={server.yesterday_av_costs} />]
                                        </td>
                                        <td className='text-right'>
                                            <Dollars value={server.today_av_margin} /> <br />
                                            [<Dollars value={server.yesterday_av_margin} />]
                                        </td>
                                    </tr>
                                )
                            })}
                           
                        </tbody>
                    </Table>
                ) : (
                    <Loading />
                )}
            </Card.Body>
        </Card>
    )
}

export { AvatarNumbers }