import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import Loading from '../../../../templates/UI/Loading/Loading'
import { colors } from '../../../../theme/variables'

const AffiliateEarningChanges = ({ manager }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)
    const [option, setOption] = useState('dropped')

    let statLabelClasses = 'btn btn-secondary'

    useEffect(() => {
        const fetchCampaigns = async () => {

            let url = `affiliates/earningChanges`

            if (manager) {
                url += `?adminId=${manager.id}`
            }

            const data = await callAPI(url, 'GET')
            if (data.success) {
                let result
                result = {
                    increased_affiliates: data.increased_affiliates,
                    dropped_affiliates: data.dropped_affiliates,
                }
                setData(result)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchCampaigns()
    }, [manager])

    const handleOptionChange = (event) => {
        event.preventDefault()
        setOption(event.target.value)
    }
    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>Affiliate Earning Changes (24h)</span>
            </Card.Header>
            <div className="d-flex justify-content-center">
                <div className="m-2 btn-group btn-group-sm btn-group-toggle w-75" data-toggle="buttons" role="group">
                    <label className={option === 'dropped' ? statLabelClasses + ' active' : statLabelClasses}>
                        <input type="radio" name="statOptions" id="option1" className="change-graph-option" autoComplete="off" value="dropped" onClick={handleOptionChange} /> Dropped
                    </label>
                    <label className={option === 'increased' ? statLabelClasses + ' active' : statLabelClasses}>
                        <input type="radio" name="statOptions" id="option2" className="change-graph-option" autoComplete="off" value="increased" onClick={handleOptionChange} /> Increased
                    </label>
                </div>
            </div>
            <Card.Body className="p-0 table-container-scroll">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th className="text-right">Avg Aff Earn</th>
                            <th className="text-right">Current Aff Earn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {option === 'dropped' ? (
                                    !data || data.dropped_affiliates.length === 0 ? (
                                        <tr>
                                            <td colSpan={12}>No data.</td>
                                        </tr>
                                    ) : (
                                        data.dropped_affiliates.map((record) => (
                                            <tr key={record.affiliate.id}>
                                                <td xs={3}>{record.affiliate.id}</td>
                                                <td xs={6}>
                                                    {record.affiliate.online ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill={colors.success} className="bi bi-circle-fill mr-3" viewBox="0 0 16 16">
                                                            <circle cx="8" cy="8" r="8" />
                                                        </svg>
                                                    ) : null}
                                                    <Affiliate aid={`${record.affiliate.id}`} name={`${record.affiliate.name}`} status={`${record.affiliate.status}`} priority={`${record.affiliate.priority}`} />
                                                </td>
                                                <td className="text-right" xs={6}>
                                                    <Dollars value={record.avg_earnings} />
                                                </td>
                                                <td className="text-right" xs={3}>
                                                    <Link to={`/reports/stats/campaigns?affiliateId=${record.affiliate.id}&startDate=${moment().add(-24, 'hours').format('YYYY-MM-DD HH:mm:ss')}&withAvatar=true`} className="text-primary font-weight-bold">
                                                        <Dollars value={record.current_earnings} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                ) : !data || data.increased_affiliates.length === 0 ? (
                                    <tr>
                                        <td colSpan={12}>No data.</td>
                                    </tr>
                                ) : (
                                    data.increased_affiliates.map((record) => (
                                        <tr key={record.affiliate.id}>
                                            <td xs={3}>{record.affiliate.id}</td>
                                            <td xs={6}>
                                                {record.affiliate.online ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill={colors.success} className="bi bi-circle-fill mr-3" viewBox="0 0 16 16">
                                                        <circle cx="8" cy="8" r="8" />
                                                    </svg>
                                                ) : null}
                                                <Affiliate aid={`${record.affiliate.id}`} name={`${record.affiliate.name}`} status={`${record.affiliate.status}`} priority={`${record.affiliate.priority}`} />
                                            </td>
                                            <td className="text-right" xs={6}>
                                                <Dollars value={record.avg_earnings} />
                                            </td>
                                            <td className="text-right" xs={3}>
                                                <Link to={`/reports/stats/campaigns?affiliateId=${record.affiliate.id}&startDate=${moment().add(-24, 'hours').format('YYYY-MM-DD HH:mm:ss')}&withAvatar=true`} className="text-primary font-weight-bold">
                                                    <Dollars value={record.current_earnings} />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default AffiliateEarningChanges
