import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import callAPI from '../../../helpers/callAPI'

const Referrals = ({ affiliateId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliate, setAffiliate] = useState({})
    const [affiliates, setAffiliates] = useState([])
    const location = useLocation()

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    document.title = `${dataLoaded ? `${affiliate.name}: ` : ''}Referrals`

    useEffect(() => {
        getReport()        
    }, [location])

    const getReport = async () => {
        setDataLoaded(false)

        let url = `affiliate/${affiliateId}/referrals`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setAffiliate(result.affiliate)
                    setAffiliates(result.affiliates)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching affiliate referrals: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching affiliate referrals')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} priority={tableData.data[tableData.row.index].priority} status={tableData.data[tableData.row.index].status} />
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={affiliates} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Referrals</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliate ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : 'Affiliate Not Found' : spinner}</h1>
                                    </div>
                                </div>
                            </div>
                            {dataLoaded ? affiliates.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Referrals
