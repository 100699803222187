import React, { useState, useEffect } from 'react'
import useCallApi from '../../../../hooks/useCallApi'
import BizYear from '../../../Reports/ReportBizDevPipeline/BizYear'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function BizdevPipelineSummary({ showSystemMessage }) {
    const { data, dataLoaded } = useCallApi(`reports/bizdev_pipeline`, 'GET', {}, [], showSystemMessage)

    return (
        <div className="card mb-2">
            <div className="card-header d-flex justify-content-between">
                <div>Bizdev Pipeline</div>
                <Link to={`/reports/custom/bizdevPipeline`}>Report</Link>
            </div>
            <div className="overflow-auto">
                <table className="table table-striped table-card table-hover mb-0 bizdev">
                    <thead style={{ fontSize: '0.75em' }}>
                        <tr>
                            <th scope="col">&nbsp;</th>
                            <th scope="col" className="text-right">
                                Active Adv.
                            </th>
                            <th scope="col" className="text-right">
                                Adv. with Sales
                            </th>
                            <th scope="col" className="text-right">
                                New
                            </th>
                            <th scope="col" className="text-right">
                                Discussion
                            </th>
                            <th scope="col" className="text-right">
                                Negotiation
                            </th>
                            <th scope="col" className="text-right">
                                Promoted to Active
                            </th>
                            <th scope="col" className="text-right">
                                Cancel-old
                            </th>
                            <th scope="col" className="text-right">
                                Cancel-new
                            </th>
                            <th scope="col" width="20px">
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded &&
                            data.report.map((record) => {
                                return <BizYear record={record} key={record.db_date} />
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
