import moment from 'moment'
import { useState } from 'react'
import { Badge, Button, Card, Form, Table } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'
import _ from 'lodash'
import CampaignCategoriesSelect from '../../../../../components/CampaignCategoriesSelect/CampaignCategoriesSelect'
import KeywordSearch from '../../../../../components/KeywordSearch/KeywordSearch'
import { useAuth, useProtectedContent } from '../../../../../context/AuthContext'
import callAPI from '../../../../../helpers/callAPI'
import useCallApi from '../../../../../hooks/useCallApi'
import Advertiser from '../../../../../templates/Advertiser/Advertiser'
import campaignStatuses from '../../../../../data/campaignStatuses'

const GeneralInfo = ({ showSystemMessage, campaignId, campaign, data, setData, refetch, setFormData, setIsDisabled }) => {
    const auth = useAuth()
    const showRelaunch = useProtectedContent(auth.permissions, 'campaign_relaunch')

    const { data: keywordListData } = useCallApi(`keywordList`, 'GET', {}, [campaignId], showSystemMessage)

    const [reload, setReload] = useState(false)

    // const [dataLoaded, setDataLoaded] = useState(true)

    const removeKeyword = (index) => {
        let keywords = data.keywords
        keywords.splice(index, 1)
        setData({ ...data, keywords: keywords })
    }

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
    }

    const handleOnHover = (result) => {
        // the item hovered
    }

    const handleOnSelect = (item) => {
        let temp = data.keywords
        temp.push(item.name)
        setData({ ...data, keywords: temp })
        setIsDisabled(false)
    }

    const handleOnFocus = () => { }

    const setCategory = (categoryObj) => {
        setIsDisabled(false)
        setData({ ...data, category: { ...data.category, ids: categoryObj } })
    }

    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <div>General Info</div>
                </div>
            </Card.Header>
            <Table striped bordered>
                <tbody>
                    <tr>
                        <td>
                            <span className="d-flex justify-content-end align-items-center">Campaign Name</span>
                        </td>
                        <td colSpan={2}>
                            <Form.Control
                                type="text"
                                value={data.name}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, name: e.target.value })
                                }}
                                size="sm"
                                required
                            />
                        </td>
                        <td>
                            <span className="d-flex justify-content-end align-items-center">
                                Mobile Screen Optimized{' '}
                                <Form.Check
                                    className="ml-2"
                                    size="sm"
                                    type="checkbox"
                                    checked={data.mobile_optimized}
                                    onChange={(e) => {
                                        setIsDisabled(false)
                                        setData({ ...data, mobile_optimized: e.target.checked })
                                    }}
                                />
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td align="right">Advertiser</td>
                        <td colSpan={3}>
                            <Advertiser name={data.advertiser.name} id={data.advertiser.id} status={data.advertiser.status.value} />
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Status</span>
                        </td>
                        <td>
                            <div className="d-flex justify-content-between align-items-center">
                                <Form.Control
                                    as="select"
                                    value={data.status.value}
                                    onChange={(e) => {
                                        setIsDisabled(false)
                                        setData({ ...data, status: { value: e.target.value } })
                                    }}
                                    size="sm"
                                >
                                    {campaignStatuses.campaignStatuses.map(status => <option value={status.value} key={status.value}>{status.text}</option>)}
                                </Form.Control>
                            </div>
                            {showRelaunch && (data.status.value === 'A' || data.status.value === 'B') && (
                                <Button
                                    variant="warning"
                                    size="sm"
                                    className="mt-2"
                                    onClick={async () => {
                                        const result = await callAPI(`campaign/${campaignId}/relaunch`, 'PUT')
                                        if (result.success) {
                                            showSystemMessage('success', 'Campaign relaunched successfully')
                                        } else {
                                            showSystemMessage('error', result.errors.message)
                                        }
                                    }}
                                >
                                    Relaunch {/* The purpose of relaunching is to put the campaign back in the NEW campaigns list. It's a fake launch of an already active campaign. */}
                                </Button>
                            )}
                        </td>
                        <td align="right">
                            <span>Campaign Type</span>
                        </td>
                        <td>
                            <Form.Control
                                as="select"
                                value={data.type.value}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setReload(true)
                                    setData({ ...data, type: { value: e.target.value } })
                                }}
                                size="sm"
                                className="w-100"
                            >
                                <option value="C">Normal</option>
                                <option value="H">Host & Post</option>
                                <option value="A">Avatar</option>
                            </Form.Control>
                            {data.type.value === 'H' ? (
                                <>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                        <div className="d-flex align-items-center">Post type: </div>
                                        <Form.Control
                                            as="select"
                                            value={data.tracking.host_and_post.post_response_type}
                                            onChange={(e) => {
                                                setIsDisabled(false)
                                                setData({ ...data, tracking: { ...data.tracking, host_and_post: { ...data.tracking.host_and_post, post_response_type: e.target.value } } })
                                            }}
                                            size="sm"
                                            className="w-50"
                                            required
                                        >
                                            <option value=""></option>
                                            <option value="FORM_POST">Form</option>
                                            <option value="JSON_POST">JSON</option>
                                        </Form.Control>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                        <div className="d-flex align-items-center">Scrub duplicates: </div>
                                        <Form.Check
                                            className="ml-2"
                                            size="sm"
                                            type="checkbox"
                                            checked={data.tracking.host_and_post.scrub_required}
                                            onChange={(e) => {
                                                setIsDisabled(false)
                                                setData({ ...data, tracking: { ...data.tracking, host_and_post: { ...data.tracking.host_and_post, scrub_required: e.target.checked } } })
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                        <div className="d-flex align-items-center">Response type: </div>
                                        <Form.Control
                                            as="select"
                                            value={data.tracking.host_and_post.post_success_response_type}
                                            onChange={(e) => {
                                                setIsDisabled(false)
                                                setData({ ...data, tracking: { ...data.tracking, host_and_post: { ...data.tracking.host_and_post, post_success_response_type: e.target.value } } })
                                            }}
                                            size="sm"
                                            className="w-50"
                                        >
                                            <option value=""></option>
                                            <option value="JSON">JSON</option>
                                            <option value="STRING">Text</option>
                                        </Form.Control>
                                    </div>
                                    {data.tracking.host_and_post.post_success_response_type === 'STRING' ? (
                                        <div className="d-flex justify-content-between align-items-center mt-2">
                                            <div className="d-flex align-items-center mr-2">Response: </div>
                                            <Form.Control
                                                type="text"
                                                value={data.tracking.host_and_post.post_success_response}
                                                onChange={(e) => {
                                                    setIsDisabled(false)
                                                    setData({ ...data, tracking: { ...data.tracking, host_and_post: { ...data.tracking.host_and_post, post_success_response: e.target.value } } })
                                                }}
                                                size="sm"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="mt-2">
                                                <div className="d-flex align-items-center mr-2">
                                                    SUCCESS KEY: <span className="text-danger">*Case sensitive!</span>
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    value={data.tracking.host_and_post.post_success_json_key}
                                                    onChange={(e) => {
                                                        setIsDisabled(false)
                                                        setData({ ...data, tracking: { ...data.tracking, host_and_post: { ...data.tracking.host_and_post, post_success_json_key: e.target.value } } })
                                                    }}
                                                    size="sm"
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <div className="d-flex align-items-center mr-2">
                                                    SUCCESS VALUE: <span className="text-danger">*Case sensitive!</span>
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    value={data.tracking.host_and_post.post_success_json_key_value}
                                                    onChange={(e) => {
                                                        setIsDisabled(false)
                                                        setData({ ...data, tracking: { ...data.tracking, host_and_post: { ...data.tracking.host_and_post, post_success_json_key_value: e.target.value } } })
                                                    }}
                                                    size="sm"
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <div className="d-flex align-items-center mr-2">
                                                    SALE AMOUNT KEY: <span className="text-danger">*Case sensitive!</span>
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    value={data.tracking.host_and_post.post_sale_amount_key}
                                                    onChange={(e) => {
                                                        setIsDisabled(false)
                                                        setData({ ...data, tracking: { ...data.tracking, host_and_post: { ...data.tracking.host_and_post, post_sale_amount_key: e.target.value } } })
                                                    }}
                                                    size="sm"
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Ignore Blank Lead Data</span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.ignore_blank_leaddata}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, ignore_blank_leaddata: e.target.checked } })
                                }}
                            />
                        </td>
                        <td align="right">
                            <span>Expiry Date (YYYY-MM-DD)</span>
                        </td>
                        <td>
                            <div className="d-flex justify-content-between align-items-center">{moment(data.expiry_date).year() === 2099 ? 'Does not expire' : moment(data.expiry_date).utc().format('YYYY-MM-DD')}</div>
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Reversal Criteria</span>
                        </td>
                        <td>
                            <Form.Control
                                as="select"
                                size="sm"
                                className="w-100"
                                value={data.reversal_criteria.value}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, reversal_criteria: e.target.value })
                                }}
                            >
                                <option value="U">Unknown</option>
                                <option value="N">None Allowed</option>
                                <option value="F">Fraud Only</option>
                                <option value="I">Fraud/Invalid Leads</option>
                                <option value="O">Other</option>
                            </Form.Control>
                        </td>
                        <td align="right">
                            <span>AVATAR redirect - Convert MBID? (573358_avOfferId_sub2)</span>
                        </td>
                        <td>
                            <Form.Check
                                size="sm"
                                type="checkbox"
                                checked={data.tracking.avatar.split_mbid}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, avatar: { ...data.tracking.avatar, split_mbid: e.target.checked } } })
                                }}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td align="right">
                            <span>Category</span>
                        </td>
                        <td colSpan={3}>
                            <div className="d-flex">
                                <CampaignCategoriesSelect category={data.category.ids} setCategory={setCategory} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Visible URL</span>
                        </td>
                        <td colSpan={3}>
                            <Form.Control
                                type="text"
                                size="sm"
                                className="w-100"
                                value={data.visible_url}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, visible_url: e.target.value })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Description</span>
                        </td>
                        <td colSpan={3}>
                            <Form.Control
                                as="textarea"
                                size="sm"
                                className="w-100"
                                style={{ height: '150px' }}
                                value={data.description}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, description: e.target.value })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Keywords</span>
                        </td>
                        <td colSpan={3}>
                            {data.keywords.map((keyword, index) => {
                                return (
                                    <Badge pill variant="dark" className="mx-1 my-1" style={{ fontSize: '1rem' }} key={keyword}>
                                        <div className="d-flex align-items-center">
                                            <div>{keyword}</div>
                                            <button
                                                className="btn btn-link btn-sm text-white"
                                                onClick={() => {
                                                    setIsDisabled(false)
                                                    removeKeyword(index)
                                                }}
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    </Badge>
                                )
                            })}
                            {/* <ReactSearchAutocomplete
                                className="w-25 mt-2"
                                items={keywordListData.keywordList}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelect}
                                onFocus={handleOnFocus}
                                autoFocus
                                formatResult={formatResult}
                                styling={{
                                    height: '27px',
                                    borderRadius: '3px',
                                    boxShadow: 'none',
                                    border: '1px solid #ced4da',
                                    fontSize: '1rem',
                                    zIndex: 500,
                                }}
                            /> */}
                            {
                                data.keywords.length < 5 ?
                                    <KeywordSearch keywordListData={keywordListData} handleOnSearch={handleOnSearch} handleOnHover={handleOnHover} handleOnSelect={handleOnSelect} handleOnFocus={handleOnFocus} />
                                    :
                                    <input type="text" className="form-control" disabled={true} placeholder="5 keyword limit reached." />
                            }

                        </td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}

export default GeneralInfo
