import React, { useState, useEffect } from 'react'
import useCallApi from '../../../../hooks/useCallApi'
import { Line } from 'react-chartjs-2'
import Loading from '../../../../templates/UI/Loading/Loading'

export default function CampaignsByStatusSummary({ showSystemMessage }) {
    const { data, dataLoaded } = useCallApi(`reports/sotu`, 'GET', {}, [], showSystemMessage)

    return (
        <div className="card mb-2">
            <div className="card-header">Campaign By Status</div>
            <div style={{ height: '202px' }}>
                {dataLoaded ? (
                    <Line
                        data={{
                            labels: data.report.map((quarter) => quarter.quarter).reverse(),
                            datasets: [
                                {
                                    label: 'Active',
                                    data: data.report.map((quarter) => quarter.off_statusA_count).reverse(),
                                    fill: false,
                                    backgroundColor: 'rgb(22, 95, 205)',
                                    borderColor: 'rgb(22, 95, 205)',
                                },
                                {
                                    label: 'By Request',
                                    data: data.report.map((quarter) => quarter.off_statusB_count).reverse(),
                                    fill: false,
                                    backgroundColor: 'rgb(242, 101, 20)',
                                    borderColor: 'rgb(242, 101, 20)',
                                },
                                {
                                    label: 'Hidden',
                                    data: data.report.map((quarter) => quarter.off_statusH_count).reverse(),
                                    fill: false,
                                    backgroundColor: 'rgb(255, 205, 0)',
                                    borderColor: 'rgb(255, 205, 0)',
                                    hidden: true,
                                },
                                {
                                    label: 'Testing',
                                    data: data.report.map((quarter) => quarter.off_statusT_count).reverse(),
                                    fill: false,
                                    backgroundColor: 'rgb(239,0,255)',
                                    borderColor: 'rgb(239,0,255)',
                                    hidden: true,
                                },
                            ],
                        }}
                        options={{
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                        }}
                        height={100}
                    />
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}
