import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import { useAuth, useProtectedContent, useProtectedPage, useSetManager } from '../../../context/AuthContext'
import queryString from 'query-string'
import callAPI from '../../../helpers/callAPI'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import Dollars from '../../../templates/Dollars/Dollars'
import CreateInvoiceModal from '../../../components/CreateInvoiceModal/CreateInvoiceModal'
import UpdateBalancesModal from './UpdateBalancesModal'
import ViewModal from './ViewModal'

export default function AdvertiserBalanceReport({ showSystemMessage }) {
    document.title = 'Pre-paid Advertiser Balance Report'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_balance_report')
    const showUpdateBalances = useProtectedContent(auth.permissions, 'advertiser_update_balances')

    const [updateIsOpen, setUpdateIsOpen] = useState(false)
    const [viewIsOpen, setViewIsOpen] = useState(false)

    const location = useLocation()
    let urlParams = queryString.parse(location.search)

    const auth_setManager = useSetManager(auth.permissions, 'advertiser_balance_report')
    const auth_requestInvoice = useProtectedContent(auth.permissions, 'request_invoice')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [adminId, setAdminId] = useState(urlParams.adminId && auth_setManager ? urlParams.adminId : 0)
    const [report, setReport] = useState([])
    const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false)
    const [advertiser, setAdvertiser] = useState({ id: 19, name: 'Jagged Mind Media Inc' })

    const fetchData = async () => {
        let url = `advertisers/balances`

        if (adminId > 0) {
            url += `?adminId=${adminId}`
        }

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setReport(result.advertisers)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching click referrers: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching click referrers')
            })
    }

    useEffect(() => {
        fetchData()
    }, [adminId])

    const handleClose = (isEdited = false) => {
        setShowCreateInvoiceModal(false)

        if (isEdited) {
            fetchData()
        }
    }

    const handleRequestClick = (advertiser) => {
        setAdvertiser(advertiser)
        setShowCreateInvoiceModal(true)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'advertiser.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.name',
                Cell: (tableData) => {
                    return (
                        <>
                            <Advertiser id={tableData.data[tableData.row.index].advertiser.id} name={tableData.data[tableData.row.index].advertiser.name} status={tableData.data[tableData.row.index].advertiser.status.value} />
                        </>
                    )
                },
            },
            {
                Header: ()=><div className="text-right">Balance</div>,
                accessor: 'balance',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return (
                        <>
                            <Dollars value={value} />
                        </>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'owing',                
                Cell: (tableData) => {
                    return tableData.data[tableData.row.index].balance >= 0 ? 'Credit' : 'Owing'                     
                },
            },
            {
                Header: 'Terms',
                accessor: 'terms',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Last Inv',
                accessor: 'last_invoice_period',
                Cell: (tableData) => {
                    return (
                        <>
                            {tableData.data[tableData.row.index].invoice_status_letter === 'S' ? 'Sent' : tableData.data[tableData.row.index].invoice_status_letter === 'C' ? 'Paid' : 'Requested'} {(tableData.data[tableData.row.index].invoice_status_letter === 'S' || tableData.data[tableData.row.index].invoice_status_letter === 'P' || tableData.data[tableData.row.index].invoice_status_letter === 'C') && tableData.data[tableData.row.index].last_invoice_period}
                        </>
                    )
                },
            },
            {
                Header: 'Invoices',
                accessor: '',
                Cell: (tableData) => {
                    return (
                        <>
                            <Link
                                onClick={() => {
                                    setAdvertiser(tableData.data[tableData.row.index].advertiser)
                                    setViewIsOpen(true)
                                }}
                            >
                                View
                            </Link>
                        </>
                    )
                },
            },
            {
                Header: 'Campaigns',
                accessor: 'campaigns',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Last Inv Period',
                accessor: 'balance_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value !== '' ? value : ''}</>
                },
            },
            {
                Header: 'Request Invoice',
                accessor: '',
                Cell: (tableData) => {
                    return auth_requestInvoice && moment(tableData.data[tableData.row.index].balance_date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') ? (
                        <button className="btn btn-sm btn-link" onClick={() => handleRequestClick(tableData.data[tableData.row.index].advertiser)}>
                            Request Invoice
                        </button>
                    ) : (
                        'Created Today'
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={report}
                    columns={columns}
                    getRowProps={(row) => {

                        let classes = ``

                        if (row.original.balance < -2500 && !(row.original.balance === 0 && row.original.campaigns === 0)) {
                            if (row.original.balance < 0 || (row.original.balance < -1000 && row.original.campaigns > 0 && row.original.invoice_status_letter === 'N')) {
                                classes = 'bgDanger25'
                            }
                        }                        

                        return {
                            className: classes
                        }

                    }}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            {showUpdateBalances && <UpdateBalancesModal showSystemMessage={showSystemMessage} isOpen={updateIsOpen} setIsOpen={setUpdateIsOpen} refetch={fetchData} />}
            <ViewModal isOpen={viewIsOpen} setIsOpen={setViewIsOpen} showSystemMessage={showSystemMessage} advertiser={advertiser} />
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Pre-paid Advertiser Balance Report</h1>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            {showCreateInvoiceModal && <CreateInvoiceModal isOpen={showCreateInvoiceModal} handleClose={handleClose} advertiser={advertiser} showSystemMessage={showSystemMessage} />}
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">Admin</h1>
                                    </div>
                                    {auth_setManager && <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD', 'BIZDEV', 'BIZDEV_DIRECTOR', 'ADVERTISER_DIRECTOR']} />}
                                </div>
                                {showUpdateBalances && (
                                    <button className="btn btn-sm btn-warning" onClick={() => setUpdateIsOpen(true)}>
                                        Update Balances
                                    </button>
                                )}
                            </div>
                            <div>{dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
