import { useState } from 'react'
import callAPI from '../../../../../../helpers/callAPI'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AppApproveModal = ({ affiliateId, affiliateName, showSystemMessage, handleClose, setUpdateList }) => {
    const [submitted, setSubmitted] = useState(false)

    const submitForm = (e) => {
        e.preventDefault()
        setSubmitted(true)

        let putBody = {
            notes: e.target.notes.value,
        }

        callAPI(`affiliate/${affiliateId}/approveApplication`, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Application Approved')
                    if (setUpdateList){setUpdateList(true)}                    
                    handleClose(null, false)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
                setSubmitted(false)
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'An error occurred while approving the application')
                setSubmitted(false)
            })
    }

    return (
        <div className="card">
            <div className="card-header">Application Approval</div>
            <div className="card-body">
                <form onSubmit={submitForm} method="POST">
                    <table className="table table-card table-borderless mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    Affiliate approval for: <span className="font-weight-bold">{affiliateName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    Enter any special notes to send affiliate:
                                    <textarea name="notes" rows="4" cols="40" className="form-control" />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    {submitted ? (
                                        <button disabled className="btn btn-primary">
                                            Approve Affiliate <FontAwesomeIcon icon="spinner" spin />
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn-primary">
                                            Approve Affiliate
                                        </button>
                                    )}
                                    &nbsp; &nbsp;
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    )
}

export default AppApproveModal
