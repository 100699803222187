import React, { useState, useEffect } from 'react'
import useCallApi from '../../hooks/useCallApi'

export default function CampaignCategoriesSelect({ category, setCategory }) {
    let apiUrl = `campaigns/categories`

    const { dataLoaded, data, refetch, setData } = useCallApi(apiUrl, 'GET', {}, [])

    return (
        <>
            <span className="d-flex align-items-center ml-3">
                Category&nbsp;&nbsp;
                <select
                    name="category"
                    className="form-control form-control-sm"
                    value={category.categoryId}
                    onChange={(e) => {
                        setCategory({ categoryId: parseInt(e.target.value), subCategoryId: 0 })
                    }}
                >
                    <option value="0">Any</option>
                    {dataLoaded &&
                        data.categories &&
                        data.categories
                            .filter((category) => category.enabled)
                            .map((category) => {
                                return (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                )
                            })}
                </select>
            </span>
            <span className="d-flex align-items-center ml-3">
                Subcategory&nbsp;&nbsp;&nbsp;
                <select
                    name="subcategory"
                    className="form-control form-control-sm"
                    value={category.subCategoryId}
                    onChange={(e) => {
                        setCategory({ ...category, subCategoryId: parseInt(e.target.value) })
                    }}
                >
                    <option value="0">Any</option>
                    {dataLoaded &&
                        data.sub_categories &&
                        data.sub_categories
                            .filter((category) => category.enabled)
                            .map((subCategory) => {
                                if (subCategory.parent_category_id === category.categoryId) {
                                    return (
                                        <option value={subCategory.id} key={subCategory.id}>
                                            {subCategory.name}
                                        </option>
                                    )
                                }
                                return
                            })}
                </select>
            </span>
        </>
    )
}
