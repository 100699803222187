import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import IpDetailsContent from './IpDetailsContent'

const IpDetails = ({ showSystemMessage }) => {
    const location = useLocation()

    let urlParams = queryString.parse(location.search)
    let ip = urlParams.ip ? urlParams.ip : '0.0.0.0'

    // const { dataLoaded, data } = useCallApi(`affiliate/${affiliateId}/ipDetails?ip=${ipAddress}`, 'GET', {}, [])

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">IP Details</h1>
            </div>
            <div className="row">
                <div className="col-lg-5">
                    <IpDetailsContent showSystemMessage={showSystemMessage} ipAddress={ip} />
                </div>
            </div>
        </div>
    )
}

export default IpDetails
