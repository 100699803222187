import { Link } from 'react-router-dom'

import useCallApi from '../../../hooks/useCallApi'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'

const CampaignList = ({ advertiserId }) => {
    const { data, dataLoaded } = useCallApi(`advertiser/${advertiserId}/campaigns`, 'GET', {}, [advertiserId])

    return (
        <div className="card mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>Campaign List</div>
                <div className="float-right">
                    <Link to={`/advertiser/${advertiserId}/campaigns`} className="text-primary">
                        List All
                    </Link>
                </div>
            </div>
            <div className="card-body p-0 table-container-scroll">
                {dataLoaded ? (
                    data.campaign_list.length === 0 ? (
                        <div className="p-2">No data</div>
                    ) : (
                        <table className="table table-striped table-card m-0 table-hover">
                            <tbody>
                                {data.campaign_list.map((item) => (
                                    <tr key={item.id}>
                                        <td>
                                            <Campaign name={item.name} oid={item.id} status={item.status.value} />
                                        </td>
                                        <td className="text-right">{item.status.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default CampaignList
