import getCreativeType from '../../../helpers/getCreativeType'

const ByCreative = ({ creatives, totalLeadsFromStats }) => {
    return (
        <div>
            <div className="card mb-2">
                <div className="align-items-center p-2 card-header">
                    <div className="font-weight-bold float-left">By Creative</div>
                </div>
                <div className="card-body p-2">
                    <table className="table table-card table-borderless table-striped table-hover">
                        <thead className="border-bottom">
                            <tr>
                                <th scope="col">Creative Type</th>
                                <th scope="col" className="text-right">
                                    Lead %
                                </th>
                                <th scope="col" className="text-right">
                                    EPC
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {creatives.length === 0 ? (
                                <tr>
                                    <td colSpan={3}>No stats available</td>
                                </tr>
                            ) : (
                                creatives.map((item) => (
                                    <tr key={item.creative_id}>
                                        <td>{getCreativeType(item.creative_id, item.creative_type, item.width, item.height)}</td>
                                        <td align="right">{totalLeadsFromStats > 0 ? Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((item.lead_count / totalLeadsFromStats) * 100) : 0}%</td>
                                        <td align="right">${item.epc > 0 ? Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.epc) : 0.0}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ByCreative

{
    /* <CFSET search_creative_id = "918273">
<CFSET contextual_creative_id = "918271">
<CFSET call_creative_id = "918275">
<CFSET raw_creative_id = "918277"> */
}
