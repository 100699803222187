import React, { useState, useEffect } from 'react'
import AdminRolePicker from './AdminRolePicker'
import Role from './Role'
import { Trash2 } from 'react-feather'

export default function EndpointAuthorization({ parentRef, index, showSystemMessage, roles, record, addDeleteRole, changePrivilege, deleteAuth, addNote, key }) {
    const [selectedRoleToAdd, setSelectedRoleToAdd] = useState('')
    const [deleteInit, setDeleteInit] = useState(false)
    const [notes, setNotes] = useState('')

    useEffect(() => {
        // initialize values
        setSelectedRoleToAdd('')
        setDeleteInit(false)
    }, [record])

    return (
        <tr ref={(el) => (parentRef.current[index] = el)} key={key}>
            <td>
                <div className="d-flex align-items-center">
                    <div>{record.resource}</div>
                    {record.roles.length <= 0 && (
                        <div>
                            {deleteInit ? (
                                <button className="btn btn-lnk btn-sm text-danger ml-2" onClick={() => deleteAuth('api', record.id)}>
                                    Confirm
                                </button>
                            ) : (
                                <button className="btn btn-sm ml-2">
                                    <Trash2 size={16} color="red" onClick={() => setDeleteInit(true)} />
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </td>
            <td className="border-left">
                <div>{record.uri}</div>
            </td>
            <td>
                {record.roles.map((role) => (
                    <Role role={role} record={record} addDeleteRole={addDeleteRole} changePrivilege={changePrivilege} mode="api" />
                ))}
                <div className="d-flex">
                    <AdminRolePicker record={record} selectRole={setSelectedRoleToAdd} passedRoles={roles} showSystemMessage={showSystemMessage} />
                    {selectedRoleToAdd !== '' ? (
                        <button className="ml-2 btn btn-sm btn-primary" onClick={() => addDeleteRole('add', 'api', record.id, selectedRoleToAdd)}>
                            Add Role
                        </button>
                    ) : (
                        <button className="ml-2 btn btn-sm btn-primary" disabled>
                            Add Role
                        </button>
                    )}
                </div>
            </td>
            <td style={{ height: '200px' }}>
                <textarea rows="5" cols="30" className="form-control" onChange={(e) => setNotes(e.target.value)}>
                    {record.notes}
                </textarea>
                <button className="btn btn-primary btn-sm mt-2" onClick={(e) => addNote('api', record.resource, notes)}>Save Note</button>
            </td>
        </tr>
    )
}
