import { Card, Table } from 'react-bootstrap'
import Affiliate from '../../../templates/Affiliate/Affiliate'

const IDNumber = ({ data }) => {
    return (
        <Card className="mb-4">
            <Card.Header>
                <div>ID Number (Government ID) <span className='font-weight-normal'> : {data.info.aff_image_signup_id ? data.info.aff_image_signup_id : 'Not available'}</span></div>
            </Card.Header>

            <div className="m-2 font-weight-bold">Other affiliates (up to 10) with this ID number:</div>

            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Affiliate</th>
                        <th>Status</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                {data.success && data.info.matchingId.length > 0 ? (
                    <tbody>
                        {data.info.matchingId.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.affiliate_id}</td>
                                    <td>
                                        <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                    </td>
                                    <td>{item.aff_status}</td>
                                    <td>{item.followup_notes}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td colSpan="4">No data found</td>
                        </tr>
                    </tbody>
                )}
            </Table>
        </Card>
    )
}

export default IDNumber
