import React, { useState, useEffect } from 'react'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import callAPI from '../../../helpers/callAPI'
import { Modal } from 'react-bootstrap'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import MarketingPlatformList from '../Components/MarketingPlatformList/MarketingPlatformList'
import MarketingPlatformModal from '../Components/MarketingPlatformModal/MarketingPlatformModal'

export default function MarketingPlatforms({ showSystemMessage }) {

    document.title = 'Marketing Platforms'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'marketing')

    const [showModal, setShowModal] = useState(false)

    // Get the marketing campaigns using useCallAPI
    const { data, dataLoaded, refetch } = useCallApi('marketing/platforms', 'GET', {}, [])

    const togglePlatformStatus = (platformId, status) => {
        callAPI(`marketing/platform/${platformId}?status=${status}`, 'PUT')
            .then((result) => {
                if (result['success'] === true) {
                    refetch()
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while updating marketing platform: ${error.message}`)
                showSystemMessage('error', 'Error updating marketing platform.')
            })
    }

    return (
        <React.Fragment>
            <Modal
                show={showModal}
                onHide={() => {
                    setShowModal(false)
                }}
            >
                <MarketingPlatformModal setShowModal={setShowModal} refetch={refetch} showSystemMessage={showSystemMessage} />
            </Modal>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Marketing Platforms</h1>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="mb-h1-dark">Active Platforms</h1>
                                <button
                                    className="btn btn-warning btn-sm"
                                    onClick={() => {
                                        setShowModal(true)
                                    }}
                                >
                                    Create Platform
                                </button>
                            </div>
                        </div>
                        <div>{dataLoaded && data.marketing_platforms ? <MarketingPlatformList data={data.marketing_platforms.active} type="active" togglePlatformStatus={togglePlatformStatus} /> : <Loading />}</div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <h1 className="mb-h1-dark">Inactive Platforms</h1>
                        </div>
                        <div>{dataLoaded && data.marketing_platforms ? <MarketingPlatformList data={data.marketing_platforms.inactive} type="inactive" togglePlatformStatus={togglePlatformStatus} /> : <Loading />}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
