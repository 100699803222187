import { Button, Form, Modal } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'

const ResourceModal = ({ isOpen, setIsOpen, isCreate, formData, setFormData, categories, refetch, showSystemMessage }) => {
    const createResource = async () => {
        const response = await callAPI('affiliates/resources', 'POST', formData)
        if (response.success) {
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while creating the resource')
        }
    }
    const updateResource = async () => {
        const response = await callAPI(`affiliates/resources`, 'PUT', formData)
        if (response.success) {
            showSystemMessage('success', 'Resource updated successfully')
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while updating the resource')
        }
    }

    const deleteResource = async () => {
        const response = await callAPI(`affiliates/resources/${formData.id}`, 'DELETE')
        if (response.success) {
            showSystemMessage('success', 'Resource deleted successfully')
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', 'An error occurred while deleting the resource')
        }
    }
    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Create' : 'Edit'} Resource</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isOpen ? (
                    <>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Name:</span>
                            <input type="text" className="form-control" value={formData.name} onChange={(event) => setFormData((prev) => ({ ...prev, name: event.target.value }))} />
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Description:</span>
                            <input type="text" className="form-control" value={formData.description} onChange={(event) => setFormData((prev) => ({ ...prev, description: event.target.value }))} />
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Category:</span>
                            <Form.Control as="select" value={formData.category.order} onChange={(event) => setFormData((prev) => ({ ...prev, category: { order: event.target.value, name: categories[categories.findIndex((category) => category.order === parseInt(event.target.value))].name } }))}>
                                {categories.map((category, index) => (
                                    <option key={category.order} value={category.order}>
                                        {category.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Image:</span>
                            <input type="text" className="form-control" value={formData.image} onChange={(event) => setFormData((prev) => ({ ...prev, image: event.target.value }))} />
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">URL:</span>
                            <input type="text" className="form-control" value={formData.url} onChange={(event) => setFormData((prev) => ({ ...prev, url: event.target.value }))} />
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Promo Code:</span>
                            <input type="text" className="form-control" value={formData.promoCode} onChange={(event) => setFormData((prev) => ({ ...prev, promoCode: event.target.value }))} />
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="w-25">Promo Description:</span>
                            <input type="text" className="form-control" value={formData.promoDescription} onChange={(event) => setFormData((prev) => ({ ...prev, promoDescription: event.target.value }))} />
                        </div>
                    </>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="primary" onClick={createResource}>
                        Create Resource
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={updateResource}>
                            Update Resource
                        </Button>
                        <Button variant="danger" onClick={deleteResource}>
                            Delete Resource
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default ResourceModal
