import { useState } from 'react'
import { Form, Modal, Table, Button } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'

const OSModal = ({ campaignName, tracking, campaignId, showSystemMessage, isOpen, setIsOpen }) => {
    const [data, setData] = useState(tracking)

    const { dataLoaded: osDataLoaded, data: osData } = useCallApi(`osList`, 'GET', {}, [], showSystemMessage)

    const save = async () => {
        //save data to db
        const result = await callAPI(`campaign/${campaignId}/os`, 'PUT', data)
        if (result.success) {
            showSystemMessage('success', 'OS updated successfully')
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }
    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            {osDataLoaded ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Operating systems list for {campaignName}</Modal.Title>
                    </Modal.Header>
                    <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                        <Table striped bordered hover className="mb-0">
                            <tbody>
                                {osData &&
                                    osData.os.map((os, index) => (
                                        <tr key={os}>
                                            <td>{os}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={data.traffic.os_filtering.operating_systems.findIndex((existingOs) => existingOs.device_os === os) > -1}
                                                    onChange={(e) => {
                                                        let osList = data.traffic.os_filtering.operating_systems
                                                        if (e.target.checked) {
                                                            osList.push({ device_os: os })
                                                        } else {
                                                            let arrayLocation = osList.findIndex((existingOs) => existingOs.device_os === os)
                                                            if (arrayLocation > -1) {
                                                                osList.splice(arrayLocation, 1)
                                                            }
                                                        }
                                                        setData({ ...data, traffic: { ...data.traffic, os_filtering: { ...data.traffic.os_filtering, operating_systems: osList } } })
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                    <Modal.Footer>
                        <Button variant="primary" onClick={save}>
                            Save
                        </Button>
                    </Modal.Footer>
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    )
}

export default OSModal
