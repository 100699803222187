import { useState, useEffect } from 'react'

export default function useMaxbountyEntities() {
    const [affiliate, setAffiliate] = useState({
        id: 0,
        name: '',
        status: {
            value: '',
            name: '',
        },
        priority: {
            value: '',
            name: '',
        },
        manager: {
            id: 0,
            name: '',
        },
        online: false,
    })
    const [campaign, setCampaign] = useState({
        id: 0,
        name: '',
        status: {
            value: '',
            name: '',
        },
        manager: {
            id: 0,
            name: '',
        },
    })
    const [advertiser, setAdvertiser] = useState({
        id: 0,
        name: '',
        status: {
            value: '',
            name: '',
        },
        manager: {
            id: 0,
            name: '',
        },
    })

    return { affiliate, setAffiliate, campaign, setCampaign, advertiser, setAdvertiser }
}
