import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import callAPI from '../../../helpers/callAPI'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../hooks/useQueryParams'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import ManagerDropdown from '../../../templates/ManagerDropdown/ManagerDropdown'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import { useAuth, useSetManager } from '../../../context/AuthContext'

const AdvertiserFollowupReport = ({ showSystemMessage }) => {
    const auth = useAuth()
    const showManagerDropdown = useSetManager(auth.permissions, 'advertiser_followup_list')

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const location = useLocation()
    const history = useHistory()
    let urlParams = queryString.parse(location.search)
    const type = urlParams.type ? urlParams.type : 'advertiser'

    useEffect(() => {
        getReport()
    }, [location])

    const refreshReport = (e) => {
        e.preventDefault()
        let url = `/advertisers/followups?adminId=${adminId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`

        history.push(url)
    }

    const handleManagerChange = (mgrId) => {
        setAdminId(mgrId)
    }

    const getReport = () => {
        setDataLoaded(false)

        let url = buildUrl(`advertisers/followups?adminId=${adminId}&startDate=${initStartDate}&endDate=${initEndDate}&t=${type}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    // setManagerId(result.admin_id)
                    setReport(result.report)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load followups list.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching followup list: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching followup list: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Name',
                accessor: 'advertiser.name',
                Cell: (tableData) => {
                    return <Advertiser id={tableData.data[tableData.row.index].advertiser.id} name={tableData.data[tableData.row.index].advertiser.name} status={tableData.data[tableData.row.index].advertiser.status.id} />
                },
            },
            {
                Header: 'Status',
                accessor: 'advertiser.status',
                Cell: ({ cell: { value } }) => {
                    return <>{value.name}</>
                },
            },
            {
                Header: 'Next Follow-up Date',
                accessor: 'next_followup_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm')}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={report}
                    columns={columns}
                    getRowProps={(row) => {
                        if (moment(row.values.next_followup_date).utc().format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                            return {
                                className: 'bgDanger25',
                            }
                        }
                    }}
                />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">{type === 'adops' ? 'AdOps' : 'Advertiser'} Followup List</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark mr-2">Followups for: </h1>
                                {showManagerDropdown && <ManagerDropdown mgrId={adminId} mgrSelected={handleManagerChange} showSystemMessage={showSystemMessage} mgrType={'merchantAndBizdev'} />}
                                <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                    Refresh Report
                                </button>
                            </div>
                        </div>
                    </div>

                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                </div>
            </div>
        </div>
    )
}

export default AdvertiserFollowupReport
