import React, { useState, useEffect } from 'react'
import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'

export default function MarketingCampaignDropdown({ setMarketingCampaignId, ommittedCampaignIdArray = [] }) {
    // Get the marketing campaigns using useCallAPI
    const { data, dataLoaded, refetch } = useCallApi('marketing/campaigns', 'GET', {}, [])

    return dataLoaded ? (
        <select
            className="form-control"
            onChange={(e) => {
                setMarketingCampaignId(!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0)
            }}
        >
            <option value="">Select a marketing campaign</option>
            {data.marketing_campaigns.active.map((campaign) => {
                if (ommittedCampaignIdArray.findIndex((item) => item.id === campaign.id) < 0) {
                    return <option value={campaign.id}>{campaign.name}</option>
                }
            })}
        </select>
    ) : (
        <Loading />
    )
}
