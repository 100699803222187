import React, { useEffect, useState } from 'react'
import callAPI from '../../../../../../helpers/callAPI'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IPCheck = ({ ip, showSystemMessage }) => {
    // check phone against IPQS
    // Fetch accounts with same phone numbers

    const [dataLoaded, setDataLoaded] = useState(false)
    const [ipqsDetails, setIpqsDetails] = useState({})

    useEffect(async () => {

        setDataLoaded(false)

        let url = `ipqs/ip/${ip}`       
        let result = await callAPI(url, 'GET')

        if (result.success === false) {
            showSystemMessage('error', 'There was an error getting proxy data.')
        }
        setIpqsDetails(result.ip_result)

        setDataLoaded(true)

    }, [])

    return (
        <div>
            <span>
                Proxy Score: {(dataLoaded && ipqsDetails) ? ipqsDetails.ipqs_fraud_score : <FontAwesomeIcon icon="spinner" spin />}
            </span>           
        </div>
    )
}

export default IPCheck
