import React, { Component } from 'react'
import ImTypes from '../../data/imtypes'

class ImType extends Component {
    state = {
        types: ImTypes.types,
    }

    render() {
        return (
            <React.Fragment>
                {this.state.types.map((type) => (
                    <option key={type.value} value={type.value}>
                        {type.text}
                    </option>
                ))}
            </React.Fragment>
        )
    }
}

export default ImType
