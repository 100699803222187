import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Loading from '../../../../templates/UI/Loading/Loading'
import DrilldownItem from '../../../../templates/DrilldownItem/DrilldownItem'
import PaginationContainer from '../../../../templates/UI/PaginationContainer/PaginationContainer'
import callAPI from '../../../../helpers/callAPI'

const SurveyResponsesReport = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [property, setProperty] = useState({ propertyId: 0, name: '' })
    const [site, setSite] = useState({ siteId: 0, name: '' })
    const [survey, setSurvey] = useState({ surveyId: 0, name: '' })
    const [report, setReport] = useState([])
    const [affiliate, setAffiliate] = useState({ affiliateId: 0, name: '' })
    const [fraudMode, setFraudMode] = useState(false)

    const { propertyId = 0, siteId = 0, surveyId = 0, affiliateId = 0, subid1 = '', clientId = '', startDate, endDate, showSystemMessage } = props

    useEffect(() => {
        const fetchData = async () => {
            if (surveyId > 0) {
                setDataLoaded(false)

                let fetchUrl = `reports/avatar/surveyResponses?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}&siteId=${siteId}&surveyId=${surveyId}&affiliateId=${affiliateId}&clientId=${clientId}&subid1=${subid1 === 'undefined' ? '' : subid1}`

                let result = await callAPI(fetchUrl, 'GET')

                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setProperty({ propertyId: propertyId, name: result.property })
                    setSite({ siteId: siteId, name: result.site })
                    setSurvey({ surveyId: surveyId, name: result.survey })
                    setAffiliate({ affiliateId: affiliateId, name: result.affiliate })
                    setReport(result.report)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            } else {
                setDataLoaded(true)
            }
        }
        fetchData()
    }, [propertyId, siteId, surveyId, affiliateId, subid1, clientId, startDate, endDate]) // Passing an array as second argument (props), means if the the argument variable has changed, it will re-run this effect.

    const handleFraudMode = () => {
        setFraudMode(!fraudMode)
    }

    const getPaginationFormattedData = () => {
        let formattedReport = report

        if (fraudMode) {
            formattedReport = report.filter((record) => record.fraud !== undefined)
        }

        return formattedReport.map((client) => {
            return (
                <div className="card m-3" style={{ flex: '1 0 47%' }}>
                    <div className="card-body">
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <div>
                                Client ID: {client.clientId} <br></br>
                                <Link to={`post?startDate=${startDate}&endDate=${endDate}&surveyId=${surveyId}&clientId=${client.clientId}`}>View Post Data</Link>
                            </div>
                            {client.fraud && (
                                <h5>
                                    <span className="badge badge-danger">Fraud</span>
                                </h5>
                            )}
                        </div>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Step</th>
                                    <th scope="col">Question</th>
                                    <th scope="col">Response</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {client.responses.map((response) => {
                                    return (
                                        <tr>
                                            <td>{response.step}</td>
                                            <td>
                                                {response.questionText} {response.subQuestion !== '' && `- ${response.subQuestion}`}
                                            </td>
                                            <td>{response.response}</td>
                                            <td>{response.date}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {client.fraud && (
                            <div className="alert alert-danger">
                                <h1 className="mb-h1-dark mb-2">Fraud information:</h1>
                                <table className="table table-striped table-hover table-danger">
                                    <thead>
                                        <tr>
                                            <th scope="col">Platform</th>
                                            <th scope="col">Fraud Type</th>
                                            <th scope="col">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {client.fraud.map((fraud) => {
                                            return (
                                                <tr>
                                                    <td>{fraud.platform}</td>
                                                    <td>{fraud.description}</td>
                                                    <td>{fraud.notes}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            )
        })
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            <div className="d-flex mx-2">
                {property.propertyId > 0 && <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name} cancelDrilldownUrl={`/avatar/reports/survey_responses?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=0&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&subid1=${subid1}&clientId=${clientId}`}></DrilldownItem>}
                {site.siteId > 0 && <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name} cancelDrilldownUrl={`/avatar/reports/survey_responses?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=0&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&subid1=${subid1}&clientId=${clientId}`}></DrilldownItem>}
                {survey.surveyId > 0 && <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name}></DrilldownItem>}
                {affiliate.affiliateId > 0 && <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliate.affiliateId}`} forText={affiliate.name} cancelDrilldownUrl={`/avatar/reports/survey_responses?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=0&clientId=${clientId}`}></DrilldownItem>}
                {subid1 !== '' && subid1 !== 'undefined' && <DrilldownItem for="Subid1" forText={subid1} cancelDrilldownUrl={`/avatar/reports/survey_responses?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&clientId=${clientId}`}></DrilldownItem>}
                {clientId !== '' && <DrilldownItem for="ClientId" forText={clientId} cancelDrilldownUrl={`/avatar/reports/survey_responses?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&subid1=${subid1}`}></DrilldownItem>}
            </div>
            {dataLoaded ? (
                report.length === 0 ? (
                    <div className="p-3">No stats for the current parameters.</div>
                ) : (
                    <div className="p-3">
                        <hr></hr>
                        <div className="d-flex">
                            <div>
                                Total responses: {report.length} ({report.filter((record) => record.fraud !== undefined).length} fraud)
                            </div>
                            <div className="custom-control custom-switch ml-5">
                                <input type="checkbox" className="custom-control-input" id="fraudSwitch" onClick={(e) => handleFraudMode(e)} checked={fraudMode} />
                                <label className="custom-control-label" for="fraudSwitch">
                                    Show Fraud Only
                                </label>
                            </div>
                        </div>
                        <hr></hr>
                        <PaginationContainer data={getPaginationFormattedData()} recordsPerPage={10} parentClasses={'text-small'} flex={true} flexSettings={{ flex: '1 0 47%' }} />
                    </div>
                )
            ) : (
                <Loading></Loading>
            )}
        </div>
    )
}

export default SurveyResponsesReport
