import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import { colors } from '../../../../theme/variables'

import Loading from '../../../../templates/UI/Loading/Loading'
import Affiliate from '../../../../templates/Affiliate/Affiliate'

const YesterdaysCapHits = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [capHits, setCapHits] = useState([])

    const { showSystemMessage, manager } = props

    useEffect(() => {
        fetchData()
    }, [manager])

    async function fetchData() {
        let url = `reports/yesterdaysCapHits/${manager.id}`

        let result = await callAPI(url, 'GET')

        if (result.success === false) {
            showSystemMessage('error', "There was an error while fetching yesterday's cap hits. Please try again.")
        }

        setCapHits(result.yesterdays_cap_hits)
        setDataLoaded(true)
    }

    return (
        <div className="card mb-3">
            <div className="card-header">
                <div className="font-weight-bold float-left">Yesterday's Cap Hits</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped table-card m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Campaign</th>
                            <th>Affiliate</th>
                            <th className="text-right">Cap</th>
                            <th className="text-right">Sent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {capHits.length === 0 ? (
                                    <tr>
                                        <td colSpan={5}>No stats available</td>
                                    </tr>
                                ) : (
                                    capHits.map((item, index) => (
                                        <tr key={`${item.campaign.id}-${index}`}>
                                            <td>{item.campaign.id}</td>
                                            <td>
                                                <Link to={`/campaign/${item.campaign.id}`}>{item.campaign.name}</Link>
                                            </td>
                                            <td>
                                                {item.affiliate.online && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill={colors.success} className="bi bi-circle-fill mr-3" viewBox="0 0 16 16">
                                                        <circle cx="8" cy="8" r="8" />
                                                    </svg>
                                                )}
                                                <Affiliate aid={item.affiliate.id} name={item.affiliate.name} status={item.affiliate.status} priority={item.affiliate.priority} />
                                            </td>
                                            <td className="text-right">{item.cap}</td>
                                            <td className="text-right">{item.sent}</td>
                                        </tr>
                                    ))
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5}>
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default YesterdaysCapHits
