import { Card, Button, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import EmployeePicker from '../../../../components/EmployeePicker/EmployeePicker'
import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'
import AdvertiserNavbar from '../../AdvertiserNavBar/AdvertiserNavBar'
import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import { useState } from 'react'
import TrackingModal from './TrackingModal'
import { useEffect } from 'react'

const AdvertiserTrackingPlatforms = ({ showSystemMessage, advertiserId, platformId }) => {
    document.title = 'Advertiser Tracking Platforms'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_platforms')

    const [isOpen, setIsOpen] = useState(false)
    const [id, setId] = useState(0)
    const [isCreate, setIsCreate] = useState(true)
    const { data, setData, dataLoaded, refetch } = useCallApi(`advertisers/platformsList`, 'GET', {}, [], showSystemMessage)
    useEffect(() => {
        if (dataLoaded && platformId > 0) {
            setIsCreate(false)
            let index = data.platformsList.findIndex((item) => parseInt(item.platform_id) === parseInt(platformId))
            setId(index)
            setIsOpen(true)
        }
    }, [platformId, dataLoaded])

    return (
        <div>
            <TrackingModal
                id={id}
                setIsCreate={setIsCreate}
                showSystemMessage={showSystemMessage}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isCreate={isCreate}
                data={isCreate ? { success: true } : data}
                setData={(value) => {
                    let platformsList = data.platformsList
                    platformsList[id] = { ...platformsList[id], ...value }
                    setData({ ...data, platformsList })
                }}
                dataLoaded={dataLoaded}
                refetch={refetch}
            />
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertiser Tracking Platforms</h1>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <Card className="mb-3 p-0">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <span>
                                <span className="mr-1 d-flex">
                                    <span style={{ width: '-webkit-fill-available' }}>Manage Platforms</span>
                                </span>
                            </span>
                            <Button
                                variant="warning"
                                size="sm"
                                onClick={() => {
                                    setIsCreate(true)
                                    setIsOpen(true)
                                }}
                            >
                                Add New Platform
                            </Button>
                        </Card.Header>
                        <Table striped bordered hover className="m-0">
                            <thead>
                                <tr>
                                    <th>Platform Name</th>
                                    <th>Supports Multiple Postbacks</th>
                                    <th>API Tracking</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataLoaded && data.success ? (
                                    <>
                                        {data.platformsList.length === 0 ? (
                                            <div className="text-center">No Advertiser Platforms Available</div>
                                        ) : (
                                            data.platformsList.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.platform_name}</td>
                                                        <td>{item.supports_multiple_postback === 'Y' ? 'Yes' : 'No'}</td>
                                                        <td>{item.api_tracking === 'Y' ? 'Yes' : 'No'}</td>
                                                        <td>
                                                            <Button
                                                                variant="warning"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setId(index)
                                                                    setIsCreate(false)
                                                                    setIsOpen(true)
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan="6">
                                            <Loading />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default AdvertiserTrackingPlatforms
