import { useState } from 'react'

import callAPI from '../../../../../../helpers/callAPI'

const AppRejectModal = (props) => {
    const [rejectType, setRejectType] = useState('')
    const [rejectNotes, setRejectNotes] = useState('')

    const { handleClose, affiliateName, affiliateId, showSystemMessage } = props

    const submitForm = (event) => {
        event.preventDefault()

        let putBody = {
            rejectType: rejectType,
            rejectNotes: rejectNotes,
        }

        callAPI(`affiliate/${affiliateId}/reject`, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Application rejected')
                    handleClose(null, true)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'An error occurred while rejecting the application')
            })
    }

    const handleReasonChange = (event) => {
        event.preventDefault()
        setRejectType(event.target.value)
    }

    const handleExtraInfo = (event) => {
        event.preventDefault()
        setRejectNotes(event.target.value)
    }

    return (
        <div className="card">
            <div className="card-header">Application Rejection</div>
            <div className="card-body">
                Affiliate Rejection for - {affiliateName}
                <form onSubmit={submitForm} method="POST">
                    <table className="table table-card table-borderless mt-4 mb-0">
                        <tbody>
                            <tr>
                                <td>Reason for rejection: </td>
                                <td>
                                    <select name="reject_type" className="form-control" onChange={handleReasonChange} required>
                                        <option value="">Please select one</option>
                                        <option value="E">Cannot speak English (NO REAPPLY)</option>
                                        <option value="C">Course we don't allow (REAPPLY)</option>
                                        <option value="N">Did not apply (NO REAPPLY)</option>
                                        <option value="A">Fraudulent application (NO REAPPLY)</option>
                                        <option value="I">Invalid incentive (REAPPLY)</option>
                                        <option value="L">Insufficient contact info (REAPPLY)</option>
                                        <option value="W">Networks not allowed (REAPPLY)</option>
                                        <option value="P">No plans to promote (REAPPLY)</option>
                                        <option value="M">Promotional method not allowed (REAPPLY)</option>
                                        <option value="X">Duplicate affiliate (NO REAPPLY)</option>
                                        <option value="F">No ID provided (REAPPLY)</option>
                                        <option value="D">Miscellaneous (REAPPLY)</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Extra Info (optional): </td>
                                <td>
                                    <input type="text" name="reject_notes" className="form-control" maxLength={100} size="50" onChange={handleExtraInfo} autoComplete="off" />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit" className="btn btn-danger">
                                        Reject Affiliate
                                    </button>
                                    &nbsp; &nbsp;
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    )
}

export default AppRejectModal
