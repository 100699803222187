import { useState } from 'react'
import { Card, Table, Button } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import { HelpTextModal } from './HelpTextModal'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

export const HelpText = ({ showSystemMessage }) => {

    document.title = 'Affiliate Help Text'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_help')

    const { data, dataLoaded, refetch } = useCallApi('admin/helpText', 'GET', {}, [], showSystemMessage)
    const [deleteId, setDeleteId] = useState(null)
    const [selectedHelpText, setSelectedHelpText] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isCreate, setIsCreate] = useState(true)

    const deleteHelpText = async (helpTextId) => {
        const result = await callAPI(`admin/helpText`, 'DELETE', { help_id: helpTextId })
        if (result.success) {
            showSystemMessage('success', 'Help Text deleted successfully')
            refetch()
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }
    return (
        <div className="row">
            <div className="col-md-6">
                <Card>
                    <HelpTextModal data={data} refetch={refetch} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} isCreate={isCreate} setIsCreate={setIsCreate} helpText={selectedHelpText} deleteHelpText={deleteHelpText} />
                    <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="mb-h1-dark">Manage Help Text</h1>
                            <Button
                                variant="warning"
                                size="sm"
                                onClick={() => {
                                    setIsCreate(true)
                                    setSelectedHelpText(null)
                                    setIsOpen(true)
                                }}
                            >
                                Add HelpText
                            </Button>
                        </div>
                    </Card.Header>
                    <Table striped bordered hover className="m-0">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {dataLoaded ? (
                                <>
                                    {data.success && data.helpText.length > 0 ? (
                                        data.helpText.map((helpText, index) => (
                                            <tr key={helpText.help_id}>
                                                <td>{helpText.help_id}</td>
                                                <td>{helpText.help_title}</td>
                                                <td>
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        className="mr-2"
                                                        onClick={() => {
                                                            setIsCreate(false)
                                                            setSelectedHelpText(helpText)
                                                            setIsOpen(true)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    {deleteId !== helpText.help_id ? (
                                                        <Button variant="danger" size="sm" onClick={() => setDeleteId(helpText.help_id)}>
                                                            Delete
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => {
                                                                deleteHelpText(helpText.help_id)
                                                                setDeleteId(null)
                                                            }}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No Help Text found</td>
                                        </tr>
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="4">
                                        <Loading />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card>
            </div>
        </div>
    )
}
