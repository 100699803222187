import moment from 'moment'

import Loading from '../../../../templates/UI/Loading/Loading'
import useCallApi from '../../../../hooks/useCallApi'

const CampaignSearches = ({ affiliateId }) => {
    let startDate = moment().startOf('month').format('YYYY-MM-DD')
    let endDate = moment().endOf('month').format('YYYY-MM-DD')
    let { data, dataLoaded } = useCallApi(`affiliate/${affiliateId}/campaignSearches?startDate=${startDate}&endDate=${endDate}`, 'GET', {}, [affiliateId])

    return (
        <div>
            {dataLoaded ? (
                data.recent_searches.length === 0 ? (
                    <div className="p-2">No recent campaign searches available</div>
                ) : (
                    <table className="table table-striped table-card table-hover">
                        <thead>
                            <tr>
                                <th>Search Term</th>
                                <th className="text-center">Frequency</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.recent_searches.slice(0, 4).map((item, index) => (
                                <tr key={`${item.month}-${index}`}>
                                    <td>{item.pattern}</td>
                                    <td align="center">{item.total_count}</td>
                                    <td>{moment(item.view_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            ) : (
                <Loading></Loading>
            )}
        </div>
    )
}

export default CampaignSearches
