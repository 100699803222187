import moment from "moment"
import { useContext, useState } from "react"
import { Modal } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { Calendar } from "react-feather"
import { SystemMessageContext } from "../../../context/SystemMessageContext"
import campaignStatuses from "../../../data/campaignStatuses"
import callAPI from "../../../helpers/callAPI"
import CustomButton from "../../../templates/UI/CustomButton/CustomButton"


const ScheduleRelaunchModal = ({ isOpen, handleClose, campaignId, refetch = ()=>{return} }) => {

    const { showSystemMessage } = useContext(SystemMessageContext)
    const [submitting, setSubmitting] = useState(false)
    const [date, setDate] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()

        const body = {
            status: e.target.status.value,
            date: date
        }

        setSubmitting(true)

        callAPI(`campaign/${campaignId}/scheduleRelaunch`, 'POST', body)
            .then(result => {
                if (result["success"] === true) {
                    showSystemMessage('success', result.message)
                    handleClose()
                    refetch()
                } else if (result["success"] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            }).catch((error) => {
                showSystemMessage('error', error.message)
                setSubmitting(false)
            })
    }

    const handleDateChange = (date) => {
        let s = document.getElementById('date_time').setCustomValidity('')
        setDate(date)
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Schedule Campaign Relaunch
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-borderless">
                        <tbody>
                            <tr>
                                <td>Status:</td>
                                <td>
                                    <select className="form-control w-50" name="status" required>
                                        <option value=""></option>
                                        {campaignStatuses.campaignStatuses.map(status => {
                                            if (status.value === 'A' || status.value === 'B' || status.value === 'H'){
                                                return <option key={status.value} value={status.value}>{status.text}</option>
                                            }                                            
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Relaunch On:</td>
                                <td>
                                    <DatePicker
                                        placeholderText='Select Date & Time'
                                        className='form-control'
                                        selected={date}
                                        minDate={moment().toDate()}
                                        showTimeSelect
                                        dateFormat="yyyy/MM/dd HH:mm:ss"
                                        onChange={(date) => handleDateChange(date)} id="date_time" required autoComplete="off" /><Calendar size={16} color="#007bff" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton name="Schedule" spin={submitting} type="submit" />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ScheduleRelaunchModal