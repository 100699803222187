import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import AffiliateSearch from '../../../../components/AffiliateSearch/AffiliateSearch'
import callAPI from '../../../../helpers/callAPI'
import CustomButton from '../../../../templates/UI/CustomButton/CustomButton'

const AddToWatchlistModal = ({ affiliate, isOpen, handleClose, showSystemMessage }) => {
    let isCreate

    if (affiliate.affiliate_id) {
        isCreate = false
    } else {
        isCreate = true
    }

    const [selectedAffiliate, setSelectedAffiliate] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        let body, result

        if (isCreate) {
            body = {
                affiliate_id: isCreate ? selectedAffiliate.id : affiliate.affiliate_id,
                notes: e.target.notes.value,
            }

            result = await callAPI(`compliance/watchlist`, 'POST', body)
        } else {
            body = {
                notes: e.target.notes.value,
            }

            result = await callAPI(`compliance/${affiliate.affiliate_id}/watchlist`, 'PUT', body)
        }

        if (result.success) {
            setSubmitting(false)
            handleClose(true)
            showSystemMessage('success', result.message)
        } else if (!result.success) {
            showSystemMessage('error', result.errors.message)
            setSubmitting(false)
        } else {
            showSystemMessage('error', `Error while ${isCreate ? 'adding' : 'updating'} affiliate watchlist.`)
            setSubmitting(false)
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault()

        setDeleting(true)

        const result = await callAPI(`compliance/${affiliate.affiliate_id}/watchlist`, 'DELETE', {})

        if (result.success) {
            setDeleting(false)
            handleClose(true)
            showSystemMessage('success', result.message)
        } else if (!result.success) {
            showSystemMessage('error', result.errors.message)
            setDeleting(false)
        } else {
            showSystemMessage('error', `Error while deleting an affiliate from watchlist.`)
            setDeleting(false)
        }
    }

    return (
        <Modal show={isOpen} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Watchlist Affiliate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <table className="table table-borderless m-0">
                        <tbody>
                            <tr>
                                <td width="20%">Affiliate ID:</td>
                                <td>
                                    {isCreate ? (
                                        <div className="w-50">
                                            {' '}
                                            <AffiliateSearch setAffiliate={setSelectedAffiliate} />
                                        </div>
                                    ) : (
                                        affiliate.affiliate_id
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Notes:</td>
                                <td>
                                    <input name="notes" type="text" defaultValue={affiliate.reason_for_monitoring} className="form-control" required />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {!isCreate ? (
                                        <>
                                            <CustomButton name="Delete" type="button" variant="danger" onClick={handleDelete} spin={deleting} />
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomButton name="Update" type="submit" variant="primary" spin={submitting} />
                                        </>
                                    ) : (
                                        <CustomButton name="Add" type="submit" variant="primary" spin={submitting} />
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddToWatchlistModal
