const ByRegion = ({ region, totalLeads }) => {
    return (
        <div>
            <div className="card mb-2">
                <div className="align-items-center p-2 card-header">
                    <div className="font-weight-bold float-left">By Region</div>
                </div>
                <div className="card-body p-2">
                    <table className="table table-card table-borderless table-striped table-hover">
                        <thead className="border-bottom">
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Region</th>
                                <th scope="col" className="text-right">
                                    Lead %
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {region.length === 0 ? (
                                <tr>
                                    <td colSpan={3}>No stats available</td>
                                </tr>
                            ) : (
                                region.map((item, index) => (
                                    <tr key={`${index}-${item.ip_region}`}>
                                        <td>{item.ip_country}</td>
                                        <td>{item.ip_region}</td>
                                        <td align="right">{totalLeads > 0 ? Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((item.lead_count / totalLeads) * 100) : 0}%</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ByRegion
