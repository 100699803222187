import { Button, Card, Modal, Table } from 'react-bootstrap'
import { useState } from 'react'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'
import getCampaignStatus from '../../../../../helpers/getCampaignStatus'
import LPModal from './LPModal'
import callAPI from '../../../../../helpers/callAPI'
import TestLinksModal from './TestLinks'
import ScreenshotsModal from './Screenshots'
import { FaExternalLinkAlt } from 'react-icons/fa'
import HostAndPost from './HostAndPost'

const LandingPages = ({ showSystemMessage, campaign, campaignId }) => {
    const { dataLoaded, data, setData, refetch } = useCallApi(`campaign/${campaignId}/landingPages`, 'GET', {}, [campaignId])
    const [campaignData, setCampaignData] = useState(campaign)
    const [tLIsOpen, setTLIsOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [ssIsOpen, setSSIsOpen] = useState(false)
    const [hpIsOpen, setHPIsOpen] = useState(false)
    const [isCreate, setIsCreate] = useState(true)
    const [selectedLP, setSelectedLP] = useState(false)
    const [deleteId, setDeleteId] = useState(-1)

    const deleteLandingPage = async (id) => {
        const response = await callAPI(`campaign/${campaignId}/landingPages/${id}`, 'DELETE', {}, 'DELETE')
        if (response.success) {
            showSystemMessage('success', 'Landing Page Deleted')
            refetch()
        } else {
            showSystemMessage('danger', response.errors.message)
        }
    }

    return (
        <Card className="mb-4">
            <ScreenshotsModal isOpen={ssIsOpen} setIsOpen={setSSIsOpen} showSystemMessage={showSystemMessage} refetch={refetch} campaignId={campaignId} landingPages={data.landingPages} campaignData={campaignData} selectedLP={selectedLP} dataLoaded={dataLoaded} />
            <TestLinksModal isOpen={tLIsOpen} setIsOpen={setTLIsOpen} campaignId={campaignId} landingPages={data.landingPages} campaignData={campaignData} dataLoaded={dataLoaded} />

            {isOpen && <LPModal setIsOpen={setIsOpen} isOpen={isOpen} isCreate={isCreate} showSystemMessage={showSystemMessage} refetch={refetch} campaignId={campaignId} selectedLP={selectedLP} landingPages={data.landingPages} />}

            <Modal
                size="xl"
                show={hpIsOpen}
                onHide={() => {
                    setHPIsOpen(false)
                }}
            >
                <HostAndPost isOpen={hpIsOpen} setIsOpen={setHPIsOpen} showSystemMessage={showSystemMessage} refetch={refetch} campaignId={campaignId} landingPages={data.landingPages} campaignData={campaignData} dataLoaded={dataLoaded} />
            </Modal>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <div>Landing Pages</div>
                <div>
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            setSelectedLP({
                                lp_record_id: 0,
                                lp_name: '',
                                lp_landing_url: '',
                                lp_default_flag: 'N',
                                lp_status: '',
                            })
                            setIsCreate(true)
                            setIsOpen(true)
                        }}
                    >
                        Add Landing Page
                    </Button>
                    <Button
                        variant="warning"
                        size="sm"
                        className="ml-2"
                        onClick={() => {
                            setTLIsOpen(true)
                        }}
                    >
                        Generate Test Links
                    </Button>
                    {campaignData.type.value === 'H' && (
                        <Button
                            variant="warning"
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                                setHPIsOpen(true)
                            }}
                        >
                            POST Settings
                        </Button>
                    )}
                </div>
            </Card.Header>
            <Table striped bordered>
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Name</td>
                        <td>Status</td>
                        <td>Default</td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                {dataLoaded ? (
                    <tbody>
                        {data.landingPages.map((page, index) => (
                            <tr key={index}>
                                <td>{page.lp_record_id}</td>
                                <td>{page.lp_name}</td>
                                <td>{getCampaignStatus(page.lp_status)}</td>
                                <td>{page.lp_default_flag === 'Y' ? 'Yes' : 'No'}</td>
                                <td width="20%">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a target="_blank" rel="noopener noreferrer" className="mr-2" href={page.lp_landing_url}>
                                            <FaExternalLinkAlt /> View LP
                                        </a>
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    setSelectedLP({
                                                        lp_record_id: page.lp_record_id,
                                                        lp_name: page.lp_name,
                                                        lp_landing_url: page.lp_landing_url,
                                                        lp_default_flag: page.lp_default_flag,
                                                        lp_status: page.lp_status,
                                                    })
                                                    setIsCreate(false)
                                                    setIsOpen(true)
                                                }}
                                                variant="warning"
                                                size="sm"
                                            >
                                                Edit
                                            </Button>
                                            {page.lp_default_flag === 'N' && (
                                                <>
                                                    {deleteId !== page.lp_record_id ? (
                                                        <Button variant="outline-danger" size="sm" className="ml-2" onClick={() => setDeleteId(page.lp_record_id)}>
                                                            Delete
                                                        </Button>
                                                    ) : (
                                                        <Button variant="danger" size="sm" className="ml-2" onClick={() => deleteLandingPage(page.lp_record_id)}>
                                                            Confirm
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </td>
                                <td width="15%">
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        onClick={() => {
                                            setSelectedLP({
                                                lp_record_id: page.lp_record_id,
                                                lp_name: page.lp_name,
                                                lp_landing_url: page.lp_landing_url,
                                                lp_default_flag: page.lp_default_flag,
                                                lp_status: page.lp_status,
                                            })
                                            setSSIsOpen(true)
                                        }}
                                    >
                                        Screenshots
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td colSpan={6}>
                                <Loading />
                            </td>
                        </tr>
                    </tbody>
                )}
            </Table>
        </Card>
    )
}

export default LandingPages

