import React, { Component } from 'react'
import moment from 'moment'
import YearSelect from './YearSelect'
import MakeSelect from './MakeSelect'
import ModelSelect from './ModelSelect'
import TrimSelect from './TrimSelect'

class ShortFormVehicle extends Component {
    state = {
        year: moment().format('YYYY'),
        make: '',
        model: '',
        trim: '',
    }

    handleChangeYear = (e) => {
        const year = e.target.value

        this.setState({
            ...this.state,
            year: year,
        })
    }

    handleChangeMake = (e) => {
        const make = e.target.value

        this.setState({
            ...this.state,
            make: make,
        })
    }

    handleChangeModel = (e) => {
        const model = e.target.value

        this.setState({
            ...this.state,
            model: model,
        })
    }

    handleChangeTrim = (e) => {
        const trim = e.target.value

        this.setState({
            ...this.state,
            trim: trim,
        })
    }

    render() {
        let { order } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Short Form - Vehicle</div>
                <h3 className="text-xlarge">Vehicle Information</h3>
                <div className="mt-3">
                    <h3 className="text-regular">Year</h3>
                    <div className="mt-3">
                        <YearSelect handleChangeYear={this.handleChangeYear} />
                    </div>
                </div>
                <div className="mt-3">
                    <h3 className="text-regular">Make</h3>
                    <div className="mt-3">
                        <MakeSelect key={this.state.year} handleChangeMake={this.handleChangeMake} year={this.state.year} />
                    </div>
                </div>
                <div className="mt-3">
                    <h3 className="text-regular">Model</h3>
                    <div className="mt-3">
                        <ModelSelect key={`${this.state.year}-${this.state.make}`} handleChangeModel={this.handleChangeModel} year={this.state.year} make={this.state.make} />
                    </div>
                </div>
                <div className="mt-3">
                    <h3 className="text-regular">Trim</h3>
                    <div className="mt-3">
                        <TrimSelect key={`${this.state.year}-${this.state.make}-${this.state.model}`} handleChangeTrim={this.handleChangeTrim} year={this.state.year} make={this.state.make} model={this.state.model} />
                    </div>
                </div>
            </div>
        )
    }
}

export default ShortFormVehicle
