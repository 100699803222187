import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import Loading from '../../../../templates/UI/Loading/Loading'
import { colors } from '../../../../theme/variables'

const TopAffiliates = ({ manager }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)
    const [selectedStatPeriod, setSelectedStatPeriod] = useState('Monthly')

    let statLabelClasses = 'btn btn-secondary'

    let startDate = moment().add(-30, 'days').format('YYYY-MM-DD')
    let endDate = moment().endOf('month').format('YYYY-MM-DD')

    if (selectedStatPeriod === 'Weekly') {
        startDate = moment().add(-7, 'days').format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
    }

    let url = `reports/affiliates?startDate=${startDate}&endDate=${endDate}&withAvatar=true`

    if (manager) {
        url += `&adminId=${manager.id}`
    }

    useEffect(() => {
        fetchCampaigns()
    }, [selectedStatPeriod, manager])

    const fetchCampaigns = async () => {
        setDataLoaded(false)

        const data = await callAPI(url, 'GET')
        if (data.success) {
            const result = data.report.map((item, index) => {
                return {
                    affiliate: item.affiliate,
                    earnings: item.affiliate_earnings,
                    margin: item.margin,
                    active: item.affiliate.online === 'N' ? false : true,
                }
            })

            setData(result)
        }
        setDataLoaded(true)
    }

    const handleStatTimeChange = (event) => {
        event.preventDefault()
        setSelectedStatPeriod(event.target.value)
        setDataLoaded(false)
    }
    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>Top Affiliates </span>
                <Link to={`/affiliates/top?adminId=${manager ? manager.id : 0}`}>List All</Link>
            </Card.Header>
            <div className="d-flex justify-content-center">
                <div className="m-2 btn-group btn-group-sm btn-group-toggle w-75" data-toggle="buttons" role="group">
                    <label className={selectedStatPeriod === 'Monthly' ? statLabelClasses + ' active' : statLabelClasses}>
                        <input type="radio" name="statOptions" id="option1" className="change-graph-option" autoComplete="off" value="Monthly" onClick={handleStatTimeChange} /> Last 30 days
                    </label>
                    <label className={selectedStatPeriod === 'Weekly' ? statLabelClasses + ' active' : statLabelClasses}>
                        <input type="radio" name="statOptions" id="option2" className="change-graph-option" autoComplete="off" value="Weekly" onClick={handleStatTimeChange} /> Last 7 days
                    </label>
                </div>
            </div>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th className="text-right">Earnings</th>
                            <th className="text-right">Margin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.length === 0 ? (
                                    <tr>
                                        <td colSpan={4}>No data.</td>
                                    </tr>
                                ) : (
                                    <>
                                        {data.slice(0, 15).map((record) => (
                                            <tr key={record.affiliate.id}>
                                                <td xs={3}>{record.affiliate.id}</td>
                                                <td xs={6}>
                                                    {record.affiliate.active ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill={colors.success} class="bi bi-circle-fill mr-3" viewBox="0 0 16 16">
                                                            <circle cx="8" cy="8" r="8" />
                                                        </svg>
                                                    ) : null}
                                                    <Affiliate aid={`${record.affiliate.id}`} name={`${record.affiliate.name}`} status={`${record.affiliate.status.value}`} priority={`${record.affiliate.priority.value}`} />
                                                </td>
                                                <td className="text-right" xs={6}>                                                    
                                                    <Dollars value={record.earnings} />
                                                </td>
                                                <td className="text-right" xs={3}>
                                                    <Link to={`/reports/stats/campaigns?affiliateId=${record.affiliate.id}&startDate=${selectedStatPeriod === 'Monthly' ? moment().add(-30, 'days').format('YYYY-MM-DD') : moment().add(-7, 'days').format('YYYY-MM-DD')}&withAvatar=true`} className="text-primary font-weight-bold">
                                                        <Dollars value={record.margin} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default TopAffiliates
