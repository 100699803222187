import { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import callAPI from '../../helpers/callAPI'
import { SystemMessageContext } from '../../context/SystemMessageContext'

export default function AdvertiserAdditionalContactModal({ advertiserId, modalData, setModalData, refetch }){
    
    const { showSystemMessage } = useContext(SystemMessageContext)

    const createContact = async () => {
        const result = await callAPI(`advertiser/${advertiserId}/additionalContact`, 'POST', modalData)
        if (result.success) {
            setModalData({open:false, name: '', e_mail: '', notes: ''})
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const updateContact = async () => {
        const result = await callAPI(`advertiser/${advertiserId}/additionalContact`, 'PUT', modalData)
        if (result.success) {
            setModalData({open:false, name: '', e_mail: '', notes: ''})
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const deleteContact = async () => {
        const result = await callAPI(`advertiser/${advertiserId}/additionalContact`, 'DELETE', modalData)
        if (result.success) {
            setModalData({open:false, name: '', e_mail: '', notes: ''})
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={modalData.open} onHide={() => setModalData({open:false, name: '', e_mail: '', notes: ''})}>
            <Modal.Header closeButton>
                <Modal.Title>Additional Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center mb-1">
                    <span className="w-25">Name:</span>                   
                    <input type="text" className="form-control" defaultValue={modalData.name} onChange={(event) => {setModalData({...modalData, name: event.target.value})}} />
                </div>
                <div className="d-flex align-items-center mb-1">
                    <span className="w-25">Email:</span>                   
                    <input type="email" className="form-control" defaultValue={modalData.e_mail} onChange={(event) => {setModalData({...modalData, e_mail: event.target.value})}} />
                </div>
                <div className="d-flex align-items-center mb-1">
                    <span className="w-25">Notes:</span>                   
                    <textarea className="form-control" defaultValue={modalData.notes} onChange={(event) => {setModalData({...modalData, notes: event.target.value})}}></textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {!modalData.id ? (
                    <Button variant="primary" onClick={()=>createContact()}>
                        Create Contact
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" onClick={()=>updateContact()}>
                            Update Contact
                        </Button>
                        <Button variant="danger" onClick={()=>deleteContact()}>
                            Delete Contact
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}