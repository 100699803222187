import moment from 'moment'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import MBDomainsSelect from '../../../../../components/MBDomainsSelect/MBDomainsSelect'
import useCallApi from '../../../../../hooks/useCallApi'
import CountryModal from './CountryModal'
import OSModal from './OSModal'

export default function TrackingSettings({ campaignId, campaignName, campaign, refetch, showSystemMessage, data, setData, setIsDisabled }) {
    const [cLIsOpen, cLSetIsOpen] = useState(false)
    const [osIsOpen, osSetIsOpen] = useState(false)
    const [reload, setReload] = useState(false)

    const { dataLoaded: osDataLoaded, data: osData } = useCallApi(`osList`, 'GET', {}, [], showSystemMessage)

    const setDomain = (domain) => {
        setIsDisabled(false)
        setData({ ...data, tracking: { ...data.tracking, link_domain: domain } })
    }

    return (
        <div className="card mb-2">
            {cLIsOpen && <CountryModal campaignName={campaignName} tracking={data.tracking} campaignId={campaignId} showSystemMessage={showSystemMessage} isOpen={cLIsOpen} setIsOpen={cLSetIsOpen} refetch={refetch} />}
            <OSModal campaignName={campaignName} tracking={data.tracking} campaignId={campaignId} showSystemMessage={showSystemMessage} isOpen={osIsOpen} setIsOpen={osSetIsOpen} />

            <div className="card-header d-flex justify-content-between align-items-center">
                <div>Tracking</div>
            </div>
            <table className="table table-striped table-bordered">
                <tbody>
                    <tr>
                        <td align="right">
                            <span>Link Domain</span>
                        </td>
                        <td>
                            {/* Only pixel and iframe needs a selection for link domain, otherwise it's afflat3 */}
                            {data.tracking.type.value === 'L' || data.tracking.type.value === 'I' ?
                                <MBDomainsSelect domain={data.tracking.link_domain} setDomain={setDomain} />
                                :
                                <div>afflat domain</div>
                            }
                        </td>
                        <td width="25%" align="right">
                            <span>Tracking Type</span>
                        </td>
                        <td width="25%">
                            <select
                                className="form-control form-control-sm"
                                value={data.tracking.type.value}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setReload(true)
                                    setData({ ...data, tracking: { ...data.tracking, type: { ...data.tracking.type, value: e.target.value } } })
                                }}
                            >
                                <option value=""></option>
                                <option value="G">Global</option>
                                <option value="I">I-Frame</option>
                                <option value="L">Pixel</option>
                                <option value="S">Server</option>
                                <option value="M">MaxBounty Tag</option>
                                {campaign.advertiser.tracking.api_enabled && <option value="A">API</option>}
                                {campaign.type.value === 'H' && <option value="H">Instant</option>}
                            </select>

                            {data.tracking.type.value === 'A' && (
                                <div>
                                    API Campaign ID:{' '}
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="API Campaign ID"
                                        defaultValue={data.tracking.api.api_campaign_id > 0 ? data.tracking.api.api_campaign_id : ''}
                                        onChange={(e) => {
                                            setIsDisabled(false)
                                            setData({ ...data, tracking: { ...data.tracking, api: { ...data.tracking.api, api_campaign_id: e.target.value } } })
                                        }}
                                    />
                                    API Start Date:{' '}
                                    <DatePicker
                                        placeholderText="API Start Date"
                                        className="form-control form-control-sm"
                                        selected={data.tracking.api.api_start_date ? moment(moment(data.tracking.api.api_start_date).utc().format('YYYY-MM-DD')).toDate() : ''}
                                        dateFormat="yyyy/MM/dd"
                                        onChange={(date) => {
                                            setIsDisabled(false)
                                            setData({ ...data, tracking: { ...data.tracking, api: { ...data.tracking.api, api_start_date: date } } })
                                        }}
                                    />
                                </div>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td width="25%" align="right">
                            Mobile Campaign
                        </td>
                        <td width="25%">
                            <input
                                type="checkbox"
                                checked={data.tracking.is_mobile_offer}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setReload(true)
                                    setData({ ...data, tracking: { ...data.tracking, is_mobile_offer: e.target.checked } })
                                }}
                            />
                        </td>
                        {/* <td align="right">
                            <span>Callrouted Campaign</span>
                        </td>
                        <td>
                            <div className="d-flex justify-content-between align-items-center">
                                <input
                                    type="checkbox"
                                    checked={data.tracking.callrouted_campaign}
                                    onChange={(e) => {
                                        setIsDisabled(false)
                                        setData({ ...data, tracking: { ...data.tracking, callrouted_campaign: e.target.checked } })
                                    }}
                                />
                            </div>
                        </td> */}
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Default Daily Affiliate Cap</span>
                        </td>
                        <td colSpan={1}>
                            <input
                                type="number"
                                className="form-control form-control-sm"
                                value={data.tracking.cap.daily}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, cap: { ...data.tracking.cap, daily: (e.target.value) } } })
                                }}
                                required
                            />
                            -1 = unlimited cap
                        </td>
                        <td align="right">Desktop Traffic Allowed</td>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.tracking.traffic.traffic_types.desktop_flag}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, traffic_types: { ...data.tracking.traffic.traffic_types, desktop_flag: e.target.checked } } } })
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Country Filtering Enabled</span>
                        </td>
                        <td>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="w-50 d-flex">
                                    <input
                                        type="checkbox"
                                        checked={data.tracking.traffic.country_filtering.enabled}
                                        onChange={(e) => {
                                            setIsDisabled(false)
                                            setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, country_filtering: { ...data.tracking.traffic.country_filtering, enabled: e.target.checked } } } })
                                        }}
                                    />
                                    <Button variant="primary" size="sm" className="ml-2" onClick={() => cLSetIsOpen(true)}>
                                        Country List
                                    </Button>
                                </span>
                            </div>
                        </td>
                        <td align="right">
                            <span>OS Filtering Enabled</span>
                        </td>
                        <td>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="w-50 d-flex">
                                    <input
                                        type="checkbox"
                                        checked={data.tracking.traffic.os_filtering.enabled}
                                        onChange={(e) => {
                                            setIsDisabled(false)
                                            setData({ ...data, tracking: { ...data.tracking, traffic: { ...data.tracking.traffic, os_filtering: { ...data.tracking.traffic.os_filtering, enabled: e.target.checked } } } })
                                        }}
                                    />

                                    <Button variant="primary" size="sm" className="ml-2" onClick={() => osSetIsOpen(true)}>
                                        OS List
                                    </Button>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td align="right">
                            <span>Payment Delay (Days)</span>
                        </td>
                        <td>
                            <div className="d-flex justify-content-between align-items-center">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={data.tracking.payment_delay.delay}
                                    onChange={(e) => {
                                        setIsDisabled(false)
                                        if (!isNaN(e.target.value)) {
                                            setData({ ...data, tracking: { ...data.tracking, payment_delay: { ...data.tracking.payment_delay, delay: e.target.value } } })
                                        }
                                    }}
                                />
                            </div>
                        </td>
                        <td align="right">Payment Delay Mode</td>
                        <td>
                            <select
                                className="form-control form-control-sm"
                                value={data.tracking.payment_delay.mode}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, payment_delay: { ...data.tracking.payment_delay, mode: e.target.value } } })
                                }}
                            >
                                <option value="END_OF_MONTH">From end of month</option>
                                <option value="LEAD DATE">From lead data</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
