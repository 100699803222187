import React, { useEffect, useState } from 'react'

import Flags from '../../../components/Flags/Flags'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import Loading from '../../../templates/UI/Loading/Loading'
import CampaignNavbar from '../CampaignNavBar/CampaignNavBar'
import AvatarSettings from './Edit/AvatarSettings/AvatarSettings'
import CampaignApprovalInformation from './Edit/CampaignApprovalInformation/CampaignApprovalInformation'
import GeneralInfo from './Edit/GeneralInfo/GeneralInfo'
import LandingPages from './Edit/LandingPages/LandingPages'
import Notes from './Edit/Notes/Notes'
import Rates from './Edit/Rates/Rates'
import RedirectionInfo from './Edit/RedirectionInfo/RedirectionInfo'
import Restrictions from './Edit/Restrictions/Restrictions'
import TrackingSettings from './Edit/TrackingSettings/TrackingSettings'
import TrafficSettings from './Edit/TrafficSettings/TrafficSettings'
import MobileAppEvents from './Edit/MobileAppEvents/MobileAppEvents'
import MobileKPI from './Edit/MobileKPI/MobileKPI'
import callAPI from '../../../helpers/callAPI'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'
import { Link } from 'react-router-dom/cjs/react-router-dom'

export default function CampaignSettings({ campaignId, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'campaign_settings')

    const [manager, setManager] = useState(auth.user)
    const [isOpen, setIsOpen] = useState(false)
    const [campaign, setCampaign] = useState()
    const [data, setData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        if (campaignId) {
            fetchData()
        }
    }, [campaignId])

    const fetchData = () => {
        setDataLoaded(false)

        callAPI(`campaign/${campaignId}`, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setCampaign(result.campaign)
                    setData(result.campaign) // this is to mutate
                    document.title = `${result.campaign.name}: Settings`
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    document.title = 'Campaign Settings'
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching campaign details: ${error}`)
                document.title = 'Campaign'
                showSystemMessage('error', 'An error occurred while fetching campaign details')
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        const generalInfoUpdateResult = await callAPI(`campaign/${campaignId}/generalInfo`, 'PUT', data)

        let approvalInfoUpdateResult, avatarInfoUpdateResult

        if (data.status.value === 'B') {
            approvalInfoUpdateResult = await callAPI(`campaign/${campaignId}/campaignApproval`, 'PUT', data.campaign_approval)

            setSubmitting(false)

            if (approvalInfoUpdateResult.success === false) {
                showSystemMessage('error', 'Error while updating approval information')
                return
            }
        }

        if (data.type.value === 'A') {
            avatarInfoUpdateResult = await callAPI(`campaign/${campaignId}/avatarSettings`, 'PUT', data.tracking.avatar)

            setSubmitting(false)

            if (avatarInfoUpdateResult.success === false) {
                showSystemMessage('error', avatarInfoUpdateResult.errors.message)
                setSubmitting(false)
                return
            }
        }

        const redirectsUpdateResult = await callAPI(`campaign/${campaignId}/redirectInfo`, 'PUT', data.redirects)

        const notesUpdateResult = await callAPI(`campaign/${campaignId}/notes`, 'PUT', data.notes)

        const trackingUpdateResult = await callAPI(`campaign/${campaignId}/tracking`, 'PUT', data.tracking)

        const trafficUpdateResult = await callAPI(`campaign/${campaignId}/trafficTypes`, 'PUT', data.tracking.traffic.traffic_types)

        const restrictionsUpdateResult = await callAPI(`campaign/${campaignId}/restrictions`, 'PUT', data.restrictions)

        setSubmitting(false)

        if (!generalInfoUpdateResult.success) {
            showSystemMessage('error', `Error while updating general information - ${generalInfoUpdateResult.errors.message}`)
            return
        }

        if (!redirectsUpdateResult['success']) {
            showSystemMessage('error', `Error while updating redirects information - ${redirectsUpdateResult.errors.message}`)
            return
        }

        if (trackingUpdateResult.success === false) {
            showSystemMessage('error', `Error while updating tracking information - ${trackingUpdateResult.errors.message}`)
            return
        }

        if (notesUpdateResult.success === false) {
            showSystemMessage('error', `Error while updating notes - ${notesUpdateResult.errors.message}`)
            return
        }

        if (trafficUpdateResult.success === false) {
            showSystemMessage('error', `Error while updating traffic information - ${trafficUpdateResult.errors.message}`)
            return
        }

        if (restrictionsUpdateResult.success === false) {
            showSystemMessage('error', `Error while updating restrictions - ${restrictionsUpdateResult.errors.message}`)
            return
        }

        showSystemMessage('success', 'Campaign settings saved.')
        setIsDisabled(true)
        fetchData()
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">{dataLoaded && <Link to={`/campaign/${campaignId}`}> {campaign.name} </Link>}</h1>
                <div className="ml-5">{dataLoaded && <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} isMobile={data.tracking.is_mobile_offer} refetchParent={fetchData} />}</div>
            </div>
            {dataLoaded ? (
                <React.Fragment>
                    <form>
                        <div className="card mb-2 text-right">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <p className="mb-h1-dark">Campaign Settings</p>
                                <CustomButton name="Save" variant="success" type="button" disabled={isDisabled} spin={submitting} onClick={handleSubmit} />
                            </div>
                        </div>
                        <div className="Affiliate row">
                            <div className="col-xl-6 mb-2">
                                <GeneralInfo campaignId={campaignId} data={data} showSystemMessage={showSystemMessage} setData={setData} setIsDisabled={setIsDisabled} />
                                {data.type.value === 'A' && <AvatarSettings campaignId={campaignId} data={data} setData={setData} showSystemMessage={showSystemMessage} setIsDisabled={setIsDisabled} />}
                                {data.status.value === 'B' && <CampaignApprovalInformation data={data} showSystemMessage={showSystemMessage} setData={setData} setIsDisabled={setIsDisabled} />}
                                <RedirectionInfo campaignId={campaignId} data={data} showSystemMessage={showSystemMessage} setData={setData} setIsDisabled={setIsDisabled} />
                                <Notes campaignId={campaignId} data={data} setData={setData} showSystemMessage={showSystemMessage} setIsDisabled={setIsDisabled} />
                                <Flags campaignId={campaignId} showSystemMessage={showSystemMessage} setIsDisabled={setIsDisabled} />
                            </div>
                            <div className="col-xl-6 pb-0">
                                <TrackingSettings campaignId={campaignId} campaignName={data.name} data={data} campaign={data} showSystemMessage={showSystemMessage} setData={setData} setIsDisabled={setIsDisabled} refetch={fetchData} />
                                {data.tracking.is_mobile_offer && <MobileAppEvents campaignId={campaignId} campaignName={data.name} data={data} refetch={fetchData} showSystemMessage={showSystemMessage} />}
                                {data.tracking.is_mobile_offer && <MobileKPI campaignId={campaignId} campaignName={data.name} data={data} refetch={fetchData} showSystemMessage={showSystemMessage} />}
                                <TrafficSettings campaignId={campaignId} data={data} setData={setData} showSystemMessage={showSystemMessage} setIsDisabled={setIsDisabled} />
                                <LandingPages campaignId={campaignId} campaign={data} showSystemMessage={showSystemMessage} setIsDisabled={setIsDisabled} />
                                <Rates campaignId={campaignId} campaign={data} showSystemMessage={showSystemMessage} rates={data.rates} isMobileOffer={data.tracking.is_mobile_offer} setIsDisabled={setIsDisabled} campaignData={data} refetch={fetchData} />
                                <Restrictions campaignId={campaignId} data={data} trafficTypes={data.tracking.traffic.traffic_types} showSystemMessage={showSystemMessage} setData={setData} setIsDisabled={setIsDisabled} />
                                <div className="card mb-2">
                                    <div className="card-header text-right">
                                        <CustomButton name="Save" variant="success" type="button" disabled={isDisabled} spin={submitting} onClick={handleSubmit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
