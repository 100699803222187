import React, { useState } from 'react'

import { ProgressBar } from 'react-bootstrap'
import useCallApi from '../../../../hooks/useCallApi'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'

import { MoreVertical } from 'react-feather'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import Number from '../../../../templates/Number/Number'
import CampaignCapWatchModal from '../../../../components/CampaignCapWatchModal/CampaignCapWatchModal'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import { useAuth, useEditableContent } from '../../../../context/AuthContext'

const AdvertiserCapWatch = ({ manager, advertiserId, showSystemMessage }) => {
    let apiUrl = `advertiser/${advertiserId}/capWatch`

    const auth = useAuth()
    const showEditCapWatchMaster = useEditableContent(auth.permissions, 'add_cap_watch')

    const { dataLoaded, data, refetch } = useCallApi(apiUrl, 'GET', {}, [advertiserId], showSystemMessage)
    const [modal, setModal] = useState({ open: false, id: 0, campaign: false, type: false, cap: false, watch_type: false })

    const [dropDown, setDropdown] = useState(0)

    const handleCloseModal = () => {
        setModal({ open: false, id: 0, campaign: false, type: false, cap: false, watch_type: false })
    }

    const displayCapWatch = (item, index) => {
        let progressbarVariant = ''
        let leadsOverCap = ((item.cap_count / item.cap) * 100).toFixed(2)

        if (leadsOverCap >= 80) {
            progressbarVariant = 'red-highlight'
        } else if (leadsOverCap >= 50) {
            progressbarVariant = 'yellow-highlight'
        } else {
            progressbarVariant = 'green-highlight'
        }

        return (
            <tr key={`${item.id}-${index}`}>
                <td width={'45%'}>
                    <Campaign oid={item.campaign.id} name={item.campaign.name} />
                </td>
                <td>{item.affiliate.id > 0 ? <Affiliate id={item.affiliate.id} name={item.affiliate.name} status={item.affiliate.status.value} priority={item.affiliate.priority.value} /> : ''}</td>
                <td width={'45%'}>
                    <ProgressBar variant={progressbarVariant} now={leadsOverCap} className="mt-1 mb-1" />
                </td>
                <td>
                    <span className="font-weight-bold">{item.watch_type === 'LEAD' ? <Number value={item.cap_count} /> : <Dollars value={item.cap_count} />}</span>/{item.watch_type === 'LEAD' ? <Number value={item.cap} /> : <Dollars value={item.cap} />}
                </td>
                <td width={'10%'}>{item.type.name}</td>
                <td width={'1%'}>
                    {(item.admin.id === manager.id || showEditCapWatchMaster) && (
                        <Dropdown
                            isOpen={dropDown === item.id}
                            toggle={() => {
                                setDropdown(!dropDown ? item.id : false)
                            }}
                            direction="down"
                        >
                            <DropdownToggle tag="a" onClick={() => setDropdown(item.id)} data-toggle="dropdown" aria-expanded={dropDown === item.id} className="report-more-toggle">
                                <MoreVertical size={20} />
                            </DropdownToggle>
                            <DropdownMenu>
                                <button
                                    className="dropdown-item btn btn-link"
                                    onClick={() => {
                                        setModal({
                                            id: item.id,
                                            open: true,
                                            campaign: item.campaign,
                                            type: item.type,
                                            cap: item.cap,
                                            watch_type: item.watch_type
                                        })
                                    }}
                                >
                                    Edit
                                </button>
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </td>
            </tr>
        )
    }

    return (
        <div className="card mb-3">
            <div className="card-header d-flex align-items-center justify-content-between">
                <div className="font-weight-bold">Campaign Watch</div>
            </div>
            <div className="card-body p-0 table-container-scroll">
                <CampaignCapWatchModal key={modal.id} manager={manager} id={modal.id} open={modal.open} campaign={modal.campaign} type={modal.type} cap={modal.cap} close={handleCloseModal} refetch={refetch} showSystemMessage={showSystemMessage} watch_type={modal.watch_type} />
                {dataLoaded ? (
                    data && data.campaigns && data.campaigns.length === 0 ? (
                        <div className="p-2 border-top">No stats available </div>
                    ) : (
                        <table className="table table-card m-0">
                            <thead>
                                <tr className="text-secondary">
                                    <th width="25%">Campaign</th>
                                    <th width="25%">Affiliate</th>
                                    <th width="35%">Leads/Net Earnings</th>
                                    <th width="5%"></th>
                                    <th width="5%">Type</th>
                                    <th width="5%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.campaigns.map((item, index) => {
                                    return displayCapWatch(item, index)
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default AdvertiserCapWatch
