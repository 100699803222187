import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'

import callAPI from '../../../../../helpers/callAPI'
import Loading from '../../../../../templates/UI/Loading/Loading'
import AffiliateSearch from '../../../../../components/AffiliateSearch/AffiliateSearch'
import AdvertiserSearch from '../../../../../components/AdvertiserSearch/AdvertiserSearch'
import { Modal } from 'react-bootstrap'
import AdminPicker from '../../../../../components/AdminPicker/AdminPicker'
import CustomButton from '../../../../../templates/UI/CustomButton/CustomButton'
import { SystemMessageContext } from '../../../../../context/SystemMessageContext'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EditIssue = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [issueInfo, setIssueInfo] = useState({})
    const [actionType, setActionType] = useState('create')
    const [adminId, setAdminId] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    const { showSystemMessage } = useContext(SystemMessageContext)
    const [copied, setCopied] = useState('')
    const { issueId, manager, type } = props

    let createIssue = false

    if (issueId === 0) {
        createIssue = true
    }

    useEffect(() => {
        if (createIssue) {
            setDataLoaded(true)
            return
        }

        getIssueInfo()
    }, [issueId])

    const getIssueInfo = () => {
        let url = `issue/${issueId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setIssueInfo(result.issue_info)
                } else {
                    showSystemMessage('error', result.errors.message)
                }
                setDataLoaded(true)
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching issue info.')
            })
    }

    const submitForm = (e) => {
        e.preventDefault()
        setSubmitting(true)

        let newNotes = e.target.new_notes.value.trim() || ''
        let meetingWith = e.target.meeting_with.value.trim()
        let notes = ``

        if (newNotes.length > 0) {
            if (e.target.past_notes.value.length > 0) {
                notes = (e.target.past_notes.value || '')


                notes =

                    `${notes}
---------------------------------------
${manager.name} - ${moment().format('YYYY-MM-DD HH:mm:ss')} (${meetingWith})

${newNotes}`
            } else {
                notes =
                    `${manager.name} - ${moment().format('YYYY-MM-DD HH:mm:ss')} (${meetingWith})

${newNotes}`
            }
        } else {
            notes =
                `${manager.name} - ${moment().format('YYYY-MM-DD HH:mm:ss')} (${meetingWith})`
        }

        let postBody = {
            assignedTo: adminId > 0 ? adminId : issueInfo.assigned_to ? issueInfo.assigned_to : 0,
            advertiserId: issueInfo.advertiser ? issueInfo.advertiser.id : 0,
            affiliateId: issueInfo.affiliate ? issueInfo.affiliate.id : 0,
            title: e.target.title.value,
            description: e.target.description.value,
            priority: issueInfo.priority,
            notes: notes,
            actionType: actionType,
            meetingWith: meetingWith,
        }

        let url = `issue/${issueId}`

        callAPI(url, 'POST', { issueInfo: postBody })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setSubmitting(false)
                    if (props.type === 'page'){
                        getIssueInfo()
                    }
                    props.handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setSubmitting(false)
                }
            })
            .catch((error) => {
                console.log(`Error while editing issue: ${error}`)
                showSystemMessage('error', 'Connection Error')
                setSubmitting(false)
            })
    }

    const inputChangeHandler = (e) => {
        // console.log(e.target.name, e.target.value);
    }

    let buttons

    if (issueId === 0) {
        buttons = (
            <CustomButton
                type="submit"
                name="Create Issue"
                onClick={() => {
                    setActionType('create')
                }}
                spin={submitting}
            />
        )
    } else if (issueInfo.status === 'O') {
        buttons = (
            <>
                <CustomButton
                    type="submit"
                    name="Save Changes"
                    onClick={() => {
                        setActionType('save')
                    }}
                    spin={submitting}
                />
                <span className="mr-3"></span>
                <CustomButton
                    type="submit"
                    name="Save and Close Issue"
                    onClick={() => {
                        setActionType('close')
                    }}
                    spin={submitting}
                />
            </>
        )
    }

    return (
        <React.Fragment>
            {type !== 'page' && <Modal.Header closeButton>
                <Modal.Title>{createIssue ? 'Create Issue' : 'Edit Issue'}</Modal.Title>
            </Modal.Header>}

            <div className={`${type === 'page' ? 'row' : ''}`}>
                <div className={`${type === 'page' ? 'col-md-8' : ''}`}>
                    <div className="card">
                        {(type !== 'page' && !createIssue )&& <div className='alert alert-secondary m-3'>
                            Page Link : <Link to={`/issue/${issueId}`}>{`${process.env.REACT_APP_PORTAL_URL}/issue/${issueId}`}</Link>
                            <span className='ml-3'>
                                <CopyToClipboard text={`${process.env.REACT_APP_PORTAL_URL}/issue/${issueId}`} onCopy={() => setCopied(true)}>
                                    <button type="button" className="btn btn-outline-primary shadow-none" size="sm">
                                        <FontAwesomeIcon icon="copy" />
                                    </button>
                                </CopyToClipboard>
                            </span>
                        </div>}
                        <form onSubmit={submitForm} method="POST" className="form-group">
                            <div className="card-body p-3">
                                {dataLoaded ? (
                                    <table className="table table-card table-borderless w-100 m-0">
                                        <tbody>
                                            {!createIssue && (
                                                <>
                                                    <tr>
                                                        <td width="20%">Issue ID:</td>
                                                        <td width="80%">{issueInfo.issue_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Opened By:</td>
                                                        <td>
                                                            {issueInfo.full_name} on {moment(issueInfo).format('YYYY-MM-DD HH:mm:ss')}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status:</td>
                                                        <td>{issueInfo.status === 'O' ? `Open - Last Update on ${moment(issueInfo.last_update_date).format('YYYY-MM-DD HH:mm:ss')}` : `Closed on ${moment(issueInfo.closed_date).format('YYYY-MM-DD HH:mm:ss')}`}</td>
                                                    </tr>
                                                </>
                                            )}
                                            <tr>
                                                <td width="20%">&nbsp;</td>
                                                <td width="80%">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>Title:</td>
                                                <td>
                                                    <input name="title" defaultValue={_.unescape(issueInfo.title)} className="form-control form-control-sm" onChange={inputChangeHandler} required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Description:</td>
                                                <td>
                                                    <textarea name="description" defaultValue={_.unescape(issueInfo.description)} className="form-control" rows={6} onChange={inputChangeHandler} required />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>Assign To:</td>
                                                <td>
                                                    {/* <ManagerDropdown mgrId={issueInfo.assigned_to} mgrSelected={handleManagerChange} /> */}
                                                    <AdminPicker adminId={adminId > 0 ? adminId : issueInfo.assigned_to} setAdminId={setAdminId} required={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Priority:</td>
                                                <td>
                                                    <select className="form-control form-control-sm w-50" defaultValue={issueInfo.priority} onChange={(e) => setIssueInfo({ ...issueInfo, priority: e.target.value })} required>
                                                        <option value=""></option>
                                                        <option value={1}>High</option>
                                                        <option value={2}>Medium</option>
                                                        <option value={3}>Low</option>
                                                        <option value={4}>Campaign Request</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr className="align-items-center">
                                                <td>Advertiser:</td>
                                                <td>
                                                    <AdvertiserSearch advertiser={issueInfo.advertiser ? (issueInfo.advertiser.id ? { id: issueInfo.advertiser.id, name: issueInfo.advertiser.name } : false) : false} setFormData={setIssueInfo} order={2} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Affiliate:</td>
                                                <td>
                                                    <AffiliateSearch affiliate={issueInfo.affiliate ? (issueInfo.affiliate.id ? { id: issueInfo.affiliate.id, name: issueInfo.affiliate.name } : false) : false} setFormData={setIssueInfo} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>Past Notes:</td>
                                                <td>
                                                    <textarea name="past_notes" value={issueInfo.notes} className="form-control" rows={6} readOnly />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Met With:</td>
                                                <td>
                                                    <input name="meeting_with" type="text" className="form-control form-control-sm w-50" onChange={inputChangeHandler} required autoComplete="off" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>New Notes:</td>
                                                <td>
                                                    <textarea name="new_notes" defaultValue="" className="form-control form-control-sm" rows={6} onChange={inputChangeHandler} required={!createIssue} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>
                                                    {buttons}
                                                    <button type="button" name="cancel" className="btn btn-danger ml-3" onClick={props.handleClose}>
                                                        Cancel
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ) : (
                                    <Loading />
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditIssue
