import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'

import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import useMaxbountyEntities from '../../../hooks/useMaxbountyEntities'
import useQueryParams from '../../../hooks/useQueryParams'
import ReportAppliedFilters from '../../../templates/ReportAppliedFilters/ReportAppliedFilters'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import useCallApi from '../../../hooks/useCallApi'
import DownloadCSV from '../../../templates/DownloadCSV/DownloadCSV'
import moment from 'moment'
import AffiliateMonthEndBalancesReport from './AffiliateMonthEndBalancesReport'
// import ByAdvertiser from '../ByAdvertiser/ByAdvertiser'
// import ByAffiliate from './ByAffiliate/ByAffiliate'
// import ByCampaign from './ByCampaign/ByCampaign'
// import ByReverser from './ByReverser/ByReverser'

const AffiliateMonthEndBalances = ({ showSystemMessage, type }) => {
    document.title = 'Affiliate Month-End Balances'
    const [monthEndDate, setMonthEndDate] = useState(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))

    const { data: datesData, dataLoaded: datesDataLoaded } = useCallApi('affiliates/monthEndDates', 'GET', {}, [], showSystemMessage)
    const { data, dataLoaded, refetch } = useCallApi(`affiliates/balanceReport?me=${monthEndDate}`, 'GET', {}, [], showSystemMessage)

    let reportComponent = <AffiliateMonthEndBalancesReport showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />

    useEffect(() => {
        refetch(`affiliates/balanceReport?me=${monthEndDate}`)
    }, [monthEndDate])

    return (
        <div>
            <div>
                <div className="page-header d-flex align-items-center">
                    <h1 className="mb-h1">Affiliate Month-End Balances</h1>
                </div>
                <div className="card">
                    <div className="card-body p-0">
                        <div className="d-flex align-items-center justify-content-between p-3">
                            <div className="d-flex align-items-center justify-content-start w-25">
                                <h1 className="mb-h1-dark mr-2">Month End</h1>
                                <div className="mr-2 w-50">
                                <select className="custom-select custom-select-sm" value={monthEndDate} onChange={(e) => setMonthEndDate(e.target.value)}>
                                    {datesDataLoaded && datesData.balanceReport.map((date, index) => <option key={index} value={date}>{moment(date).format('YYYY-MMMM')}</option>)}
                                </select>
                                </div>
                            </div>

                            <div>{dataLoaded && data.success && <DownloadCSV filename={`Affiliate Month-End Balances - ${moment(monthEndDate).format('YYYY-MMM-DD')}`} headerLabels={['Affiliate ID', 'Name', 'Current Status', 'Signup Date', 'Month End Date', 'Balance', 'MM Balance', 'Pay Status', 'Hold Status']} data={data.balanceReport} dataLabels={['affiliate_id', 'name', 'aff_status', 'signup_date', 'month_end_date', 'balance', 'mm_balance', 'pay_status', 'hold_status']} />}</div>
                        </div>
                        <div>
                            {reportComponent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AffiliateMonthEndBalances