import AdminPicker from '../../../components/AdminPicker/AdminPicker'

import CustomFlags from '../../../components/CustomFlags/CustomFlags'
import affiliatePriorities from '../../../data/AffiliatePriorities'
import affiliateStatuses from '../../../data/AffiliateStatuses'
import Countries from '../../Avatar/AvatarSurvey/Survey/templates/Countries'

const SearchOptions = ({ status, setStatus, adminId, country, setCountry, setAdminId, flagId, setFlagId, priority, setPriority, prevPriority, setPrevPriority, maxPriority, setMaxPriority, incentive, network, trafficNotes, setIncentive, setNetwork, setTrafficNotes, showSystemMessage, experience, setExperience }) => {
    return (
        <table className="table table-card text-nowrap">
            <tbody style={{ lineHeight: '40px' }}>
                <tr>
                    <td className="d-flex">
                        <span className="d-flex align-items-center ml-3">
                            Affiliate Manager &nbsp;&nbsp;&nbsp;
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Priority &nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="priority" defaultValue={priority} onChange={(e) => setPriority(e.target.value)}>
                                <option value="">Any</option>
                                {affiliatePriorities.affiliatePriorities.map((priority, index) => {
                                    return <option value={`${priority.value}`} key={`${index}-${priority.value}`}>{priority.text}</option>
                                })}
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Prev Priority &nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="prev_priority" defaultValue={prevPriority} onChange={(e) => setPrevPriority(e.target.value)}>
                                <option value="">Any</option>
                                {affiliatePriorities.affiliatePriorities.map((priority, index) => {
                                    return <option value={`${priority.value}`} key={`${index}-${priority.value}`}>{priority.text}</option>
                                })}
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Max Priority &nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="prev_priority" defaultValue={maxPriority} onChange={(e) => setMaxPriority(e.target.value)}>
                                <option value="">Any</option>
                                {affiliatePriorities.affiliatePriorities.map((priority, index) => {
                                    return <option value={`${priority.value}`} key={`${index}-${priority.value}`}>{priority.text}</option>
                                })}
                            </select>
                        </span>
                        {/* REFACTOR - could use value ='A' and so on instead of full text */}
                        <span className="d-flex align-items-center ml-3">
                            Status&nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="status" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="">Any</option>
                                {affiliateStatuses.affiliateStatuses.map((status, index) => {
                                    return <option value={`${status.value}`} key={`${index}-${status.value}`}>{status.text}</option>
                                })}
                            </select>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="d-flex">
                        <span className="d-flex align-items-center ml-3">
                            Incentive Affiliate&nbsp;&nbsp;
                            <select name="incentive" className="form-control" defaultValue={incentive} onChange={(e) => setIncentive(e.target.value === 'Y' ? true : false)}>
                                <option value="">Any</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Network Affiliate&nbsp;&nbsp;&nbsp;
                            <select name="network" className="form-control" defaultValue={network} onChange={(e) => setNetwork(e.target.value === 'Y' ? true : false)}>
                                <option value="">Any</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Country&nbsp;&nbsp;&nbsp;
                            <select name="country" className="form-control" defaultValue={country} onChange={(e) => setCountry(e.target.value)}>
                                <option value="">Select Country</option>
                                <option value="tier1">Tier 1</option>
                                <Countries />
                            </select>
                        </span>
                        <span className='d-flex align-items-center ml-3'>
                            Experience Level&nbsp;&nbsp;&nbsp;
                            <select name="experience_level" className="form-control" defaultValue={experience} onChange={(e) => setExperience(e.target.value)}>
                                <option value="">Any</option>
                                <option value="4">High/Medium</option>
                                <option value="3">High</option>
                                <option value="2">Medium</option>
                                <option value="1">Little</option>
                                <option value="0">No Experience</option>
                            </select>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="d-flex">
                        <span className="d-flex align-items-center ml-3">
                            Custom Flag&nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="flag" defaultValue={flagId} onChange={(e) => setFlagId(e.target.value)}>
                                <option value="">Select Flag</option>
                                <CustomFlags target="affiliate" showSystemMessage={showSystemMessage} />
                            </select>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="d-flex">
                        <span className="d-flex align-items-center ml-3">
                            Traffic Notes Only &nbsp;&nbsp;&nbsp;
                            <select name="traffic_notes" className="form-control" defaultValue={trafficNotes} onChange={(e) => setTrafficNotes(e.target.value === 'Y' ? true : false)}>
                                <option value="">Any</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default SearchOptions
